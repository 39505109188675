/*
 * Copyright (C) 2023 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import { useCallback } from 'react';
import { getWalletConfig, redirectUri, setWalletConfig } from '../ssi-libs';
import { useNavigate } from 'react-router-dom';
import { routes } from '../routes';
import { CopyToClipboard } from '../components/CopyToClipboard';
import { ClearLocalStorage } from '../components/ClearLocalStorage';

export function Settings(): JSX.Element {
  const walletConfig = getWalletConfig();
  const navigate = useNavigate();
  const handleSubmit = useCallback(
    ev => {
      ev.preventDefault();
      const formData = new FormData(ev.target);
      // input type and validation should ensure correct type for 'id' and 'secret'
      const id = formData.get('id') as string;
      const secret = formData.get('secret') as string;
      const preRegisteredClientId = formData.get('pre-registered-client-id') as string;
      const preRegisteredClientJwksUri = formData.get('pre-registered-client-jwks') as string;
      setWalletConfig({
        oauthClient: {
          id: id,
          secret: secret,
        },
        preRegisteredClient: {
          id: preRegisteredClientId,
          jwksUri: preRegisteredClientJwksUri,
        },
      });
      navigate(routes.HOME);
    },
    [navigate]
  );
  return (
    <>
      <h2>OAuth 2.0 client settings</h2>
      <form onSubmit={ev => handleSubmit(ev)}>
        {/* client ID */}
        <label className="block" htmlFor="oauth-client-id">
          Client ID
        </label>
        <input
          type="text"
          placeholder="OAuth client ID"
          className="field w100"
          name="id"
          id="oauth-client-id"
          required
          defaultValue={walletConfig.oauthClient.id}
        />
        {/* client secret */}
        <label className="block" htmlFor="oauth-client-secret">
          Client Secret
        </label>
        <input
          type="password"
          placeholder="OAuth client secret"
          className="field w100"
          name="secret"
          id="oauth-client-secret"
          required
          defaultValue={walletConfig.oauthClient.secret}
        />
        {/* info about the redirect URI */}
        <div className="mb2">
          <p>Make sure the following redirect URI is configured for this client on the used Authorization Servers:</p>
          <div className="flex flex-gap-1 flex-center justify-between">
            <pre>
              <code>{redirectUri}</code>
            </pre>
            <div>
              <CopyToClipboard textToCopy={redirectUri} />
            </div>
          </div>
        </div>
        <h2>Pre-Registered Client Settings</h2>
        <label className="block" htmlFor="pre-registered-client-id">
          Client ID
        </label>
        <input
          type="text"
          placeholder="Pre-registered client ID"
          className="field w100"
          name="pre-registered-client-id"
          id="pre-registered-client-id"
          required
          defaultValue={walletConfig.preRegisteredClient?.id}
        />
        <label className="block" htmlFor="pre-registered-client-jwks">
          JWKS URI
        </label>
        <input
          type="text"
          placeholder="Pre-registered client JWKS URI"
          className="field w100"
          name="pre-registered-client-jwks"
          id="pre-registered-client-jwks"
          required
          defaultValue={walletConfig.preRegisteredClient?.jwksUri}
        />
        <div className="flex flex-column flex-gap-2 flex-align-normal">
          <input className="button button-medium button-primary " type="submit" value="Save" />
        </div>

        <fieldset className="mt2">
          <legend>Clear App Data</legend>
          <p>Removes all the Local Storage items for the current domain. </p>
          <ClearLocalStorage />
        </fieldset>
      </form>
    </>
  );
}
