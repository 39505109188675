import { getCredentialTypeColor } from '../getCredentialTypeColor';
import * as styles from './credential-card.module.css';
import { ReactNode } from 'react';

interface CredentialCardProps {
  name: string;
  mode: string;
  children?: ReactNode;
}

export const CredentialCard: React.FC<CredentialCardProps> = ({ name, mode, children }) => {
  return (
    <div
      className={`br-8 ${styles['wallet-card']} ${styles['wallet-card']}-${mode} ${styles['fade-in']}`}
      style={{ backgroundImage: `${getCredentialTypeColor(name.charAt(0)).toString()}` }}
    >
      {children}
    </div>
  );
};
