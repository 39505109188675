/*
 * Copyright (C) 2023 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

package io.curity.vc.services

import io.curity.oauth.OAuthRefreshFlowClient
import io.curity.oauth.OAuthSuccessTokenResponse
import io.ktor.client.HttpClient
import io.ktor.client.call.HttpClientCall
import io.ktor.client.plugins.HttpSend
import io.ktor.client.plugins.Sender
import io.ktor.client.plugins.plugin
import io.ktor.client.request.HttpRequestBuilder
import io.ktor.client.request.header

/**
 * KTor HttpClient interceptor for OAuth 2.0:
 * - Adds an existing access token to an outgoing request
 * - Automatically tries to refresh a denied access token, using the refresh token grant
 */
// TODO take advantage of access token expiration information
class OAuthTokenInterceptor(
    initialTokenResponse: OAuthSuccessTokenResponse<*>,
    private val refreshFlowClient: OAuthRefreshFlowClient,
) {
    private var currentTokenResponse: OAuthSuccessTokenResponse<*> = initialTokenResponse

    suspend fun intercept(sender: Sender, request: HttpRequestBuilder): HttpClientCall {
        val isNewToken = withToken(request)
        val call = sender.execute(request)
        if (call.response.status.value in 401..403 && !isNewToken) {
            // the token was not accepted, and we did not just request one, try again
            return if (withToken(request, force = true)) {
                sender.execute(request)
            } else {
                call
            }
        }
        return call
    }

    private suspend fun withToken(request: HttpRequestBuilder, force: Boolean = false): Boolean {
        var requested = false
        val refreshToken = currentTokenResponse.refreshToken
        if (force && refreshToken == null) {
            return false
        }
        val tokenResponse = if (force && refreshToken != null) {
            requested = true
            refreshFlowClient.requestToken(refreshToken)
        } else {
            currentTokenResponse
        }
        request.header("Authorization", "Bearer ${tokenResponse.accessToken}")
        if (requested) {
            currentTokenResponse = tokenResponse
        }
        return requested
    }
}

fun HttpClient.installOAuthSupport(
    initialTokenResponse: OAuthSuccessTokenResponse<*>,
    refreshFlowClient: OAuthRefreshFlowClient,
) {
    val oauthInterceptor = OAuthTokenInterceptor(
        initialTokenResponse,
        refreshFlowClient,
    )
    plugin(HttpSend).intercept(oauthInterceptor::intercept)
}
