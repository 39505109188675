.header {
  height: 48px;
  top: 0;
  left: 0;
  border-bottom: 1px solid var(--color-grey-light);
}

.header-button {
  position: absolute;
  left: 0;
}
