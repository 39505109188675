/*
 * Copyright (C) 2023 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

package io.curity.ssi.json.data

import io.curity.ssi.data.Dictionary
import io.curity.ssi.data.MultiValue
import io.curity.ssi.data.ToString
import kotlinx.serialization.KSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.json.Json
import kotlinx.serialization.json.JsonArray
import kotlinx.serialization.json.JsonElement
import kotlinx.serialization.json.JsonObject
import kotlinx.serialization.json.JsonPrimitive

/**
 * Helper object that makes implementing [FromJson] easy.
 */
object FromJsonHelper {
    fun requireJsonObject(json: JsonElement, type: String): JsonObject {
        if (json is JsonObject) return json
        error("Cannot deserialize '$type', not an object")
    }

    fun requireJsonArray(json: JsonElement, type: String): JsonArray {
        if (json is JsonArray) return json
        error("Cannot deserialize '$type', not an array")
    }

    fun requireJsonString(json: JsonElement, type: String): JsonPrimitive {
        if (json is JsonPrimitive && json.isString) return json
        error("Cannot deserialize '$type', not a String")
    }
}

/**
 * Interface that should be implemented by the companion object of all types that implement
 * [ToJson].
 *
 * See [FromJsonHelper].
 */
interface FromJson<T : ToJson> {
    fun fromJson(json: JsonElement): T
}

/**
 * Base class for types that want to implement [FromJson] for type [T]
 * but wish to re-use another simpler type [S] (a type that has fewer constraints)
 * that is also serializable for the basic serialization format.
 */
abstract class CompositeFromJson<S : ToJson, T : ToJson>(
    private val _fromJson: FromJson<S>
) : FromJson<T> {
    abstract fun fromSerialized(toJson: S): T

    override fun fromJson(json: JsonElement): T {
        val serialized = _fromJson.fromJson(json)
        return fromSerialized(serialized)
    }
}

/**
 * Interface implemented by all types that serialize to a pure JSON representation.
 *
 * It is recommended that all types `T` implementing this interface should provide a
 * companion object that implements [FromJson], allowing
 * conversion to/from JSON to go both ways.
 */
interface ToJson {
    companion object : FromJson<ToJson> {
        override fun fromJson(json: JsonElement): DefaultToJson = DefaultToJson.fromJson(json)
    }

    fun toJson(): JsonElement
}

fun String.toJson() = JsonPrimitive(this)
fun Number.toJson() = JsonPrimitive(this)
fun Boolean.toJson() = JsonPrimitive(this)

fun List<String>.toJson() = JsonArray(map { it.toJson() })
fun List<ToJson>.toJsons() = JsonArray(map { it.toJson() })

fun Set<String>.toJson() = JsonArray(map { it.toJson() })
fun Set<ToJson>.toJsons() = JsonArray(map { it.toJson() })

data class DefaultToJson(val json: JsonElement) : ToJson {
    companion object : FromJson<ToJson> {
        override fun fromJson(json: JsonElement): DefaultToJson {
            return DefaultToJson(json)
        }
    }

    override fun toJson() = json
}

/**
 * String wrapper type to let any string be used in places where we don't currently know which string
 * values should be possible.
 *
 * The idea is that where the valid String values are known, enums can be used instead of Strings
 * so that all allowed string values are clearly enumerated.
 */
@Serializable(with = AnyStringSerializer::class)
data class AnyString(val value: String) : ToString, ToJson {
    companion object : FromJson<AnyString> {
        override fun fromJson(json: JsonElement): AnyString {
            return AnyString(FromJsonHelper.requireJsonString(json, "AnyString").toString())
        }
    }

    override fun toJson() = JsonPrimitive(value)

    override fun toString() = value
}

/**
 * Interface for types that must be serialized to [Dictionary<JsonElement>].
 *
 * In other words, types that serialize to [JsonObject] with possibly unknown claims
 * may implement this interface so that they can be easily serialized.
 */
@Serializable(with = ToJsonDictionarySerializer::class)
interface ToJsonDictionary : Dictionary<JsonElement>, ToJson {
    fun toJsonDictionary(): JsonDictionary

    override val keys: Set<String>
        get() = toJsonDictionary().keys

    override fun get(key: String): JsonElement? = toJsonDictionary()[key]

    override fun toJson(): JsonObject = JsonObject(toMap())
}

/**
 * The default class used to de-serialize [ToJsonDictionary].
 */
@Serializable(JsonDictionarySerializer::class)
data class JsonDictionary(
    private val _claims: Map<String, JsonElement>,
) : ToJsonDictionary {

    companion object : FromJson<JsonDictionary> {
        override fun fromJson(json: JsonElement): JsonDictionary =
            JsonDictionary(FromJsonHelper.requireJsonObject(json, "JsonDictionary").toMap())
    }

    override val keys: Set<String>
        get() = _claims.keys

    override fun get(key: String): JsonElement? = _claims[key]

    override fun toMap(): Map<String, JsonElement> = _claims

    override fun toJsonDictionary() = this
}

/**
 * Helper class that can be used to implement [FromJson] for [ToJsonDictionary]
 * for types that have a discriminator claim (given by [_typeKey]) that gives its `type`.
 */
class TypedDictionaryFromJson(private val _typeKey: String) : FromJson<TypedJsonDictionary> {
    override fun fromJson(json: JsonElement): TypedJsonDictionary {
        val map = FromJsonHelper.requireJsonObject(json, "TypedJsonDictionary").toMutableMap()
        val type = map.unwrapString(_typeKey)
        return TypedJsonDictionary(_typeKey, type, if (map.isEmpty()) null else JsonDictionary(map))
    }

    fun toJson(type: String, customClaims: JsonDictionary? = null): JsonDictionary {
        return JsonDictionary(buildMap {
            if (customClaims != null) putAll(customClaims.toMap())
            put(_typeKey, JsonPrimitive(type))
        })
    }
}

/**
 * An implementation of [ToJsonDictionary] that requires a `type` claim called [typeKey].
 *
 * The [typeKey] claim is separated from any others, which are available in the optional [customClaims] dictionary.
 */
data class TypedJsonDictionary(
    val typeKey: String,
    val type: String,
    val customClaims: JsonDictionary? = null
) : ToJsonDictionary {

    private val _serializable by lazy {
        JsonDictionary(
            buildMap {
                if (customClaims != null) putAll(customClaims.toMap())
                put(typeKey, JsonPrimitive(type))
            }
        )
    }

    override fun toJsonDictionary() = _serializable
}

/**
 * Default implementation of [MultiValue].
 *
 * This type does not enforce that elements have a serializable type, hence instances of
 * [DefaultJsonMultiValue] are only serializable if their elements are serializable.
 */
@Serializable(with = DefaultJsonMultiValueSerializer::class)
data class DefaultJsonMultiValue<T> internal constructor(
    private val _first: T,
    private val _rest: List<T>,
) : MultiValue<T> {

    companion object {
        fun <T> fromJson(json: JsonElement, serializer: KSerializer<T>): DefaultJsonMultiValue<T> {
            return when (json) {
                is JsonArray -> DefaultJsonMultiValue(
                    json.map { Json.decodeFromJsonElement(serializer, it) })

                else -> DefaultJsonMultiValue(Json.decodeFromJsonElement(serializer, json))
            }
        }
    }

    /**
     * Create a single-item [MultiValue].
     */
    constructor(singleItem: T) :
            this(singleItem, emptyList())

    /**
     * Create a many-item [MultiValue].
     *
     * An error occurs if the provided list is empty.
     */
    constructor(many: Iterable<T>) :
            this(many.first(), many.drop(1).toList())

    override val first = _first

    override val rest = _rest

    override fun <V> map(mapper: (T) -> V): MultiValue<V> {
        return MultiValue.DefaultMultiValue(all.map(mapper))
    }

    fun toJson(valueMapper: (T) -> JsonElement): JsonElement {
        return if (isSingleItem()) valueMapper(first) else JsonArray(all.map { valueMapper(it) })
    }
}

fun MultiValue<out ToJson>.toJson(): JsonElement {
    return DefaultJsonMultiValue(all).toJson { it.toJson() }
}
