/*
 * Copyright (C) 2023 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

@file:OptIn(ExperimentalJsExport::class)

// Because we want these symbols to be in the root package, for better usage from JS
@file:Suppress("PackageDirectoryMismatch")

package vc_js.services

import JsVcClient
import io.curity.oauth.OAuthAuthorizationRequest
import io.curity.oauth.OAuthClient
import io.curity.oauth.OAuthCodeFlowClient
import io.curity.oauth.OAuthSuccessTokenResponse
import io.curity.oauth.OAuthSupport
import io.curity.oauth.appendParameters
import io.ktor.client.engine.js.Js
import io.ktor.http.formUrlEncode
import io.ktor.http.parameters
import kotlinx.browser.window
import kotlinx.coroutines.DelicateCoroutinesApi
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.channels.Channel
import kotlinx.coroutines.currentCoroutineContext
import kotlinx.coroutines.launch
import kotlinx.coroutines.withTimeout
import org.w3c.dom.MessageEvent
import org.w3c.dom.events.EventListener
import org.w3c.dom.url.URLSearchParams
import kotlin.time.DurationUnit
import kotlin.time.toDuration

/**
 * Create a [JsOAuthSupport] instance which can then be used to create an HTTP Client type
 */
@JsExport
fun createBrowserWindowOAuthSupport(
    authorizationRequest: OAuthAuthorizationRequest,
    oauthClient: OAuthClient,
    authorizationEndpoint: String,
    tokenEndpoint: String,
    /** Optional callback to be notified when authorization is completed. */
    onAuthorizationDone: () -> Unit = {}
): JsOAuthSupport {
    return JsOAuthSupport(
        BrowserWindowOAuthSupport(
            authorizationRequest, oauthClient,
            authorizationEndpoint, tokenEndpoint
        )
    )
}

/**
 * This is an opaque type in JavaScript, but can be used for safely creating an instance of [OAuthSupport],
 * then providing that to the [JsVcClient] constructor.
 */
@JsExport
class JsOAuthSupport internal constructor(internal val delegate: OAuthSupport)

/**
 * Implementation of [OAuthSupport] that relies on a new browser window to authorize and authenticate a user.
 *
 */
private class BrowserWindowOAuthSupport(
    val authorizationRequest: OAuthAuthorizationRequest,
    val oauthClient: OAuthClient,
    val authorizationEndpoint: String,
    val tokenEndpoint: String,
) : OAuthSupport {

    @OptIn(DelicateCoroutinesApi::class)
    override suspend fun getToken(previous: OAuthSuccessTokenResponse<*>?): OAuthSuccessTokenResponse<*> {
        // TODO try to refresh previous token?

        val authorizationRequestParams = parameters {
            appendParameters(authorizationRequest)
        }.formUrlEncode()

        // we open the authorization URL on a browser window, then wait for the OAuth callback
        // to post a message back to us with the authorization response
        val externalWindow = window.open("$authorizationEndpoint?$authorizationRequestParams")
            ?: throw Exception("Could not open window for authorization")

        // channel to be completed once the message is posted back from the OAuth callback handler
        val channel = Channel<OAuthSuccessTokenResponse<*>>(capacity = 1)

        val context = currentCoroutineContext()

        // TODO add options argument so that the even-listener is cancelled once the message is received
        window.addEventListener("message", EventListener { event ->
            event as MessageEvent
            if (channel.isClosedForReceive) {
                return@EventListener
            }
            val data = event.data
            if (event.source == externalWindow && data is String) {
                externalWindow.close()
                // TODO confirm whether this coroutine context is appropriate and works in the browser.
                GlobalScope.launch(context) {
                    try {
                        val callbackParameters = URLSearchParams(data)
                        val tokenResponse = OAuthCodeFlowClient(Js.create(), oauthClient, tokenEndpoint)
                            .requestToken(
                                callbackParameters.get("code")
                                    ?: throw Exception("OAuth callback parameters missing 'code'")
                            )
                        channel.send(tokenResponse)
                        channel.close()
                    } catch (e: Exception) {
                        channel.close(e)
                    }
                }
            }
        }, null)

        // wait for authorization/authentication
        return withTimeout(5.toDuration(DurationUnit.MINUTES)) {
            channel.receive()
        }
    }
}
