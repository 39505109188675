/*
 * Copyright (C) 2023 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

package io.curity.ssi.did.document

import io.curity.ssi.PublicApi

@PublicApi
class DidResolutionException(
    message: String,
    cause: Exception? = null,
) : Exception(message, cause)