.footer {
    --height: 68px;

    height: var(--height);
    padding-bottom: 1rem;
    bottom: 0;
    left: 0;
    border-top: 1px solid var(--color-grey-light);
    background-color: white;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
}

@media screen and (width >=600px) {
    .footer {
        --height: 48px;

        padding-bottom: 0;
    }
}

a.footer-button-qrcode {
    background-color: var(--color-spot);
    border-radius: 50%;
    padding: 0;
}
