/*
 * Copyright (C) 2023 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

export const routes = {
  HOME: '/',
  ABOUT: '/about',
  OFFER: '/offer',
  SCAN: '/start/scan',
  ISSUER_LIST: '/issuers',
  SETTINGS: '/settings',
  ISSUER_DETAIL: '/issuers/id/:id',
  ISSUER_ADD: '/issuers/add',
  CREDENTIAL_ISSUANCE: '/issuers/id/:id/cs/:credentialId/issue',
  OAUTH_CALLBACK: '/oauth/callback',
  CREDENTIAL_LIST: '/credentials',
  CREDENTIAL_DETAIL: '/credentials/:id',
  START: '/start',
  OFFER_RESOLVE: '/offer/resolve',
  PRESENTATION_RESOLVE: '/presentation/resolve/:id?',
  TO_ISSUER_DETAIL: function (id: string) {
    return this.ISSUER_DETAIL.replace(':id', id);
  },
  TO_CREDENTIAL_ISSUANCE: function (issuerId: string, credentialId: string) {
    return this.CREDENTIAL_ISSUANCE.replace(':id', encodeURIComponent(issuerId)).replace(
      ':credentialId',
      encodeURIComponent(credentialId)
    );
  },
  TO_CREDENTIAL_DETAIL: function (id: string) {
    return this.CREDENTIAL_DETAIL.replace(':id', id);
  },
  TO_OFFER_RESOLVE: function (offerRequest: string) {
    return this.OFFER_RESOLVE + '?' + offerRequest;
  },
  TO_PRESENTATION_RESOLVE: function (presentationRequest: string) {
    return this.PRESENTATION_RESOLVE.replace(':id?', '') + '?' + presentationRequest;
  },
  TO_PRESENTATION_RESOLVE_WITH_ID: function (id: string) {
    return this.PRESENTATION_RESOLVE.replace(':id?', id);
  },
};
