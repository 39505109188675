import { createContext, useContext, useEffect, useState } from 'react';

/*
 * TODO remove the array spread ([...navigator.languages]) when Kotlin JS correcty generates
 * TS signatures with ReadonlyArray
 */

export const LanguagesContext = createContext({ languages: [...navigator.languages] });

export function useLanguages(): Array<string> {
  return useContext(LanguagesContext).languages;
}

export function LanguagesProvider({ children }) {
  const [languages, setLanguages] = useState([...navigator.languages]);
  useEffect(() => {
    const abortController = new AbortController();
    window.addEventListener('languagechange', () => setLanguages([...navigator.languages]), {
      signal: abortController.signal,
    });
    return () => {
      abortController.abort();
    };
  }, []);
  return <LanguagesContext.Provider value={{ languages: languages }}>{children}</LanguagesContext.Provider>;
}
