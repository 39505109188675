import { useEffect } from 'react';
import CookieConsent, { Cookies, getCookieConsentValue } from 'react-cookie-consent';
import ReactGA from 'react-ga4';
import './analytics.css';

const PRODUCTION_HOST = 'wallet.decentralized-identity.io';

const initGA = (id: string) => {
  if (PRODUCTION_HOST === location.host) {
    ReactGA.initialize(id);
  }
};

export const Analytics: React.FC = () => {
  const handleAcceptCookie = (): void => {
    if (process.env.GOOGLE_ANALYTICS_ID) {
      initGA(process.env.GOOGLE_ANALYTICS_ID);
    }
  };
  const handleDeclineCookie = (): void => {
    Cookies.remove('_ga');
    Cookies.remove('_gat');
    Cookies.remove('_gid');
  };
  useEffect(() => {
    const isConsent: string | undefined = getCookieConsentValue();
    if (isConsent === 'true') {
      handleAcceptCookie();
    }
  }, []);
  return (
    <CookieConsent
      disableStyles={true}
      enableDeclineButton
      onAccept={handleAcceptCookie}
      onDecline={handleDeclineCookie}
    >
      We use cookies to enhance your online experience and help personalize your visit so you are receiving the most
      relevant content.{' '}
      <a href="https://curity.io/terms-of-service/" target="_blank" rel="noreferrer">
        Privacy Policy
      </a>
    </CookieConsent>
  );
};
