/*
 * Copyright (C) 2024 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

package io.curity.vc

import io.curity.ssi.crypto.SigningKeyPair
import io.curity.ssi.did.DidUrl
import io.curity.ssi.jose.JsonJwsHeader
import io.curity.ssi.jose.JsonJwtClaimsSet
import io.curity.ssi.jose.JwsUtil
import io.curity.ssi.json.data.DefaultJsonMultiValue
import kotlinx.datetime.Instant
import kotlinx.serialization.json.JsonElement

object ProofOfPossessionBuilder {

    /**
     * Creates a POP token using a [DidUrl] as the key information.
     * Typically used with W3C DM credentials.
     */
    suspend fun createJwtProofTokenUsingDid(
        signingKeyPair: SigningKeyPair,
        kid: DidUrl,
        alg: String,
        iss: String,
        aud: DefaultJsonMultiValue<String>,
        iat: Instant,
        additionalClaims: Map<String, JsonElement>
    ): String {
        val jwsHeader = JsonJwsHeader(
            typ = "openid4vci-proof+jwt",
            alg = alg,
            kid = kid.toString()
        )

        val jwsPayload = JsonJwtClaimsSet(
            iss = iss,
            aud = aud,
            iat = iat.epochSeconds,
            customClaims = additionalClaims
        )

        return JwsUtil.build(signingKeyPair.signingKey, jwsHeader, jwsPayload)
    }

    /**
     * Creates a POP token using a JWK as the key information.
     * Typically used with SD-JWT credentials.
     */
    suspend fun createJwtProofTokenUsingJwk(
        signingKeyPair: SigningKeyPair,
        alg: String,
        iss: String,
        aud: DefaultJsonMultiValue<String>,
        iat: Instant,
        additionalClaims: Map<String, JsonElement>
    ): String {
        val jwsHeader = JsonJwsHeader(
            typ = "openid4vci-proof+jwt",
            alg = alg,
            jwk = signingKeyPair.verificationKey.jwk
        )

        val jwsPayload = JsonJwtClaimsSet(
            iss = iss,
            aud = aud,
            iat = iat.epochSeconds,
            customClaims = additionalClaims
        )

        return JwsUtil.build(signingKeyPair.signingKey, jwsHeader, jwsPayload)
    }
}