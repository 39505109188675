/*
 * Copyright (C) 2023 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

package io.curity.oauth

import kotlin.js.ExperimentalJsExport
import kotlin.js.JsExport

/**
 * Representation of an OAuth 2.0 client
 */
@OptIn(ExperimentalJsExport::class)
@JsExport
data class OAuthClient(
    val id: String,
    val redirectUri: String,
    val secret: String,
)