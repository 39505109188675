/*
 * Copyright (C) 2023 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import { Footer } from '../footer/Footer';
import { Outlet } from 'react-router-dom';
import * as classes from './layout.module.css';

export const Layout = () => (
  <div className={`${classes['layout']} relative`}>
    <Outlet />
    <Footer />
  </div>
);
