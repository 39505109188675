/*
 * Copyright (C) 2023 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

package io.curity.vc.serialization

import io.curity.ssi.json.data.FromJson
import io.curity.ssi.json.data.FromJsonHelper
import io.curity.ssi.json.data.JsonDictionary
import io.curity.ssi.json.data.ToJsonDictionary
import io.curity.ssi.json.data.toJson
import io.curity.ssi.json.data.toJsons
import io.curity.ssi.json.data.unwrapOptionalString
import io.curity.ssi.json.data.unwrapOptionalStringList
import io.curity.ssi.json.data.unwrapString
import io.curity.vc.Displayable
import io.curity.vc.Image
import io.curity.vc.Logo
import io.curity.vc.Metadata
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.json.JsonElement

/*
 * Contains format-independent metadata types
 */

@Serializable
data class JsonCredentialIssuerMetadata(
    @SerialName(Metadata.CredentialIssuerMetadata.Keys.AUTHORIZATION_SERVERS)
    override val authorizationServers: List<String>? = null,
    @SerialName(Metadata.CredentialIssuerMetadata.Keys.CREDENTIAL_ENDPOINT)
    override val credentialEndpoint: String,
    @SerialName(Metadata.CredentialIssuerMetadata.Keys.BATCH_CREDENTIAL_ENDPOINT)
    override val batchCredentialEndpoint: String? = null,
    @SerialName(Metadata.CredentialIssuerMetadata.Keys.DEFERRED_CREDENTIAL_ENDPOINT)
    override val deferredCredentialEndpoint: String? = null,
    @SerialName(Metadata.CredentialIssuerMetadata.Keys.NOTIFICATION_ENDPOINT)
    override val notificationEndpoint: String? = null,
    @SerialName(Metadata.CredentialIssuerMetadata.Keys.CREDENTIAL_CONFIGURATION_SUPPORTED)
    override val credentialConfigurationsSupported: Map<String, JsonCredentialConfigurationsSupported>,
    @SerialName(Metadata.CredentialIssuerMetadata.Keys.CREDENTIAL_ISSUER)
    override val credentialIssuer: String,
    @SerialName(Metadata.CredentialIssuerMetadata.Keys.DISPLAY)
    override val display: List<JsonIssuerDisplay>? = null,
    @SerialName(Metadata.CredentialIssuerMetadata.Keys.CREDENTIAL_RESPONSE_ENCRYPTION)
    override val credentialResponseEncryption: DefaultCredentialResponseEncryption? = null,
    @SerialName(Metadata.CredentialIssuerMetadata.Keys.CREDENTIAL_IDENTIFIERS_SUPPORTED)
    override val credentialIdentifiersSupported: Boolean? = null,
    @SerialName(Metadata.CredentialIssuerMetadata.Keys.SIGNED_METADATA)
    override val signedMetadata: String? = null,
) : Metadata.CredentialIssuerMetadata<JsonElement>

@Serializable
data class DefaultCredentialResponseEncryption(
    @SerialName(Metadata.CredentialResponseEncryption.Keys.ALG_VALUES_SUPPORTED)
    override val algValuesSupported: List<String>,
    @SerialName(Metadata.CredentialResponseEncryption.Keys.ENC_VALUES_SUPPORTED)
    override val encValuesSupported: List<String>,
    @SerialName(Metadata.CredentialResponseEncryption.Keys.ENCRYPTION_REQUIRED)
    override val encryptionRequired: Boolean
) : Metadata.CredentialResponseEncryption

@Serializable(with = JsonDisplayableSerializer::class)
data class JsonDisplayable(
    override val name: String? = null,
    override val locale: String? = null,
    val customClaims: ToJsonDictionary = JsonDictionary(emptyMap()),
) : ToJsonDictionary, Displayable<JsonElement> {

    companion object : FromJson<JsonDisplayable> {
        override fun fromJson(json: JsonElement): JsonDisplayable {
            val map = FromJsonHelper.requireJsonObject(json, "JsonDisplayable").toMutableMap()
            return JsonDisplayable(
                name = map.unwrapString(Displayable.Keys.NAME),
                locale = map.unwrapOptionalString(Displayable.Keys.LOCALE),
                customClaims = JsonDictionary(map.toMap())
            )
        }
    }

    private val _serializable by lazy {
        JsonDictionary(buildMap {
            putAll(customClaims.toJson())
            if (name != null) put(Displayable.Keys.NAME, name.toJson())
            if (locale != null) put(Displayable.Keys.LOCALE, locale.toJson())
        })
    }

    override fun toJsonDictionary(): JsonDictionary = _serializable
}

@Serializable(with = JsonIssuerDisplaySerializer::class)
data class JsonIssuerDisplay(
    override val name: String?,
    override val locale: String?,
    override val logo: JsonLogo?,
    val customClaims: ToJsonDictionary = JsonDictionary(emptyMap()),
) : ToJsonDictionary, Metadata.IssuerDisplay<JsonElement> {

    companion object : FromJson<JsonIssuerDisplay> {
        override fun fromJson(json: JsonElement): JsonIssuerDisplay {
            val map = FromJsonHelper.requireJsonObject(json, "JsonIssuerDisplay").toMutableMap()
            return JsonIssuerDisplay(
                name = map.unwrapString(Displayable.Keys.NAME),
                locale = map.unwrapOptionalString(Displayable.Keys.LOCALE),
                logo = map.unwrapOptionalLogo(Metadata.IssuerDisplay.Keys.LOGO),
                customClaims = JsonDictionary(map.toMap())
            )
        }
    }

    private val _serializable by lazy {
        JsonDictionary(buildMap {
            putAll(customClaims.toJson())
            if (name != null) put(Displayable.Keys.NAME, name.toJson())
            if (locale != null) put(Displayable.Keys.LOCALE, locale.toJson())
            if (logo != null) put(Metadata.IssuerDisplay.Keys.LOGO, logo.toJson())
        })
    }

    override fun toJsonDictionary(): JsonDictionary = _serializable
}

@Serializable(with = JsonLogoSerializer::class)
data class JsonLogo(
    override val uri: String,
    override val altText: String? = null,
    val customClaims: ToJsonDictionary? = null,
) : ToJsonDictionary, Logo<JsonElement> {

    companion object : FromJson<JsonLogo> {
        override fun fromJson(json: JsonElement): JsonLogo {
            val map = FromJsonHelper.requireJsonObject(json, "JsonLogo").toMutableMap()
            return JsonLogo(
                uri = map.unwrapString(Logo.Keys.URI),
                altText = map.unwrapOptionalString(Logo.Keys.ALT_TEXT),
                customClaims = if (map.isEmpty()) null else JsonDictionary(map.toMap())
            )
        }

    }

    private val _serializable by lazy {
        JsonDictionary(buildMap {
            if (customClaims != null) putAll(customClaims.toJson())
            put(Logo.Keys.URI, uri.toJson())
            if (altText != null) put(Logo.Keys.ALT_TEXT, altText.toJson())
        })
    }

    override fun toJsonDictionary(): JsonDictionary = _serializable
}

@Serializable(with = JsonImageSerializer::class)
data class JsonImage(
    override val uri: String,
) : ToJsonDictionary, Image<JsonElement> {
    companion object : FromJson<JsonImage> {
        override fun fromJson(json: JsonElement): JsonImage {
            val map = FromJsonHelper.requireJsonObject(json, "JsonImage").toMutableMap()
            return JsonImage(
                uri = map.unwrapString(Image.Keys.URI),
            )
        }
    }

    private val _serializable by lazy {
        JsonDictionary(buildMap {
            put(Logo.Keys.URI, uri.toJson())
        })
    }

    override fun toJsonDictionary(): JsonDictionary = _serializable
}


@Serializable(with = JsonCredentialDisplaySerializer::class)
data class JsonCredentialDisplay(
    override val name: String,
    override val locale: String? = null,
    override val logo: JsonLogo? = null,
    override val description: String? = null,
    override val backgroundColor: String? = null,
    override val backgroundImage: Image<JsonElement>? = null,
    override val textColor: String? = null,
    val customClaims: ToJsonDictionary = JsonDictionary(emptyMap()),
) : ToJsonDictionary, Metadata.CredentialDisplay<JsonElement> {

    companion object : FromJson<JsonCredentialDisplay> {
        override fun fromJson(json: JsonElement): JsonCredentialDisplay {
            val map = FromJsonHelper.requireJsonObject(json, "JsonLogo").toMutableMap()
            return JsonCredentialDisplay(
                name = map.unwrapString(Displayable.Keys.NAME),
                locale = map.unwrapString(Displayable.Keys.LOCALE),
                logo = map.unwrapOptionalLogo(Metadata.CredentialDisplay.Keys.LOGO),
                description = map.unwrapOptionalString(Metadata.CredentialDisplay.Keys.DESCRIPTION),
                backgroundColor = map.unwrapOptionalString(Metadata.CredentialDisplay.Keys.BACKGROUND_COLOR),
                backgroundImage = map.unwrapOptionalImage(Metadata.CredentialDisplay.Keys.BACKGROUND_IMAGE),
                textColor = map.unwrapOptionalString(Metadata.CredentialDisplay.Keys.TEXT_COLOR),
                customClaims = JsonDictionary(map.toMap())
            )
        }
    }

    private val _serializable by lazy {
        JsonDictionary(buildMap {
            putAll(customClaims.toJson())
            put(Displayable.Keys.NAME, name.toJson())
            if (locale != null) put(Displayable.Keys.LOCALE, locale.toJson())
            if (logo != null) put(Metadata.CredentialDisplay.Keys.LOGO, logo.toJson())
            if (description != null) put(Metadata.CredentialDisplay.Keys.DESCRIPTION, description.toJson())
            if (backgroundColor != null) put(
                Metadata.CredentialDisplay.Keys.BACKGROUND_COLOR,
                backgroundColor.toJson()
            )
            if (textColor != null) put(Metadata.CredentialDisplay.Keys.TEXT_COLOR, textColor.toJson())
        })
    }

    override fun toJsonDictionary(): JsonDictionary = _serializable
}

@Serializable(with = JsonSupportedCredentialSerializer::class)
sealed interface JsonCredentialConfigurationsSupported : Metadata.CredentialConfigurationsSupported<JsonElement>

@Serializable(with = DefaultJsonSupportedCredentialSerializer::class)
data class DefaultJsonCredentialConfigurationsSupported(
    override val format: String,
    override val cryptographicBindingMethodsSupported: List<String>?,
    override val display: List<JsonCredentialDisplay>? = null,
    val customClaims: ToJsonDictionary? = null,
    override val scope: String?,
    override val credentialSigningAlgValuesSupported: List<String>?,
    override val proofTypesSupported: Map<String, JsonProofTypesSupported>?,
) : JsonCredentialConfigurationsSupported, ToJsonDictionary {

    companion object : FromJson<DefaultJsonCredentialConfigurationsSupported> {
        override fun fromJson(json: JsonElement): DefaultJsonCredentialConfigurationsSupported {
            val map = FromJsonHelper.requireJsonObject(json, "JsonSupportedCredential").toMutableMap()
            return DefaultJsonCredentialConfigurationsSupported(
                format = map.unwrapString(Metadata.CredentialConfigurationsSupported.Keys.FORMAT),
                cryptographicBindingMethodsSupported = map.unwrapOptionalStringList(
                    Metadata.CredentialConfigurationsSupported.Keys.CRYPTOGRAPHIC_BINDING_METHODS_SUPPORTED
                ),
                display = map.unwrapCredentialDisplays(Metadata.CredentialConfigurationsSupported.Keys.DISPLAY),
                customClaims = if (map.isEmpty()) null else JsonDictionary(map.toMap()),
                scope = map.unwrapOptionalString(Metadata.CredentialConfigurationsSupported.Keys.SCOPE),
                proofTypesSupported = map.unwrapSupportedProofType(Metadata.CredentialConfigurationsSupported.Keys.PROOF_TYPES_SUPPORTED),
                credentialSigningAlgValuesSupported = map.unwrapOptionalStringList(
                    Metadata.CredentialConfigurationsSupported.Keys.CREDENTIAL_SIGNING_ALG_VALUES_SUPPORTED
                )
            )
        }
    }

    private val _serializable by lazy {
        JsonDictionary(buildMap {
            if (customClaims != null) putAll(customClaims.toJson())
            put(Metadata.CredentialConfigurationsSupported.Keys.FORMAT, format.toJson())
            if (cryptographicBindingMethodsSupported != null) put(
                Metadata.CredentialConfigurationsSupported.Keys.CRYPTOGRAPHIC_BINDING_METHODS_SUPPORTED,
                cryptographicBindingMethodsSupported.toJson()
            )
            if (display != null) put(
                Metadata.CredentialConfigurationsSupported.Keys.DISPLAY,
                display.toJsons()
            )
            if (scope != null) put(
                Metadata.CredentialConfigurationsSupported.Keys.SCOPE,
                scope.toJson()
            )

        })
    }

    override fun toJsonDictionary(): JsonDictionary = _serializable
}

@Serializable
data class JsonProofTypesSupported(
    @SerialName(Metadata.ProofTypesSupported.Keys.PROOF_SIGNING_ALG_VALUES_SUPPORTED)
    override val proofSigningAlgValuesSupported: List<String>,
) : Metadata.ProofTypesSupported<JsonElement>
