/*
 * Copyright (C) 2023 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import { ReactNode } from 'react';
import { JsCredentialIssuerSupportedCredential } from '../ssi-libs';
import { CredentialCard } from './credential-card/CredentialCard';
import { useLanguages } from './LanguagesContext';
import { CredentialDefaultIcon } from './icons';

export function CredentialSupported({
  supportedCredential,
  children,
}: {
  supportedCredential: JsCredentialIssuerSupportedCredential;
  children?: ReactNode;
}) {
  const languages = useLanguages();
  const display = supportedCredential.display.getBestMatchForLocales(languages);
  const name = display?.name;
  const description = display?.description;

  const renderLogo = () =>
    display.logo?.url ? (
      <img src={display.logo?.url} alt={`Logotype for ${name}`} width="48" height="48" />
    ) : (
      <CredentialDefaultIcon width="48" height="48" />
    );

  return (
    <CredentialCard name={name} mode="summary">
      <div className="flex flex-column flex-gap-2">
        <div className="flex flex-row flex-gap-3">
          <div className="white">{renderLogo()}</div>
          <div>
            <h4 className="mt0 white uppercasecaps">{name}</h4>
            <p className="m0 white">
              <small>{description}</small>
            </p>
          </div>
        </div>

        {children && children}
      </div>
    </CredentialCard>
  );
}
