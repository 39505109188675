/*
 * Copyright (C) 2023 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

package io.curity.ssi.js

import io.curity.ssi.data.SerializationAdapter
import kotlinx.serialization.json.JsonArray
import kotlinx.serialization.json.JsonElement
import kotlinx.serialization.json.JsonNull
import kotlinx.serialization.json.JsonObject
import kotlinx.serialization.json.JsonPrimitive
import kotlin.js.Json

/**
 * Converter from native JS types to [JsonElement].
 *
 * This converter will not handle Kotlin [List] and [Map] (or any other non-native types), as it is
 * meant to only convert native JS values like `Array` and `object`.
 *
 * See [JsonToJsConverter] for more details.
 */
object JsToJsonConverter : SerializationAdapter<Any?, JsonElement> {

    override fun convertValue(value: Any?): JsonElement {
        if (value == null) return JsonNull
        return when (value) {
            is String -> JsonPrimitive(value)
            is Boolean -> JsonPrimitive(value)
            is Number -> JsonPrimitive(value)
            else -> {
                @Suppress("UnsafeCastFromDynamic")
                when {
                    js("Array").isArray(value) -> {
                        val jsArray: dynamic = value
                        val result = ArrayList<JsonElement>()
                        for (item in jsArray) {
                            result.add(convertValue(item))
                        }
                        return JsonArray(result)
                    }

                    jsTypeOf(value) == "object" -> convertJson(value.unsafeCast<Json>())

                    else -> throw IllegalArgumentException("Cannot convert JS Value to JsonElement: $value")
                }
            }
        }
    }

    fun convertJson(jsObject: Json): JsonObject {
        val keys = js("Object").keys(jsObject)
        val result = mutableMapOf<String, JsonElement>()
        for (key: Any in keys) {
            if (key is String) {
                result[key] = convertValue(jsObject[key])
            } else {
                throw IllegalArgumentException("Cannot convert JS object to JsonElement: non-string key: '$key'")
            }
        }
        return JsonObject(result)
    }

}

