/*
 * Copyright (C) 2023 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

package io.curity.vc.services

import io.curity.vc.serialization.JsonCredentialIssuerMetadata
import io.curity.vc.serialization.JwtVcJsonLdVerifiableCredentialRequest
import io.curity.vc.serialization.JwtVcJsonVerifiableCredentialRequest
import io.curity.vc.serialization.LdpVerifiableCredentialRequest
import io.curity.vc.serialization.ValidatedJwtVcJsonCredentialResponse
import io.curity.vc.serialization.ValidatedJwtVcJsonLdCredentialResponse
import io.curity.vc.serialization.ValidatedLdpVcCredentialResponse
import io.curity.vc.serialization.ValidatedVcSdJwtCredentialResponse
import io.curity.vc.serialization.VcSdJwtVerifiableCredentialRequest
import io.ktor.client.HttpClient
import io.ktor.client.call.body
import io.ktor.client.request.post
import io.ktor.client.request.setBody
import io.ktor.http.ContentType
import io.ktor.http.contentType
import io.ktor.http.path
import io.ktor.utils.io.core.Closeable
import kotlinx.coroutines.CoroutineScope

/**
 * Example Multi-platform Verifiable Credential Client based on Ktor.
 */
class VcClient(
    private val httpClient: HttpClient,
    private val vcServerMetadata: JsonCredentialIssuerMetadata,
    val responseValidator: VerifiableCredentialResponseValidator,
) : Closeable {

    /**
     * Get this HTTP Client's coroutine scope so suspend functions can be launched using the same
     * scope.
     */
    val coroutineScope: CoroutineScope = httpClient

    override fun close() = httpClient.close()

    suspend fun requestCredential(request: JwtVcJsonVerifiableCredentialRequest):
            ValidatedJwtVcJsonCredentialResponse {
        val response = httpClient.post(vcServerMetadata.credentialEndpoint) {
            contentType(ContentType.Application.Json)
            setBody(request)
        }
        if (response.status.value >= 300) {
            val body = response.body<String>()
            throw Exception("Credential request with non-success response: ${response.status.value}, $body")
        }
        return responseValidator.validateJwtVcJson(response.body())
    }

    suspend fun requestCredential(request: VcSdJwtVerifiableCredentialRequest):
            ValidatedVcSdJwtCredentialResponse {
        val response = httpClient.post(vcServerMetadata.credentialEndpoint) {
            contentType(ContentType.Application.Json)
            setBody(request)
        }
        if (response.status.value >= 400) {
            val body = response.body<String>()
            throw Exception("Credential request with non-success response: ${response.status.value}, $body")
        }
        return responseValidator.validateVcSdJwt(response.body())
    }

    suspend fun requestCredential(request: JwtVcJsonLdVerifiableCredentialRequest):
            ValidatedJwtVcJsonLdCredentialResponse {
        val response = httpClient.post {
            url.path(vcServerMetadata.credentialEndpoint)
            contentType(ContentType.Application.Json)
            setBody(request)
        }
        if (response.status.value >= 400) {
            val body = response.body<String>()
            throw Exception("Credential request with non-success response: ${response.status.value}, $body")
        }
        return responseValidator.validateJwtVcJsonLd(response.body())
    }

    suspend fun requestCredential(request: LdpVerifiableCredentialRequest):
            ValidatedLdpVcCredentialResponse {
        val response = httpClient.post {
            url.path(vcServerMetadata.credentialEndpoint)
            contentType(ContentType.Application.Json)
            setBody(request)
        }
        if (response.status.value >= 400) {
            val body = response.body<String>()
            throw Exception("Credential request with non-success response: ${response.status.value}, $body")
        }
        return responseValidator.validateLdp(response.body())
    }

}
