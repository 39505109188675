/*
 * Copyright (C) 2024 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

package io.curity.vc.serialization

import io.curity.vc.HasDisplayInfo
import kotlinx.serialization.Serializable

@Serializable
data class DidValidatedJwtVcJsonCredentialResponse(
    override val response: JwtVcJsonCredentialResponse,
    override val credentialJwt: String,
    val w3cCredential: SerializableW3CVerifiableCredential,
    override val claimDisplayables: Map<String, List<JsonDisplayable>>,
    override val credentialDisplay: List<JsonCredentialDisplay>?,

    ) : ValidatedJwtVcJsonCredentialResponse, HasDisplayInfo