/*
 * Copyright (C) 2023 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

package io.curity.vc.serialization

import io.curity.vc.OAuthMetadata
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class JsonOAuthMetadata(
    @SerialName(OAuthMetadata.Keys.AUTHORIZATION_ENDPOINT)
    override val authorizationEndpoint: String,
    @SerialName(OAuthMetadata.Keys.TOKEN_ENDPOINT)
    override val tokenEndpoint: String,
) : OAuthMetadata
