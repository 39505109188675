/*
 * Copyright (C) 2024 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import { JsInputDescriptor, JsUserVerifiableCredential } from 'curity-ssi-libs-verifiable-credentials-vc-ks-services';
import { Picker } from './picker/Picker';
import { useState } from 'react';
import { useLanguages } from './LanguagesContext';
import { getCredentialSimpleLabel } from './getCredentialSimpleLabel';
import { Alert } from './Alert';

export function CredentialSelector({
  inputDescriptor,
  credentials,
  onSelectCredential,
}: {
  inputDescriptor: JsInputDescriptor;
  credentials: Array<JsUserVerifiableCredential>;
  onSelectCredential;
}) {
  const [selectedCredential, setSelectedCredential] = useState(null);
  const languages = useLanguages();

  const handleCredentialSelected = (credential: JsUserVerifiableCredential) => {
    setSelectedCredential(credential);
    onSelectCredential(credential);
  };

  return (
    <>
    {credentials.length ?
      <Picker
        credentials={credentials}
        callToAction={selectedCredential ? getCredentialSimpleLabel(selectedCredential, languages) : 'Select credential'}
        viewMessage="View details"
        onCredentialSelected={handleCredentialSelected}
      />
      : <Alert kind="warning" title="No matching credentials found" errorMessage="Get credentials meeting your specified criteria from existing issuers, or initiate the process by adding a new issuer."/>}

      <p>{inputDescriptor.purpose}</p>
    </>
  );
}
