/*
 * Copyright (C) 2024 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

package io.curity.ssi.crypto

import js.typedarrays.Int8Array
import kotlinx.coroutines.await

actual fun createHashFunction(algorithm: String): HashFunction? {
    val webCryptoName = ianaNamesToWebCryptoNames[algorithm] ?: return null
    return { input ->
        val uint8Array = input.unsafeCast<Int8Array>()
        val arrayBuffer = portableCrypto.subtle.digest(webCryptoName, uint8Array).await()
        Int8Array(arrayBuffer).unsafeCast<ByteArray>()
    }
}

private val ianaNamesToWebCryptoNames = mapOf(
    HashAlgorithmsNames.SHA_256 to "SHA-256",
    HashAlgorithmsNames.SHA_384 to "SHA-384",
    HashAlgorithmsNames.SHA_384 to "SHA-512",
)