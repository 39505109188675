/*
 * Copyright (C) 2024 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

package io.curity.vc.serialization

import io.curity.ssi.json.data.FromJson
import io.curity.ssi.json.data.FromJsonHelper
import io.curity.ssi.json.data.JsonDictionary
import io.curity.ssi.json.data.ToJsonDictionary
import io.curity.ssi.json.data.toJson
import io.curity.ssi.json.data.toJsons
import io.curity.ssi.json.data.unwrapOptionalBoolean
import io.curity.ssi.json.data.unwrapOptionalString
import io.curity.vc.Metadata
import io.curity.vc.VcSdJwtFormat
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.json.JsonElement

/*
 * Contains metadata types for the 'vc+sd-jwt'
 */

@Serializable(with = VcSdJwtClaimsMetadataSerializer::class)
data class VcSdJwtClaimsMetadata(
    override val claims: Map<String, VcSdJwtClaimsEntryMetadata>
) : ToJsonDictionary, VcSdJwtFormat.ClaimsMetadata<JsonElement> {

    companion object : FromJson<VcSdJwtClaimsMetadata> {
        override fun fromJson(json: JsonElement): VcSdJwtClaimsMetadata {
            val map = FromJsonHelper.requireJsonObject(json, "VcSdJwtClaimsMetadata")
            return VcSdJwtClaimsMetadata(
                claims = map.entries.associate { (key, value) ->
                    key to VcSdJwtClaimsEntryMetadata.fromJson(value)
                }
            )
        }
    }

    private val _serialized by lazy {
        JsonDictionary(
            claims.entries.associate { (key, value) ->
                key to value.toJsonDictionary().toJson()
            }
        )
    }

    override fun toJsonDictionary(): JsonDictionary = _serialized
}

@Serializable(with = VcSdJwtClaimsMetadataEntrySerializer::class)
data class VcSdJwtClaimsEntryMetadata(
    override val valueType: String? = null,
    override val display: List<JsonDisplayable>? = null,
    private val _mandatory: Boolean? = null,
    val customClaims: ToJsonDictionary? = null,
) : ToJsonDictionary, VcSdJwtFormat.ClaimsEntryMetadata<JsonElement> {

    companion object : FromJson<VcSdJwtClaimsEntryMetadata> {
        override fun fromJson(json: JsonElement): VcSdJwtClaimsEntryMetadata {
            val map = FromJsonHelper.requireJsonObject(json, "VcSdJwtClaimsEntryMetadata").toMutableMap()
            return VcSdJwtClaimsEntryMetadata(
                valueType = map.unwrapOptionalString(VcSdJwtFormat.ClaimsEntryMetadata.Keys.VALUE_TYPE),
                display = map.unwrapDisplays(VcSdJwtFormat.ClaimsEntryMetadata.Keys.DISPLAY),
                _mandatory = map.unwrapOptionalBoolean(VcSdJwtFormat.ClaimsEntryMetadata.Keys.MANDATORY),
                customClaims = if (map.isEmpty()) null else JsonDictionary(map.toMap())
            )
        }
    }

    override val mandatory: Boolean
        get() = _mandatory ?: super.mandatory

    private val _serializable by lazy {
        JsonDictionary(buildMap {
            if (customClaims != null) putAll(customClaims.toJson())
            if (valueType != null) put(
                VcSdJwtFormat.ClaimsEntryMetadata.Keys.VALUE_TYPE,
                valueType.toJson()
            )
            if (display != null) put(
                VcSdJwtFormat.ClaimsEntryMetadata.Keys.DISPLAY,
                display.toJsons()
            )
            if (_mandatory != null) put(
                VcSdJwtFormat.ClaimsEntryMetadata.Keys.MANDATORY,
                _mandatory.toJson()
            )
        })
    }

    override fun toJsonDictionary(): JsonDictionary = _serializable
}

@Serializable
data class VcSdJwtCredentialConfigurationsSupported(
    @SerialName(Metadata.CredentialConfigurationsSupported.Keys.FORMAT)
    override val format: String,
    @SerialName(VcSdJwtFormat.CredentialConfigurationsSupported.Keys.VCT)
    override val vct: String,
    @SerialName(VcSdJwtFormat.CredentialConfigurationsSupported.Keys.CLAIMS)
    override val claims: VcSdJwtClaimsMetadata? = null,
    @SerialName(VcSdJwtFormat.CredentialConfigurationsSupported.Keys.ORDER)
    override val order: List<String>? = null,
    @SerialName(Metadata.CredentialConfigurationsSupported.Keys.CRYPTOGRAPHIC_BINDING_METHODS_SUPPORTED)
    override val cryptographicBindingMethodsSupported: List<String>? = null,
    @SerialName(Metadata.CredentialConfigurationsSupported.Keys.DISPLAY)
    override val display: List<JsonCredentialDisplay>? = null,
    @SerialName(Metadata.CredentialConfigurationsSupported.Keys.SCOPE)
    override val scope: String? = null,
    @SerialName(Metadata.CredentialConfigurationsSupported.Keys.CREDENTIAL_SIGNING_ALG_VALUES_SUPPORTED)
    override val credentialSigningAlgValuesSupported: List<String>? = null,
    @SerialName(Metadata.CredentialConfigurationsSupported.Keys.PROOF_TYPES_SUPPORTED)
    override val proofTypesSupported:  Map<String, JsonProofTypesSupported>? = null,
) : JsonCredentialConfigurationsSupported, VcSdJwtFormat.CredentialConfigurationsSupported<JsonElement>

