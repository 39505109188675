/*
 * Copyright (C) 2023 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

package io.curity.ssi.crypto

import io.curity.ssi.PublicApi
import io.curity.ssi.jose.Jose
import io.curity.ssi.jose.NativeSigningKey
import kotlinx.coroutines.await

@PublicApi
actual interface SigningKey {

    actual companion object {
        actual suspend fun fromJwk(jwkString: String): SigningKey = createJsSigningKey(jwkString)

        suspend fun createJsSigningKey(jwkString: String): NativeSigningKey = try {
            val key = Jose.JWK.asKey(jwkString).await()
            // Unfortunately, the only way I found to check if the key is a private key or not
            // is to check if the export without private key is different from the export with private key
            if (JSON.stringify(key.toJSON()) == JSON.stringify(key.toJSON(true))) {
                throw CryptoException.jwkDoesNotContainSigningKey()
            }
            NativeSigningKey(key)
        } catch (ex: Throwable) {
            throw CryptoException.jwkDoesNotContainSigningKey(ex.message)
        }
    }
}