/*
 * Copyright (C) 2023 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

package io.curity.ssi.jose

import io.curity.ssi.PublicApi
import io.curity.ssi.crypto.CryptoException
import io.curity.ssi.crypto.SigningKey
import io.curity.ssi.crypto.VerificationKey
import io.curity.ssi.crypto.VerificationKeySet
import io.curity.ssi.crypto.tryAndMapToCryptoException
import io.curity.ssi.js.JsToJsonConverter
import io.curity.ssi.js.JsonToJsConverter
import kotlinx.coroutines.await
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json
import kotlinx.serialization.json.JsonObject
import web.encoding.TextDecoder
import kotlin.js.json

/**
 * The Jws object implementation for the JS target, using node-jose
 */
@PublicApi
actual object JwsUtil {
    actual suspend fun build(key: SigningKey, header: JsonJwsHeader, payload: JsonJwtClaimsSet): String {
        val privateKey = (key as NativeSigningKey).key
        val opt = json(
            "compact" to true,
            "fields" to JsonToJsConverter.convertToJson(header.toMap()),
        )

        val keyOpt = json(
            "key" to privateKey,
            "reference" to false,
        )
        return Jose.JWS.createSign(opt, keyOpt)
            .update(Json.encodeToString(payload))
            .final().await()
    }

    actual fun unsafeParseJws(jws: String): JsonJwt.Jws {
        val parts = jws.split(".")
        if (parts.size != 3) {
            throw CryptoException("JWS must have three parts, however has ${parts.size} parts")
        }

        val header = tryAndMapToCryptoException("First part must be a JSON object") {
            Json.decodeFromString<JsonJwsHeader>(
                decoder.decode(
                    Jose.util.base64url.decode(parts[0])
                )
            )
        }

        val payload = tryAndMapToCryptoException("Second part must be a JSON object") {
            Json.decodeFromString<JsonJwtClaimsSet>(
                decoder.decode(
                    Jose.util.base64url.decode(parts[1])
                )
            )
        }

        return JsonJwt.Jws(header = header, payload = payload)
    }

    private val decoder = TextDecoder()

    actual suspend fun verifyJws(key: VerificationKey, jws: String): JsonJwt.Jws {
        val verificationKey = (key as NativeVerificationKey).key
        val result = tryAndMapToCryptoException("Error verifying JWS") {
            Jose.JWS.createVerify(verificationKey).verify(jws).await()
        }

        val header = JsToJsonConverter.convertValue(result.header) as JsonObject
        val protectedHeaders = result.protected.toSet()
        if (header.keys != protectedHeaders) {
            throw CryptoException("Header properties don't match protected header properties")
        }

        val payload = tryAndMapToCryptoException("Payload must be a JSON object") {
            Json.decodeFromString<JsonJwtClaimsSet>(decoder.decode(result.payload))
        }

        if (result.key != verificationKey) {
            throw CryptoException("Key mismatch")
        }

        return JsonJwt.Jws(
            header = JsonJwsHeader.fromJson(header),
            payload = payload
        )
    }

    actual suspend fun verifyJws(
        keySet: VerificationKeySet,
        jws: String
    ): JsonJwt.Jws {
        val nativeKeyStore = keySet.keyStore.keyStore

        val result = tryAndMapToCryptoException("Error verifying JWS") {
            Jose.JWS.createVerify(nativeKeyStore).verify(jws).await()
        }

        val header = JsToJsonConverter.convertValue(result.header) as JsonObject
        val protectedHeaders = result.protected.toSet()
        if (header.keys != protectedHeaders) {
            throw CryptoException("Header properties don't match protected header properties")
        }

        val payload = tryAndMapToCryptoException("Payload must be a JSON object") {
            Json.decodeFromString<JsonJwtClaimsSet>(decoder.decode(result.payload))
        }

        if (!nativeKeyStore.all().contains(result.key)) {
            throw CryptoException("Key mismatch")
        }

        return JsonJwt.Jws(
            header = JsonJwsHeader.fromJson(header),
            payload = payload
        )
    }
}
