/*
 * Copyright (C) 2023 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import * as React from 'react';
import { Dispatch, useEffect, useReducer } from 'react';
import { DebugPane } from '../components/debug-pane/DebugPane';
import { Spinner } from '../components/Spinner';
import { credentialFinder, presentationResponseCreator, requestJwtStore, verifierClient } from '../ssi-libs';
import {
  JsCredentialFinderResult,
  JsPresentationDisclosure,
  JsSdArray,
  JsSdArrayValue,
  JsSdObject,
  JsSdPrimitive,
  JsSdProperty,
  JsSdValue,
  JsUserSelectedCredential,
  JsUserSelectedCredentials,
  JsUserVerifiableCredential,
  JsValidatedRequestJwt,
} from 'curity-ssi-libs-verifiable-credentials-vc-ks-services';
import { Link, Location, NavigateFunction, useLocation, useNavigate } from 'react-router-dom';
import { routes } from '../routes';
import { Alert } from '../components/Alert';
import { CredentialSelector } from '../components/CredentialSelector';
import { SuccessCheckmark } from '../components/SuccessCheckmark';
import { logGAEvent } from '../util';

/**
 * The possible component states
 */
type State =
  // Fetching the presentation request, transient state.
  | { state: 'fetching-presentation-request' }

  // Presentation request was fetched, user should select credential, stable state.
  | {
      state: 'presentation-request-fetched';
      validatedRequestJwt: JsValidatedRequestJwt;
      credentialFinderResult: JsCredentialFinderResult;
    }

  // User is selecting disclosures to add, stable state.
  | {
      state: 'select-disclosures';
      validatedRequestJwt: JsValidatedRequestJwt;
      credentialFinderResult: JsCredentialFinderResult;
      selectedCredential: JsUserVerifiableCredential;
      disclosures: Array<string>;
    }

  // Submitting presentation, transient state.
  | {
      state: 'submitting-presentation';
      validatedRequestJwt: JsValidatedRequestJwt;
      credentialFinderResult: JsCredentialFinderResult;
    }

  // Presentation submitted, final state.
  | { state: 'presentation-submitted' }

  // Generic error, final state.
  | { state: 'error'; message: string }

  // Submission error, final state
  | {
      state: 'submit-error';
      message: string;
      selectedCredential: JsUserVerifiableCredential;
      validatedRequestJwt: JsValidatedRequestJwt;
      credentialFinderResult: JsCredentialFinderResult;
    };

/**
 * The possible component actions
 */
type Action =
  // Started the fetch for the presentation request
  | { type: 'start-fetch' }

  // Presentation request was fetched
  | {
      type: 'presentation-request-fetched';
      validatedRequestJwt: JsValidatedRequestJwt;
      credentialFinderResult: JsCredentialFinderResult;
    }

  // Started selecting the disclosures
  | {
      type: 'select-disclosures';
      selectedCredential: JsUserVerifiableCredential;
    }

  // Added a new disclosure to include in the presentation
  | {
      type: 'add-disclosure';
      disclosure: string;
    }

  // Removed a set of disclosures
  | {
      type: 'remove-disclosures';
      disclosures: Array<string>;
    }

  // Started submitting the presentation
  | {
      type: 'submit-presentation';
      validatedRequestJwt: JsValidatedRequestJwt;
      credentialFinderResult: JsCredentialFinderResult;
    }

  // Presentation submitted with success
  | { type: 'submitting-done' }

  // Generic error occurred
  | { type: 'error'; message: string }

  // Presentation submission error occurred
  | {
      type: 'submit-error';
      message: string;
      selectedCredential: JsUserVerifiableCredential;
      validatedRequestJwt: JsValidatedRequestJwt;
      credentialFinderResult: JsCredentialFinderResult;
    };

/**
 * The state machine reducer
 * @param state the current state
 * @param action the action describing the last occuring event
 * @return the new state
 */
function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'error':
      return { state: 'error', message: action.message };

    case 'submit-error':
      return {
        state: 'submit-error',
        message: action.message,
        selectedCredential: action.selectedCredential,
        validatedRequestJwt: action.validatedRequestJwt,
        credentialFinderResult: action.credentialFinderResult,
      };

    case 'presentation-request-fetched':
      return {
        state: 'presentation-request-fetched',
        validatedRequestJwt: action.validatedRequestJwt,
        credentialFinderResult: action.credentialFinderResult,
      };

    case 'start-fetch':
      return { state: 'fetching-presentation-request' };

    case 'select-disclosures':
      if (state.state == 'presentation-request-fetched') {
        return {
          state: 'select-disclosures',
          validatedRequestJwt: state.validatedRequestJwt,
          credentialFinderResult: state.credentialFinderResult,
          selectedCredential: action.selectedCredential,
          disclosures: [],
        };
      }
      // this should not happen
      return state;

    case 'add-disclosure':
      if (state.state == 'select-disclosures') {
        return { ...state, disclosures: state.disclosures.concat(action.disclosure) };
      }
      // this should not happen
      return state;

    case 'remove-disclosures':
      if (state.state == 'select-disclosures') {
        return { ...state, disclosures: state.disclosures.filter(it => !action.disclosures.includes(it)) };
      }
      // this should not happen
      return state;

    case 'submit-presentation':
      return {
        state: 'submitting-presentation',
        validatedRequestJwt: action.validatedRequestJwt,
        credentialFinderResult: action.credentialFinderResult,
      };

    case 'submitting-done':
      return { state: 'presentation-submitted' };
  }
}

/*
 * Utility functions
 */

async function resolvePresentationRequest(
  uri: string,
  requestId: string,
  dispatcher: Dispatch<Action>,
  navigate: NavigateFunction
) {
  try {
    const validatedRequestJwt = await getValidatedRequestJwt(uri, requestId, navigate);
    const credentialFinderResult = await credentialFinder.find(validatedRequestJwt.presentationDefinition);
    dispatcher({
      type: 'presentation-request-fetched',
      validatedRequestJwt: validatedRequestJwt,
      credentialFinderResult: credentialFinderResult,
    });
  } catch (error: unknown) {
    const msg = sanitizeErrorMessage(error);
    dispatcher({ type: 'error', message: msg });
  }
}

async function getValidatedRequestJwt(
  uri: string,
  requestId: string,
  navigate: NavigateFunction
): Promise<JsValidatedRequestJwt> {
  // try from the store first
  if (requestId == null) {
    throw new Error('Invalid presentation request uri');
  }
  let validatedRequestJwt: JsValidatedRequestJwt = requestJwtStore.getById(requestId);
  if (validatedRequestJwt == null) {
    if (uri == null) {
      throw new Error('Invalid presentation request');
    }
    validatedRequestJwt = await verifierClient.fetchRequestObjectJwt(uri);
    if (validatedRequestJwt != null) {
      // put the request jwt into the store
      requestJwtStore.insert(requestId, validatedRequestJwt);
      // go to this page with request-id in path
      navigate(routes.TO_PRESENTATION_RESOLVE_WITH_ID(requestId));
    }
  } else if (uri != null) {
    // validatedRequestJwt was found and uri was in the search params
    // go to this page with request-id in path
    navigate(routes.TO_PRESENTATION_RESOLVE_WITH_ID(requestId));
  }
  return validatedRequestJwt;
}

function sanitizeErrorMessage(error: unknown): string {
  let msg = error['message'] || 'Generic error.';
  if (msg == 'Parent job is Cancelled' && error['cause']?.message != null) {
    msg = error['cause']?.message;
  }
  msg = msg?.replace("Error at 'unknown location': RuntimeException:", '');
  msg = msg?.replace("Error at 'unknown location': Exception:", '');
  msg = msg?.replace("Error at 'unknown location': TypeError:", '');

  return msg;
}

function getRequestUriAndId(location: Location): [string, string] {
  if (location.search) {
    const queryParams = new URLSearchParams(location.search);
    const requestUri = queryParams.get('request_uri');
    const requestUrl = new URL(requestUri);
    const requestId = requestUrl.searchParams.get('id');
    return [requestUri, requestId];
  }

  // get last part of path, which should be the request id
  const requestId = location.pathname.split('/').pop();

  return [null, requestId];
}

async function submitResponse(
  selectedCredential: JsUserVerifiableCredential | null,
  state: State & ({ state: 'presentation-request-fetched' } | { state: 'select-disclosures' }),
  dispatcher: React.Dispatch<Action>,
  location: Location
) {
  // if 'vc+sd-jwt' go to the select disclosure state...
  if (selectedCredential.credential.vcSdJwt && state.state == 'presentation-request-fetched') {
    dispatcher({ type: 'select-disclosures', selectedCredential });
    return;
  }

  // ... else create and submit the presentation immediately

  // So that the UI reflects the ongoing submission
  dispatcher({
    type: 'submit-presentation',
    validatedRequestJwt: state.validatedRequestJwt,
    credentialFinderResult: state.credentialFinderResult,
  });
  const disclosures = state.state == 'select-disclosures' ? state.disclosures : [];
  const userSelectedCredentials = new JsUserSelectedCredentials(
    state.credentialFinderResult.data.map(pair => {
      return new JsUserSelectedCredential(pair.first, selectedCredential, new JsPresentationDisclosure(disclosures));
    })
  );
  const uri = state.validatedRequestJwt.responseUri;
  const params = await presentationResponseCreator.createVerifiablePresentationResponse(
    state.validatedRequestJwt,
    userSelectedCredentials
  );
  try {
    await verifierClient.postDirectPostAuthorizationResponse(uri, params);
    logGAEvent('User actions', 'Credential', 'presented');
    dispatcher({ type: 'submitting-done' });

    const [, requestId] = getRequestUriAndId(location);
    requestJwtStore.delete(requestId);
  } catch (error) {
    dispatcher({
      type: 'submit-error',
      message: sanitizeErrorMessage(error),
      selectedCredential: selectedCredential,
      validatedRequestJwt: state.validatedRequestJwt,
      credentialFinderResult: state.credentialFinderResult,
    });
  }
}

/**
 * The component function
 */
export function PresentationRequestResolve() {
  const [state, dispatch] = useReducer(reducer, { state: 'fetching-presentation-request' });
  const [selectedCredential, setSelectedCredential] = React.useState<JsUserVerifiableCredential>(null);

  const navigate: NavigateFunction = useNavigate();
  const handleCredentialSelected = (credential: JsUserVerifiableCredential) => {
    setSelectedCredential(credential);
  };

  const location: Location = useLocation();

  useEffect(() => {
    const [requestUri, requestId] = getRequestUriAndId(location);
    resolvePresentationRequest(requestUri, requestId, dispatch, navigate);
  }, [location, navigate, location.search]);

  return render(state, dispatch, selectedCredential, handleCredentialSelected, location, navigate);
}

/**
 * Render function
 */
function render(
  state: State,
  dispatcher: React.Dispatch<Action>,
  selectedCredential: JsUserVerifiableCredential,
  handleCredentialSelected: (credential: JsUserVerifiableCredential) => void,
  location: Location,
  navigate: NavigateFunction
) {
  switch (state.state) {
    case 'fetching-presentation-request':
      return renderSpinner('Please wait. Resolving presentation request...');
    case 'presentation-request-fetched':
      return renderSelectCredentials(
        state,
        dispatcher,
        state.validatedRequestJwt,
        state.credentialFinderResult,
        selectedCredential,
        handleCredentialSelected,
        location
      );
    case 'error':
      return renderError(state, dispatcher, location, navigate);
    case 'submit-error':
      return renderSubmitError(state, dispatcher, location);
    case 'select-disclosures':
      return renderSelectDisclosures(state, dispatcher, location);
    case 'submitting-presentation':
      return renderSpinner('Please wait. Submitting presentation...');
    case 'presentation-submitted':
      return renderDone();
  }
}

function renderSpinner(message: string) {
  return (
    <>
      <h1 className="h4 center">{message}</h1>
      <Spinner />
    </>
  );
}

function renderDone() {
  return (
    <>
      <SuccessCheckmark />
      <div className="center">
        <p>You are done!</p>
        <p>Please continue at the Verifier...</p>
        <Link to={routes.HOME} className="button button-medium button-primary-outline w100">
          OK
        </Link>
      </div>
    </>
  );
}

function renderSelectCredentials(
  state: State & { state: 'presentation-request-fetched' },
  dispatcher: Dispatch<Action>,
  validatedRequestJwt: JsValidatedRequestJwt,
  credentialFinderResult: JsCredentialFinderResult,
  selectedCredential: JsUserVerifiableCredential,
  handleCredentialSelected: (credential: JsUserVerifiableCredential) => void,
  location: Location
) {
  const submitButtonText = 'Accept and continue';
  return (
    <>
      <p>{validatedRequestJwt.presentationDefinition.name}</p>
      <h1>{validatedRequestJwt.presentationDefinition.purpose}</h1>

      {credentialFinderResult.data.length !== 0 ? (
        credentialFinderResult.data.map(descriptorCredentialsPair => (
          <CredentialSelector
            key={descriptorCredentialsPair.first.id}
            inputDescriptor={descriptorCredentialsPair.first}
            credentials={descriptorCredentialsPair.second}
            onSelectCredential={handleCredentialSelected}
          ></CredentialSelector>
        ))
      ) : (
        <Alert kind="info" title="No credentials" errorMessage="No matching credentials found" />
      )}
      <div className="flex flex-column flex-gap-2">
        {credentialFinderResult.data.length !== 0 && (
          <button
            className="button button-medium button-primary w100"
            onClick={() => submitResponse(selectedCredential, state, dispatcher, location)}
            disabled={selectedCredential == null}
          >
            {submitButtonText}
          </button>
        )}
        <Link to={routes.HOME} className="button button-medium button-primary-outline ">
          Cancel
        </Link>
      </div>
    </>
  );
}

function renderSelectDisclosures(
  state: State & { state: 'select-disclosures' },
  dispatcher: React.Dispatch<Action>,
  location: Location
) {
  return (
    <>
      <div className="select-disclosures">
        <h1>Choose Credential Information to Disclose</h1>
        {renderSdValue(dispatcher, state.selectedCredential.credential.vcSdJwt.customSdClaims, true, state.disclosures)}
        <button
          className="button button-medium button-primary w100 mt2"
          onClick={() => submitResponse(state.selectedCredential, state, dispatcher, location)}
        >
          Accept and continue
        </button>
        {/* TODO disclosures being shown just for debug purposes, should be removed in the future */}
      </div>
      <DebugPane title="Disclosures" data={state.disclosures} />
    </>
  );
}

function renderSdValue(
  dispatcher: React.Dispatch<Action>,
  value: JsSdValue,
  isDisclosed: boolean,
  disclosures: Array<string>
) {
  if (value instanceof JsSdObject) {
    return renderSdObject(dispatcher, value, isDisclosed, disclosures);
  } else if (value instanceof JsSdArray) {
    return renderSdArray(dispatcher, value, isDisclosed, disclosures);
  } else if (value instanceof JsSdPrimitive) {
    return <span>{value.value}</span>;
  }
}

function renderSdObject(
  dispatcher: React.Dispatch<Action>,
  obj: JsSdObject,
  isDisclosed: boolean,
  disclosures: Array<string>
) {
  return (
    <div>
      {Array.from(obj.members.values()).map(property => {
        return renderSdProperty(dispatcher, property, isDisclosed, disclosures);
      })}
    </div>
  );
}

function renderSdProperty(
  dispatcher: React.Dispatch<Action>,
  property: JsSdProperty,
  isParentDisclosed: boolean,
  disclosures: Array<string>
) {
  const isCheckboxDisabled = !isParentDisclosed || !property.isSelectivelyDisclosable;
  const isChecked = isParentDisclosed && (!property.isSelectivelyDisclosable || property.isDisclosedBy(disclosures));
  const isDisclosed = isChecked;

  function handleClick() {
    if (!isDisclosed) {
      dispatcher({ type: 'add-disclosure', disclosure: property.disclosureString });
    } else {
      dispatcher({
        type: 'remove-disclosures',
        disclosures: property.value.getNestedDisclosures().concat([property.disclosureString]),
      });
    }
  }

  return (
    <div key={property.name} className="sd-property">
      <input type="checkbox" disabled={isCheckboxDisabled} checked={isChecked} onClick={handleClick} />
      <div className="sd-property-value">
        <span>{property.name}</span>
        {renderSdValue(dispatcher, property.value, isDisclosed, disclosures)}
      </div>
    </div>
  );
}

function renderSdArray(
  dispatcher: React.Dispatch<Action>,
  array: JsSdArray,
  isDisclosed: boolean,
  disclosures: Array<string>
) {
  return (
    <div className="sd-array">
      {array.elements.map((element, ix) => renderSdArrayValue(dispatcher, element, ix, isDisclosed, disclosures))}
    </div>
  );
}

function renderSdArrayValue(
  dispatcher: React.Dispatch<Action>,
  arrayValue: JsSdArrayValue,
  index: number,
  isParentDisclosed: boolean,
  disclosures: Array<string>
) {
  const isCheckboxDisabled = !isParentDisclosed || !arrayValue.isSelectivelyDisclosable;
  const isChecked =
    isParentDisclosed && (!arrayValue.isSelectivelyDisclosable || arrayValue.isDisclosedBy(disclosures));
  const isDisclosed = isChecked;

  function handleClick() {
    if (!isDisclosed) {
      dispatcher({ type: 'add-disclosure', disclosure: arrayValue.disclosureString });
    } else {
      dispatcher({
        type: 'remove-disclosures',
        disclosures: arrayValue.value.getNestedDisclosures().concat([arrayValue.disclosureString]),
      });
    }
  }

  return (
    <div key={index} className="sd-array-value">
      <input type="checkbox" disabled={isCheckboxDisabled} checked={isChecked} onClick={handleClick} />
      {renderSdValue(dispatcher, arrayValue.value, isDisclosed, disclosures)}
    </div>
  );
}

function renderError(
  state: State & { state: 'error' },
  dispatcher: Dispatch<Action>,
  location: Location,
  navigate: NavigateFunction
) {
  function onClick() {
    dispatcher({ type: 'start-fetch' });
    const [requestUri, requestId] = getRequestUriAndId(location);
    resolvePresentationRequest(requestUri, requestId, dispatcher, navigate);
  }

  return (
    <>
      <h1>Something went wrong</h1>
      <Alert kind="danger" title="There was an error resolving presentation request" errorMessage={state.message} />
      <div className="flex flex-column flex-gap-2">
        <button className="button button-medium button-primary w100" onClick={onClick}>
          Try again
        </button>
        <Link to={routes.HOME} className="button button-medium button-primary-outline ">
          Cancel
        </Link>
      </div>
    </>
  );
}

function renderSubmitError(
  state: State & {
    state: 'submit-error';
  },
  dispatcher: Dispatch<Action>,
  location: Location
) {
  function onClick() {
    const fetchedState: State = {
      state: 'presentation-request-fetched',
      validatedRequestJwt: state.validatedRequestJwt,
      credentialFinderResult: state.credentialFinderResult,
    };
    submitResponse(state.selectedCredential, fetchedState, dispatcher, location);
  }

  return (
    <>
      <h1>Something went wrong</h1>
      <Alert kind="danger" title="There was an error sending presentation to verifier" errorMessage={state.message} />
      <div className="flex flex-column flex-gap-2">
        <button className="button button-medium button-primary w100" onClick={onClick}>
          Try again
        </button>
        <Link to={routes.HOME} className="button button-medium button-primary-outline ">
          Cancel
        </Link>
      </div>
    </>
  );
}
