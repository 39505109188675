export const Spinner: React.FC = () => (
  <div className="spinner-container py2">
    <div className="spinner spinner-medium">
      <div className="spinner-circle"></div>
      <svg
        aria-hidden="true"
        width="1.25em"
        height="1.25em"
        viewBox="0 0 91.08 58.46"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="bevel"
        className="spinner-logo"
      >
        <path
          className="a"
          d="M82 37l-.9.9c-5.09 5.09-11.49 8-17.55 8-9.63 0-16.62-7-16.62-16.7s6.77-16.66 16.16-16.66A26.77 26.77 0 0 1 79.51 19l.9.79 9-9.41-1-.86A39.66 39.66 0 0 0 62.13 0c-38.33 1.27-41.28 55.09.4 58.35 13.6 1.06 23.15-7 27.6-11.14l1-.88z"
        />
        <path className="spinner-middle-line" d="M31.29 25.11H10.18l-1 8.17H30.9a30.4 30.4 0 0 1 .39-8.17z" />
        <path className="spinner-top-line" d="M19.15 12.35l-1 8.17H32a36.37 36.37 0 0 1 3.72-8.17z" />
        <path className="spinner-bottom-line" d="M32 37.86H1L0 46h35.93a26 26 0 0 1-4-8.17z" />
        <path className="spinner-tiny-line" d="M7.86 25.11H1.84l-.97 8.17h6.02l.97-8.17z" />
      </svg>
    </div>
  </div>
);
