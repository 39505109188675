/*
 * Copyright (C) 2024 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

package io.curity.ssi.sdjwt

import io.curity.ssi.crypto.HashFunction
import io.curity.ssi.crypto.decodeBase64Url
import io.curity.ssi.crypto.encodeBase64url
import io.curity.ssi.crypto.secureRandom
import io.curity.ssi.sdjwt.Disclosure.Property
import io.curity.ssi.sdjwt.Disclosure.Value
import kotlinx.serialization.SerializationException
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json
import kotlinx.serialization.json.JsonArray
import kotlinx.serialization.json.JsonElement
import kotlinx.serialization.json.JsonPrimitive

/**
 * Represents a disclosure, with all information about a [Property] or a [Value].
 */
sealed interface Disclosure {

    /**
     * The value of the disclosed element
     */
    val value: JsonElement

    /**
     * Returns the string with the complete disclosure information,
     * i.e., the information present outside the JWT, in a SD-JWT.
     */
    fun toDisclosureString(): String

    /**
     * Returns the string with the hashed disclosure information,
     * i.e., the information present inside the JWT payload, in a SD-JWT.
     */
    suspend fun toDisclosureHashString(hashFunction: HashFunction): String =
        hashDisclosure(hashFunction, toDisclosureString())

    /**
     * Disclosure for a property.
     *
     * @property salt the base64url encoded salt.
     * @property name the property name.
     * @property value the property value.
     */
    class Property(
        val salt: String,
        val name: String,
        override val value: JsonElement,
    ) : Disclosure {
        companion object {
            /**
             * Creates a [Disclosure] for a property.
             * @param name the property name
             * @param value the property value
             */
            fun create(name: String, value: JsonElement) =
                Property(
                    encodeBase64url(secureRandom(SdJwtConstants.USED_SALT_BYTE_SIZE)),
                    name,
                    value
                )

            fun create(salt: String, name: String, value: JsonElement) =
                Property(
                    salt,
                    name,
                    value
                )

        }

        /**
         * Produces the disclosure string.
         */
        override fun toDisclosureString(): String =
            encodeBase64url(
                Json.encodeToString(
                    JsonArray(
                        listOf(
                            JsonPrimitive(salt),
                            JsonPrimitive(name),
                            value
                        )
                    )
                ).encodeToByteArray()
            )
    }

    /**
     * Disclosure for an array value.
     *
     * @property salt the base64url encoded salt.
     * @property value the array value.
     */
    class Value(
        val salt: ByteArray,
        override val value: JsonElement,
    ) : Disclosure {
        companion object {

            /**
             * Creates a disclosure for an array value
             * @param value the array value
             */
            fun create(value: JsonElement) =
                Value(
                    secureRandom(SdJwtConstants.USED_SALT_BYTE_SIZE),
                    value
                )

            fun create(salt: ByteArray, value: JsonElement) =
                Value(
                    salt,
                    value
                )

        }

        override fun toDisclosureString(): String =
            encodeBase64url(
                Json.encodeToString(
                    JsonArray(
                        listOf(
                            JsonPrimitive(encodeBase64url(salt)),
                            value
                        )
                    )
                ).encodeToByteArray()
            )
    }

    companion object {

        /**
         * Parses a disclosure string into a [Disclosure] object.
         * @param disclosure the disclosure string
         * @return the parsed [Disclosure]
         * @throws [IllegalArgumentException] is the string does not represent a valid disclosure
         */
        fun parse(disclosure: String, json: Json = Json): Disclosure {
            val decodedDisclosure = decodeBase64Url(disclosure)
            val jsonElement = try {
                json.parseToJsonElement(decodedDisclosure.decodeToString())
            } catch (ex: SerializationException) {
                throw IllegalArgumentException("Disclosure has bad format", ex)
            }
            if (jsonElement !is JsonArray) {
                // Must be an array
                throw IllegalArgumentException("Disclosure must be an array")
            }
            if (jsonElement.size !in 2..3) {
                throw IllegalArgumentException("Disclosure must contain two or three elements")
            }
            val salt = jsonElement[0]
            if (salt !is JsonPrimitive || !salt.isString) {
                throw IllegalArgumentException("Disclosure has bad format: salt is not a string")
            }
            val decodedSalt = try {
                decodeBase64Url(salt.content)
            } catch (ex: IllegalArgumentException) {
                throw IllegalArgumentException("Disclosure has bad format: salt is not correctly encoded")
            }
            if (decodedSalt.size < SdJwtConstants.MIN_SALT_BYTE_SIZE) {
                throw IllegalArgumentException("Disclosure has bad format: salt is too small")
            }
            if (jsonElement.size == 2) {
                val value = jsonElement[1]

                return Value(
                    decodedSalt,
                    value
                )
            }
            if (jsonElement.size == 3) {
                val name = jsonElement[1]
                val value = jsonElement[2]
                if (name !is JsonPrimitive || !name.isString) {
                    throw IllegalArgumentException("Disclosure has bad format: name must be a string")
                }
                return Property(
                    salt.content,
                    name.content,
                    value
                )
            }
            throw IllegalArgumentException("Disclosure must contain two or three elements")
        }

        /**
         * Computes the hash of a disclosure.
         */
        suspend fun hashDisclosure(hashFunction: HashFunction, disclosure: String) =
            encodeBase64url(hashFunction(disclosure.encodeToByteArray()))
    }
}
