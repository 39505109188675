/*
 * Copyright (C) 2023 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

package io.curity.vp.serialization

import io.curity.vp.VerifierDirectPostAuthorizationResponse
import io.curity.vp.VerifierDirectPostAuthorizationResponse.Keys.REDIRECT_URI
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class JsonVerifierDirectPostAuthorizationResponse(
    @SerialName(REDIRECT_URI)
    override val redirectUri: String? = null
) : VerifierDirectPostAuthorizationResponse
