/*
 * Copyright (C) 2023 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

package io.curity.vc.serialization

import io.curity.ssi.json.data.DelegateSerializer
import io.curity.ssi.json.data.DerivedSerializer
import io.curity.ssi.json.data.FromJsonHelper
import io.curity.ssi.json.data.JsonSerializer
import io.curity.vc.CredentialEndpoint
import io.curity.vc.JwtVcJsonFormat
import io.curity.vc.JwtVcJsonLdFormat
import io.curity.vc.LdpVcFormat
import kotlinx.serialization.DeserializationStrategy
import kotlinx.serialization.builtins.serializer
import kotlinx.serialization.json.JsonContentPolymorphicSerializer
import kotlinx.serialization.json.JsonElement
import kotlinx.serialization.json.JsonPrimitive
import kotlin.reflect.KClass

/*
 * Contains serializers for format-independent types
 * or that need to handle all formats.
 * (by format we mean VCI formats).
 */

internal object JsonProofOfPossessionSerializer : JsonSerializer<JsonProofOfPossession>(JsonProofOfPossession)

internal object ToJsonProofOfPossessionSerializer : DelegateSerializer<JsonProofOfPossession, ToJsonProofOfPossession>(
    JsonProofOfPossessionSerializer, { toJson -> toJson.toJsonProofOfPossession() }
)

internal object JsonProofSerializer : JsonSerializer<JsonProof>(JsonProof)

internal object ToJsonProofSerializer : DelegateSerializer<JsonProof, ToJsonProof>(
    JsonProofSerializer, { toJson -> toJson.toJsonProof() }
)

internal object JsonIssuerSerializer :
    JsonContentPolymorphicSerializer<JsonIssuer>(JsonIssuer::class) {

    private val _stringSerializer = DerivedSerializer(String.serializer(),
        { it.value }, { JsonIssuer.IssuerURI(it) })

    private val _objectSerializer = object : JsonSerializer<JsonIssuer.IssuerObject>(JsonIssuer.IssuerObject) {}

    override fun selectDeserializer(element: JsonElement): DeserializationStrategy<JsonIssuer> {
        return if (element is JsonPrimitive) _stringSerializer else _objectSerializer
    }
}

internal object ToJsonIssuerSerializer : DelegateSerializer<JsonIssuer, ToJsonIssuer>(
    JsonIssuerSerializer, { toJson -> toJson.toJsonIssuer() }
)

internal object JsonCredentialStatusSerializer :
    JsonSerializer<JsonCredentialStatus>(JsonCredentialStatus)

internal object BasicCredentialSubjectSerializer :
    JsonSerializer<BasicCredentialSubject>(BasicCredentialSubject)

internal object SerializableW3CVerifiableCredentialSerializer :
    JsonSerializer<SerializableW3CVerifiableCredential>(SerializableW3CVerifiableCredential)

internal object JwtProofOfPossessionSerializer :
    JsonSerializer<JwtProofOfPossession>(JwtProofOfPossession)

internal object JsonVerifiableCredentialSerializer :
    JsonSerializer<JsonVerifiableCredential>(JsonVerifiableCredential)

internal abstract class VerifiableCredentialResponseSerializer<T : Any>(
    baseClass: KClass<T>,
    private val _format: String?,
    val deferredSerializer: DeserializationStrategy<T>,
    val synchronousSerializer: DeserializationStrategy<T>,
) : JsonContentPolymorphicSerializer<T>(baseClass) {

    private val _typeName = baseClass.simpleName ?: "VerifiableCredentialResponse"

    override fun selectDeserializer(element: JsonElement): DeserializationStrategy<T> {
        val response = FromJsonHelper.requireJsonObject(element, _typeName)
        return if (CredentialEndpoint.DeferredVerifiableCredentialResponse.Keys.TRANSACTION_ID in response) {
            deferredSerializer
        } else {
            synchronousSerializer
        }
    }
}

internal object DefaultJsonSupportedCredentialSerializer :
    JsonSerializer<DefaultJsonCredentialConfigurationsSupported>(DefaultJsonCredentialConfigurationsSupported)

internal object JsonDisplayableSerializer :
    JsonSerializer<JsonDisplayable>(JsonDisplayable)

internal object JsonIssuerDisplaySerializer :
    JsonSerializer<JsonIssuerDisplay>(JsonIssuerDisplay)

internal object JsonLogoSerializer :
    JsonSerializer<JsonLogo>(JsonLogo)

internal object JsonImageSerializer :
    JsonSerializer<JsonImage>(JsonImage)

internal object JsonCredentialDisplaySerializer :
    JsonSerializer<JsonCredentialDisplay>(JsonCredentialDisplay)

internal object SerializableVerifiableCredentialRequestSerializer :
    JsonContentPolymorphicSerializer<SerializableVerifiableCredentialRequest>(SerializableVerifiableCredentialRequest::class) {

    private const val formatKey = CredentialEndpoint.VerifiableCredentialRequest.Keys.FORMAT

    override fun selectDeserializer(element: JsonElement): DeserializationStrategy<SerializableVerifiableCredentialRequest> {
        val jsonObject = FromJsonHelper.requireJsonObject(element, "SerializableVerifiableCredentialRequest")
        val format = jsonObject[formatKey]
        if (format is JsonPrimitive && format.isString) {
            return when (format.content) {
                JwtVcJsonFormat.FORMAT -> JwtVcJsonVerifiableCredentialRequest.serializer()
                JwtVcJsonLdFormat.FORMAT -> JwtVcJsonLdVerifiableCredentialRequest.serializer()
                LdpVcFormat.FORMAT -> LdpVerifiableCredentialRequest.serializer()
                else -> error("unrecognized $formatKey: '${format.content}'")
            }
        } else {
            error("$formatKey is missing")
        }
    }
}
