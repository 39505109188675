/*
 * Copyright (C) 2023 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

package io.curity.ssi.jose

import JsVerificationKey
import io.curity.ssi.crypto.VerificationKey
import io.curity.ssi.js.JsToJsonConverter
import kotlinx.serialization.json.JsonObject

data class NativeVerificationKey(
    val key: NativeKey,
) : VerificationKey, JsVerificationKey {
    override val jwk: JsonJwk by lazy {
        when (val jsonElement = JsToJsonConverter.convertValue(key.toJSON())) {
            is JsonObject -> JsonJwk.fromJson(jsonElement)
            else -> throw Exception("Unexpected JWT format")
        }
    }
}


