export const SuccessCheckmark = () => (
  <div className="cta-success">
    <div className="flex justify-center flex-column flex-center p2">
      <svg className="success-checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
        <circle className="success-checkmark-circle" cx="26" cy="26" r="25" fill="none" />
        <path className="success-checkmark-check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
      </svg>
    </div>
  </div>
);
