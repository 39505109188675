/*
 * Copyright (C) 2023 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import { routes } from '../routes';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useCallback, useMemo } from 'react';
import { verifiableCredentialStore } from '../ssi-libs';
import { VerifiableCredential as ShowVerifiableCredential } from '../components/VerifiableCredential';
import { EmptyState } from '../components/EmptyState';
import { HomeIllustration } from '../components/icons';
import { JsUserVerifiableCredential } from 'curity-ssi-libs-verifiable-credentials-vc-ks-services';
import { PwaPrompt } from '../components/pwa-prompt/PwaPrompt';

function getCredentialOfferFromQuery(queryString: string): string | undefined {
  if (queryString) {
    // If the query contains an offer request, then go to the offer resolution page
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.has('credential_offer') || queryParams.has('credential_offer_uri')) {
      return queryParams.toString();
    }
    // To support PWA protocol handlers
    try {
      const url = new URL(decodeURIComponent(location.search.substring(1)));
      if (url.protocol === 'web+openidcredentialoffer:') {
        const queryParams = new URLSearchParams(url.search);
        if (queryParams.has('credential_offer') || queryParams.has('credential_offer_uri')) {
          return queryParams.toString();
        }
      }
    } catch (error) {
      // ignore
    }
    return undefined;
  }
}

function getPresentationRequestString(queryString: string): string | undefined {
  if (queryString) {
    // If the query contains a presentation request, then go to the presentation resolution page
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.has('request_uri')) {
      return queryParams.toString();
    }
    // To support PWA protocol handlers
    try {
      const url = new URL(decodeURIComponent(location.search.substring(1)));
      if (url.protocol === 'web+haip:') {
        const queryParams = new URLSearchParams(url.search);
        if (queryParams.has('request_uri')) {
          return queryParams.toString();
        }
      }
    } catch (error) {
      // ignore
    }
    return undefined;
  }
}

export function Home() {
  const navigate = useNavigate();
  const onClick = useCallback(
    (credential: JsUserVerifiableCredential) => {
      navigate(routes.TO_CREDENTIAL_DETAIL(credential.id));
    },
    [navigate]
  );

  const credentials = useMemo(() => verifiableCredentialStore.getAll(), []);
  const location = useLocation();

  // Check if URL contains a credential offer
  const offerQueryString = getCredentialOfferFromQuery(location.search);
  if (offerQueryString) {
    return <Navigate to={routes.TO_OFFER_RESOLVE(offerQueryString)} />;
  }

  // Check if URL contains a presentation request
  const presentationRequestString = getPresentationRequestString(location.search);
  if (presentationRequestString) {
    return <Navigate to={routes.TO_PRESENTATION_RESOLVE(presentationRequestString)} />;
  }

  return credentials.length ? (
    <div>
      <div className="mx-auto-w-3 h-3 mt2 mb2">
        <HomeIllustration />
      </div>
      <h1 className="h3 mt0 mb3 center">
        Welcome to the <br /> Curity Demo Wallet
      </h1>
      <h4 className="uppercasecaps">Credentials</h4>
      <div className="flex flex-column flex-gap-1">
        {credentials.map(credential => (
          <ShowVerifiableCredential
            key={credential.id}
            credential={credential as JsUserVerifiableCredential}
            detailLevel="name-only"
            onClick={onClick}
          />
        ))}
      </div>
    </div>
  ) : (
    <>
      <PwaPrompt />
      <div className="mx-auto-w-7 h-7 mt2 mb2">
        <HomeIllustration />
      </div>
      <EmptyState
        heading="Looks you don't have any credentials"
        text="A way to obtain credentials is by going to the issuers and using one of
      its supported credentials."
        link="/issuers/"
        cta="Get my first credential"
      />
    </>
  );
}
