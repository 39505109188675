import { useState } from 'react';
import { Trash, Check } from './icons';

export const ClearLocalStorage: React.FC = () => {
  const [isCleared, setIsCleared] = useState(false);

  const handleClearLocalStorage = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    try {
      localStorage.clear();
      setIsCleared(true);
      setTimeout(() => {
        setIsCleared(false);
        location.reload();
      }, 1000);
    } catch (error) {
      console.error('Failed to clear Local Storage:', error);
      setIsCleared(false);
    }
  };

  return (
    <div>
      <button className="button button-tiny button-danger" onClick={handleClearLocalStorage}>
        {isCleared ? <Check width="24" height="24" /> : <Trash width="24" height="24" />}
        {isCleared ? 'Cleared' : 'Clear Local Storage'}
      </button>
    </div>
  );
};
