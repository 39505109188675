/*
 * Copyright (C) 2023 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import { useEffect, useReducer, useRef } from 'react';
import QrScanner from 'qr-scanner';
import * as styles from '../components/page/page.module.css';
import { Link, Navigate } from 'react-router-dom';
import { routes } from '../routes';
import { Spinner } from '../components/Spinner';

type ParseResult =
  | { tag: 'valid-offer'; queryString: string }
  | { tag: 'valid-presentation'; queryString: string }
  | { tag: 'error'; message: string };

function parseQrCode(code: string): ParseResult {
  try {
    const url = new URL(code);
    const searchParams = url.searchParams;

    if (url.protocol === 'openid-credential-offer:') {
      if (searchParams.has('credential_offer') || searchParams.has('credential_offer_uri')) {
        return { tag: 'valid-offer', queryString: url.search.substring(1) };
      }
      return { tag: 'error', message: 'missing required parameters' };
    } else if (searchParams.has('request_uri')) {
      return { tag: 'valid-presentation', queryString: url.search.substring(1) };
    }
    return { tag: 'error', message: 'URI not recognized' };
  } catch (error) {
    return { tag: 'error', message: 'not an URI' };
  }
}

type State =
  | { tag: 'start' }
  | { tag: 'scanning'; message: string }
  | { tag: 'unrecoverable-error'; message: string }
  | { tag: 'scanned-offer'; queryString: string }
  | { tag: 'scanned-presentation'; queryString: string };

type Action =
  | { type: 'started-scan' }
  | { type: 'unrecoverable-error'; message: string }
  | { type: 'scan-event'; message: string }
  | { type: 'scanned-offer'; queryString: string }
  | { type: 'scanned-presentation'; queryString: string };

function reducer(state: State, action: Action): State {
  if (action.type === 'unrecoverable-error') {
    return { tag: 'unrecoverable-error', message: action.message };
  }
  switch (state.tag) {
    case 'start':
      if (action.type === 'started-scan') {
        return { tag: 'scanning', message: 'scanning' };
      }
      return state;
    case 'scanning':
      if (action.type === 'scan-event') {
        return { tag: 'scanning', message: action.message };
      } else if (action.type === 'scanned-offer') {
        return { tag: 'scanned-offer', queryString: action.queryString };
      } else if (action.type === 'scanned-presentation') {
        return { tag: 'scanned-presentation', queryString: action.queryString };
      }
      return state;
    case 'unrecoverable-error':
    case 'scanned-offer':
    case 'scanned-presentation':
      // end state
      return state;
  }
}

function renderMessage(state: State) {
  switch (state.tag) {
    case 'start':
      return <Spinner />;
    case 'scanning':
      return <p>Scanning: {state.message}</p>;
    case 'unrecoverable-error':
      return (
        <>
          <p>Unrecoverable error</p>
          <Link className="button button-medium button-transparent button-fullwidth" to={routes.HOME}>
            Cancel
          </Link>
        </>
      );
    case 'scanned-offer':
      return <Navigate to={routes.TO_OFFER_RESOLVE(state.queryString)} />;
    case 'scanned-presentation':
      return <Navigate to={routes.TO_PRESENTATION_RESOLVE(state.queryString)} />;
  }
}

interface ScanProps {}

export const Scan: React.FC<ScanProps> = () => {
  const webcamRef = useRef<HTMLVideoElement | null>(null);
  const [state, dispatch] = useReducer(reducer, { tag: 'start' });

  useEffect(() => {
    const currentWebcamRef = webcamRef.current;
    if (!currentWebcamRef) {
      dispatch({ type: 'unrecoverable-error', message: 'unexpected error: video ref is undefined' });
      return;
    }
    let qrScanner: QrScanner;
    const startWebcam = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: {
            facingMode: 'environment',
          },
        });
        currentWebcamRef.srcObject = stream;
        qrScanner = new QrScanner(
          currentWebcamRef,
          result => {
            const parseResult = parseQrCode(result.data);
            if (parseResult.tag === 'valid-offer') {
              dispatch({ type: 'scanned-offer', queryString: parseResult.queryString });
              qrScanner.stop();
              qrScanner.destroy();
              qrScanner = null;
            } else if (parseResult.tag === 'valid-presentation') {
              dispatch({ type: 'scanned-presentation', queryString: parseResult.queryString });
              qrScanner.stop();
              qrScanner.destroy();
              qrScanner = null;
            } else {
              dispatch({
                type: 'scan-event',
                message: `invalid QR code '${parseResult.message}', continuing`,
              });
            }
          },
          {
            onDecodeError: () => {
              dispatch({
                type: 'scan-event',
                message: `unable to scan, continuing...`,
              });
            },
            maxScansPerSecond: 10,
            highlightCodeOutline: true,
            highlightScanRegion: true,
          }
        );
        await qrScanner.start();
        dispatch({ type: 'started-scan' });
      } catch (error) {
        dispatch({ type: 'unrecoverable-error', message: 'unable to access camera' });
      }
    };

    startWebcam();

    return () => {
      const tracks = (currentWebcamRef.srcObject as MediaStream)?.getTracks() || [];
      tracks.forEach(track => track.stop());
      if (qrScanner) {
        qrScanner.stop();
        qrScanner.destroy();
      }
    };
  }, []);

  return (
    <>
      <video className={`${styles['video']} br-8`} ref={webcamRef} autoPlay playsInline />
      {renderMessage(state)}
    </>
  );
};
