/*
 * Copyright (C) 2023 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

package io.curity.vp.services

import io.curity.vc.services.VerifiableCredentialStore
import io.curity.vp.serialization.JsonField
import io.curity.vp.serialization.JsonPresentationDefinition

class OpenIdWalletAuthenticatorCredentialFinder(
    private val credentialStore: VerifiableCredentialStore,
) : CredentialFinder {

    override suspend fun find(presentationDefinition: JsonPresentationDefinition): CredentialFinderResult {
        val data = presentationDefinition.inputDescriptors.map { inputDescriptor ->
            val constraintFields = inputDescriptor.constraints.fields
                ?: error("Unsupported presentation_definition format (missing 'fields').")
            val matchers = getRequestedConstraintsAsMatchers(constraintFields)
            val credentials = credentialStore.getAll().filter { credential ->
                matchers.all { it.matches(credential) }
            }

            (inputDescriptor to credentials)
        }

        return CredentialFinderResult(data)
    }

    private fun getRequestedConstraintsAsMatchers(constraintFields: List<JsonField>): List<CredentialMatcher> =
        constraintFields.map { field ->
            CredentialMatcher.buildFrom(field) ?: error("Unsupported field filter $field")
        }

}