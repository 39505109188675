/*
 * Copyright (C) 2023 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

package io.curity.vp.serialization

import io.curity.ssi.json.data.JsonSerializer
import io.curity.vp.PresentationDefinition.ArrayContainsFilter.Keys.CONTAINS
import io.curity.vp.PresentationDefinition.StringPatternFilter.Keys.PATTERN
import kotlinx.serialization.json.JsonContentPolymorphicSerializer
import kotlinx.serialization.json.JsonElement
import kotlinx.serialization.json.jsonObject


internal object JsonFilterSerializer : JsonContentPolymorphicSerializer<AbstractJsonFilter>(AbstractJsonFilter::class) {
    override fun selectDeserializer(element: JsonElement) = when {
        CONTAINS in element.jsonObject -> JsonArrayContainsFilter.serializer()
        PATTERN in element.jsonObject -> JsonStringFilter.serializer()
        else -> error("Unsupported filter: ${element.jsonObject}")
    }
}

internal object SerializableW3CVerifiablePresentationSerializer :
    JsonSerializer<SerializableW3CVerifiablePresentation>(SerializableW3CVerifiablePresentation)

internal object JsonVerifiablePresentationSerializer :
    JsonSerializer<JsonVerifiablePresentation>(JsonVerifiablePresentation)

