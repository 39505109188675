/*
 * Copyright (C) 2023 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

// Because we want these symbols to be in the root package, for better usage from JS
@file:Suppress("PackageDirectoryMismatch")

package vc_js.services

import io.curity.oauth.OAuthAuthorizationRequest
import io.curity.oauth.OAuthAuthorizationResponse
import io.curity.oauth.OAuthAuthorizer
import io.curity.oauth.appendParameters
import io.ktor.http.formUrlEncode
import io.ktor.http.parameters
import js.core.jso
import kotlinx.browser.window
import kotlinx.coroutines.CancellableContinuation
import kotlinx.coroutines.suspendCancellableCoroutine
import org.w3c.dom.MessageEvent
import org.w3c.dom.Window
import org.w3c.dom.events.EventListener
import org.w3c.dom.url.URLSearchParams
import kotlin.coroutines.resume

/**
 * [OAuthAuthorizer] using an external browser window to perform the
 * authorization request.
 * Usable both on browser apps and on electron.
 */
class BrowserWindowOAuthAuthorizer : OAuthAuthorizer {

    override suspend fun authorize(
        authorizationEndpoint: String,
        authorizationRequest: OAuthAuthorizationRequest
    ): OAuthAuthorizationResponse {
        val authorizationRequestParams = parameters {
            appendParameters(authorizationRequest)
        }.formUrlEncode()

        // we open the authorization URL on a browser window, then wait for the OAuth callback
        // to post a message back to us with the authorization response
        // note that on electron, externalWindow may be nullish
        val externalWindow: Window? = window.open("$authorizationEndpoint?$authorizationRequestParams")
        val abortController = web.abort.AbortController()
        try {
            val authorizationResponseParams = suspendCancellableCoroutine { continuation ->
                addEventListener(continuation, externalWindow, authorizationRequest.state, abortController.signal)
            }
            return OAuthAuthorizationResponse(
                state = authorizationResponseParams.get("state") ?: throw Exception("missing state"),
                code = authorizationResponseParams.get("code") ?: throw Exception("missing code"),
            )
        } finally {
            externalWindow?.close()
            abortController.abort()
        }
    }

    private fun addEventListener(
        continuation: CancellableContinuation<URLSearchParams>,
        externalWindow: Window?,
        expectedState: String?,
        abortSignal: web.abort.AbortSignal
    ) {
        window.addEventListener(
            "message",
            EventListener { event ->
                event as MessageEvent
                val data = event.data
                val receivedParams = try {
                    URLSearchParams(data)
                }catch(ex: Exception) {
                    null
                }
                if ((event.source == externalWindow || externalWindow == null)
                    && receivedParams != null
                    && expectedState == receivedParams.get("state")
                ) {
                    externalWindow?.close()
                    continuation.resume(receivedParams)
                }
            }, jso {
                signal = abortSignal
            })
    }
}