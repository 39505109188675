/*
 * Copyright (C) 2024 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

package io.curity.vc.serialization

import io.curity.ssi.json.data.ToJsonDictionary
import io.curity.vc.CredentialEndpoint
import io.curity.vc.VcSdJwtFormat
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class VcSdJwtVerifiableCredentialRequest(
    @SerialName(CredentialEndpoint.VerifiableCredentialRequest.Keys.PROOF)
    override val proof: ToJsonProofOfPossession? = null,
    @SerialName(VcSdJwtFormat.VerifiableCredentialRequest.Keys.VCT)
    override val vct: String,
    @SerialName(VcSdJwtFormat.VerifiableCredentialRequest.Keys.CLAIMS)
    override val claims:  ToJsonDictionary? = null,
    override val format: String = VcSdJwtFormat.FORMAT
): VcSdJwtFormat.VerifiableCredentialRequest, SerializableVerifiableCredentialRequest