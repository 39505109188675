/*
 * Copyright (C) 2023 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

/*
 * Defines the functions usable directly from JS
 */
@file:OptIn(ExperimentalJsExport::class, DelicateCoroutinesApi::class)

// Because we want these symbols to be in the root package, for better usage from JS
@file:Suppress("PackageDirectoryMismatch")

import io.curity.ssi.crypto.JWK
import io.curity.ssi.crypto.KeyStore
import io.curity.ssi.crypto.NodeJoseKeyStore
import io.curity.ssi.crypto.SigningKey
import io.curity.ssi.crypto.VerificationKey
import io.curity.ssi.jose.JsonJwsHeader
import io.curity.ssi.jose.JsonJwt
import io.curity.ssi.jose.JsonJwtClaimsSet
import io.curity.ssi.jose.JwsUtil
import io.curity.ssi.jose.NativeSigningKey
import io.curity.ssi.jose.NativeVerificationKey
import io.curity.ssi.js.JsToJsonConverter
import io.curity.ssi.js.JsonToJsConverter
import kotlinx.coroutines.DelicateCoroutinesApi
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.promise
import kotlin.js.Json
import kotlin.js.Promise

@JsExport
interface JsSigningKey

@JsExport
interface JsVerificationKey

@JsExport
data class JsSigningKeyPair(
    val signingKey: JsSigningKey,
    val verificationKey: JsVerificationKey,
    val id: String,
)

@JsExport
sealed interface JsKeyStore {
    fun getSigningKeyPairById(id: String): Promise<JsSigningKeyPair?>
    fun importSigningKeySpec(id: String, jwkString: String): Promise<JsSigningKeyPair>
}

class JsDefaultKeyStore : JsKeyStore {
    val inner: KeyStore = NodeJoseKeyStore.create()

    override fun getSigningKeyPairById(id: String): Promise<JsSigningKeyPair?> =
        GlobalScope.promise {
            inner.getSigningKeyPairById(id)?.let {
                JsSigningKeyPair(
                    it.signingKey as NativeSigningKey,
                    it.verificationKey as NativeVerificationKey,
                    it.id,
                )
            }
        }

    override fun importSigningKeySpec(id: String, jwkString: String): Promise<JsSigningKeyPair> =
        GlobalScope.promise {
            inner.importSigningKeyPair(id, JWK(jwkString)).let {
                JsSigningKeyPair(
                    it.signingKey as NativeSigningKey,
                    it.verificationKey as NativeVerificationKey,
                    it.id
                )
            }
        }

}

@JsExport
class JsJwsHeaderAndPayload(inner: JsonJwt.Jws) {
    val header: Json by lazy { JsonToJsConverter.convertToJson(inner.header!!.toMap()) }
    val payload: Json by lazy { JsonToJsConverter.convertToJson(inner.payload.toMap()) }
}

@JsExport
@JsName("createSigningKeyFromJwk")
fun createSigningKeyFromJwk(jwkString: String): Promise<JsSigningKey> = GlobalScope.promise {
    SigningKey.createJsSigningKey(jwkString)
}

@JsExport
@JsName("createVerificationKeyFromJwk")
fun createVerificationKeyFromJwk(jwkString: String): Promise<JsVerificationKey> = GlobalScope.promise {
    VerificationKey.createJsVerificationKey(jwkString)
}

@JsExport
@JsName("createJws")
fun createJws(key: JsSigningKey, header: Json, payload: Json): Promise<String> = GlobalScope.promise {
    val headerMap = JsToJsonConverter.convertJson(header)
    val payloadMap = JsToJsonConverter.convertJson(payload)
    JwsUtil.build(key as NativeSigningKey, JsonJwsHeader.fromJson(headerMap), JsonJwtClaimsSet.fromJson(payloadMap))
}

@JsExport
@JsName("unsafeParseJws")
fun unsafeParseJws(jws: String): JsJwsHeaderAndPayload {
    val result = JwsUtil.unsafeParseJws(jws)
    return JsJwsHeaderAndPayload(result)
}

@JsExport
@JsName("verifyJws")
fun verifyJws(key: JsVerificationKey, jws: String): Promise<JsJwsHeaderAndPayload> =
    GlobalScope.promise {
        val result = JwsUtil.verifyJws(key as VerificationKey, jws)
        JsJwsHeaderAndPayload(result)
    }
