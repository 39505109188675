/*
 * Copyright (C) 2024 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

package io.curity.vc.services

import io.curity.ssi.sdjwt.vc.JsonVcSdJwtIssuerMetadata
import io.curity.ssi.sdjwt.vc.VcSdJwtConstants
import io.curity.ssi.sdjwt.vc.VcSdJwtIssuerMetadataLoader
import io.ktor.client.HttpClient
import io.ktor.client.call.body
import io.ktor.client.request.get
import io.ktor.http.URLBuilder
import io.ktor.http.Url

class DefaultVcSdJwtIssuerMetadataLoader(
    private val httpClient: HttpClient
) : VcSdJwtIssuerMetadataLoader {

    override suspend fun get(issuerId: String): JsonVcSdJwtIssuerMetadata {

        val issuerUrl = Url(issuerId)
        val metadataUrl = URLBuilder(issuerUrl).apply {
            pathSegments = listOf(
                ".well-known",
                VcSdJwtConstants.ISSUER_METADATA_WELL_KNOWN_PATH
            ) + removeTrailingEmptySegments(pathSegments)
        }.build()

        val response = httpClient.get(metadataUrl)
        if (response.status.value >= 400) {
            val body = response.body<String>()
            throw Exception(
                "Issuer metadata request for $issuerId with non-success status response: ${response.status.value}, $body"
            )
        }
        return response.body()
    }

    companion object {
        private fun removeTrailingEmptySegments(segments: List<String>): List<String> =
            segments.takeLastWhile { it.isNotBlank() }
    }
}