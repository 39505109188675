/*
 * Copyright (C) 2023 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

package io.curity.ssi.jose

import io.curity.ssi.json.data.JsonSerializer

object JsonJwsHeaderSerializer: JsonSerializer<JsonJwsHeader>(JsonJwsHeader)

object JsonJwtClaimsSetSerializer: JsonSerializer<JsonJwtClaimsSet>(JsonJwtClaimsSet)

object JsonJwkSerializer: JsonSerializer<JsonJwk>(JsonJwk)