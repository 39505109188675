/*
 * Copyright (C) 2023 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

package io.curity.oauth

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.json.JsonElement

sealed interface JsonOAuthTokenResponse

/**
* Represents an OAuth 2.0 Success Token Response.
*/
@Serializable
data class JsonOAuthSuccessTokenResponse(
    @SerialName(OAuthSuccessTokenResponse.Keys.TOKEN_TYPE)
    override val tokenType: String,
    @SerialName(OAuthSuccessTokenResponse.Keys.ACCESS_TOKEN)
    override val accessToken: String,
    @SerialName(OAuthSuccessTokenResponse.Keys.REFRESH_TOKEN)
    override val refreshToken: String? = null,
    @SerialName(OAuthSuccessTokenResponse.Keys.EXPIRES_IN)
    override val expiresIn: Long? = null,
    @SerialName(OAuthSuccessTokenResponse.Keys.SCOPE)
    override val scope: String? = null,
    @SerialName(OAuthSuccessTokenResponse.Keys.AUTHORIZATION_DETAILS)
    override val authorizationDetails: List<JsonElement>? = null,
    @SerialName(OAuthSuccessTokenResponse.Keys.C_NONCE)
    override val cNonce: String? = null,
    @SerialName(OAuthSuccessTokenResponse.Keys.C_NONCE_EXPIRES_IN)
    override val cNonceExpiresIn: Long? = null,
) : OAuthSuccessTokenResponse<JsonElement>, JsonOAuthTokenResponse

/**
 * Represents an OAuth 2.0 Error Token Response.
 */
@Serializable
data class JsonOAuthErrorTokenResponse(
    @SerialName(OAuthErrorTokenResponse.Keys.ERROR)
    override val error: String,

    @SerialName(OAuthErrorTokenResponse.Keys.ERROR_DESCRIPTION)
    override val errorDescription: String? = null,

    @SerialName(OAuthErrorTokenResponse.Keys.ERROR_URI)
    override val errorUri: String? = null,
): OAuthErrorTokenResponse, JsonOAuthTokenResponse