/*
 * Copyright (C) 2023 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

package io.curity.ssi.data

/**
 * Represents a language tag as defined on [https://www.rfc-editor.org/rfc/rfc5646.html]
 */
data class Locale(
    val value: String
) {
    val isLanguageOnly = value.length == 2 && !value.contains("-")
    val language = value.subSequence(0, 2)

    fun hasSameLanguageAs(other: Locale?) = language == other?.language

    fun hasSameLanguageAs(other: String?) = language == other?.subSequence(0, 2)

    override fun equals(other: Any?): Boolean =
        other is Locale && other.value.equals(value, ignoreCase = true)

    override fun hashCode(): Int = value.hashCode()

    override fun toString() = value
}
