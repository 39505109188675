/*
 * Copyright (C) 2023 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

package io.curity.vp

import io.curity.ssi.data.Dictionary
import io.curity.ssi.data.ToString
import io.curity.vc.JwtVcJsonFormat
import io.curity.vc.LdpVcFormat
import io.curity.vc.VcSdJwtFormat

interface RequestObjectJwt {
    object Keys {
        const val CLIENT_ID = "client_id"
        const val CLIENT_ID_SCHEME = "client_id_scheme"
        const val CLIENT_METADATA = "client_metadata"
        const val PRESENTATION_DEFINITION = "presentation_definition"
        const val NONCE = "nonce"
        const val RESPONSE_TYPE = "response_type"
        const val RESPONSE_TYPE_VP_TOKEN = "vp_token"
        const val RESPONSE_MODE = "response_mode"
        const val RESPONSE_MODE_DIRECT_POST = "direct_post"
        const val RESPONSE_URI = "response_uri"
        const val STATE = "state"
    }
}

/**
 * OpenID for Verifiable Presentations specification's client_id_scheme parameter.
 * See [OpenID for Verifiable Presentations specification](https://openid.github.io/OpenID4VP/openid-4-verifiable-presentations-wg-draft.html#name-verifier-metadata-managemen)
 */
enum class ClientIdScheme(val clientIdScheme: String) : ToString {
    PRE_REGISTERED("pre-registered"),
    DID("did"),
    X509_SAN_DNS("x509_san_dns"),
    X509_SAN_URI("x509_san_uri"),
    VERIFIER_ATTESTATION("verifier_attestation"),
    REDIRECT_URI("redirect_uri"),
    ENTITY_ID("entity_id");

    companion object {
        fun fromString(clientIdScheme: String) : ClientIdScheme {
            for (cis in entries) {
                if (cis.clientIdScheme == clientIdScheme) {
                    return cis
                }
            }
            throw IllegalArgumentException("Unknown client id scheme: $clientIdScheme")
        }
    }

    override fun toString() = clientIdScheme
}

/**
 * OpenID for Verifiable Presentations specification's presentation_definition parameter.
 * See [OpenID for Verifiable Presentations specification](https://openid.github.io/OpenID4VP/openid-4-verifiable-presentations-wg-draft.html#request_presentation_definition)
 *
 * The structure of presentation_definition is defined in
 * the [Presentation Exchange](https://identity.foundation/presentation-exchange/#presentation-definition) spec.
 */
object PresentationDefinition { // namespace
    interface PresentationDefinition<T> {

        object Keys {
            const val ID = "id"
            const val FORMAT = "format"
            const val NAME = "name"
            const val PURPOSE = "purpose"
            const val INPUT_DESCRIPTORS = "input_descriptors"
        }

        /**
         * The Presentation Definition MUST contain an id property. The value of this property MUST be a string.
         * The string SHOULD provide a unique ID for the desired context.
         */
        val id: String

        /**
         * The Presentation Definition MAY contain a name property. If present, its value SHOULD be a human-friendly
         * string intended to constitute a distinctive designation of the Presentation Definition.
         */
        val name: String?

        /**
         * The Presentation Definition MAY contain a purpose property. If present, its value MUST be a string that
         * describes the purpose for which the Presentation Definition's inputs are being used for.
         */
        val purpose: String?

        /**
         * The Presentation Definition MAY include a format property. Some envelope transport protocols
         * may include the value of this property in other locations and use different property names,
         * but regardless of whether it resides at the default location (the format property of the
         * presentation_definition object), the value MUST be an object consisting of keyed arrays.
         * Each array should be keyed to a Claim Format Designation (e.g., jwt, jwt_vc, jwt_vp, etc.)
         * registered in the governing registry.
         */
        val format: Format<T>?

        /**
         * The Presentation Definition MUST contain an input_descriptors property.
         * Its value MUST be an array of Input Descriptor Objects
         */
        val inputDescriptors: List<InputDescriptor<T>>
    }

    /**
     * See [Presentation Exchange specification](https://identity.foundation/presentation-exchange/#input-descriptor)
     */
    interface InputDescriptor<T> {

        object Keys {
            const val ID = "id"
            const val NAME = "name"
            const val PURPOSE = "purpose"
            const val CONSTRAINTS = "constraints"
            const val FORMAT = "format"
        }

        /**
         * The Input Descriptor Object MUST contain an id property. The value of the id property MUST be
         * a string that does not conflict with the id of another Input Descriptor Object in the same
         * Presentation Definition and SHOULD not conflict with any other id value present in the same
         * Presentation Definition.
         */
        val id: String

        /**
         * The Input Descriptor Object MAY contain a name property. If present, its value
         * SHOULD be a human-friendly name that describes what the target schema represents.
         *
         */
        val name: String?

        /**
         * The Input Descriptor Object MAY contain a purpose property. If present, its value
         * MUST be a string that describes the purpose for which the Claim's data is being requested.
         */
        val purpose: String?

        /**
         * The Input Descriptor Object MUST contain a constraints property. Its value MUST be an object.
         */
        val constraints: Constraints

        /**
         * The Input Descriptor Object MAY contain a format property. If present, its value MUST be an
         * object with one or more properties matching the registered Claim Format Designations (e.g., jwt,
         * jwt_vc, jwt_vp, etc.). This format property is identical in value signature to the top-level
         * format object, but can be used to specifically constrain submission of a single input to a subset
         * of formats or algorithms.
         */
        val format: Format<T>?
    }

    /**
     * Defined in [Presentation Exchange specification]()https://identity.foundation/presentation-exchange/#presentation-definition)
     * One or more values may be non-null.
     */
    interface Format<T>: Dictionary<T> {
        val jwtVcJson: AlgFormat?
        val jwtVpJson: AlgFormat?
        val vcSdJwt: VcSdJwtAlgsFormat?
        val ldpVc: ProofTypeFormat?
        val ldpVp: ProofTypeFormat?

        object Keys {
            const val JWT_VC_JSON = JwtVcJsonFormat.FORMAT
            const val JWT_VP_JSON = JwtVpJsonFormat.FORMAT
            const val VC_SD_JWT = VcSdJwtFormat.FORMAT
            const val LDP_VC = LdpVcFormat.FORMAT
            const val LDP_VP = LdpVpFormat.FORMAT
        }
    }

    /**
     * Format defined in [Claim Format Registry](https://identity.foundation/claim-format-registry/)
     */
    interface AlgFormat {
        object Keys {
            const val ALG = "alg"
        }
        val alg: Set<String>
    }

    /**
     * Format defined in [Claim Format Registry](https://identity.foundation/claim-format-registry/)
     */
    interface ProofTypeFormat {
        object Keys {
            const val PROOF_TYPE = "proof_type"
        }
        val proofType: Set<String>
    }

    /**
     * Format *inferred* from [https://openid.github.io/OpenID4VP/openid-4-verifiable-presentations-wg-draft.html#name-presentation-request-5](https://openid.github.io/OpenID4VP/openid-4-verifiable-presentations-wg-draft.html#name-presentation-request-5)
     */
    // TODO try to use strongly typed algorithms (see IS-9215)
    interface VcSdJwtAlgsFormat {
        object Keys {
            const val SD_JWT_ALG_VALUES = "sd-jwt_alg_values"
            const val KB_JWT_ALG_VALUES = "kb-jwt_alg_values"
        }

        val sdJwtAlgValues: Set<String>
        val kbJwtAlgValues: Set<String>
    }

    interface Constraints {
        object Keys {
            const val FIELDS = "fields"
            const val LIMIT_DISCLOSURE = "limit_disclosure"
        }

        /**
         * The fields object MUST contain a path property. The value of this property MUST be an array of one or
         * more JSONPath string expressions (as defined in the JSONPath Syntax Definition section) that select
         * a target value from the input.
         */
        val fields: List<Field>?

        /**
         * The constraints object MAY contain a limit_disclosure property. If present, its value
         * MUST be one of the following strings: "required" or "preferred".
         */
        val limitDisclosure: String?
    }

    interface Field {
        object Keys {
            const val ID = "id"
            const val NAME = "name"
            const val PURPOSE = "purpose"
            const val PATH = "path"
            const val FILTER = "filter"
            const val OPTIONAL = "optional"

        }
        /**
         * The fields object MAY contain an id property. If present, its value
         * MUST be a string that is unique from every other field object’s id property
         */
        val id: String?

        /**
         * The fields object MAY contain a name property. If present, its value MUST be a string,
         * and SHOULD be a human-friendly name that describes what the target field represents.
         */
        val name: String?

        /**
         * The fields object MAY contain a purpose property. If present, its value MUST
         * be a string that describes the purpose for which the field is being requested.
         */
        val purpose: String?

        /**
         * The fields object MUST contain a path property. The value of this property MUST be an array of one or
         * more JSONPath string expressions that select a target value from the input.
         */
        val path: List<String>

        /**
         * The fields object MAY contain a filter property, and if present its value MUST be a JSON Schema descriptor
         * used to filter against the values returned from evaluation of the JSONPath string expressions in the
         * path array.
         */
        val filter: Filter?

        /**
         * The fields object MAY contain an optional property. The value of this property MUST be a boolean
         * wherein true indicates the field is optional, and false or non-presence of the property indicates
         * the field is required. Even when the optional property is present, the value located at the indicated
         * path of the field MUST validate against the JSON Schema filter, if a filter is present.
         */
        val optional: Boolean?
    }

    // This implementation of Filter is very limited and is designed to be just enough
    // to support Curity's openid-wallet authenticator.
    // TODO: implement Filter according to https://json-schema.org/specification
    interface Filter {
        object Keys {
            const val TYPE = "type"
        }

        val type: String
    }

    interface StringPatternFilter: Filter {
        object Keys {
            const val PATTERN = "pattern"
        }

        val pattern: String

    }

    interface ArrayContainsFilter: Filter {
        object Keys {
            const val CONTAINS = "contains"
        }

        val contains: Contains
    }

    interface Contains {
        object Keys {
            const val TYPE = "type"
            const val PATTERN = "pattern"
        }

        val type: String
        val pattern: String
    }
}

/**
 * OpenID for Verifiable Presentations specification's presentation_submission response parameter.
 * See [OpenID for Verifiable Presentations specification](https://openid.github.io/OpenID4VP/openid-4-verifiable-presentations-wg-draft.html#name-response-parameters)
 *
 * The structure of presentation_submission is defined in
 * the [Presentation Exchange](https://identity.foundation/presentation-exchange/#presentation-submission) spec.
 *
 */
object PresentationSubmission {
    interface PresentationSubmission {
        val id: String
        val definitionId: String
        val descriptorMap: List<DescriptorObject>

        object Keys {
            const val ID = "id"
            const val DEFINITION_ID = "definition_id"
            const val DESCRIPTOR_MAP = "descriptor_map"
        }
    }

    interface DescriptorObject {
        val id: String
        val format: String
        val path: String
        val pathNested: PathNested?

        object Keys {
            const val ID = "id"
            const val FORMAT = "format"
            const val PATH = "path"
            const val PATH_NESTED = "path_nested"
        }
    }

    interface PathNested {
        val format: String
        val path: String

        object Keys {
            const val FORMAT = "format"
            const val PATH = "path"
        }
    }
}

sealed interface VerifiablePresentation {
    /**
     * The presentation represented as a String value.
     *
     * This type of value requires the caller to decode the String into something meaningful.
     * For example, in the `jwt_vp_json` format, this String is a JWT.
     */
    data class StringPresentation(
        val value: String
    ) : VerifiablePresentation

    /**
     * The presentation represented as a [Dictionary] of claims.
     *
     * The format of the credential should allow verifiers to make sense of what each claim means, usually
     * via use of common schemas understood by all parties.
     */
    data class DictionaryPresentation<T : Dictionary<out Any>>(val value: T) : VerifiablePresentation
}