/*
 * Copyright (C) 2024 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

interface CloseProps {
  color?: string;
  width?: string;
  height?: string;
}

export const Close: React.FC<CloseProps> = ({ width = '24px', height = '24px', color = 'currentColor' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
    stroke={color}
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="2"
    className="lucide lucide-x-circle"
    viewBox="0 0 24 24"
  >
    <path d="M15 9l-6 6M9 9l6 6"></path>
  </svg>
);
