/*
 * Copyright (C) 2024 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

@file:OptIn(ExperimentalJsExport::class)

// Because we want these symbols to be in the root package, for better usage from JS
@file:Suppress("PackageDirectoryMismatch")

@JsExport
class Either<L, R> private constructor(private val _left: L?, private val _right: R?) {
    companion object {
        fun <L, R> left(left: L): Either<L, R> = Either(left, null)
        fun <L, R> right(right: R): Either<L, R> = Either(null, right)
    }

    fun <T> map(whenLeft: (L) -> T, whenRight: (R) -> T): T {
        return if (_left != null) whenLeft(_left)
        else whenRight(_right!!)
    }
}