/*
 * Copyright (C) 2024 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

package io.curity.vc.serialization

import io.curity.ssi.sdjwt.SdValue
import io.curity.vc.HasDisplayInfo
import kotlinx.serialization.Serializable

@Serializable
class DefaultValidatedVcSdJwtCredentialResponse(
    override val response: VcSdJwtCredentialResponse,
    override val claimDisplayables: Map<String, List<JsonDisplayable>>,
    override val credentialDisplay: List<JsonCredentialDisplay>?,
    val credential: String, // The SD-JWT encoded as a String
    val issuer: String,
    val vct: String,
    val customClaims: SdValue.Object,
    // the key id for the used key
    val kid: String,
) : ValidatedVcSdJwtCredentialResponse, HasDisplayInfo