/*
 * Copyright (C) 2023 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

package io.curity.ssi.did

import io.curity.ssi.PublicApi
import io.curity.ssi.data.ToString
import kotlin.jvm.JvmStatic

/**
 * Represents a Decentralized Identifier URL, as defined by [https://www.w3.org/TR/did-core/]
 */
@PublicApi
data class DidUrl(
    /** The base DID */
    val did: Did,
    /** The DID URL path (does not include leading '/') */
    val path: String? = null,
    /** The DID URL query string */
    val query: String? = null,
    /** The DID URL fragment */
    val fragment: String? = null,
) : ToString {

    /**
     * Evaluates to `true` if the DID URL is also a DID
     */
    val isDid = path == null && query == null && fragment == null

    override fun toString(): String {
        val builder = StringBuilder("$did")
        if (path != null) {
            builder.append("/$path")
        }
        if (query != null) {
            builder.append("?$query")
        }
        if (fragment != null) {
            builder.append("#$fragment")
        }
        return builder.toString()
    }

    companion object {

        /**
         * Tries to build a [DidUrl] from the provided [String], returning `null` if the string does not represent a
         * DID URL.
         */
        @JvmStatic
        fun from(didString: String): DidUrl? {
            val matchResult = DID_MATCHER.find(didString) ?: return null
            val groupValues: Array<String?> = matchResult.groupValues.toTypedArray()
            if (groupValues.size != 7) {
                return null
            }
            val method: String = require(groupValues[1]) ?: return null
            val id = require(groupValues[2]) ?: return null
            val path: String? = groupValues[4]?.let {
                if (it.isBlank()) {
                    null
                } else {
                    it.substring(1)
                }
            }
            val queryString: String? = groupValues[5]?.let {
                if (it.isBlank()) {
                    null
                } else {
                    it.substring(1)
                }
            }
            val fragment: String? = groupValues[6]?.let {
                if (it.isBlank()) {
                    null
                } else {
                    it.substring(1)
                }
            }
            return DidUrl(Did(method, id), path, queryString, fragment)
        }

        private fun require(value: String?): String? =
            if (value.isNullOrBlank()) {
                null
            } else {
                value
            }

        // From https://github.com/decentralized-identity/did-resolver/blob/master/src/resolver.ts (Apache 2.0)
        // with params removed
        private const val PCT_ENCODED = "(?:%[0-9a-fA-F]{2})"
        private const val ID_CHAR = "(?:[a-zA-Z0-9._-]|${PCT_ENCODED})"
        private const val METHOD = "([a-z0-9]+)"
        private const val METHOD_ID = "((?:${ID_CHAR}*:)*(${ID_CHAR}+))"
        private const val PATH = "(/[^#?]*)?"
        private const val QUERY = "([?][^#]*)?"
        private const val FRAGMENT = "(#.*)?"
        private val DID_MATCHER = Regex("^did:${METHOD}:${METHOD_ID}${PATH}${QUERY}${FRAGMENT}$")
    }
}