import { useState } from 'react';
import { Copy, Check } from './icons';

interface CopyToClipboardProps {
  textToCopy: string;
}

export const CopyToClipboard: React.FC<CopyToClipboardProps> = ({ textToCopy }) => {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopyToClipboard = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    try {
      await navigator.clipboard.writeText(textToCopy);
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 1000);
    } catch (error) {
      console.error('Failed to copy to clipboard:', error);
      setIsCopied(false);
    }
  };

  return (
    <button className="button button-small button-transparent" onClick={handleCopyToClipboard}>
      {isCopied ? <Check width="24" height="24" /> : <Copy width="24" height="24" />}
    </button>
  );
};
