/*
 * Copyright (C) 2023 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

package io.curity.ssi.json.data

import kotlinx.serialization.KSerializer
import kotlinx.serialization.builtins.MapSerializer
import kotlinx.serialization.builtins.serializer
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import kotlinx.serialization.json.Json
import kotlinx.serialization.json.JsonArray
import kotlinx.serialization.json.JsonElement
import kotlinx.serialization.json.JsonObject
import kotlinx.serialization.json.JsonPrimitive
import kotlinx.serialization.json.encodeToJsonElement


private fun <T> identity(t: T): T = t

fun JsonObject.getStringOrDefault(key: String, defaultValue: String = ""): String {
    val value = this[key]
    return if (value is JsonPrimitive && value.isString) value.content
    else defaultValue
}

/**
 * Helper class that allows deriving a serializable for type [DerivedSerializable]
 * given an existing serializer for type [Serializable] and functions that can convert
 * back/forth between [DerivedSerializable] and [Serializable].
 */
open class DerivedSerializer<Serializable, DerivedSerializable>(
    private val _delegate: KSerializer<Serializable>,
    val toSerializable: (DerivedSerializable) -> Serializable,
    val fromSerializable: (Serializable) -> DerivedSerializable
) : KSerializer<DerivedSerializable> {
    override val descriptor = _delegate.descriptor

    override fun serialize(encoder: Encoder, value: DerivedSerializable) =
        encoder.encodeSerializableValue(_delegate, toSerializable(value))

    override fun deserialize(decoder: Decoder): DerivedSerializable =
        fromSerializable(decoder.decodeSerializableValue(_delegate))
}

/**
 * Helper class to implement [KSerializer] for types that convert to [SubType]
 * that is itself serializable.
 *
 * This is used to make any subtype of [SuperType], not just [SubType], automatically serializable
 * by converting any other subtypes to the "canonical", serializable subtype.
 *
 * Deserializing [SuperType], however, will always result in the default [SubType] implementation.
 */
open class DelegateSerializer<SubType : SuperType, SuperType>(
    delegate: KSerializer<SubType>,
    converter: (SuperType) -> SubType
) : DerivedSerializer<SubType, SuperType>(
    delegate, converter, ::identity
)

internal object JsonDictionarySerializer : KSerializer<JsonDictionary> {
    private val _delegate = MapSerializer(String.serializer(), JsonElement.serializer())

    override val descriptor = _delegate.descriptor

    override fun serialize(encoder: Encoder, value: JsonDictionary) {
        encoder.encodeSerializableValue(_delegate, value.toMap())
    }

    override fun deserialize(decoder: Decoder): JsonDictionary {
        val map = decoder.decodeSerializableValue(_delegate)
        return JsonDictionary(map)
    }

}

internal object ToJsonDictionarySerializer : DelegateSerializer<JsonDictionary, ToJsonDictionary>(
    JsonDictionarySerializer, { toJson -> toJson.toJsonDictionary() }
)

/**
 * Base class for objects implementing [ToJson] and having the matching implementation
 * of [FromJson].
 */
abstract class JsonSerializer<T : ToJson>(
    private val _fromJson: FromJson<T>
) : KSerializer<T> {
    private val _delegate = JsonElement.serializer()

    override val descriptor = _delegate.descriptor

    override fun serialize(encoder: Encoder, value: T) {
        encoder.encodeSerializableValue(_delegate, value.toJson())
    }

    override fun deserialize(decoder: Decoder): T =
        _fromJson.fromJson(decoder.decodeSerializableValue(_delegate))

}

internal object AnyStringSerializer : JsonSerializer<AnyString>(AnyString)

internal object DefaultJsonMultiValueSerializer : KSerializer<DefaultJsonMultiValue<*>> {
    private val _delegate = JsonElement.serializer()

    override val descriptor = _delegate.descriptor

    override fun serialize(encoder: Encoder, value: DefaultJsonMultiValue<*>) {
        encoder.encodeSerializableValue(_delegate, value.toJson {
            when (it) {
                is JsonElement -> it
                is ToJson -> it.toJson()
                else -> Json.encodeToJsonElement(it)
            }
        })
    }

    override fun deserialize(decoder: Decoder): DefaultJsonMultiValue<JsonElement> {
        val result = _delegate.deserialize(decoder)
        if (result is JsonArray) {
            return DefaultJsonMultiValue(result.toList())
        }
        return DefaultJsonMultiValue(result)
    }

}
