/*
 * Copyright (C) 2023 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

package io.curity.vc.services

import io.curity.oauth.JsonOAuthErrorTokenResponse
import io.curity.oauth.JsonOAuthSuccessTokenResponse
import io.curity.oauth.OAuthAuthorizationRequest
import io.curity.oauth.OAuthAuthorizer
import io.curity.oauth.OAuthClient
import io.curity.oauth.OAuthCodeFlowClient
import io.curity.oauth.OAuthErrorTokenResponse
import io.curity.oauth.OAuthPreAuthorizedCodeFlowClient
import io.curity.oauth.OAuthRefreshFlowClient
import io.curity.ssi.crypto.SigningKeyPair
import io.curity.ssi.crypto.encodeBase64url
import io.curity.ssi.did.JwkDid
import io.curity.ssi.did.document.DidResolver
import io.curity.ssi.json.data.DefaultJsonMultiValue
import io.curity.ssi.json.data.JsonDictionary
import io.curity.ssi.sdjwt.vc.VcSdJwtIssuerMetadataLoader
import io.curity.vc.Offer
import io.curity.vc.ProofOfPossessionBuilder
import io.curity.vc.serialization.JsonCredentialConfigurationsSupported
import io.curity.vc.serialization.JsonCredentialIssuer
import io.curity.vc.serialization.JsonOffer
import io.curity.vc.serialization.JsonProofOfPossession
import io.curity.vc.serialization.JwtVcJsonAuthorizationDetail
import io.curity.vc.serialization.JwtVcJsonCredentialConfigurationsSupported
import io.curity.vc.serialization.JwtVcJsonVerifiableCredentialRequest
import io.curity.vc.serialization.ResolvedOffer
import io.curity.vc.serialization.UserVerifiableCredential
import io.curity.vc.serialization.VcSdJwtAuthorizationDetail
import io.curity.vc.serialization.VcSdJwtCredentialConfigurationsSupported
import io.curity.vc.serialization.VcSdJwtVerifiableCredentialRequest
import io.ktor.client.HttpClient
import io.ktor.client.call.body
import io.ktor.client.engine.HttpClientEngine
import io.ktor.client.plugins.contentnegotiation.ContentNegotiation
import io.ktor.client.request.get
import io.ktor.http.URLBuilder
import io.ktor.serialization.kotlinx.json.json
import kotlinx.datetime.Clock
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json
import kotlinx.serialization.json.JsonElement
import kotlinx.serialization.json.JsonPrimitive
import kotlin.random.Random

/**
 * High-level API to request verifiable credentials, given metadata entries or credential offers.
 * It is responsible for obtaining an adequate access token and construct one or more credential requests.
 */
class CredentialIssuanceCoordinator(
    private val clock: Clock,
    val oAuthClient: OAuthClient,
    private val didResolver: DidResolver<JsonElement>,
    private val oAuthAuthorizer: OAuthAuthorizer,
    private val httpClientEngine: HttpClientEngine,
    private val issuerLoader: CredentialIssuerLoader,
    private val vcSdJwtIssuerMetadataLoader: VcSdJwtIssuerMetadataLoader
) {

    private val json = Json

    /**
     * Receives an [offerString] with an encoded offer request and returns a [ResolvedOffer] with resolved
     * contents of that offer.
     * @param offerString a string containing the offer request URL
     * @return a [ResolvedOffer] with the offer information
     */
    suspend fun startHandleOffer(
        offerString: String,
    ): ResolvedOffer {

        val parameters = URLBuilder(offerString).build().parameters

        // parse the offer string to obtain an offer
        val credentialOfferString = parameters.getAll(Offer.Keys.CREDENTIAL_OFFER)
        val credentialOfferUri = parameters.getAll(Offer.Keys.CREDENTIAL_OFFER_URI)
        if (credentialOfferString != null && credentialOfferUri != null) {
            throw Exception("both '${Offer.Keys.CREDENTIAL_OFFER}' and '${Offer.Keys.CREDENTIAL_OFFER_URI}' are present")
        }
        val offer: JsonOffer = if (credentialOfferUri != null) {
            val url = credentialOfferUri.singleOrNull()?.let { URLBuilder(it).build() }
                ?: throw Exception("'${Offer.Keys.CREDENTIAL_OFFER_URI}' is empty or has more than one value")
            val httpClient = HttpClient(httpClientEngine) {
                install(ContentNegotiation) { json() }
            }
            val resp = httpClient.get(url)
            if (resp.status.value != 200) {
                throw throw Exception("Communication error while fetching the offer")
            }
            resp.body<JsonOffer>()
        } else if (credentialOfferString != null) {
            credentialOfferString.singleOrNull()?.let {
                Json.decodeFromString<JsonOffer>(it)
            }
                ?: throw Exception("'${Offer.Keys.CREDENTIAL_OFFER}' is empty or has more than one value")
        } else {
            throw Exception("both '${Offer.Keys.CREDENTIAL_OFFER}' and '${Offer.Keys.CREDENTIAL_OFFER_URI}' are missing")
        }

        val offerGrants = offer.grants
        if (offerGrants == null || (offerGrants.authorizationCode == null && offerGrants.preAuthorizedCode == null)) {
            throw Exception("Not enough grant information")
        }

        // load the issuer
        val issuer = issuerLoader.load(offer.credentialIssuer)

        // match offered credentials with issuer credentials
        val credentialsSupported = offer.credentialConfigurationIds.map { credentialSupportedId ->
            issuer.issuerMetadata.credentialConfigurationsSupported[credentialSupportedId]
                ?: throw Exception("Issue does not have supported Credential with ID '$credentialSupportedId'")
        }
        val resolvedOffer = ResolvedOffer(
            offer = offer,
            issuer = issuer,
            credentialsSupported = credentialsSupported,
        )

        return resolvedOffer
    }

    /**
     * Given a [ResolvedOffer], obtains the credentials offered by it.
     *
     * @param resolvedOffer a previously resolved offer.
     * @param credentialsSupported the subset of credentials supported by the offer that should be retrieved.
     * @param code the user provided code, when applicable.
     * @return a [OfferResult] containing the issued credential, in case of success, or an indication if the offer
     * or the user code are invalid.
     */
    suspend fun continueHandleOffer(
        resolvedOffer: ResolvedOffer,
        credentialsSupported: List<JsonCredentialConfigurationsSupported>,
        code: String?,
        signingKeyPair: SigningKeyPair,
    ): OfferResult {

        val offer = resolvedOffer.offer

        val offerGrants = offer.grants
        if (offerGrants == null || (offerGrants.authorizationCode == null && offerGrants.preAuthorizedCode == null)) {
            throw Exception("Not enough grant information")
        }

        if (resolvedOffer.txCode != null && code == null) {
            throw Exception("User code is required")
        }

        val offerGrantsPreAuthorizedCode = offerGrants.preAuthorizedCode
        return if (offerGrantsPreAuthorizedCode != null
            && (offerGrantsPreAuthorizedCode.txCode == null || code != null)
        ) {
            requestCredentialsUsingPreAuthorizedCode(
                resolvedOffer.issuer,
                // TODO support multiple
                credentialsSupported.first(),
                offerGrantsPreAuthorizedCode.preAuthorizedCode,
                code,
                signingKeyPair
            )
        } else if (offerGrants.authorizationCode != null) {
            OfferResult.IssuedCredentials(
                requestCredentials(
                    resolvedOffer.issuer,
                    // TODO support multiple
                    credentialsSupported.first(),
                    signingKeyPair,
                )
            )
        } else {
            throw Exception("No grant available")
        }
    }

    private suspend fun requestCredentialsUsingPreAuthorizedCode(
        issuer: JsonCredentialIssuer,
        credentialSupported: JsonCredentialConfigurationsSupported,
        preAuthorizedCode: String,
        code: String?,
        signingKeyPair: SigningKeyPair,
    ): OfferResult {

        val preAuthorizedCodeFlowClient = OAuthPreAuthorizedCodeFlowClient(
            httpClientEngine,
            oAuthClient,
            issuer.authorizationServerMetadata.tokenEndpoint
        )

        return when (val tokenResponse = preAuthorizedCodeFlowClient.requestToken(preAuthorizedCode, code)) {
            is JsonOAuthSuccessTokenResponse -> {
                val refreshFlowClient = OAuthRefreshFlowClient(
                    httpClientEngine,
                    oAuthClient,
                    issuer.authorizationServerMetadata.tokenEndpoint
                )

                OfferResult.IssuedCredentials(
                    requestCredential(
                        tokenResponse,
                        refreshFlowClient,
                        issuer,
                        signingKeyPair,
                        credentialSupported
                    )
                )
            }

            is JsonOAuthErrorTokenResponse -> {
                if (tokenResponse.error == OAuthErrorTokenResponse.Errors.INVALID_GRANT) {
                    OfferResult.InvalidOfferOrUserCode
                } else {
                    throw Exception("Unexpected error when using the pre-authorized code flow: ${tokenResponse.error}")
                }
            }
        }
    }

    /**
     * Returns one or more credentials for a provided `supported_credentials` entry.
     * @param issuer the credential issuer
     * @param credentialSupported information about the credentials to issue
     */
    // TODO - receive a selection of the optional claims to include (or a callback for that selection)
    // TODO - does not yet support credential identifiers
    suspend fun requestCredentials(
        issuer: JsonCredentialIssuer,
        credentialSupported: JsonCredentialConfigurationsSupported,
        signingKeyPair: SigningKeyPair
    ): List<UserVerifiableCredential> {

        // Build the authorization_details to use on the Authorization Request
        val serializedAuthorizationDetails =
            when (credentialSupported) {
                is JwtVcJsonCredentialConfigurationsSupported -> {
                    json.encodeToString(
                        listOf(
                            JwtVcJsonAuthorizationDetail(
                                types = credentialSupported.credentialDefinition.type.toSet(),
                                credentialSubject = null
                            )
                        )
                    )
                }

                is VcSdJwtCredentialConfigurationsSupported -> {
                    json.encodeToString(
                        listOf(
                            VcSdJwtAuthorizationDetail(
                                vct = credentialSupported.vct,
                                claims = null,
                            )
                        )
                    )
                }

                else -> throw Exception("Format '${credentialSupported.format}' is not supported")
            }

        // Create the Authorization Request and delegate its execution on the OAuthAuthorizer
        val authorizationRequest = OAuthAuthorizationRequest(
            clientId = oAuthClient.id,
            authorizationDetails = serializedAuthorizationDetails,
            redirectUri = oAuthClient.redirectUri,
            state = encodeBase64url(Random.nextBytes(128 / 8)),
        )

        val authorizationResponse = oAuthAuthorizer.authorize(
            issuer.authorizationServerMetadata.authorizationEndpoint,
            authorizationRequest
        )
        if (authorizationResponse.state != authorizationRequest.state) {
            throw Exception("Authorization response state is not valid.")
        }

        // Exchange the authorization code for an access token and extra information
        val code = authorizationResponse.code ?: throw Exception("authorization response is missing a code")
        val codeFlowClient = OAuthCodeFlowClient(
            httpClientEngine,
            oAuthClient,
            issuer.authorizationServerMetadata.tokenEndpoint
        )
        val refreshFlowClient = OAuthRefreshFlowClient(
            httpClientEngine,
            oAuthClient,
            issuer.authorizationServerMetadata.tokenEndpoint
        )
        val tokenResponse = codeFlowClient.requestToken(code)

        return requestCredential(tokenResponse, refreshFlowClient, issuer, signingKeyPair, credentialSupported)
    }

    private suspend fun requestCredential(
        tokenResponse: JsonOAuthSuccessTokenResponse,
        refreshFlowClient: OAuthRefreshFlowClient,
        issuer: JsonCredentialIssuer,
        signingKeyPair: SigningKeyPair,
        credentialSupported: JsonCredentialConfigurationsSupported
    ): List<UserVerifiableCredential> {
        // FIXME c_nonce is not mandatory, so this code should be able to handle that scenario
        val cNonce = tokenResponse.cNonce
            ?: throw Exception("token response is missing the c_nonce")

        val httpClient = HttpClient(httpClientEngine) {
            install(ContentNegotiation) { json() }
        }

        httpClient.installOAuthSupport(tokenResponse, refreshFlowClient)

        val keyProof = when (credentialSupported) {
            is JwtVcJsonCredentialConfigurationsSupported -> {
                // Using DIDs for W3C-based credentials
                ProofOfPossessionBuilder.createJwtProofTokenUsingDid(
                    signingKeyPair,
                    JwkDid.toDidUrl(signingKeyPair.verificationKey),
                    "ES256",
                    oAuthClient.id,
                    DefaultJsonMultiValue(issuer.issuerMetadata.credentialIssuer),
                    clock.now(),
                    mapOf("nonce" to JsonPrimitive(cNonce))
                )
            }

            is VcSdJwtCredentialConfigurationsSupported -> {
                // Using JWK for SD-JWT-based credentials
                ProofOfPossessionBuilder.createJwtProofTokenUsingJwk(
                    signingKeyPair,
                    "ES256",
                    oAuthClient.id,
                    DefaultJsonMultiValue(issuer.issuerMetadata.credentialIssuer),
                    clock.now(),
                    mapOf("nonce" to JsonPrimitive(cNonce))
                )
            }

            else -> throw Exception("Unsupported supported credential ${credentialSupported::class.simpleName}")
        }


        val validatedResponse = when (credentialSupported) {
            is JwtVcJsonCredentialConfigurationsSupported -> {
                val vcClient = VcClient(
                    httpClient,
                    issuer.issuerMetadata,
                    DidSubjectVerifiableCredentialResponseValidator(
                        issuer = issuer,
                        didSubjectIdentifier = JwkDid.toDidUrl(signingKeyPair.verificationKey),
                        supportedCredential = credentialSupported,
                        didResolver = didResolver,
                    )
                )
                vcClient.requestCredential(
                    JwtVcJsonVerifiableCredentialRequest(
                        credentialDefinition = JwtVcJsonVerifiableCredentialRequest.CredentialDefinition(
                            type = credentialSupported.credentialDefinition.type,
                        ),
                        proof = JsonProofOfPossession(
                            "jwt",
                            customClaims = JsonDictionary(mapOf("jwt" to JsonPrimitive(keyProof)))
                        ),
                    )
                )
            }

            is VcSdJwtCredentialConfigurationsSupported -> {
                val vcClient = VcClient(
                    httpClient,
                    issuer.issuerMetadata,
                    VcSdJwtVerifiableCredentialResponseValidator(
                        clock,
                        vcSdJwtIssuerMetadataLoader,
                        issuer,
                        credentialSupported,
                        signingKeyPair,
                    )
                )
                vcClient.requestCredential(
                    VcSdJwtVerifiableCredentialRequest(
                        vct = credentialSupported.vct,
                        proof = JsonProofOfPossession(
                            "jwt",
                            customClaims = JsonDictionary(mapOf("jwt" to JsonPrimitive(keyProof)))
                        ),
                    )
                )
            }

            else -> throw Exception("Unsupported credential ${credentialSupported::class.simpleName}")
        }

        return listOf(
            UserVerifiableCredential(
                localId = UserVerifiableCredential.newId(),
                issuerId = issuer.issuerMetadata.credentialIssuer,
                issuedAt = Clock.System.now(),
                credentialResponse = validatedResponse,
                userDefinedName = ""
            )
        )
    }
}

sealed class OfferResult {
    data class IssuedCredentials(
        val credentials: List<UserVerifiableCredential>
    ) : OfferResult()

    data object InvalidOfferOrUserCode : OfferResult()
}