/*
 * Copyright (C) 2023 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

export const About: React.FC = () => (
  <div className="flex flex-column h100">
    <h1>Curity Demo Wallet</h1>
    <p>Copyright (C) 2023 Curity AB. All rights reserved.</p>
  </div>
);
