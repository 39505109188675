package io.curity.vc.serialization

import io.curity.vc.Offer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class JsonOffer(
    @SerialName(Offer.Keys.CREDENTIAL_ISSUER)
    override val credentialIssuer: String,

    @SerialName(Offer.Keys.CREDENTIAL_CONFIGURATION_IDS)
    override val credentialConfigurationIds: List<String>,

    @SerialName(Offer.Keys.GRANTS)
    override val grants: JsonGrants? = null
) : Offer {

    @Serializable
    data class JsonGrants(
        @SerialName(Offer.Grants.AuthorizationCode.Keys.TYPE)
        override val authorizationCode: JsonAuthorizationCode? = null,
        @SerialName(Offer.Grants.PreAuthorizedCode.Keys.TYPE)
        override val preAuthorizedCode: JsonPreAuthorizedCode? = null,
    ) : Offer.Grants {

        @Serializable
        data class JsonAuthorizationCode(
            @SerialName(Offer.Grants.AuthorizationCode.Keys.ISSUER_STATE)
            override val issuerState: String? = null,
            @SerialName(Offer.Grants.AuthorizationCode.Keys.AUTHORIZATION_SERVER)
            override val authorizationServer: String? = null,
        ) : Offer.Grants.AuthorizationCode

        @Serializable
        data class JsonPreAuthorizedCode(
            @SerialName(Offer.Grants.PreAuthorizedCode.Keys.PRE_AUTHORIZED_CODE)
            override val preAuthorizedCode: String,
            @SerialName(Offer.Grants.PreAuthorizedCode.Keys.TX_CODE)
            override val txCode: JsonTxCode? = null,
            @SerialName(Offer.Grants.PreAuthorizedCode.Keys.INTERVAL)
            override val interval: Long = 5,
            @SerialName(Offer.Grants.PreAuthorizedCode.Keys.AUTHORIZATION_SERVER)
            override val authorizationServer: String? = null,
        ) : Offer.Grants.PreAuthorizedCode {

            @Serializable
            data class JsonTxCode(
                @SerialName(Offer.Grants.PreAuthorizedCode.TxCode.Keys.INPUT_MODE)
                override val inputMode: String? = null,
                @SerialName(Offer.Grants.PreAuthorizedCode.TxCode.Keys.LENGTH)
                override val length: Int? = null,
                @SerialName(Offer.Grants.PreAuthorizedCode.TxCode.Keys.DESCRIPTION)
                override val description: String? = null,
            ) : Offer.Grants.PreAuthorizedCode.TxCode
        }
    }
}

