/*
 * Copyright (C) 2023 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

package io.curity.vp.services

import io.curity.vp.VerifierDirectPostAuthorizationResponse
import io.curity.vp.serialization.JsonVerifierDirectPostAuthorizationResponse
import io.curity.vp.services.VerifiablePresentationResponseParameters.Companion.PRESENTATION_SUBMISSION_PARAM_NAME
import io.curity.vp.services.VerifiablePresentationResponseParameters.Companion.STATE_PARAM_NAME
import io.curity.vp.services.VerifiablePresentationResponseParameters.Companion.VP_TOKEN_PARAM_NAME
import io.ktor.client.HttpClient
import io.ktor.client.call.body
import io.ktor.client.request.forms.submitForm
import io.ktor.client.request.get
import io.ktor.client.request.header
import io.ktor.http.HttpStatusCode
import io.ktor.http.Parameters
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json

class VerifierClient(
    private val httpClient: HttpClient,
    private val validator: HaipRequestObjectJwtValidator,
) {

    suspend fun fetchRequestObjectJwt(uri: String): ValidatedRequestJwt {
        val result = httpClient.get(uri) {
            header("Accept", "application/jwt")
        }

        if (result.status != HttpStatusCode.OK) {
            throw RuntimeException("Unexpected response while fetching request object. Got status: ${result.status.value}")
        }

        return validator.validate(result.body())
    }

    suspend fun postDirectPostAuthorizationResponse(
        uri: String,
        params: VerifiablePresentationResponseParameters
    ): VerifierDirectPostAuthorizationResponse {
        val presSub = Json.encodeToString(params.presentationSubmission)
        val vpToken = Json.encodeToString(params.vpToken)
        val result = httpClient.submitForm(
            url = uri,
            formParameters = Parameters.build {
                append(VP_TOKEN_PARAM_NAME, vpToken)
                append(PRESENTATION_SUBMISSION_PARAM_NAME, presSub)
                if (params.state != null) {
                    append(STATE_PARAM_NAME, params.state)
                }
            }
        )

        return if (result.status == HttpStatusCode.OK) {
            result.body<JsonVerifierDirectPostAuthorizationResponse>()
        } else {
            throw Exception("Validating verifiable presentations failed: ${result.status}")
        }
    }
}