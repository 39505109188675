/*
 * Copyright (C) 2024 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

package io.curity.ssi.sdjwt.vc

import io.curity.ssi.jose.JsonJwks
import io.curity.ssi.jose.Jwks
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.json.JsonElement

/**
 * Represents the SD-JWT VC issuer metadata, as documented in
 * [https://www.ietf.org/archive/id/draft-ietf-oauth-sd-jwt-vc-03.html#name-jwt-vc-issuer-metadata](https://www.ietf.org/archive/id/draft-ietf-oauth-sd-jwt-vc-03.html#name-jwt-vc-issuer-metadata)
 */
interface VcSdJwtIssuerMetadata<T> {
    object Keys {
        const val ISSUER = "issuer"
        const val JWKS = "jwks"
    }

    val issuer: String
    val jwks: Jwks<T>
}

@Serializable
data class JsonVcSdJwtIssuerMetadata(
    @SerialName(VcSdJwtIssuerMetadata.Keys.ISSUER)
    override val issuer: String,
    @SerialName(VcSdJwtIssuerMetadata.Keys.JWKS)
    override val jwks: JsonJwks
) : VcSdJwtIssuerMetadata<JsonElement>
