/*
 * Copyright (C) 2023 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

package io.curity.vc.serialization

import io.curity.ssi.json.data.CompositeFromJson
import io.curity.ssi.json.data.FromJson
import io.curity.ssi.json.data.JsonDictionary
import io.curity.ssi.json.data.ToJsonDictionary
import io.curity.ssi.json.data.TypedDictionaryFromJson
import io.curity.vc.Proof
import io.curity.vc.ProofOfPossession
import kotlinx.serialization.Serializable
import kotlinx.serialization.json.JsonElement
import kotlinx.serialization.json.JsonPrimitive

@Serializable(with = ToJsonProofOfPossessionSerializer::class)
interface ToJsonProofOfPossession : ProofOfPossession<JsonElement>, ToJsonDictionary {
    fun toJsonProofOfPossession(): JsonProofOfPossession

    override fun toJsonDictionary(): JsonDictionary = toJsonProofOfPossession().toJsonDictionary()
}

/**
 * Default implementation of [ToJsonProofOfPossession].
 */
@Serializable(with = JsonProofOfPossessionSerializer::class)
data class JsonProofOfPossession(
    override val proofType: String,
    val customClaims: JsonDictionary? = null
) : ToJsonProofOfPossession {

    companion object : FromJson<JsonProofOfPossession> {
        private val _serialized = TypedDictionaryFromJson(ProofOfPossession.Keys.PROOF_TYPE)

        override fun fromJson(json: JsonElement): JsonProofOfPossession {
            val typedDictionary = _serialized.fromJson(json)
            return JsonProofOfPossession(typedDictionary.type, typedDictionary.customClaims)
        }
    }

    override fun toJsonDictionary(): JsonDictionary = _serialized.toJson(proofType, customClaims)

    override fun toJsonProofOfPossession() = this
}

/**
 * Objects of this type contain a single `jwt` element with a JWS [RFC7515](https://openid.net/specs/openid-4-verifiable-credential-issuance-1_0.html#RFC7515)
 * as proof of possession.
 */
@Serializable(with = JwtProofOfPossessionSerializer::class)
data class JwtProofOfPossession(
    private val _jwt: String
) : ToJsonProofOfPossession {

    companion object : CompositeFromJson<JsonProofOfPossession, JwtProofOfPossession>(
        JsonProofOfPossession
    ), FromJson<JwtProofOfPossession> {
        const val JWT = "jwt"

        override fun fromSerialized(toJson: JsonProofOfPossession): JwtProofOfPossession {
            val serialized = toJson.toJsonProofOfPossession()
            val type = toJson.proofType
            return if (serialized.proofType == JWT) {
                val customClaims = serialized.customClaims ?: error("Missing the \"$JWT\" claim")
                if (customClaims.keys.size == 1 && customClaims.keys.first() == JWT) {
                    val jwt = customClaims[JWT]
                    if (jwt is JsonPrimitive && jwt.isString) JwtProofOfPossession(jwt.content)
                    else error("\"$JWT\" is not a String")
                } else error("Expected a single \"$JWT\" custom claim but got '${serialized.customClaims.keys}'")
            } else error("Unexpected \"${ProofOfPossession.Keys.PROOF_TYPE}\": '$type'")
        }
    }

    override val proofType: String = JWT

    private val _serialized by lazy {
        mapOf(
            ProofOfPossession.Keys.PROOF_TYPE to JsonPrimitive(JWT),
            JWT to JsonPrimitive(_jwt)
        )
    }

    override fun toMap() = _serialized

    override fun toJsonDictionary() = JsonDictionary(toMap())

    override fun toJsonProofOfPossession(): JsonProofOfPossession {
        return JsonProofOfPossession(
            JWT,
            customClaims = JsonDictionary(mapOf(JWT to JsonPrimitive(_jwt)))
        )
    }
}

@Serializable(with = ToJsonProofSerializer::class)
interface ToJsonProof : Proof<JsonElement>, ToJsonDictionary {
    fun toJsonProof(): JsonProof

    override fun toJsonDictionary(): JsonDictionary = toJsonProof().toJsonDictionary()
}

/**
 * Default implementation of [ToJsonProof].
 */
@Serializable(with = JsonProofSerializer::class)
data class JsonProof(
    override val type: String,
    val customClaims: JsonDictionary? = null
) : ToJsonProof {

    companion object : FromJson<JsonProof> {
        private val _serialized = TypedDictionaryFromJson(Proof.Keys.TYPE)

        override fun fromJson(json: JsonElement): JsonProof {
            val typedDictionary = _serialized.fromJson(json)
            return JsonProof(typedDictionary.type, typedDictionary.customClaims)
        }
    }

    override fun toJsonDictionary(): JsonDictionary = _serialized.toJson(type, customClaims)

    override fun toJsonProof() = this
}
