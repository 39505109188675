/*
 * Copyright (C) 2023 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import { Link, useParams } from 'react-router-dom';
import { verifiableCredentialStore } from '../ssi-libs';
import { Dispatch, useMemo, useReducer } from 'react';
import { VerifiableCredential as ShowVerifiableCredential } from '../components/VerifiableCredential';
import { JsUserVerifiableCredential } from 'curity-ssi-libs-verifiable-credentials-vc-ks-services';
import { SuccessCheckmark } from '../components/SuccessCheckmark';
import { routes } from '../routes';
import { Alert } from '../components/Alert';

type State =
  | { tag: 'loaded' }
  | { tag: 'removal-confirmation' }
  | { tag: 'removal-cancel' }
  | { tag: 'error'; message: string }
  | { tag: 'removed' };

type Action = { type: 'remove' } | { type: 'cancel-remove' } | { type: 'removed' } | { type: 'error'; message: string };

function reducer(state: State, action: Action): State {
  switch (state.tag) {
    case 'loaded':
      if (action.type == 'remove') {
        return { tag: 'removal-confirmation' };
      }
      break;
    case 'removal-confirmation':
      if (action.type == 'removed') {
        return { tag: 'removed' };
      }
      if (action.type == 'cancel-remove') {
        return { tag: 'loaded' };
      }
      if (action.type == 'error') {
        return { tag: 'error', message: action.message };
      }
      break;
  }
  return state;
}

export function CredentialDetail() {
  const credentialId = useParams().id;
  const [state, dispatch] = useReducer(reducer, { tag: 'loaded' });
  const credential = useMemo(() => verifiableCredentialStore.getById(credentialId), [credentialId]);
  if (!credential) {
    return (
      <div className="h100 flex flex-column flex-center justify-center">
        <h1>Oops, credential not found</h1>
        <Link className="button button-primary-outline button-medium button-fullwidth" to="/">
          Home
        </Link>
      </div>
    );
  }
  switch (state.tag) {
    case 'loaded':
      return renderLoaded(credential, dispatch);
    case 'removal-confirmation':
      return renderRemovalConfirmation(credential, dispatch);
    case 'removed':
      return renderRemoved();
    case 'error':
      return renderRemovalError(state.message);
  }
  return <ShowVerifiableCredential credential={credential} detailLevel="detailed" />;
}

function renderLoaded(credential: JsUserVerifiableCredential, dispatch: Dispatch<Action>) {
  return (
    <>
      <ShowVerifiableCredential credential={credential} detailLevel="detailed" />
      <button
        className="button button-medium button-fullwidth button-danger mt2"
        onClick={() => dispatch({ type: 'remove' })}
      >
        Remove credential
      </button>
    </>
  );
}

function renderRemovalConfirmation(credential: JsUserVerifiableCredential, dispatch: Dispatch<Action>) {
  function handleRemove() {
    try {
      verifiableCredentialStore.delete(credential.id);
      dispatch({ type: 'removed' });
    } catch (error) {
      dispatch({ type: 'error', message: error.message });
    }
  }

  return (
    <>
      <ShowVerifiableCredential credential={credential} detailLevel="detailed" />
      <div className="flex flex-column flex-gap-2 mt2">
      <button className="button button-medium button-fullwidth button-danger" onClick={handleRemove}>
        Confirm credential removal
      </button>
      <button
        className="button button-medium button-fullwidth button-primary-outline"
        onClick={() => dispatch({ type: 'cancel-remove' })}
      >
        Cancel
      </button>
      </div>
    </>
  );
}

function renderRemoved() {
  return (
    <>
      <div className="h100 flex flex-column flex-center justify-center">
        <div className="center">
          <SuccessCheckmark />

          <h1 className="h3 mt0">Success</h1>
          <p>Credential was successfully removed</p>
        </div>

        <Link to={routes.HOME} className="button button-medium button-primary button-fullwidth">
          View Credentials
        </Link>
      </div>
    </>
  );
}

function renderRemovalError(errorMessage: string) {
  return (
    <Alert
      kind="danger"
      title="There was an error removing the credential"
      errorMessage={errorMessage}
      link={routes.HOME}
      linkText="Credential list"
    />
  );
}
