/*
 * Copyright (C) 2024 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

package io.curity.ssi.jose

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.json.JsonElement

/**
 * Serialization dependent representation for a JWK
 */
@Serializable
class JsonJwks(
    @SerialName(Jwks.Keys.KEYS)
    override val keys: List<JsonJwk>,
) : Jwks<JsonElement> {

    override fun getKeyWithKid(id: String): Jwk<JsonElement>? =
        keys.firstOrNull { it.kid == id }

}
