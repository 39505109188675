/*
 * Copyright (C) 2024 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

package io.curity.ssi.sdjwt

import io.curity.ssi.crypto.HashFunction
import kotlinx.serialization.json.Json
import kotlinx.serialization.json.JsonElement

/**
 * Maintains an associated between disclosures hashes and disclosures.
 */
class DisclosuresByHash private constructor(
    private val disclosureStringsByHash: Map<String, String>,
    private val json: Json,
) {

    fun getProperty(hash: String): Triple<String, JsonElement, String>? =
        disclosureStringsByHash[hash]?.let { disclosureString ->
            val disclosure = Disclosure.parse(disclosureString, json)
            if (disclosure !is Disclosure.Property) {
                throw IllegalArgumentException("Property is using a disclosure of the wrong type")
            }
            Triple(disclosure.name, disclosure.value, disclosureString)
        }

    fun getArrayValue(hash: String): Pair<JsonElement, String>? =
        disclosureStringsByHash[hash]?.let { disclosureString ->
            val disclosure = Disclosure.parse(disclosureString, json)
            if (disclosure !is Disclosure.Value) {
                throw IllegalArgumentException("Array value is using a disclosure of the wrong type")
            }
            Pair(disclosure.value, disclosureString)
        }

    companion object {
        suspend fun create(disclosureStrings: List<String>, hashFunction: HashFunction, json: Json = Json) =
            DisclosuresByHash(
                disclosureStrings.associateBy {
                    Disclosure.hashDisclosure(hashFunction, it)
                },
                json
            )
    }
}