/*
 * Copyright (C) 2023 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

package io.curity.vp.services

import io.curity.ssi.json.data.DefaultJsonMultiValue
import io.curity.vp.JwtVpJsonFormat
import io.curity.vp.serialization.JsonPresentationSubmission
import io.curity.vp.serialization.JsonVerifiablePresentation
import kotlinx.serialization.Serializable

@Serializable
data class PreRegisteredClient(
    val clientId: String,
    val jwksUri: String,
    val redirectUri: String,
    val vpFormatsSupported: List<VpFormat>
)

@Serializable
data class VpFormat(
    val format: String,
    val algs: Set<String>,
)

object WalletMetadata {
    val vpFormatsSupported: List<VpFormat> = listOf(
        VpFormat(
            JwtVpJsonFormat.FORMAT,
            setOf("EdDSA", "ES256", "ES384", "ES512", "RS256", "RS384", "RS512", "PS256", "PS384", "PS512")
        )
    )
}

data class VerifiablePresentationResponseParameters(
    val vpToken: DefaultJsonMultiValue<JsonVerifiablePresentation>,
    val presentationSubmission: JsonPresentationSubmission,
    val state: String?,
) {

    companion object {
        const val VP_TOKEN_PARAM_NAME = "vp_token"
        const val PRESENTATION_SUBMISSION_PARAM_NAME = "presentation_submission"
        const val STATE_PARAM_NAME = "state"
    }
}