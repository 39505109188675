/*
 * Copyright (C) 2024 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import { useEffect, useState } from 'react';
import { Chevron } from '../icons';
import { Link } from 'react-router-dom';
import { JsUserVerifiableCredential } from 'curity-ssi-libs-verifiable-credentials-vc-ks-services';
import * as styles from './picker.module.css';
import { getCredentialSimpleLabel } from '../getCredentialSimpleLabel';
import { useLanguages } from '../LanguagesContext';

interface PickerProps {
  callToAction: string;
  viewMessage: string;
  credentials: Array<JsUserVerifiableCredential>;
  onCredentialSelected: (selectedItem: JsUserVerifiableCredential) => void;
}

export const Picker: React.FC<PickerProps> = ({ credentials, callToAction, viewMessage, onCredentialSelected }) => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const languages = useLanguages();

  const toggleMenu = () => setMenuOpen(!isMenuOpen);
  const closeMenu = () => setMenuOpen(false);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        closeMenu();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <button
      className={`button button-primary-outline flex-column p0 w100 relative ${styles['picker-button']}`}
      aria-controls="popup-menu"
      aria-expanded={isMenuOpen}
      aria-haspopup="true"
      onClick={toggleMenu}
    >
      <div className="flex flex-center justify-between p2">
        <span>{callToAction}</span>
        <div
          style={{
            transition: '150ms ease-in-out',
            transformOrigin: 'center',
            transform: `${isMenuOpen ? 'rotate(180deg)' : 'rotate(0)'}`,
          }}
        >
          <Chevron />
        </div>
      </div>

      {isMenuOpen && (
        <ul id="popup-menu" className={`m0 list-reset absolute w100 br-8 ${styles['picker-button-list']}`}>
          {credentials.map(item => (
            <li className="flex flex-center justify-between hover-bg-light" key={item.id}>
              <div className="flex-auto">
                <span
                  role="button"
                  aria-pressed="false"
                  aria-label={callToAction}
                  className={`button button-tiny button-link justify-start w100 p2 ${styles['picker-button-item']}`}
                  onClick={() => onCredentialSelected(item)}
                >
                  <span
                    className={`${styles['picker-button-item-label']}`}
                    title={getCredentialSimpleLabel(item, languages)}
                  >
                    {getCredentialSimpleLabel(item, languages)}
                  </span>
                </span>
              </div>

              {viewMessage && item.id ? (
                <div>
                  <Link
                    to={`/credentials/${item.id}`}
                    className="button button-tiny button-transparent"
                    aria-label={viewMessage}
                  >
                    <small>
                      <em>{viewMessage}</em>
                    </small>
                  </Link>
                </div>
              ) : null}
            </li>
          ))}
        </ul>
      )}
    </button>
  );
};
