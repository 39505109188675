/*
 * Copyright (C) 2023 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';

import { routes } from './routes';
import { Home } from './pages/Home';
import { About } from './pages/About';
import { IssuerList } from './pages/IssuerList';
import { IssuerDetail } from './pages/IssuerDetail';
import { IssuerAdd } from './pages/IssuerAdd';
import { loadStoredCredentialIssuerById, loadStoredCredentialIssuers } from './loaders';
import { CredentialIssuance } from './pages/CredentialIssuance';
import { OAuthCallback } from './pages/OAuthCallback';
import '@curity-internal/css/dist/index.css';
import './styles/global.css';
import { Layout } from './components/layout/Layout';
import { CredentialList } from './pages/CredentialList';
import { CredentialDetail } from './pages/CredentialDetail';
import { LanguagesProvider } from './components/LanguagesContext';
import { Page } from './components/page/Page';
import { Settings } from './pages/Settings';
import { Start } from './pages/Start';
import { CredentialOfferResolve } from './pages/CredentialOfferResolve';
import { Scan } from './pages/Scan';
import { PresentationRequestResolve } from './pages/PresentationRequestResolve';
import { Analytics } from './components/analytics/Analytics';

const router = createBrowserRouter([
  {
    path: '/',

    element: <Layout />,
    children: [
      {
        path: routes.HOME,
        element: (
          <Page title="Curity Demo Wallet">
            <Home />
          </Page>
        ),
      },
      {
        path: routes.ABOUT,
        element: (
          <Page title="About" backButton={-1}>
            <About />
          </Page>
        ),
      },
      {
        path: routes.START,
        element: (
          <Page title="Start" backButton={-1}>
            <Start />
          </Page>
        ),
      },
      {
        path: routes.OFFER_RESOLVE,
        element: (
          <Page title="Resolve Offer" backButton={-1}>
            <CredentialOfferResolve />
          </Page>
        ),
      },
      {
        path: routes.SCAN,
        element: (
          <Page title="Scan QR Code" backButton={-1}>
            <Scan />
          </Page>
        ),
      },
      {
        path: routes.ISSUER_LIST,
        element: (
          <Page title="Issuers" backButton={-1}>
            <IssuerList />
          </Page>
        ),
        loader: loadStoredCredentialIssuers,
      },
      {
        path: routes.SETTINGS,
        element: (
          <Page title="Settings" backButton={-1}>
            <Settings />
          </Page>
        ),
      },
      {
        path: routes.ISSUER_DETAIL,
        element: (
          <Page title="Issuer" backButton={-1}>
            <IssuerDetail />
          </Page>
        ),
        loader: loadStoredCredentialIssuerById,
      },
      {
        path: routes.ISSUER_ADD,
        element: (
          <Page title="Add issuer" backButton={-1}>
            <IssuerAdd />
          </Page>
        ),
      },
      {
        path: routes.CREDENTIAL_ISSUANCE,
        element: (
          <Page title="Request credential" backButton={-1}>
            <CredentialIssuance />
          </Page>
        ),
        loader: loadStoredCredentialIssuerById,
      },
      {
        path: routes.CREDENTIAL_LIST,
        element: (
          <Page title="Credentials" backButton={-1}>
            <CredentialList />
          </Page>
        ),
      },
      {
        path: routes.CREDENTIAL_DETAIL,
        element: (
          <Page title="Credential" backButton={-1}>
            <CredentialDetail />
          </Page>
        ),
      },
      {
        path: routes.OAUTH_CALLBACK,
        element: <OAuthCallback />,
      },
      {
        path: routes.PRESENTATION_RESOLVE,
        element: (
          <Page title="Present Credentials" backButton={-1}>
            <PresentationRequestResolve />
          </Page>
        ),
      },
    ],
  },
  {
    // fallback route (not sure if needed)
    path: '*',
    element: <Navigate to="/" />,
  },
]);

export function App() {
  return (
    <LanguagesProvider>
      <RouterProvider router={router} />
      <Analytics />
    </LanguagesProvider>
  );
}
