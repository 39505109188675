/*
 * Copyright (C) 2023 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

package io.curity.vc.serialization

import io.curity.ssi.crypto.encodeBase64url
import io.curity.ssi.json.data.FromJson
import io.curity.ssi.json.data.FromJsonHelper
import io.curity.ssi.json.data.JsonDictionary
import io.curity.ssi.json.data.ToJson
import io.curity.ssi.json.data.ToJsonDictionary
import io.curity.vc.CredentialDefinition
import io.curity.vc.CredentialEndpoint
import io.curity.vc.CredentialIssuer
import kotlinx.datetime.Instant
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.json.Json
import kotlinx.serialization.json.JsonElement
import kotlinx.serialization.json.JsonPrimitive
import kotlin.random.Random

@Serializable(with = JsonVerifiableCredentialSerializer::class)
sealed interface JsonVerifiableCredential : ToJson {
    companion object : FromJson<JsonVerifiableCredential> {
        override fun fromJson(json: JsonElement): JsonVerifiableCredential {
            return if (json is JsonPrimitive && json.isString) JsonStringVerifiableCredential(json.content)
            else JsonDictionaryVerifiableCredential(
                JsonDictionary(
                    FromJsonHelper.requireJsonObject(json, "JsonDictionaryVerifiableCredential")
                )
            )
        }
    }

    fun toVerifiableCredential(): CredentialEndpoint.VerifiableCredential

    data class JsonStringVerifiableCredential(
        val value: String
    ) : JsonVerifiableCredential {
        override fun toVerifiableCredential() = CredentialEndpoint.VerifiableCredential.StringCredential(value)

        override fun toJson() = JsonPrimitive(value)
    }

    data class JsonDictionaryVerifiableCredential(
        val value: JsonDictionary
    ) : JsonVerifiableCredential, ToJsonDictionary {

        override fun toVerifiableCredential() = CredentialEndpoint.VerifiableCredential.DictionaryCredential(value)

        override fun toJsonDictionary() = value
    }
}


@Serializable
data class JsonCredentialDefinition(
    @SerialName(CredentialDefinition.Keys.CONTEXT)
    override val context: List<String>,
    @SerialName(CredentialDefinition.Keys.TYPES)
    override val types: Set<String>,
    @SerialName(CredentialDefinition.Keys.CREDENTIAL_SUBJECT)
    override val credentialSubject: ToJsonDictionary,
) : CredentialDefinition<JsonElement>, ToJson {
    override fun toJson() = Json.encodeToJsonElement(serializer(), this)
}

/**
 * A user-managed verifiable credential.
 *
 * This type contains some metadata returned by the Verifiable Credential Response,
 * plus the actual credential itself.
 */
@Serializable
data class UserVerifiableCredential(
    /**
     * Locally defined ID for the credential.
     * This is managed by the user and is not part of the actual credential data.
     */
    val localId: String,

    /**
     * The ID of the issuer of this credential.
     */
    val issuerId: String,

    /**
     * The Instant this credential was issued.
     */
    val issuedAt: Instant,

    /**
     * The user defined name for this credential.
     */
    val userDefinedName: String,

    /**
     * The credential response.
     */
    val credentialResponse: ValidatedCredentialResponse
) {
    init {
        if (credentialResponse !is DidValidatedJwtVcJsonCredentialResponse
            && credentialResponse !is DefaultValidatedVcSdJwtCredentialResponse) {
            throw UnsupportedOperationException(
                "ValidatedCredentialResponse of this type " +
                        "is not supported yet: $credentialResponse"
            )
        }
    }

    companion object {
        // TODO use functionality from the core (or crypto) modules, when that is added there.
        fun newId() = encodeBase64url(Random.nextBytes(128 / 8))
    }
}

@Serializable
data class JsonCredentialIssuer(
    override val issuerMetadata: JsonCredentialIssuerMetadata,
    override val authorizationServerMetadata: JsonOAuthMetadata,
    override val lastUpdate: Instant,
) : CredentialIssuer<JsonElement>
