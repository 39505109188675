/*
 * Copyright (C) 2023 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

package io.curity.vc.services

import io.curity.vc.serialization.UserVerifiableCredential
import kotlinx.serialization.Serializable
import kotlinx.serialization.SerializationException
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json

@Serializable
class InMemoryVerifiableCredentialStore(
    // Using as String as the value to better simulate a scenario where
    // credentials are serialized.
    private val map: MutableMap<String, String> = mutableMapOf()
) : VerifiableCredentialStore {

    override fun insert(credential: UserVerifiableCredential) {
        if (map.containsKey(credential.localId)) {
            throw Exception("A Credential with this name already exists")
        }
        map[credential.localId] = Json.encodeToString(credential)
    }

    override fun delete(localId: String) {
        map.remove(localId)
    }

    override fun getById(localId: String): UserVerifiableCredential? = map[localId]?.let {
        deserializeUserVerifiableCredentialOrNull(it)
    }

    override fun getByName(namePrefix: String): List<UserVerifiableCredential> =
        map.values.mapNotNull {
            deserializeUserVerifiableCredentialOrNull(it)
        }.filter { verifiableCredential ->
            verifiableCredential.localId.startsWith(namePrefix)
        }

    override fun getByIssuer(issuerId: String): List<UserVerifiableCredential> =
        map.values.mapNotNull {
            deserializeUserVerifiableCredentialOrNull(it)
        }.filter {
            it.issuerId == issuerId
        }

    override fun getAll(): List<UserVerifiableCredential> = map.values.mapNotNull {
        deserializeUserVerifiableCredentialOrNull(it)
    }

    // mostly for testing and debug purposes
    fun getString(localId: String): String? = map[localId]

    fun setString(localId: String, value: String) {
        map[localId] = value
    }

    private fun deserializeUserVerifiableCredentialOrNull(serializedCredential: String)
            : UserVerifiableCredential? =
        try {
            Json.decodeFromString<UserVerifiableCredential>(serializedCredential)
        } catch (ex: SerializationException) {
            // TODO log, once we have a logging framework
            // We ignore credentials that we cannot parse, which can have multiple causes:
            // - Bug in ssi-libs that may corrected in the future. This is the main reason why we ignore and not delete.
            // - A non retro-compatible change in the format, which should NOT happen once ssi-libs is stable
            // - Data corruption
            null
        } catch (ex: IllegalArgumentException) {
            // TODO log, once we have a logging framework
            // Same as above
            null
        }
}