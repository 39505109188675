/*
 * Copyright (C) 2023 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

package io.curity.oauth

import io.ktor.client.HttpClient
import io.ktor.client.call.body
import io.ktor.client.engine.HttpClientEngine
import io.ktor.client.plugins.contentnegotiation.ContentNegotiation
import io.ktor.client.request.forms.submitForm
import io.ktor.http.parameters
import io.ktor.serialization.kotlinx.json.json

/**
 * Service that knows how to interact with an OAuth 2.0 Authorization Server
 */
class OAuthPreAuthorizedCodeFlowClient(
    engine: HttpClientEngine,
    private val oAuthClient: OAuthClient,
    private val tokenEndpoint: String,
) {
    private val _httpClient = HttpClient(engine) {
        install(ContentNegotiation) { json() }
    }

    /**
     * Performs a code-based token request
     */
    suspend fun requestToken(preAuthorizedCode: String, txCode: String?): JsonOAuthTokenResponse {
        val httpTokenResponse = _httpClient.submitForm(
            tokenEndpoint,
            parameters {
                append("grant_type", "urn:ietf:params:oauth:grant-type:pre-authorized_code")
                append("pre-authorized_code", preAuthorizedCode)
                if (txCode != null) {
                    append("tx_code", txCode)
                }
                append("client_id", oAuthClient.id)
                append("client_secret", oAuthClient.secret)
            }
        )
        return when (httpTokenResponse.status.value) {
            200 -> {
                httpTokenResponse.body<JsonOAuthSuccessTokenResponse>()
            }
            400 -> {
                httpTokenResponse.body<JsonOAuthErrorTokenResponse>()
            }
            else -> {
                throw Exception("Unexpected status code for token response: ${httpTokenResponse.status.value}")
            }
        }
    }
}
