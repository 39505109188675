/*
 * Copyright (C) 2024 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import { JsVcSdJwtCredential } from 'curity-ssi-libs-verifiable-credentials-vc-ks-services';
import { useLanguages } from '../LanguagesContext';
import { useMemo } from 'react';
import { DetailLevel } from '../../types/DetailLevel';
import { CredentialDefaultIcon } from '../icons';
import { CredentialCard } from '../credential-card/CredentialCard';

export type VcSdJwtVerifiableCredentialProps = {
  userDefinedName: string | undefined;
  credential: JsVcSdJwtCredential;
  detailLevel: DetailLevel;
  onClick?: () => void;
};

export function VcSdJwtVerifiableCredential({
  userDefinedName,
  credential,
  detailLevel,
  onClick,
}: VcSdJwtVerifiableCredentialProps) {
  const languages = useLanguages();
  const claimsTranslationMap: Map<string, string> = useMemo(() => {
    const translationMap = new Map();
    if (!credential.claimDisplayables) {
      return translationMap;
    }
    credential.claimDisplayables.forEach((value, key) => {
      const bestMatch = value.getBestMatchForLocales(languages);
      translationMap.set(key, bestMatch.name);
    });
    return translationMap;
  }, [credential, languages]);

  const localizedCredentialDisplay = credential.credentialDisplay.getBestMatchForLocales(languages);
  const credentialName = localizedCredentialDisplay.name;
  const credentialDescription = localizedCredentialDisplay.description;

  const renderLogo = () =>
    localizedCredentialDisplay.logo?.url ? (
      <img src={localizedCredentialDisplay.logo?.url} alt={`Logotype for ${credentialName}`} width="48" height="48" />
    ) : (
      <CredentialDefaultIcon width="48" height="48" />
    );

  function translate(key: string) {
    return claimsTranslationMap.get(key) || key;
  }

  const onClickHandler = onClick || (() => {});

  // Because we don't want to show these system-ish claims
  const uiProperties = Array.from(credential.customStringClaims)
    .filter(claim => claim.name !== 'vct' && claim.name !== 'purpose');

  switch (detailLevel) {
    case 'name-only':
      return (
        <CredentialCard name={credentialName} mode="name-only">
          <div className="flex flex-column flex-gap-2" onClick={() => onClickHandler()}>
            <div className={`flex ${!userDefinedName ? 'flex-center' : ''} flex-gap-3`}>
              <div className="white">{renderLogo()}</div>
              <div>
                <h4 className="m0 white uppercasecaps">{credentialName}</h4>
                {userDefinedName ? (
                  <p className="mb0 white uppercasecaps">
                    <small>{userDefinedName}</small>
                  </p>
                ) : null}
              </div>
            </div>
          </div>
        </CredentialCard>
      );
    case 'summary':
      return (
        <CredentialCard name={credentialName} mode="summary">
          <div className="flex flex-column flex-gap-2" onClick={() => onClickHandler()}>
            <div className="flex flex-row flex-gap-3">
              <div className="white">{renderLogo()}</div>
              <div>
                <h3 className="m0 white uppercasecaps">
                  <small>{credentialName}</small>
                </h3>
              </div>
            </div>
            {uiProperties.slice(0, 2).length > 0 && (
              <div className="grid-container" {...{ columns: '2' }}>
                {uiProperties.slice(0, 2).map(claim => {
                  return (
                    <div key={claim.name}>
                      <h4 className="m0 white uppercasecaps">{translate(claim.name)}</h4>
                      <p className="m0 white">{claim.value}</p>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </CredentialCard>
      );
    case 'detailed': {
      return (
        <CredentialCard name={credentialName} mode="detailed">
          <div className="flex flex-column flex-gap-2" onClick={() => onClickHandler()}>
            <div className="flex flex-row flex-gap-3">
              <div className="white">{renderLogo()}</div>
              <div>
                <h3 className="m0 white uppercasecaps">
                  <small>{credentialName}</small>
                </h3>
              </div>
            </div>
            <p className="m0 white">
              <small>{credentialDescription}</small>
            </p>

            {uiProperties.length > 0 && (
              <div className="grid-container" {...{ columns: '2' }}>
                {uiProperties.map(claim => {
                  return (
                    <div key={claim.name}>
                      <h4 className="m0 white uppercasecaps" key={`dt-${claim.name}`}>
                        {translate(claim.name) + (claim.isSelectivelyDiscloseable ? "*" : "")}
                      </h4>
                      <p className="m0 white" key={`dd-${claim.name}`}>
                        {claim.value}
                      </p>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </CredentialCard>
      );
    }
  }
}
