.wallet-card {
  background-color: #0d0d36;
  opacity: 0.8;
  background-image: repeating-radial-gradient(circle at 0 0, transparent 0, var(--university-color-dark) 10px),
    repeating-linear-gradient(var(--university-color-dark), var(--university-color));
  transition: transform 200ms ease-in-out;
}

.wallet-card .grid-container h4 {
  font-size: 90%;
}

.wallet-card img {
  min-width: 48px;
}

.wallet-card:hover {
  transform: scale(1.5);
}

.wallet-card > div {
  padding: var(--space-2) var(--space-3);
}

.wallet-card-name-only {
  cursor: pointer;
}

.wallet-card-summary,
.wallet-card-detailed {
  cursor: unset;
}

.wallet-card-name-only p {
  font-size: calc(var(--type-base-size) * 0.85);
}

.fade-in {
  opacity: 0;
  transform: translateY(0.5rem);
  animation: fade-in-bounce 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
}

@keyframes fade-in-bounce {
  0% {
    opacity: 0;
    transform: translateY(0.5rem);
  }

  70% {
    opacity: 1;
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
