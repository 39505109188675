/*
 * Copyright (C) 2024 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

// Because we want these symbols to be in the root package, for better usage from JS
@file:Suppress("PackageDirectoryMismatch")
@file:OptIn(ExperimentalJsExport::class)

import io.curity.vp.services.PreRegisteredClient
import io.curity.vp.services.PreRegisteredClientStore
import io.curity.vp.services.VpFormat
import kotlinx.coroutines.GlobalScope
import kotlin.js.Promise

@JsExport
class JsPreRegisteredClientStore internal constructor(
    val inner: PreRegisteredClientStore
) {
    fun getByClientId(clientId: String): Promise<JsPreRegisteredClient?> = GlobalScope.safePromise {
        inner.getByClientId(clientId)?.let {
            JsPreRegisteredClient(
                it.clientId,
                it.jwksUri,
                it.redirectUri,
                it.vpFormatsSupported.map {
                    vpFormat -> JsVpFormat(vpFormat.format, vpFormat.algs.toTypedArray())
                }.toTypedArray(),
            )
        }
    }

    fun upsert(preRegistredClient: JsPreRegisteredClient) {
        GlobalScope.safePromise {
            inner.upsert(
                PreRegisteredClient(
                    preRegistredClient.clientId,
                    preRegistredClient.jwksUri,
                    preRegistredClient.redirectUri,
                    preRegistredClient.vpFormatsSupported.map {
                        vpFormat -> VpFormat(vpFormat.format, vpFormat.algs.toSet())
                    },
            ))
        }
    }

    fun getAll(): Promise<Array<JsPreRegisteredClient>> = GlobalScope.safePromise {
        inner.getAll().map {
            JsPreRegisteredClient(
                it.clientId,
                it.jwksUri,
                it.redirectUri,
                it.vpFormatsSupported.map {
                    vpFormat -> JsVpFormat(vpFormat.format, vpFormat.algs.toTypedArray())
                }.toTypedArray(),
            )
        }.toTypedArray()
    }
}

@JsExport
data class JsPreRegisteredClient(
    val clientId: String,
    val jwksUri: String,
    val redirectUri: String,
    val vpFormatsSupported: Array<JsVpFormat>,
)

@JsExport
class JsVpFormat(
    val format: String,
    val algs: Array<String>,
)