/*
 * Copyright (C) 2024 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

package io.curity.ssi.sdjwt.vc

import io.curity.ssi.crypto.VerificationKey
import io.curity.ssi.crypto.fromJwkDictionary
import io.curity.ssi.jose.JsonJwk
import io.curity.ssi.jose.JsonJwsHeader
import io.curity.ssi.jose.JsonJwt
import io.curity.ssi.jose.JsonJwtClaimsSet
import io.curity.ssi.jose.JwsUtil
import io.curity.ssi.validation.ValidationScope
import kotlinx.datetime.Clock
import kotlinx.serialization.json.JsonObject
import kotlinx.serialization.json.JsonPrimitive

/**
 * Contains functions to validate the issuer-signed JWT in SD-JWTs, as documented in
 * [https://www.ietf.org/archive/id/draft-ietf-oauth-selective-disclosure-jwt-08.html#name-issuer-signed-jwt](https://www.ietf.org/archive/id/draft-ietf-oauth-selective-disclosure-jwt-08.html#name-issuer-signed-jwt)
 */
class VcSdJwtIssuerJwtValidator(
    private val clock: Clock,
    private val issuerMetadataLoader: VcSdJwtIssuerMetadataLoader,
) {

    data class Result(
        val iss: String,
        val vct: String,
        val cnf: JsonJwk,
    )

    suspend fun validateIssuerJwt(
        validationScope: ValidationScope,
        jwt: JsonJwt.Jws,
        jwtString: String,
    ): Result? = validationScope.run {
        val kid = jwt.header.let { header ->
            if (header == null) {
                addValidationError("JWS Header is missing")
                null
            } else {
                validateIssuerJwtHeaderAndGetKid(header)
                header.kid
            }
        }

        val validatedPayload = validateIssuerJwtPayload(jwt.payload)

        if (hasErrors || kid == null || validatedPayload == null) {
            return null
        }

        validateJwtSignature(validatedPayload.iss, jwtString, kid)

        return Result(validatedPayload.iss, validatedPayload.vct, validatedPayload.cnf)
    }

    private fun ValidationScope.validateIssuerJwtHeaderAndGetKid(header: JsonJwsHeader): String? {
        // validate 'typ'
        exists("'typ' claim is not present in the header", header.typ) {
            if (it != VcSdJwtConstants.TYP_ISSUER_JWT) {
                addValidationError("JWS header 'kid' is not '${VcSdJwtConstants.TYP_ISSUER_JWT}'")
            }
        }
        // TODO any additional header validation missing

        // extract kid
        return header.kid
    }

    private fun ValidationScope.validateIssuerJwtPayload(
        payload: JsonJwtClaimsSet,
    ): ValidatedPayload? {

        val nowEpoch = clock.now().epochSeconds

        // validate vct
        val vct = mandatory("vct", payload.customClaims["vct"]) {
            val stringValue = when (it) {
                is JsonPrimitive -> if (it.isString) {
                    it.content
                } else {
                    null
                }

                else -> null
            }
            if(stringValue == null) {
                addValidationError("'vct' is present but is not a string")
            }
            stringValue
        }

        // validate iss
        val iss = mandatory("iss", payload.iss) {
            it
        }

        // validate nbf
        optional(payload.nbf) {
            if (nowEpoch < it) {
                addValidationError("'nbf' is in the future")
            }
        }

        // validate iat
        optional(payload.iat) {
            if (nowEpoch < it) {
                addValidationError("'iat' is in the future")
            }
        }

        // validate exp
        optional(payload.exp) {
            if (nowEpoch > it) {
                addValidationError("'exp' is in the past")
            }
        }

        // validate cnf
        val cnfJwk = mandatory("cnf", payload.cnf) {
            if (it.keys != setOf("jwk")) {
                addValidationError("'cnf' has unexpected members")
            }
            val jwk = it["jwk"]
            if (jwk == null) {
                addValidationError("'cnf.jwk' is missing in the 'cnf' claim")
                return@mandatory null
            }
            val jwkObject = jwk as? JsonObject
            if (jwkObject == null) {
                addValidationError("'cnf.jwk' is not a JSON object")
                return@mandatory null
            }
            JsonJwk.fromJson(jwkObject)
        }

        return if (iss != null && vct != null && cnfJwk != null) {
            ValidatedPayload(iss, vct, cnfJwk)
        } else {
            null
        }
    }

    private suspend fun ValidationScope.validateJwtSignature(
        issuerId: String,
        jwtString: String,
        kid: String
    ): Boolean {
        val verificationKey: VerificationKey? = try {
            val issuerMetadata = issuerMetadataLoader.get(issuerId)
            if (issuerMetadata.issuer != issuerId) {
                addValidationError("Issuer metadata ID mismatch, metadata has " +
                        "'${issuerMetadata.issuer}', credential has '${issuerId}'")
                null
            } else {
                val jwk = issuerMetadata.jwks.getKeyWithKid(kid)
                if (jwk == null) {
                    addValidationError("Issuer metadata does not contain credential verification key")
                    null
                } else {
                    VerificationKey.fromJwkDictionary(jwk)
                }
            }
        } catch (ex: Exception) {
            addValidationError("Unexpected exception: ${ex.message}")
            null
        }
        if (verificationKey == null) {
            return false
        }
        return try {
            JwsUtil.verifyJws(verificationKey, jwtString)
            true
        } catch (ex: Exception) {
            addValidationError("JWT is not valid: ${ex.message}")
            false
        }
    }

    private data class ValidatedPayload(
        val iss: String,
        val vct: String,
        val cnf: JsonJwk,
    )
}