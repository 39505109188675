/*
 * Copyright (C) 2023 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

interface EmptyProps {
  width?: string;
  height?: string;
}

export const Empty: React.FC<EmptyProps> = ({ width = '100%', height = '100%' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="#ebebeb"
    data-name="Empty"
    viewBox="0 0 473.28 256"
    width={width}
    height={height}
  >
    <circle cx={72.92} cy={59.42} r={24.76} className="cls-1" />
    <rect width={323.32} height={44} x={121.07} y={37.42} className="cls-1" rx={22} />
    <circle cx={72.92} cy={131.18} r={24.76} className="cls-1" />
    <rect width={280.67} height={44} x={121.07} y={109.18} className="cls-1" rx={22} />
    <circle cx={72.92} cy={202.94} r={24.76} className="cls-1" />
    <rect width={247.65} height={44} x={121.07} y={180.94} className="cls-1" rx={22} />
  </svg>
);
