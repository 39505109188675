/*
 * Copyright (C) 2023 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

package io.curity.vp.services

class InMemoryPreRegisteredClientStore : PreRegisteredClientStore {

    private val memory = mutableMapOf<String, PreRegisteredClient>()

    override suspend fun getByClientId(clientId: String): PreRegisteredClient? {
        return memory[clientId]
    }

    override suspend fun upsert(preRegistredClient: PreRegisteredClient) {
        memory[preRegistredClient.clientId] = preRegistredClient
    }

    override suspend fun getAll(): List<PreRegisteredClient> {
        return memory.values.toList()
    }
}