/*
 * Copyright (C) 2023 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

package io.curity.ssi.did.resolvers.jwk

import io.curity.ssi.PublicApi
import io.curity.ssi.crypto.decodeBase64Url
import io.curity.ssi.data.ToString
import io.curity.ssi.did.Did
import io.curity.ssi.did.document.DidResolutionException
import io.curity.ssi.did.document.JsonDidResolver
import io.curity.ssi.did.json.JsonDidDocument
import io.curity.ssi.did.json.JsonDidResolutionSuccessResult
import io.curity.ssi.did.json.JsonPublicKeyJwk
import io.curity.ssi.did.json.JsonVerificationMethod
import io.curity.ssi.json.data.DefaultJsonMultiValue
import kotlinx.serialization.SerializationException
import kotlinx.serialization.json.Json
import kotlinx.serialization.json.JsonObject

enum class JwkDidType : ToString {
    JWK;

    override fun toString() = "jwk"
}

enum class VerificationMethodType(val value: String) : ToString {
    JsonWebKey2020("JsonWebKey2020"),
    EcdsaSecp256k1VerificationKey2019("EcdsaSecp256k1VerificationKey2019"), ;

    override fun toString() = name
}

@PublicApi
class JwkDidResolver : JsonDidResolver {

    override suspend fun resolve(did: Did): JsonDidResolutionSuccessResult {
        val id = did.identifier
        val jwkString: String =
            try {
                decodeBase64Url(id).decodeToString()
            } catch (ex: Exception) {
                throw DidResolutionException("DID identifier is not base64 url")
            }

        // check if jwkString is a valid JSON object
        val jwk: JsonObject
        try {
            val element = Json.parseToJsonElement(jwkString)
            if (element !is JsonObject) {
                throw DidResolutionException("DID Identifier is not valid for DID method")
            }
            jwk = element
        } catch (ex: SerializationException) {
            throw DidResolutionException("DID Identifier is not valid for DID method", ex)
        }

        val didDocument = JsonDidDocument(
            id = did,
            verificationMethod = DefaultJsonMultiValue(
                JsonVerificationMethod(
                    id = did.withFragment("0"),
                    type = VerificationMethodType.JsonWebKey2020,
                    controller = DefaultJsonMultiValue(did),
                    publicKeyJwk = JsonPublicKeyJwk(jwk),
                )
            )
        )
        return JsonDidResolutionSuccessResult(
            didDocument,
            // no document metadata or resolution metadata
        )
    }

    override val supportedDidMethods: Set<JwkDidType> = JwkDidType.entries.toSet()
}
