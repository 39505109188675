/*
 * Copyright (C) 2023 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

package io.curity.vc.serialization

import io.curity.ssi.data.MapDictionary
import io.curity.ssi.json.data.DefaultJsonMultiValue
import io.curity.vc.CredentialEndpoint
import io.curity.vc.CredentialEndpoint.VerifiableCredential.DictionaryCredential
import io.curity.vc.CredentialEndpoint.VerifiableCredential.StringCredential
import io.curity.vp.serialization.JsonAlgFormat
import io.curity.vp.serialization.JsonProofTypeFormat
import io.curity.vp.serialization.JsonVcSdJwtAlgsFormat
import kotlinx.serialization.json.Json
import kotlinx.serialization.json.JsonArray
import kotlinx.serialization.json.JsonElement
import kotlinx.serialization.json.JsonObject
import kotlinx.serialization.json.JsonPrimitive
import kotlinx.serialization.json.decodeFromJsonElement
import kotlinx.serialization.json.jsonObject
import kotlinx.serialization.json.jsonPrimitive

fun MutableMap<String, JsonElement>.unwrapIssuer(key: String): ToJsonIssuer {
    val value = remove(key) ?: throwDeserializationError("Missing mandatory key \"$key\"")
    return try {
        Json.decodeFromJsonElement<JsonIssuer>(value)
    } catch (e: IllegalArgumentException) {
        throwDeserializationError("Key \"$key\" has an invalid value: ${e.message}")
    }
}

fun MutableMap<String, JsonElement>.unwrapLogo(key: String): JsonLogo {
    val value = remove(key) ?: throwDeserializationError("Missing mandatory key \"$key\"")
    return try {
        JsonLogo.fromJson(value)
    } catch (e: IllegalArgumentException) {
        throwDeserializationError("Key \"$key\" has an invalid value: ${e.message}")
    }
}

fun MutableMap<String, JsonElement>.unwrapOptionalLogo(key: String): JsonLogo? {
    val value = remove(key) ?: return null
    return try {
        JsonLogo.fromJson(value)
    } catch (e: IllegalArgumentException) {
        throwDeserializationError("Key \"$key\" has an invalid value: ${e.message}")
    }
}

fun MutableMap<String, JsonElement>.unwrapOptionalImage(key: String): JsonImage? {
    val value = remove(key) ?: return null
    return try {
        JsonImage.fromJson(value)
    } catch (e: IllegalArgumentException) {
        throwDeserializationError("Key \"$key\" has an invalid value: ${e.message}")
    }
}

fun MutableMap<String, JsonElement>.unwrapOptionalVerifiableCredentials(
    key: String
): DefaultJsonMultiValue<CredentialEndpoint.VerifiableCredential>? {
    val value = remove(key) ?: return null
    return if (value is JsonArray) {
        DefaultJsonMultiValue(value.asSequence().map {
            when (it) {
                is JsonPrimitive -> StringCredential(it.jsonPrimitive.content)
                is JsonObject -> DictionaryCredential(MapDictionary(it.jsonObject))
                else -> throwDeserializationError("Key \"$key\" has an invalid value.")
            }
        }.toList())
    } else if (value is JsonPrimitive) {
        DefaultJsonMultiValue(StringCredential(value.jsonPrimitive.content))
    } else if (value is JsonObject) {
        DefaultJsonMultiValue(DictionaryCredential(MapDictionary(value.jsonObject)))
    } else {
        throwDeserializationError("Key \"$key\" has an invalid value.")
    }
}

fun MutableMap<String, JsonElement>.unwrapOptionalAlgFormat(key: String): JsonAlgFormat? {
    val value = remove(key) ?: return null
    return try {
        JsonAlgFormat.fromJson(value)
    } catch (e: IllegalArgumentException) {
        throwDeserializationError("Key \"$key\" has an invalid value: ${e.message}")
    }
}

fun MutableMap<String, JsonElement>.unwrapOptionalVcSdJwtAlgsFormat(key: String): JsonVcSdJwtAlgsFormat? {
    val value = remove(key) ?: return null
    return try {
        JsonVcSdJwtAlgsFormat.fromJson(value)
    } catch (e: IllegalArgumentException) {
        throwDeserializationError("Key \"$key\" has an invalid value: ${e.message}")
    }
}


fun MutableMap<String, JsonElement>.unwrapOptionalProofTypeFormat(key: String): JsonProofTypeFormat? {
    val value = remove(key) ?: return null
    return try {
        JsonProofTypeFormat.fromJson(value)
    } catch (e: IllegalArgumentException) {
        throwDeserializationError("Key \"$key\" has an invalid value: ${e.message}")
    }
}

fun MutableMap<String, JsonElement>.unwrapDisplays(key: String): List<JsonDisplayable>? {
    val value = remove(key) ?: throwDeserializationError("Missing mandatory key \"$key\"")
    return if (value is JsonArray) value.asSequence().map {
        JsonDisplayable.fromJson(it)
    }.toList() else null
}

fun MutableMap<String, JsonElement>.unwrapOptionalDisplays(key: String): List<JsonDisplayable>? {
    val value = remove(key) ?: return null
    return if (value is JsonArray) value.asSequence().map {
        JsonDisplayable.fromJson(it)
    }.toList() else null
}

fun MutableMap<String, JsonElement>.unwrapCredentialDisplays(key: String): List<JsonCredentialDisplay>? {
    val value = remove(key) ?: throwDeserializationError("Missing mandatory key \"$key\"")
    return if (value is JsonArray) value.asSequence().map {
        JsonCredentialDisplay.fromJson(it)
    }.toList() else null
}

fun MutableMap<String, JsonElement>.unwrapOptionalProofOfPossession(key: String): ToJsonProofOfPossession? {
    val value = remove(key) ?: return null
    return try {
        JsonProofOfPossession.fromJson(value)
    } catch (e: IllegalArgumentException) {
        throwDeserializationError("Key \"$key\" has an invalid value: ${e.message}")
    }
}

fun MutableMap<String, JsonElement>.unwrapOptionalProofs(key: String): DefaultJsonMultiValue<JsonProof>? {
    val item = remove(key) ?: return null
    return try {
        DefaultJsonMultiValue.fromJson(item, JsonProof.serializer())
    } catch (e: IllegalArgumentException) {
        throwDeserializationError("Key \"$key\" has an invalid value: ${e.message}")
    }
}

fun MutableMap<String, JsonElement>.unwrapOptionalCredentialStatus(key: String): JsonCredentialStatus? {
    val value = remove(key) ?: return null
    return try {
        JsonCredentialStatus.fromJson(value)
    } catch (e: IllegalArgumentException) {
        throwDeserializationError("Key \"$key\" has an invalid value: ${e.message}")
    }
}

fun MutableMap<String, JsonElement>.unwrapSupportedProofType(key: String):
        Map<String, JsonProofTypesSupported>? {
    val value = remove(key) ?: return null
    return try {
        Json.decodeFromJsonElement(value)
    } catch (e: IllegalArgumentException) {
        throwDeserializationError("Key \"$key\" has an invalid value: ${e.message}")
    }
}

// We represent an unwrap error as an IllegalArgumentException, following the pattern
// of Kotlin Serialization, which also represents a deserialization error with exceptions derived from
// IllegalArgumentException
private fun throwDeserializationError(message: String): Nothing = throw IllegalArgumentException(message)
