/*
 * Copyright (C) 2023 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

package io.curity.vc.serialization

import io.curity.ssi.data.MapDictionary
import io.curity.ssi.data.MultiValue
import io.curity.ssi.json.data.FromJson
import io.curity.ssi.json.data.FromJsonHelper
import io.curity.ssi.json.data.JsonDictionary
import io.curity.ssi.json.data.ToJson
import io.curity.ssi.json.data.ToJsonDictionary
import io.curity.ssi.json.data.toJson
import io.curity.ssi.json.data.unwrapMultiValueJsonDictionary
import io.curity.ssi.json.data.unwrapOptionalString
import io.curity.ssi.json.data.unwrapString
import io.curity.ssi.json.data.unwrapStringList
import io.curity.ssi.json.data.unwrapStringSet
import io.curity.vc.CredentialStatus
import io.curity.vc.Issuer
import io.curity.vc.W3CVerifiableCredential
import kotlinx.serialization.Serializable
import kotlinx.serialization.json.JsonArray
import kotlinx.serialization.json.JsonElement
import kotlinx.serialization.json.JsonObject
import kotlinx.serialization.json.JsonPrimitive

@Serializable(with = JsonCredentialStatusSerializer::class)
data class JsonCredentialStatus(
    override val id: String,
    override val type: String,
    val customClaims: JsonDictionary? = null
) : ToJsonDictionary, CredentialStatus<JsonElement> {

    companion object : FromJson<JsonCredentialStatus> {
        override fun fromJson(json: JsonElement): JsonCredentialStatus {
            val map = FromJsonHelper.requireJsonObject(json, "JsonCredentialStatus").toMutableMap()
            val id = map.unwrapString("id")
            val type = map.unwrapString("type")
            return JsonCredentialStatus(id, type, JsonDictionary(map.toMap()))
        }
    }

    private val _serializable by lazy {
        JsonDictionary(buildMap {
            if (customClaims != null) putAll(customClaims.toMap())
            put("id", JsonPrimitive(id))
            put("type", JsonPrimitive(type))
        })
    }

    override fun toJsonDictionary() = _serializable
}

/**
 * Serializable version of the [Issuer] interface.
 *
 * This interface does not extend [Issuer] directly because [Issuer] is a closed type hierarchy.
 * To obtain an [Issuer] from an instance of this type,
 * use the [ToJsonIssuer.toIssuer] method.
 */
@Serializable(with = ToJsonIssuerSerializer::class)
interface ToJsonIssuer : ToJson {
    fun toJsonIssuer(): JsonIssuer

    fun toIssuer(): Issuer<JsonElement> = toJsonIssuer().toIssuer()
}

/**
 * Default implementation of [ToJsonIssuer].
 *
 * This type does not implement [Issuer] directly because [Issuer] is a closed type hierarchy.
 * To obtain an [Issuer] from an instance of this type, or any [ToJsonIssuer],
 * use the [ToJsonIssuer.toIssuer] method.
 */
@Serializable(with = JsonIssuerSerializer::class)
sealed interface JsonIssuer : ToJsonIssuer {

    data class IssuerURI(val value: String) : JsonIssuer {
        override fun toIssuer() = Issuer.IssuerURI<JsonElement>(value)

        override fun toJson(): JsonPrimitive = value.toJson()
    }

    data class IssuerObject(val id: String, val customClaims: ToJsonDictionary? = null) : JsonIssuer {

        companion object : FromJson<IssuerObject> {
            override fun fromJson(json: JsonElement): IssuerObject {
                val map = FromJsonHelper.requireJsonObject(json, "IssuerObject").toMutableMap()
                val id = map.unwrapString(Issuer.IssuerObject.Keys.ID)
                return IssuerObject(id, if (map.isEmpty()) null else JsonDictionary(map))
            }
        }

        private val _serializedMap by lazy {
            buildMap {
                if (customClaims != null) putAll(customClaims.toMap())
                put(Issuer.IssuerObject.Keys.ID, id.toJson())
            }
        }

        override fun toIssuer(): Issuer.IssuerObject<JsonElement> {
            val thisId = id
            return object : MapDictionary<JsonElement>(_serializedMap), Issuer.IssuerObject<JsonElement> {
                override val id = thisId
                override fun toString() = thisId
            }
        }

        override fun toJson(): JsonObject = JsonObject(_serializedMap)
    }

    override fun toJsonIssuer() = this
}

@Serializable(with = SerializableW3CVerifiableCredentialSerializer::class)
data class SerializableW3CVerifiableCredential(
    override val id: String? = null,
    override val context: List<String>,
    override val type: Set<String>,
    override val credentialSubject: MultiValue<out ToJsonDictionary>,
    val jsonIssuer: ToJsonIssuer,
    override val issued: String,
    override val proof: MultiValue<out ToJsonProof>? = null,
    override val expirationDate: String? = null,
    override val credentialStatus: JsonCredentialStatus? = null,
    val customClaims: ToJsonDictionary? = null,
) : ToJsonDictionary, W3CVerifiableCredential<JsonElement> {

    companion object : FromJson<SerializableW3CVerifiableCredential> {
        override fun fromJson(json: JsonElement): SerializableW3CVerifiableCredential {
            val map = FromJsonHelper.requireJsonObject(json, "SerializableW3CVerifiableCredential").toMutableMap()
            return SerializableW3CVerifiableCredential(
                id = map.unwrapOptionalString(W3CVerifiableCredential.Keys.ID),
                context = map.unwrapStringList(W3CVerifiableCredential.Keys.CONTEXT),
                type = map.unwrapStringSet(W3CVerifiableCredential.Keys.TYPE),
                credentialSubject = map.unwrapMultiValueJsonDictionary(W3CVerifiableCredential.Keys.CREDENTIAL_SUBJECT),
                jsonIssuer = map.unwrapIssuer(W3CVerifiableCredential.Keys.ISSUER),
                issued = map.unwrapString(W3CVerifiableCredential.Keys.ISSUANCE_DATE),
                proof = map.unwrapOptionalProofs(W3CVerifiableCredential.Keys.PROOF),
                expirationDate = map.unwrapOptionalString(W3CVerifiableCredential.Keys.EXPIRATION_DATE),
                credentialStatus = map.unwrapOptionalCredentialStatus(W3CVerifiableCredential.Keys.CREDENTIAL_STATUS),
                customClaims = if (map.isEmpty()) null else JsonDictionary(map.toMap())
            )
        }
    }

    private val _serializable by lazy {
        JsonDictionary(buildMap {
            if (customClaims != null) putAll(customClaims.toJson())
            if (id != null) put(W3CVerifiableCredential.Keys.ID, id.toJson())
            put(W3CVerifiableCredential.Keys.CONTEXT, JsonArray(context.map { it.toJson() }))
            put(W3CVerifiableCredential.Keys.TYPE, JsonArray(type.map { it.toJson() }))
            put(W3CVerifiableCredential.Keys.CREDENTIAL_SUBJECT, credentialSubject.toJson())
            put(W3CVerifiableCredential.Keys.ISSUER, jsonIssuer.toJson())
            put(W3CVerifiableCredential.Keys.ISSUANCE_DATE, issued.toJson())
            if (proof != null) put(W3CVerifiableCredential.Keys.PROOF, proof.toJson())
            if (expirationDate != null) put(W3CVerifiableCredential.Keys.EXPIRATION_DATE, expirationDate.toJson())
            if (credentialStatus != null) put(W3CVerifiableCredential.Keys.CREDENTIAL_STATUS, credentialStatus.toJson())
        })
    }

    override val issuer: Issuer<JsonElement>
        get() = jsonIssuer.toIssuer()

    override fun toJsonDictionary(): JsonDictionary = _serializable
}
