/*
 * Copyright (C) 2023 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

package io.curity.ssi.validation

sealed interface ValidationResult<out T> {
    fun orThrow(): T
    class Valid<T>(val value: T) : ValidationResult<T> {
        override fun orThrow(): T = value
    }

    class Invalid(val errors: List<String>) : ValidationResult<Nothing> {
        override fun orThrow(): Nothing = throw ValidationException(errors)

    }

    companion object {
        fun invalid(error: String) = Invalid(listOf(error))
        fun <T> valid(value: T) = Valid(value)
    }
}

class ValidationException(
    val errors: List<String>,
) : Exception("validation error: ${errors.joinToString("\n")}") {
    constructor(error: String) : this(listOf(error))
}

class ValidationScope {
    private val errors = mutableListOf<String>()

    fun isTrue(msg: String, predicate: () -> Boolean) {
        if (!predicate()) {
            errors.add(msg)
        }
    }

    fun <T> exists(msg: String, value: T?, applyOnExisting: (T) -> Unit) {
        if (value == null) {
            errors.add(msg)
        } else {
            applyOnExisting(value)
        }
    }

    fun <T, R> mandatory(
        name: String,
        value: T?,
        block: (ValidationScope.(T) -> R?)? = null
    ): R? {
        return if (value == null) {
            addValidationError("Missing claim '$name'")
            null
        } else if (block != null) {
            this.block(value)
        } else {
            null
        }
    }

    fun <T> optional(value: T?, block: (T) -> Unit) {
        if (value != null) {
            block(value)
        }
    }

    fun <T, R> tryConvert(msg: (Exception) -> String, value: T, converter: (T) -> R): R? {
        return try {
            converter(value)
        } catch (e: Exception) {
            addValidationError(msg(e))
            null
        }
    }

    fun addValidationError(msg: String) {
        errors.add(msg)
    }

    val hasNoErrors = errors.isEmpty()

    val hasErrors = errors.isNotEmpty()

    companion object {

        fun <T> run(block: ValidationScope.() -> T?): ValidationResult<T> {
            val scope = ValidationScope()
            val res = try {
                scope.block()
            } catch (ex: Exception) {
                scope.errors.add(ex.message ?: "Unknown error")
                return ValidationResult.Invalid(scope.errors.toList())
            }
            return if (scope.errors.isNotEmpty()) {
                ValidationResult.Invalid(scope.errors.toList())
            } else {
                if (res == null) {
                    // This should not happen
                    ValidationResult.invalid("Unknown error")
                } else {
                    ValidationResult.Valid(res)
                }
            }
        }

        // TODO avoid duplicate code
        suspend fun <T> run(block: suspend ValidationScope.() -> T?): ValidationResult<T> {
            val scope = ValidationScope()
            val res = try {
                scope.block()
            } catch (ex: Exception) {
                scope.errors.add(ex.message ?: "Unknown error")
                return ValidationResult.Invalid(scope.errors.toList())
            }
            return if (scope.errors.isNotEmpty()) {
                ValidationResult.Invalid(scope.errors.toList())
            } else {
                if (res == null) {
                    // This should not happen
                    ValidationResult.invalid("Unknown error")
                } else {
                    ValidationResult.Valid(res)
                }
            }
        }
    }
}

suspend fun <T> suspendableValidationScope(
    block: suspend ValidationScope.() -> T?
): ValidationResult<T> = ValidationScope.run(block)
