/*
 * Copyright (C) 2024 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

@file:OptIn(ExperimentalJsExport::class)

// Because we want these symbols to be in the root package, for better usage from JS
@file:Suppress("PackageDirectoryMismatch")

import io.curity.vp.services.CredentialFinderResult
import io.curity.vp.services.OpenIdWalletAuthenticatorCredentialFinder
import kotlinx.coroutines.GlobalScope
import kotlin.js.Promise

/**
 * JavaScript-friendly CredentialFinder.
 *
 * Wraps [io.curity.vp.services.CredentialFinder] with a JS API.
 */
@JsExport
class JsOpenIdWalletAuthenticatorCredentialFinder(
    verifiableCredentialStore: JsVerifiableCredentialStore
) {

    private val openIdWalletCredentialFinder = OpenIdWalletAuthenticatorCredentialFinder(
        verifiableCredentialStore.inner
    )

    fun find(presentationDefinition: JsPresentationDefinition): Promise<JsCredentialFinderResult> {
        return GlobalScope.safePromise {
            val credentialFinderResult = openIdWalletCredentialFinder.find(presentationDefinition.inner)
            JsCredentialFinderResult(credentialFinderResult)
        }
    }
}

@JsExport
class JsCredentialFinderResult internal constructor(
    inner: CredentialFinderResult,
) {
    val data: Array<JsPair<JsInputDescriptor, Array<JsUserVerifiableCredential>>> by lazy {
        inner.data.map {
            val credentials = it.second.map { credential -> createJsUserVerifiableCredential(credential) }.toTypedArray()
            JsPair(JsInputDescriptor(it.first), credentials)
        }.toTypedArray()
    }
}
