const colorList = [
  { letter: 'A', color: '#2e87ba' },
  { letter: 'B', color: '#2e87ba' },
  { letter: 'C', color: '#27b197' },
  { letter: 'D', color: '#65a30d' },
  { letter: 'E', color: '#26415a' },
  { letter: 'F', color: '#4451a3' },
  { letter: 'G', color: '#6778a5' },
  { letter: 'H', color: '#7b181a' },
  { letter: 'I', color: '#ba3a17' },
  { letter: 'J', color: '#7a2c52' },
  { letter: 'K', color: '#9333ea' },
  { letter: 'L', color: '#b02929' },
  { letter: 'M', color: '#10b981' },
  { letter: 'N', color: '#6366f1' },
  { letter: 'O', color: '#5799a8' },
  { letter: 'P', color: '#8EB29A' },
  { letter: 'Q', color: '#c00000' },
  { letter: 'R', color: '#00549f' },
  { letter: 'S', color: '#fdba31' },
  { letter: 'T', color: '#fdba31' },
  { letter: 'U', color: '#cf3da4' },
  { letter: 'V', color: '#fb7185' },
  { letter: 'W', color: '#dbc6ec' },
  { letter: 'X', color: '#a351a9' },
  { letter: 'Y', color: '#c6e2ff' },
  { letter: 'Z', color: '#92a8d1' },
];

export const getCredentialTypeColor: React.FC = (letter?: string): string => {
  const selectedColor = colorList.find(color => color.letter === letter);
  const darkColor = 'rgba(0,0,0,0.0725)';

  return `repeating-radial-gradient(circle at 0 0, transparent 0, ${darkColor} 10px), repeating-linear-gradient(${darkColor}, ${selectedColor.color})`;
};
