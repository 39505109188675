/*
 * Copyright (C) 2024 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

@file:OptIn(ExperimentalJsExport::class)

// Because we want these symbols to be in the root package, for better usage from JS
@file:Suppress("PackageDirectoryMismatch")

import io.curity.ssi.data.Locale
import io.curity.ssi.js.JsonToJsConverter
import io.curity.ssi.sdjwt.SdValue
import io.curity.vc.serialization.DefaultJsonCredentialConfigurationsSupported
import io.curity.vc.serialization.DefaultValidatedVcSdJwtCredentialResponse
import io.curity.vc.serialization.DidValidatedJwtVcJsonCredentialResponse
import io.curity.vc.serialization.JsonCredentialConfigurationsSupported
import io.curity.vc.serialization.JsonCredentialDisplay
import io.curity.vc.serialization.JsonCredentialIssuer
import io.curity.vc.serialization.JsonCredentialIssuerMetadata
import io.curity.vc.serialization.JsonCredentialStatus
import io.curity.vc.serialization.JsonDisplayable
import io.curity.vc.serialization.JsonIssuerDisplay
import io.curity.vc.serialization.JsonLogo
import io.curity.vc.serialization.JwtVcJsonCredentialConfigurationsSupported
import io.curity.vc.serialization.UserVerifiableCredential
import io.curity.vc.serialization.ValidatedCredentialResponse
import io.curity.vc.serialization.ValidatedJwtVcJsonCredentialResponse
import io.curity.vc.serialization.ValidatedJwtVcJsonLdCredentialResponse
import io.curity.vc.serialization.ValidatedLdpVcCredentialResponse
import io.curity.vc.serialization.ValidatedVcSdJwtCredentialResponse
import io.curity.vc.serialization.VcSdJwtCredentialConfigurationsSupported
import js.collections.JsMap
import js.collections.ReadonlyMap
import js.core.ReadonlyArray
import js.core.push
import js.core.tupleOf
import kotlinx.serialization.json.JsonPrimitive
import kotlin.js.Json

/**
 * Localized name and extra information.
 */
@JsExport
interface JsDisplayable {
    val locale: String?
    val name: String?
    val customClaims: JSObject
}

@JsExport
interface JsIssuerDisplay : JsDisplayable {
    val logo: JsLogo?
}

/**
 * Set of localized information, for one or more locales.
 */
@JsExport
interface JsDisplayableSet<out T : JsDisplayable> {
    /**
     * Returns the best match for a provided locale array.
     *
     * @param preferredLocales The preferred locales, in decreasing preference order.
     * @return The best match given the preferred locales.
     */
    fun getBestMatchForLocales(preferredLocales: ReadonlyArray<String>): T?
}

@JsExport
class JsServerMetadata internal constructor(
    internal val inner: JsonCredentialIssuerMetadata,
) {
    val authorizationServers: Array<String>? = inner.authorizationServers?.toTypedArray()
    val credentialEndpoint: String = inner.credentialEndpoint
    val batchCredentialEndpoint: String? = inner.batchCredentialEndpoint
    val credentialsSupported: JsMap<String, JsCredentialIssuerSupportedCredential> by lazy {
        JsMap(
            inner.credentialConfigurationsSupported.map {
                tupleOf(it.key, JsCredentialIssuerSupportedCredential(it.value))
            }.toTypedArray()
        )
    }

    val credentialIssuer: String = inner.credentialIssuer
    val display: Array<JsIssuerDisplayAdapter>? by lazy {
        inner.display?.map { JsIssuerDisplayAdapter(it) }?.toTypedArray()
    }
}

// To expose the internal inner property to kotlin consumers
fun JsServerMetadata.inner() = this.inner

@JsExport
class JsDisplayableAdapter internal constructor(
    val inner: JsonDisplayable
) : JsDisplayable {
    override val locale = inner.locale
    override val name = inner.name
    override val customClaims: JSObject by lazy {
        JSObject(inner.customClaims)
    }
}

@JsExport
class JsIssuerDisplayAdapter internal constructor(
    val inner: JsonIssuerDisplay
) : JsIssuerDisplay {
    override val locale = inner.locale
    override val name = inner.name
    override val logo = inner.logo?.let { JsLogo(it) }
    override val customClaims: JSObject by lazy {
        JSObject(inner.customClaims)
    }
}

/**
 * Displayable information about a supported credential.
 */
@JsExport
class JsCredentialDisplayAdapter internal constructor(
    inner: JsonCredentialDisplay
) {
    val description = inner.description
    val backgroundColor = inner.backgroundColor
    val textColor = inner.textColor
    val logo = inner.logo?.let { JsLogo(it) }
    val locale = inner.locale
    val name = inner.name
    val customClaims: Json = JsonToJsConverter.convertToJson(inner.toMap())
}

class JsDisplayableSetAdapter<T : JsDisplayable>(
    private val inner: List<T>
) : JsDisplayableSet<T> {
    override fun getBestMatchForLocales(preferredLocales: ReadonlyArray<String>): T? =
        preferredLocales.map { Locale(it) }.firstNotNullOfOrNull { preferredLocale ->
            if (preferredLocale.isLanguageOnly) {
                inner.firstOrNull {
                    preferredLocale.hasSameLanguageAs(it.locale)
                }
            } else {
                inner.firstOrNull {
                    preferredLocale.toString() == it.locale
                } ?: inner.firstOrNull {
                    preferredLocale.hasSameLanguageAs(it.locale)
                }
            }
        } ?: inner.firstOrNull()
}

/**
 * Localized logo information.
 */
@JsExport
class JsLogo internal constructor(
    private val inner: JsonLogo
) {
    val url = inner.uri
    val altText = inner.altText

    val asJson: Json by lazy {
        JsonToJsConverter.convertToJson(inner.toMap())
    }
}

/**
 * Displayable information about a supported credential.
 */
@JsExport
class JsCredentialDisplay internal constructor(
    val inner: JsonCredentialDisplay
) : JsDisplayable {
    override val locale: String? = inner.locale
    override val name: String? = inner.name
    override val customClaims: JSObject by lazy {
        JSObject(inner.customClaims)
    }
    val description: String? = inner.description
    val backgroundColor: String? = inner.backgroundColor
    val textColor: String? = inner.textColor
    val logo: JsLogo? by lazy { inner.logo?.let { JsLogo(it) } }
}

@JsExport
class JsCredentialIssuerSupportedCredential internal constructor(
    internal val inner: JsonCredentialConfigurationsSupported,
) {
    val format = inner.format

    val display: JsDisplayableSet<JsCredentialDisplay>? by lazy {
        // both allowed subtypes provide a JsonDisplay, but the interface is generic
        val displays = when (inner) {
            is DefaultJsonCredentialConfigurationsSupported -> inner.display
            is JwtVcJsonCredentialConfigurationsSupported -> inner.display
            is VcSdJwtCredentialConfigurationsSupported -> inner.display
        }
        displays?.let { d ->
            JsDisplayableSetAdapter(d.map { JsCredentialDisplay(it) })
        }
    }
}

// To make internal function visible to other kotlin modules (but not visible from JS)
fun createJsCredentialIssuerSupportedCredential(inner: JsonCredentialConfigurationsSupported) =
    JsCredentialIssuerSupportedCredential(inner)
fun JsCredentialIssuerSupportedCredential.inner() = this.inner

@JsExport
class JsCredentialStatus internal constructor(val inner: JsonCredentialStatus) {
    val id: String = inner.id
    val type: String = inner.type
}

/**
 * JS compatible type hierarchy to represent a [SdValue].
 */
@JsExport
sealed interface JsSdValue {
    fun getNestedDisclosures(): Array<String> {
        val array = arrayOf<String>()
        buildNestedDisclosure(array)
        return array
    }

    fun buildNestedDisclosure(disclosures: Array<String>)
}

@JsExport
class JsSdObject(val members: JsMap<String, JsSdProperty>) : JsSdValue {
    override fun buildNestedDisclosure(disclosures: Array<String>) {
        members.forEach { property, _ ->
            if (property.disclosureString != null) {
                disclosures.push(property.disclosureString)
            }
        }
    }
}

@JsExport
class JsSdArray(
    val elements: Array<JsSdArrayValue>
) : JsSdValue {
    override fun buildNestedDisclosure(disclosures: Array<String>) {
        elements.forEach {
            if (it.disclosureString != null) {
                disclosures.push(it.disclosureString)
            }
        }
    }
}

@JsExport
class JsSdPrimitive(
    val value: String?,
) : JsSdValue {
    override fun buildNestedDisclosure(disclosures: Array<String>) {
        // no disclosures
    }
}

@JsExport
class JsSdProperty(
    val name: String,
    val value: JsSdValue,
    val disclosureString: String? = null
) {
    val isSelectivelyDisclosable = disclosureString != null
    fun isDisclosedBy(disclosures: Array<String>) = disclosures.contains(disclosureString)
}

@JsExport
class JsSdArrayValue(
    val value: JsSdValue,
    val disclosureString: String? = null
) {
    val isSelectivelyDisclosable = disclosureString != null
    fun isDisclosedBy(disclosures: Array<String>) = disclosures.contains(disclosureString)
}

/**
 * Maps a [SdValue] in to the JS-compatible version [JsSdValue]
 */
fun JsSdValue(value: SdValue): JsSdValue = when (value) {
    is SdValue.Object -> JsSdObject(
        JsMap(value.members.values.map {
            tupleOf(it.name, JsSdProperty(it.name, JsSdValue(it.value), it.disclosureString))
        }.toTypedArray())
    )

    is SdValue.Array -> JsSdArray(value.elements.map {
        JsSdArrayValue(JsSdValue(it.value), it.disclosureString)
    }.toTypedArray())

    is SdValue.Primitive -> JsSdPrimitive(value.value?.content)
}

/**
 * Represents a verifiable credential issuer.
 */
@JsExport
class JsCredentialIssuer internal constructor(
    val credentialIssuer: JsonCredentialIssuer
) {
    /** The issuer's identifier. */
    val id: String = credentialIssuer.issuerMetadata.credentialIssuer

    /** The issuer's name. */
    val name: String = credentialIssuer.issuerMetadata.display?.firstOrNull()?.name ?: "<no name>"

    /** The list of credentials supported by the issuer. */
    val credentialsSupported = JsMap(
        credentialIssuer.issuerMetadata.credentialConfigurationsSupported.map {
            tupleOf(it.key, JsCredentialIssuerSupportedCredential(it.value))
        }.toTypedArray()
    )

    /** The authorization endpoint URL for the associated authorization server. */
    val authorizationEndpoint = credentialIssuer.authorizationServerMetadata.authorizationEndpoint

    /** The token endpoint URL for the associated authorization server. */
    val tokenEndpoint = credentialIssuer.authorizationServerMetadata.tokenEndpoint
}

// These function export internal functionality to other Kotlin modules (but not to JS)
fun createJsCredentialIssuer(credentialIssuer: JsonCredentialIssuer) = JsCredentialIssuer(credentialIssuer)
fun JsCredentialIssuer.inner() = this.credentialIssuer

/**
 * Represents a received verifiable credential, with additional data such as a user defined name.
 */
@JsExport
class JsUserVerifiableCredential internal constructor(
    internal val userVerifiableCredential: UserVerifiableCredential
) {
    /** The credential unique identifier, generated locally */
    val id: String = userVerifiableCredential.localId

    /** The credential issuer identifier */
    val issuerId: String = userVerifiableCredential.issuerId

    // TODO is Double the right type to use here
    /** The credential issuance timestamp, in epoch seconds */
    val issuedAt: Double = userVerifiableCredential.issuedAt.epochSeconds.toDouble()

    /** The issued credential */
    val credential by lazy { JSCredentialResponse(userVerifiableCredential.credentialResponse) }

    /** The user defined name */
    val userDefinedName: String = userVerifiableCredential.userDefinedName

    /**
     * Returns a copy of this credential, with a changed user defined name.
     * @param newName The new user defined name.
     * @return A new credential, with a modified name.
     */
    fun withUserDefinedName(newName: String) = JsUserVerifiableCredential(
        userVerifiableCredential.copy(userDefinedName = newName)
    )
}

fun createJsUserVerifiableCredential(inner: UserVerifiableCredential) = JsUserVerifiableCredential(inner)
fun JsUserVerifiableCredential.inner() = this.userVerifiableCredential

@JsExport
class JsLdpVcJsonLdCredential internal constructor(val inner: ValidatedLdpVcCredentialResponse) {
    /** W3C Credential. */
    val value: JSW3CVerifiableCredential by lazy { JSW3CVerifiableCredential(inner.w3cCredential) }
}

/**
 * This is a union type: one, and only one of the fields, will be non-null.
 *
 * Notice that JS union types cannot be represented using sealed interfaces.
 */
@JsExport
class JSCredentialResponse internal constructor(val inner: ValidatedCredentialResponse) {
    val jwtVcJsonLd: JsJwtVcJsonLdCredential? by lazy {
        when (inner) {
            is ValidatedJwtVcJsonLdCredentialResponse -> JsJwtVcJsonLdCredential(inner)
            else -> null
        }
    }
    val jwtVcJson: JsJwtVcJsonCredential? by lazy {
        when (inner) {
            is ValidatedJwtVcJsonCredentialResponse -> JsJwtVcJsonCredential(inner)
            else -> null
        }
    }

    val didJwtVcJson: JsDidJwtVcJsonCredential? by lazy {
        when (inner) {
            is DidValidatedJwtVcJsonCredentialResponse -> JsDidJwtVcJsonCredential(inner)
            else -> null
        }
    }

    val ldp: JsLdpVcJsonLdCredential? by lazy {
        when (inner) {
            is ValidatedLdpVcCredentialResponse -> JsLdpVcJsonLdCredential(inner)
            else -> null
        }
    }

    val vcSdJwt: JsVcSdJwtCredential? by lazy {
        when (inner) {
            is DefaultValidatedVcSdJwtCredentialResponse -> JsVcSdJwtCredential(inner)
            else -> null
        }
    }

    fun <T> match(
        onJwtVcJsonLd: (JsJwtVcJsonLdCredential) -> T,
        onJwtVcJson: (JsJwtVcJsonCredential) -> T,
        onLdp: (JsLdpVcJsonLdCredential) -> T,
        onVcSdJwt: (JsVcSdJwtCredential) -> T
    ): T {
        return when (inner) {
            is ValidatedJwtVcJsonCredentialResponse -> onJwtVcJson(jwtVcJson!!)
            is ValidatedJwtVcJsonLdCredentialResponse -> onJwtVcJsonLd(jwtVcJsonLd!!)
            is ValidatedLdpVcCredentialResponse -> onLdp(ldp!!)
            is ValidatedVcSdJwtCredentialResponse -> onVcSdJwt(vcSdJwt!!)
        }
    }
}

@JsExport
class JsJwtVcJsonLdCredential internal constructor(val inner: ValidatedJwtVcJsonLdCredentialResponse) {
    /** Credentials of this format are simple Strings. */
    val value: String = inner.credentialJwt
}

@JsExport
class JsJwtVcJsonCredential internal constructor(val inner: ValidatedJwtVcJsonCredentialResponse) {
    /** Credentials of this format are simple Strings. */
    val value: String = inner.credentialJwt
}

@JsExport
class JsDidJwtVcJsonCredential internal constructor(private val inner: DidValidatedJwtVcJsonCredentialResponse) {

    val claimDisplayables: ReadonlyMap<String, JsDisplayableSet<JsDisplayable>> by lazy {
        JsMap(
            inner.claimDisplayables.map { (key, value) ->
                tupleOf(key, JsDisplayableSetAdapter(value.map { JsDisplayableAdapter(it) }))
            }.toTypedArray()
        )
    }

    val credentialDisplay: JsDisplayableSet<JsCredentialDisplay>? by lazy {
        inner.credentialDisplay?.let {
            JsDisplayableSetAdapter(it.map { credentialDisplayable -> JsCredentialDisplay(credentialDisplayable) })
        }
    }

    // For the moment being, we are just exposing the primitive-typed claims, since we don't know how to
    // display the other ones.
    // In the future we may want to expose a different view on the inner W3C credential
    val subjectStringProperties: ReadonlyMap<String, String> by lazy {
        val item = inner.w3cCredential.credentialSubject.first
        JsMap(
            item.keys.mapNotNull { key ->
                when (val field = item[key]) {
                    is JsonPrimitive -> tupleOf(key, field.content)
                    else -> null
                }
            }
                .toTypedArray()
        )
    }
}

@JsExport
data class JsSelectivelyDiscloseableClaim<T>(
    val name: String,
    val value: T,
    val isSelectivelyDiscloseable: Boolean,
)

@JsExport
class JsVcSdJwtCredential internal constructor(private val inner: DefaultValidatedVcSdJwtCredentialResponse) {

    val claimDisplayables: ReadonlyMap<String, JsDisplayableSet<JsDisplayable>> by lazy {
        JsMap(
            inner.claimDisplayables.map { (key, value) ->
                tupleOf(key, JsDisplayableSetAdapter(value.map { JsDisplayableAdapter(it) }))
            }.toTypedArray()
        )
    }

    val credentialDisplay: JsDisplayableSet<JsCredentialDisplay>? by lazy {
        inner.credentialDisplay?.let {
            JsDisplayableSetAdapter(it.map { credentialDisplayable -> JsCredentialDisplay(credentialDisplayable) })
        }
    }

    // For the moment being, we are just exposing the primitive-typed claims, since we don't know how to
    // display the other ones.
    // In the future we may want to expose a different view on the inner credential
    val customStringClaims: Array<JsSelectivelyDiscloseableClaim<String>> by lazy {
        val customClaims = inner.customClaims.members

        customClaims.keys.mapNotNull { key ->
            val claim = customClaims[key]!!
            when (val field = claim.value) {
                is SdValue.Primitive -> JsSelectivelyDiscloseableClaim(
                    key,
                    field.value?.content ?: "",
                    claim.disclosureString != null
                )

                else -> null
            }
        }
            .toTypedArray()

    }

    /**
     * Returns the credential custom claims added with the selectively-disclosable information.
     * See [SdValue] for more information.
     */
    val customSdClaims: JsSdValue = JsSdValue(inner.customClaims)
}