/*
 * Copyright (C) 2023 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

// Because we want these symbols to be in the root package, for better usage from JS
@file:Suppress("PackageDirectoryMismatch")

import io.ktor.utils.io.CancellationException
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.promise
import kotlin.js.Promise

/**
 * A version of Kotlin's `promise` extension function that better reports errors.
 *
 * Please use this function instead of Kotlin's so that Exceptions stackTraces are more useful,
 * as the Kotlin function will report minified JS which is almost useless for debugging.
 */
fun <T> CoroutineScope.safePromise(description: String = "", action: suspend () -> T): Promise<T> {
    return promise {
        try {
            action()
        } catch (e: Throwable) {
            // JavaScript would show the minified code's stackTrace, so the exception is almost useless,
            // so we need to convert it to something useful from Kotlin, sending just a description of it to JS
            // If the exception is an UserCancellationException we don't print the stack trace, because this is an
            // expected cancellation and we don't want a stack trace in the console
            if (e !is UserCancellationException) {
                e.printStackTrace()
            }

            val location = description.ifBlank { "unknown location" }

            throw Exception("Error at '$location': ${e.getRootCause()}")
        }
    }
}

/**
 * [CancellationException] to be used when the cancellation was explicitly triggered by a user.
 */

@OptIn(ExperimentalJsExport::class)
@JsExport
class UserCancellationException : CancellationException()

