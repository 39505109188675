/*
 * Copyright (C) 2023 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

@file:OptIn(ExperimentalJsExport::class)

// Because we want these symbols to be in the root package, for better usage from JS
@file:Suppress("PackageDirectoryMismatch")

import io.ktor.client.HttpClient
import io.ktor.client.engine.js.Js
import io.ktor.client.plugins.contentnegotiation.ContentNegotiation
import io.ktor.http.URLProtocol
import io.ktor.serialization.kotlinx.json.json
import vc_js.ProxyPlugin
import vc_js.services.JsOAuthSupport

@JsExport
data class JsProxy(
    val scheme: String,
    val host: String,
    val port: Int,
)

internal fun createJsClient(oauthSupport: JsOAuthSupport?, proxyConfig: JsProxy) =
    HttpClient(Js.create()) {
        install(ContentNegotiation) { json() }
        install(ProxyPlugin) {
            scheme = when (proxyConfig.scheme) {
                "http" -> URLProtocol.HTTP
                "https" -> URLProtocol.HTTPS
                else -> TODO("unsupported protocol")
            }
            host = proxyConfig.host
            port = proxyConfig.port
        }
    }.apply {
        // FIXME - decide how to handle this
        // installOAuthSupport(oauthSupport.delegate)
    }
