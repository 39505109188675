/*
 * Copyright (C) 2023 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

package io.curity.ssi.did.json

import io.curity.ssi.did.Did
import io.curity.ssi.did.DidUrl
import io.curity.ssi.did.json.JsonServiceEndpoint
import io.curity.ssi.did.json.JsonVerificationMethodOrDid
import io.curity.ssi.json.data.DerivedSerializer
import io.curity.ssi.json.data.JsonDictionary
import io.curity.ssi.json.data.JsonSerializer
import kotlinx.serialization.DeserializationStrategy
import kotlinx.serialization.KSerializer
import kotlinx.serialization.builtins.serializer
import kotlinx.serialization.descriptors.PrimitiveKind
import kotlinx.serialization.descriptors.PrimitiveSerialDescriptor
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import kotlinx.serialization.json.JsonContentPolymorphicSerializer
import kotlinx.serialization.json.JsonElement
import kotlinx.serialization.json.JsonObject
import kotlinx.serialization.json.JsonPrimitive
import kotlinx.serialization.json.jsonObject

object DidAsStringSerializer : KSerializer<Did> {
    override val descriptor: SerialDescriptor =
        PrimitiveSerialDescriptor("io.curity.ssi.did.json.DidAsStringSerializer", PrimitiveKind.STRING)

    override fun serialize(encoder: Encoder, value: Did) = encoder.encodeString(value.toString())

    override fun deserialize(decoder: Decoder): Did = Did.parse(decoder.decodeString())
        ?: error("String does not conform to DID syntax")
}

object DidUrlAsStringSerializer : KSerializer<DidUrl> {
    override val descriptor: SerialDescriptor =
        PrimitiveSerialDescriptor("io.curity.ssi.did.json.DidUrlAsStringSerializer", PrimitiveKind.STRING)

    override fun serialize(encoder: Encoder, value: DidUrl) = encoder.encodeString(value.toString())

    override fun deserialize(decoder: Decoder): DidUrl = DidUrl.from(decoder.decodeString())
        ?: error("String does not conform to DID URL syntax")
}

internal object JsonDidDocumentMetadataSerializer : JsonSerializer<JsonDidDocumentMetadata>(JsonDidDocumentMetadata)

internal object JsonDidResolutionMetadataSerializer :
    JsonSerializer<JsonDidResolutionMetadata>(JsonDidResolutionMetadata)

internal object JsonPublicKeyJwkSerializer :
    JsonSerializer<JsonPublicKeyJwk>(JsonPublicKeyJwk)

internal object JsonVerificationMethodOrDidSerializer :
    JsonContentPolymorphicSerializer<JsonVerificationMethodOrDid>(JsonVerificationMethodOrDid::class) {

    override fun selectDeserializer(element: JsonElement): DeserializationStrategy<JsonVerificationMethodOrDid> {
        return if (element is JsonPrimitive) JsonDidVerificationSerializer else JsonMethodVerificationSerializer
    }
}

internal object JsonServiceEndpointSerializer :
    JsonContentPolymorphicSerializer<JsonServiceEndpoint>(JsonServiceEndpoint::class) {

    override fun selectDeserializer(element: JsonElement): DeserializationStrategy<JsonServiceEndpoint> {
        return if (element is JsonPrimitive) JsonServiceUriSerializer else JsonServiceObjectSerializer
    }
}

internal object JsonServiceUriSerializer :
    DerivedSerializer<String, JsonServiceEndpoint.JsonServiceUri>(
        String.serializer(),
        { it.value },
        { JsonServiceEndpoint.JsonServiceUri(it) })

internal object JsonServiceObjectSerializer :
    DerivedSerializer<JsonObject, JsonServiceEndpoint.JsonServiceObject>(
        JsonObject.serializer(),
        { it.toJson().jsonObject },
        { JsonServiceEndpoint.JsonServiceObject(JsonDictionary(it)) })

internal object JsonMethodVerificationSerializer :
    DerivedSerializer<JsonVerificationMethod, JsonVerificationMethodOrDid.JsonMethodVerification>(
        JsonVerificationMethod.serializer(),
        { it.value },
        { JsonVerificationMethodOrDid.JsonMethodVerification(it) })

internal object JsonDidVerificationSerializer :
    DerivedSerializer<Did, JsonVerificationMethodOrDid.JsonDidVerification>(
        DidAsStringSerializer, { it.value }, { JsonVerificationMethodOrDid.JsonDidVerification(it) }
    )
