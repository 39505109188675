/*
 * Copyright (C) 2024 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

package io.curity.ssi.did

import io.curity.ssi.crypto.VerificationKey
import io.curity.ssi.crypto.encodeBase64url
import io.curity.ssi.did.resolvers.jwk.JwkDidType
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json

object JwkDid {

    fun toDidUrl(verificationKey: VerificationKey): DidUrl =
        DidUrl(
            Did(
                JwkDidType.JWK.toString(),
                encodeBase64url(Json.encodeToString(verificationKey.jwk).encodeToByteArray())
            ),
            fragment = "0",
        )
}