/*
 * Copyright (C) 2024 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

@file:OptIn(ExperimentalJsExport::class)

// Because we want these symbols to be in the root package, for better usage from JS
@file:Suppress("PackageDirectoryMismatch")

import io.curity.ssi.data.Dictionary
import io.curity.vc.CredentialStatus
import io.curity.vc.Issuer
import io.curity.vc.Proof
import kotlinx.serialization.json.JsonElement

fun Throwable.getRootCause(): Throwable {
    var result = this
    while (true) {
        result = result.cause ?: break
    }
    return result
}

@JsExport
open class JSObject internal constructor(private val _dictionary: Dictionary<JsonElement>) {
    val keys: Array<String> = _dictionary.keys.toTypedArray()

    /**
     * Retrieve the value for a key in this [Dictionary].
     *
     * Returns `null` if the value is not present.
     */
    operator fun get(key: String): Any? = _dictionary[key]
}

fun createJSObject(dictionary: Dictionary<JsonElement>) = JSObject(dictionary)

@JsExport
open class JSIssuerObject internal constructor(
    issuer: Issuer.IssuerObject<JsonElement>
) : JSObject(issuer) {
    val id = issuer.id
}

@JsExport
open class JSProofObject internal constructor(
    proof: Proof<JsonElement>
) : JSObject(proof) {
    val type = proof.type
}

@JsExport
open class JSCredentialStatusObject internal constructor(
    status: CredentialStatus<JsonElement>
) : JSObject(status) {
    val id = status.id
    val type = status.type
}

@JsExport
class JsPair<out A, out B>(
    val first: A,
    val second: B
)
