/*
 * Copyright (C) 2023 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import { Link, useLoaderData } from 'react-router-dom';
import { JsCredentialIssuer } from '../ssi-libs';
import { routes } from '../routes';
import { Add } from '../components/icons';
import { EmptyState } from '../components/EmptyState';
import { useState } from 'react';
import { IssuerCard } from '../components/issuer-card/IssuerCard';

export function IssuerList() {
  const issuers = useLoaderData() as Array<JsCredentialIssuer>;
  const [searchText, setSearchText] = useState('');
  const filteredIssuers = issuers.filter(issuer =>
          issuer.name.toLowerCase().includes(searchText.toLowerCase()))

  return (
    <>
      {issuers.length ? (
        <>
          <div className="flex flex-center justify-between flex-gap-1">
            <h1 className="flex flex-center flex-gap-1">
              Credential Issuers
              <span className="pill pill-bright">{issuers.length}</span>
            </h1>
            <div>
              <Link className="button button-tiny button-primary-outline" to={routes.ISSUER_ADD}>
                <Add width="20" height="20" />
              </Link>
            </div>
          </div>

          <div className="flex flex-gap-1 justify-between bg-light-grey mt2 mb2 p1 br-8">
            <input
              type="search"
              placeholder="Search Credential Issuers"
              className="field w100"
              value={searchText}
              onChange={e => setSearchText(e.target.value)}
            />
          </div>

          {filteredIssuers.length > 0 ? (
            <>
              {searchText && filteredIssuers.length ? (
                <p>
                  Found {filteredIssuers.length} issuer {filteredIssuers.length > 1 ? <span>(s)</span> : null}
                </p>
              ) : null}
              <ul className="list-reset m0 flex flex-column justify-between flex-gap-1">
                {issuers
                  .filter(issuer => issuer.name.toLowerCase().includes(searchText.toLowerCase()))
                  .map(issuer => {
                    const encodedId = encodeURIComponent(issuer.id);
                    return (
                      <li key={issuer.id}>
                        <IssuerCard name={issuer.name} link={routes.TO_ISSUER_DETAIL(encodedId)} />

                      </li>
                    );
                  })}
              </ul>
            </>
          ) : (
            <p>No search results</p>
          )}
        </>
      ) : (
        <EmptyState
          heading="Looks you don't have any credential issuers"
          text=" Fortunately, it's very easy to create one. Click + button
            above to add your first credential issuer. "
          link="/issuers/add"
          cta="Create your first issuer"
        />
      )}
    </>
  );
}
