/*
 * Copyright (C) 2024 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

package io.curity.vc.serialization

import io.curity.vc.CredentialEndpoint
import io.curity.vc.JwtVcJsonLdFormat
import io.curity.vc.LdpVcFormat
import io.curity.vc.VcSdJwtFormat
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable(with = JwtVcJsonCredentialResponseSerializer::class)
sealed interface JwtVcJsonCredentialResponse : ToVerifiableCredentialResponse {

    @Serializable(DeferredJwtVcJsonCredentialResponseSerializer::class)
    data class Deferred internal constructor(val value: JsonDeferredCredentialResponse) :
        JwtVcJsonCredentialResponse,
        CredentialEndpoint.DeferredVerifiableCredentialResponse by value {
        override fun toVerifiableCredentialResponse() = value
    }
}

@Serializable(with = JwtVcJsonLdCredentialResponseSerializer::class)
sealed interface JwtVcJsonLdCredentialResponse : ToVerifiableCredentialResponse {
    @Serializable(with = DeferredJwtVcJsonLdCredentialResponseSerializer::class)
    data class Deferred internal constructor(val value: JsonDeferredCredentialResponse) :
        JwtVcJsonLdCredentialResponse,
        CredentialEndpoint.DeferredVerifiableCredentialResponse by value {
        override fun toVerifiableCredentialResponse() = value
    }
}

@Serializable(with = LdpVcCredentialResponseSerializer::class)
sealed interface LdpVcCredentialResponse : ToVerifiableCredentialResponse {
    @Serializable(with = DeferredLdpVcCredentialResponseSerializer::class)
    data class Deferred internal constructor(val value: JsonDeferredCredentialResponse) :
        LdpVcCredentialResponse,
        CredentialEndpoint.DeferredVerifiableCredentialResponse by value {
        override fun toVerifiableCredentialResponse() = value
    }
}

@Serializable(with = VcSdJwtCredentialResponseSerializer::class)
sealed interface VcSdJwtCredentialResponse: ToVerifiableCredentialResponse {
    @Serializable(with = DeferredVcSdJwtCredentialResponseSerializer::class)
    data class Deferred internal constructor(val value: JsonDeferredCredentialResponse) :
        VcSdJwtCredentialResponse,
        CredentialEndpoint.DeferredVerifiableCredentialResponse by value {
        override fun toVerifiableCredentialResponse() = value
    }
}

@Serializable
data class JwtVcJsonVerifiableCredentialResponse(
    @SerialName(CredentialEndpoint.SynchronousVerifiableCredentialResponse.Keys.CREDENTIAL)
    private val _credential: String,
    @SerialName(CredentialEndpoint.VerifiableCredentialResponse.Keys.C_NONCE)
    override val cNonce: String? = null,
    @SerialName(CredentialEndpoint.VerifiableCredentialResponse.Keys.C_NONCE_EXPIRES_IN)
    override val cNonceExpiresIn: Long? = null,
) : VcSdJwtFormat.CredentialResponse, JwtVcJsonCredentialResponse, SerializableCredentialResponse {

    override val credential: CredentialEndpoint.VerifiableCredential.StringCredential
        get() = CredentialEndpoint.VerifiableCredential.StringCredential(_credential)

    override fun toCredentialResponse() = this
    override fun toVerifiableCredentialResponse() = this
}

@Serializable
data class JwtVcJsonLdVerifiableCredentialResponse(
    @SerialName(CredentialEndpoint.SynchronousVerifiableCredentialResponse.Keys.CREDENTIAL)
    private val _credential: String,
    @SerialName(CredentialEndpoint.VerifiableCredentialResponse.Keys.C_NONCE)
    override val cNonce: String? = null,
    @SerialName(CredentialEndpoint.VerifiableCredentialResponse.Keys.C_NONCE_EXPIRES_IN)
    override val cNonceExpiresIn: Long? = null,
) : JwtVcJsonLdFormat.CredentialResponse, JwtVcJsonLdCredentialResponse, SerializableCredentialResponse {

    override val credential: CredentialEndpoint.VerifiableCredential.StringCredential
        get() = CredentialEndpoint.VerifiableCredential.StringCredential(_credential)

    override fun toCredentialResponse() = this
    override fun toVerifiableCredentialResponse() = this
}

@Serializable
data class LdpVcVerifiableCredentialResponse(
    @SerialName(CredentialEndpoint.SynchronousVerifiableCredentialResponse.Keys.CREDENTIAL)
    val w3cCredential: SerializableW3CVerifiableCredential,
    @SerialName(CredentialEndpoint.VerifiableCredentialResponse.Keys.C_NONCE)
    override val cNonce: String? = null,
    @SerialName(CredentialEndpoint.VerifiableCredentialResponse.Keys.C_NONCE_EXPIRES_IN)
    override val cNonceExpiresIn: Long? = null,
) : LdpVcFormat.CredentialResponse, LdpVcCredentialResponse, SerializableCredentialResponse {
    override val credential
        get() = CredentialEndpoint.VerifiableCredential.DictionaryCredential(w3cCredential)

    override fun toCredentialResponse() = this
    override fun toVerifiableCredentialResponse() = this
}
