/*
 * Copyright (C) 2023 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

// Because we want these symbols to be in the root package, for better usage from JS
@file:Suppress("PackageDirectoryMismatch")
@file:OptIn(ExperimentalJsExport::class)

import io.curity.oauth.OAuthClient
import io.curity.ssi.did.document.CompositeDidResolver
import io.curity.ssi.did.resolvers.jwk.JwkDidResolver
import io.curity.vc.services.CredentialIssuanceCoordinator
import io.curity.vc.services.DefaultCredentialIssuerLoader
import io.curity.vc.services.DefaultVcSdJwtIssuerMetadataLoader
import io.curity.vc.services.InMemoryKeyAliasStore
import io.curity.vp.services.InMemoryPreRegisteredClientStore
import io.curity.vp.services.PresentationResponseCreator
import io.ktor.client.HttpClient
import io.ktor.client.engine.js.Js
import io.ktor.client.plugins.contentnegotiation.ContentNegotiation
import io.ktor.http.URLProtocol
import io.ktor.serialization.kotlinx.json.json
import kotlinx.datetime.Clock
import kotlinx.serialization.json.Json
import vc_js.ProxyEngine
import vc_js.ProxyPluginConfig
import vc_js.services.BrowserWindowOAuthAuthorizer
import vc_js.services.JsKeyAliasStore
import vc_js.services.LocalStorageCredentialIssuerStore
import vc_js.services.LocalStorageVerifiableCredentialStore

/**
 * Data type with proxy configuration.
 * Server-side proxies are typically used to route requests from a browser to a server in a different origin.
 */
@JsExport
data class JsHttpProxy(
    val scheme: String,
    val host: String,
    val port: Int,
)

private fun createEngine(config: JsHttpProxy) =
    ProxyEngine(
        Js.create(),
        ProxyPluginConfig().apply {
            scheme = when (config.scheme) {
                "http" -> URLProtocol.HTTP
                "https" -> URLProtocol.HTTPS
                else -> TODO("unsupported protocol")
            }
            host = config.host
            port = config.port
        }
    )

/**
 * Creates a [JsCredentialIssuerLoader].
 */
@JsExport
fun createCredentialIssuerLoader(
    proxyConfig: JsHttpProxy? = null
): JsCredentialIssuerLoader {
    val engine = if (proxyConfig != null) {
        createEngine(proxyConfig)
    } else {
        Js.create()
    }

    return JsCredentialIssuerLoader(
        DefaultCredentialIssuerLoader(
            Clock.System,
            HttpClient(engine) {
                install(ContentNegotiation) {
                    json(Json {
                        ignoreUnknownKeys = true
                    })
                }
            }
        )
    )
}

/**
 * Creates a [JsCredentialIssuerStore] using the browser's
 * [localStorage](https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API)
 * to store the issuer information.
 *
 * @return a [JsCredentialIssuerStore].
 */
@JsExport
fun createCredentialIssuerStore() = JsCredentialIssuerStore(
    LocalStorageCredentialIssuerStore()
)

/**
 * Creates a [JsVerifiableCredentialStore] using the browser's
 * [localStorage](https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API)
 * to store the issuer information.
 *
 * @return a [JsVerifiableCredentialStore].
 */
@JsExport
fun createVerifiableCredentialStore(): JsVerifiableCredentialStore =
    JsVerifiableCredentialStore(
        LocalStorageVerifiableCredentialStore()
    )

/**
 * Creates an OAuth 2.0 client.
 *
 * @return a [OAuthClient].
 */
@JsExport
fun createOAuthClient(
    id: String,
    redirectUri: String,
    secret: String,
) = OAuthClient(id, redirectUri, secret)

/**
 * Creates a [JsCredentialIssuanceCoordinator], which can be used to request verifiable credentials,
 * both in wallet-initiated flows and in offer-initiated flows.
 *
 * @param oAuthClient The OAuth 2.0 client to use when requesting access tokens.
 * @param httpProxy The server-side proxy to use.
 * @param credentialIssuerLoader The [JsCredentialIssuerLoader] to use when resolving the issuer's metadata.
 *
 * @return a [JsCredentialIssuanceCoordinator], usable to request credentials.
 *
 * Uses a new window to perform OAuth 2.0 authorization requests (see [BrowserWindowOAuthAuthorizer]).
 * Uses `did:jwk` associated with a hardcoded constant private key.
 * Only usable for demo purposes.
 */
@JsExport
fun createCredentialIssuanceCoordinator(
    oAuthClient: OAuthClient,
    httpProxy: JsHttpProxy,
    credentialIssuerLoader: JsCredentialIssuerLoader
): JsCredentialIssuanceCoordinator =
    JsCredentialIssuanceCoordinator(
        CredentialIssuanceCoordinator(
            Clock.System,
            oAuthClient,
            CompositeDidResolver(
                listOf(JwkDidResolver())
            ),
            BrowserWindowOAuthAuthorizer(),
            createEngine(httpProxy),
            credentialIssuerLoader.inner,
            DefaultVcSdJwtIssuerMetadataLoader(
                HttpClient {
                    install(ContentNegotiation) { json() }
                }
            )
        )
    )

@JsExport
fun createPreRegisteredClientStore(): JsPreRegisteredClientStore =
    JsPreRegisteredClientStore(InMemoryPreRegisteredClientStore())

@JsExport
fun createVerifierClient(
    httpProxy: JsProxy,
    preRegisteredClientStore: JsPreRegisteredClientStore
): JsVerifierClient = JsVerifierClient(httpProxy, preRegisteredClientStore)

@JsExport
fun createCredentialFinder(jsCredentialStore: JsVerifiableCredentialStore): JsOpenIdWalletAuthenticatorCredentialFinder {
    return JsOpenIdWalletAuthenticatorCredentialFinder(jsCredentialStore)
}

@JsExport
fun createPresentationResponseCreator(
    keyAliasStore: JsKeyAliasStore,
    keyStore: JsKeyStore
): JsPresentationResponseCreator = JsPresentationResponseCreator(
    PresentationResponseCreator(Clock.System, keyAliasStore.inner, (keyStore as JsDefaultKeyStore).inner)
)

@JsExport
fun newUserCancellationException() = UserCancellationException()

@JsExport
fun createRequestJwtStore(cleanupPeriod: Int): JsRequestJwtStore =
    JsRequestJwtStore(
        LocalStorageRequestJwtStore(cleanupPeriodInSeconds = cleanupPeriod)
    )

@JsExport
fun createKeyStore(): JsKeyStore = JsDefaultKeyStore()
@JsExport
fun createInMemoryKeyAliasStore(): JsKeyAliasStore = JsKeyAliasStore(InMemoryKeyAliasStore())