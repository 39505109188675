/*
 * Copyright (C) 2024 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

package io.curity.vc.serialization

import io.curity.vc.CredentialEndpoint
import io.curity.vc.VcSdJwtFormat
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class VcSdJwtVerifiableCredentialResponse(
    @SerialName(CredentialEndpoint.SynchronousVerifiableCredentialResponse.Keys.CREDENTIAL)
    val stringCredential: String,
    @SerialName(CredentialEndpoint.VerifiableCredentialResponse.Keys.C_NONCE)
    override val cNonce: String? = null,
    @SerialName(CredentialEndpoint.VerifiableCredentialResponse.Keys.C_NONCE_EXPIRES_IN)
    override val cNonceExpiresIn: Long? = null,
) : VcSdJwtFormat.CredentialResponse, VcSdJwtCredentialResponse, SerializableCredentialResponse {

    override val credential
        get() = CredentialEndpoint.VerifiableCredential.StringCredential(stringCredential)

    override fun toCredentialResponse() = this
    override fun toVerifiableCredentialResponse() = this
}