/*
 * Copyright (C) 2024 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

// Because we want these symbols to be in the root package, for better usage from JS
@file:Suppress("PackageDirectoryMismatch")

import io.curity.vp.services.HaipRequestObjectJwtValidator
import io.curity.vp.services.VerifierClient
import kotlinx.coroutines.GlobalScope
import kotlinx.datetime.Clock
import kotlin.js.Promise

/**
 * JavaScript-friendly Verifier Client.
 *
 * Wraps [io.curity.vp.services.VerifierClient] with a JS API.
 */
@JsExport
class JsVerifierClient(
    proxy: JsProxy,
    clientStore: JsPreRegisteredClientStore
) {
    private val httpClient = createJsClient(null, proxy)

    private val vcClient = VerifierClient(
        httpClient = httpClient,
        validator = HaipRequestObjectJwtValidator(clientStore.inner, Clock.System, httpClient)
    )

    fun fetchRequestObjectJwt(uri: String): Promise<JsValidatedRequestJwt> {
        return GlobalScope.safePromise {
            val validatedResponse = vcClient.fetchRequestObjectJwt(uri)
            JsValidatedRequestJwt(validatedResponse)
        }
    }

    fun postDirectPostAuthorizationResponse(
        uri: String,
        params: JsVerifiablePresentationResponseParameters
    ): Promise<JsVerifierDirectPostAuthorizationResponse> {
        return GlobalScope.safePromise {
            val response = vcClient.postDirectPostAuthorizationResponse(
                uri,
                params.inner
            )
            JsVerifierDirectPostAuthorizationResponse(response)
        }
    }
}