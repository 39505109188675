.pwa-prompt-background {
  --overlay-background-color: rgb(0 0 0 / 80%);

  background-color: var(--overlay-background-color);
  left: 0;
  min-height: 100dvh;
  position: fixed;
  top: 0;
  transition: opacity 0.2s ease-in;
  width: 100vw;
  z-index: 999999;
  height: 100dvh;
}

@media (prefers-color-scheme: dark) {
  .pwa-prompt-content {
    --overlay-background-color: #1d1d1d;
  }
}

.pwa-prompt-content {
  --background-color: #f2f2f2;
  --text-color: var(--color-heading);
  --line-color: #ccc;

  backdrop-filter: blur(10px);
  background-color: var(--background-color);
  border-radius: 10px;
  bottom: 0;
  color: black;
  filter: brightness(1.1);
  left: 0;
  margin: 0 8px 10px;
  overflow: hidden;
  position: fixed;
  animation: slide-up 0.5s ease forwards;
  transition: transform 0.4s cubic-bezier(0.4, 0.24, 0.3, 1);
  width: calc(100vw - 16px);
  z-index: 9999999;
}

@media (prefers-color-scheme: dark) {
  .pwa-prompt-content {
    --text-color: #f2f2f2;
    --background-color: #1d1d1d;
    --line-color: #777;
  }
}

@keyframes slide-up {
  from {
    transform: translateY(100%);
  }

  to {
    transform: translateY(0);
  }
}

.pwa-prompt-content-header {
  align-items: center;
  border-bottom: 1px solid var(--line-color);
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-width: 0.5px;
  display: flex;
  gap: 2rem;
  justify-content: space-between;
  padding: 13px 16px;
}

.pwa-prompt-content-header-heading {
  font-size: 1rem;
  color: var(--text-color);
}

.pwa-prompt-content-header svg {
  stroke: var(--text-color);
}

.lock-scroll {
  overflow: hidden;
}

.pwa-prompt-content-copy p,
.pwa-prompt-content-copy li {
  color: var(--text-color);
}
