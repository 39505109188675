/*
 * Copyright (C) 2024 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

/*
 * Functions and types related to wallet initialization, i.e., actions that should be done
 * on the wallet side only once.
 */

type InitState = {
  version: string;
  changedAt: number;
  loadedIssuers: Array<string>;
};

const KEY_LOCAL_STORAGE = 'init-state';
const MAX_AGE = 7 * 24 * 60 * 60 * 1000;
const VERSION = '2';

function checkSchema(initState: object): initState is InitState {
  // TODO check schema
  return (
    typeof initState['version'] == 'string' &&
    typeof initState['changedAt'] == 'number' &&
    Array.isArray(initState['loadedIssuers'])
  );
}

/**
 * Get stored initialization state.
 */
export function getInitState(): InitState | undefined {
  const initDataString = localStorage.getItem(KEY_LOCAL_STORAGE);
  if (!initDataString) {
    return;
  }
  let initData: object;
  try {
    initData = JSON.parse(initDataString);
  } catch (e) {
    localStorage.removeItem(KEY_LOCAL_STORAGE);
    return;
  }
  if (!(typeof initData == 'object')) {
    localStorage.removeItem(KEY_LOCAL_STORAGE);
    return;
  }
  if (!checkSchema(initData)) {
    localStorage.removeItem(KEY_LOCAL_STORAGE);
    return;
  }
  if (VERSION != initData.version) {
    localStorage.removeItem(KEY_LOCAL_STORAGE);
    return;
  }
  const now = Date.now();
  if (now > initData.changedAt + MAX_AGE) {
    localStorage.removeItem(KEY_LOCAL_STORAGE);
    return;
  }
  return initData;
}

/**
 * Registers that an issuer was correctly preloaded, so that it is not pre-loaded again, even if deleted.
 * @param issuerId
 */
export function addPreLoadedIssuer(issuerId: string) {
  const initState = getInitState() || {
    changedAt: Date.now(),
    version: VERSION,
    loadedIssuers: [],
  };
  initState.changedAt = Date.now();
  initState.loadedIssuers.push(issuerId);
  localStorage.setItem(KEY_LOCAL_STORAGE, JSON.stringify(initState));
}

export function reset() {
  localStorage.removeItem(KEY_LOCAL_STORAGE);
}
