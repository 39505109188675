/*
 * Copyright (C) 2023 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

package io.curity.ssi.data

private class Entry<K, V>(override val key: K, override val value: V) : Map.Entry<K, V>

/**
 * A value which can consist of one or several items of a certain type [T].
 *
 * Use this type instead of [List] where the specification allows for a single value to be used,
 * not just an array.
 */
interface MultiValue<T> {
    /**
     * The mandatory first item.
     *
     * If this is a single value, the [rest] [List] will be empty.
     */
    val first: T

    /**
     * The items of this value, excluding [first].
     */
    val rest: List<T>

    /**
     * All items of this value.
     *
     * The returned [Iterable] is guaranteed to contain at least one item.
     */
    val all: Iterable<T>
        get() = object : Iterable<T> {
            private var atFirstElement = true
            override fun iterator(): Iterator<T> = iterator {
                while (true) {
                    if (atFirstElement) {
                        yield(first)
                        atFirstElement = false
                    } else {
                        yieldAll(rest)
                        break
                    }
                }
            }
        }

    fun isSingleItem(): Boolean = rest.isEmpty()

    /**
     * Map this [MultiValue] using the provided mapping function.
     *
     * @param mapper mapping function
     */
    fun <V> map(mapper: (T) -> V): MultiValue<V> {
        return DefaultMultiValue(all.map(mapper))
    }

    fun forEach(action: (T) -> Unit) {
        all.forEach(action)
    }

    class DefaultMultiValue<T>(private val elements: List<T>) : MultiValue<T> {
        override val first: T
            get() = elements[0]
        override val rest: List<T>
            get() = elements.subList(1, elements.size)
        override val all: List<T>
            get() = elements
    }
}

/**
 * A data type that represents a key-value dictionary, similar to JSON object, where not all keys are known.
 *
 * Only types that allow unknown keys to be present should subtype [Dictionary] as it is significantly
 * harder to serialize and deserialize types with unknown data structures.
 */
interface Dictionary<Value> {
    val keys: Set<String>

    /**
     * Retrieve the value for a key in this [Dictionary].
     *
     * Returns `null` if the value is not present.
     */
    operator fun get(key: String): Value?

    /**
     * Returns a [Map] view of this [Dictionary].
     */
    fun toMap(): Map<String, Value> {
        return object : Map<String, Value> {
            override val entries: Set<Map.Entry<String, Value>>
                get() = keys.map { k -> Entry(k, this[k]!!) }.toSet()

            override val keys: Set<String> = this@Dictionary.keys

            override val size: Int
                get() = keys.size

            override val values: Collection<Value>
                get() = keys.map { k -> this[k]!! }

            override fun isEmpty() = keys.isEmpty()

            override fun get(key: String): Value? = this@Dictionary[key]

            override fun containsValue(value: Value): Boolean =
                keys.any { k -> this[k] == value }

            override fun containsKey(key: String) = keys.contains(key)
        }
    }

}

/**
 * Basic implementation of a [Dictionary] based on a Kotlin [Map].
 *
 * Prefer to use typed objects implementing [Dictionary] instead of using this type
 * except if your application must support types unknown at compile time.
 */
open class MapDictionary<V>(private val map: Map<String, V>) : Dictionary<V> {
    override val keys: Set<String>
        get() = map.keys

    override fun get(key: String): V? = map[key]

    override fun toMap(): Map<String, V> = map
}

/**
 * A type which has a canonical String representation.
 *
 * Normally, such types should be serialized to the String values representing them, though that's not
 * mandatory.
 *
 * By using this type instead of a [String] directly, it's possible to use enum classes to determine which string values
 * should be allowed.
 */
interface ToString {
    override fun toString(): String
}

data class DefaultToString(val value: String) : ToString {
    override fun toString() = value
}
