/*
 * Copyright (C) 2023 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import { Link } from 'react-router-dom';
import { Empty } from './icons';

export const EmptyState = ({
  text,
  heading,
  link,
  cta,
}: {
  text?: string;
  heading?: string;
  link?: string;
  cta?: string;
}) => (
  <div className="flex flex-column  border-light button-fullwidth p3 br-8">
    <div className="center">
      <Empty width="68" height="68" />
    </div>
    <h2 className="mt0">{heading}</h2>
    <p>{text}</p>
    {link && (
      <Link className="button button-medium button-primary-outline button-fullwidth" to={link}>
        {cta}
      </Link>
    )}
  </div>
);
