/*
 * Copyright (C) 2023 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import {
  credentialIssuanceClient,
  JsCredentialIssuerSupportedCredential,
  signingKeyPairPromise,
  verifiableCredentialStore,
} from '../ssi-libs';
import { CredentialSupported } from '../components/CredentialSupported';
import * as React from 'react';
import { Dispatch, useEffect, useReducer, useState } from 'react';
import { Spinner } from '../components/Spinner';
import {
  JsResolvedOffer,
  JsUserVerifiableCredential,
  newUserCancellationException,
} from 'curity-ssi-libs-verifiable-credentials-vc-ks-services';
import { VerifiableCredential } from '../components/VerifiableCredential';
import { SuccessCheckmark } from '../components/SuccessCheckmark';
import { Link, useLocation } from 'react-router-dom';
import { routes } from '../routes';
import { Alert } from '../components/Alert';

/*
 * React component to perform an offer-based credential issuance flow.
 */

type State =
  // Screen to collect the offer string, showing an eventual error.
  | { state: 'begin'; errorMessage?: string; offer?: string }

  // Offer resolution is pending...
  | { state: 'waiting-for-offer-handling'; abortController: AbortController; offer: string; cancelling?: boolean }

  // Show the resolved offer, namely the associated credentials, and collect the option user code. Show an eventual error.
  | { state: 'show-offer'; resolvedOffer: JsResolvedOffer; errorMessage?: string }

  // Credential issuance is pending...
  | {
      state: 'waiting-for-credential-issuance';
      abortController: AbortController;
      resolvedOffer: JsResolvedOffer;
      cancelling?: boolean;
    }

  // Credential was issued, show it and collect user label
  | { state: 'credential-issued'; credential: JsUserVerifiableCredential; credentialName: string }

  // Credential was stored successfully
  | { state: 'credential-stored'; credential: JsUserVerifiableCredential };

type Action =
  | { type: 'start-offer-handling'; abortController: AbortController; offer: string }
  | { type: 'offer-handled'; resolvedOffer: JsResolvedOffer }
  | { type: 'start-credential-request'; abortController: AbortController }
  | { type: 'cancel' }
  | { type: 'error'; message: string }
  | { type: 'credential-issued'; credential: JsUserVerifiableCredential }
  | { type: 'credential-name-assigned'; credentialName: string }
  | { type: 'credential-stored' };

function unexpectedAction(state: State, action: Action) {
  return new Error(`Unexpected action '${action.type}' on state '${state.state}'`);
}

function reducer(state: State, action: Action): State {
  switch (state.state) {
    case 'begin':
      switch (action.type) {
        case 'start-offer-handling':
          return {
            state: 'waiting-for-offer-handling',
            abortController: action.abortController,
            offer: action.offer,
          };
        case 'error':
          // ignore it
          return state;
        default:
          throw unexpectedAction(state, action);
      }

    case 'waiting-for-offer-handling':
      switch (action.type) {
        case 'cancel':
          return { ...state, cancelling: true };
        case 'offer-handled':
          return {
            state: 'show-offer',
            resolvedOffer: action.resolvedOffer,
          };
        case 'error':
          return {
            state: 'begin',
            offer: state.offer,
            errorMessage: !state.cancelling && action.message,
          };
        default:
          throw unexpectedAction(state, action);
      }

    case 'show-offer':
      switch (action.type) {
        case 'start-credential-request':
          return {
            state: 'waiting-for-credential-issuance',
            resolvedOffer: state.resolvedOffer,
            abortController: action.abortController,
          };
        default:
          throw unexpectedAction(state, action);
      }

    case 'waiting-for-credential-issuance':
      switch (action.type) {
        case 'cancel':
          return {
            ...state,
            cancelling: true,
          };
        case 'credential-issued':
          return {
            state: 'credential-issued',
            credential: action.credential,
            credentialName: '',
          };
        case 'error':
          return {
            state: 'show-offer',
            resolvedOffer: state.resolvedOffer,
            errorMessage: !state.cancelling && action.message,
          };
        default:
          throw unexpectedAction(state, action);
      }

    case 'credential-issued':
      switch (action.type) {
        case 'credential-name-assigned':
          return {
            ...state,
            credentialName: action.credentialName,
          };
        case 'credential-stored':
          return {
            state: 'credential-stored',
            credential: state.credential,
          };
        default:
          throw unexpectedAction(state, action);
      }

    case 'credential-stored':
      switch (action.type) {
        case 'credential-stored':
          return {
            state: 'credential-stored',
            credential: state.credential,
          };
        default:
          throw unexpectedAction(state, action);
      }
  }
}

async function operationStartOfferHandling(
  offer: string,
  dispatcher: Dispatch<Action>,
  providedAbortController?: AbortController
) {
  const abortController = providedAbortController || new AbortController();
  try {
    dispatcher({ type: 'start-offer-handling', abortController: abortController, offer: offer });
    const resolvedOffer = await credentialIssuanceClient.startHandleOffer(offer, abortController.signal);
    dispatcher({ type: 'offer-handled', resolvedOffer: resolvedOffer });
  } catch (e) {
    dispatcher({ type: 'error', message: e.message });
  }
}

async function operationStartCredentialRequest(
  resolvedOffer: JsResolvedOffer,
  credential: JsCredentialIssuerSupportedCredential,
  userCode: string | undefined,
  dispatcher: Dispatch<Action>
) {
  const abortController = new AbortController();
  try {
    const signingKeyPair = await signingKeyPairPromise;
    dispatcher({ type: 'start-credential-request', abortController: abortController });
    const offerResult = await credentialIssuanceClient.continueHandleOffer(
      resolvedOffer,
      [credential],
      userCode,
      signingKeyPair,
      abortController.signal
    );
    if (offerResult.issuedCredentials) {
      const issuedCredential = offerResult.issuedCredentials[0];
      if (!issuedCredential || offerResult.issuedCredentials.length > 1) {
        dispatcher({ type: 'error', message: 'response must have a single credential' });
      }
      dispatcher({ type: 'credential-issued', credential: issuedCredential });
    } else {
      dispatcher({ type: 'error', message: 'offer or user code invalid' });
    }
  } catch (error) {
    dispatcher({ type: 'error', message: error.message });
  }
}

function operationCancel(state: State, dispatcher: Dispatch<Action>) {
  if (state.state === 'waiting-for-offer-handling' || state.state === 'waiting-for-credential-issuance') {
    dispatcher({ type: 'cancel' });
    state.abortController.abort(newUserCancellationException());
  }
}

export function CredentialOfferResolve() {
  const [state, dispatcher] = useReducer(reducer, { state: 'begin' });
  const location = useLocation();
  useEffect(() => {
    const offer = 'openid-credential-offer://' + location.search;
    const abortController = new AbortController();
    operationStartOfferHandling(offer, dispatcher, abortController);
    return () => {
      // This will happen when the component is unmounted, so we consider it a user cancellation
      abortController.abort(newUserCancellationException());
    };
  }, [location.search]);

  return render(state, dispatcher);
}

function renderBegin(state: State & { state: 'begin' }, dispatcher: Dispatch<Action>) {
  const onSubmit: React.FormEventHandler<HTMLFormElement> = ev => {
    ev.preventDefault();
    operationStartOfferHandling(state.offer, dispatcher);
  };
  return (
    <div className="center">
      <h2>Retrieving Offer</h2>
      {state.errorMessage && (
        <Alert kind="danger" title="Error when Retrieving Offer" errorMessage={state.errorMessage} />
      )}
      <form onSubmit={onSubmit}>
        <button className="button button-primary-outline w100" type="submit">
          Retry retrieve offer
        </button>
      </form>
    </div>
  );
}

function renderWaitingForOfferHandling(
  state: State & { state: 'waiting-for-offer-handling' },
  dispatcher: Dispatch<Action>
) {
  return (
    <>
      <h2>Resolving offer...</h2>
      <Spinner />
      <button onClick={() => operationCancel(state, dispatcher)}>Cancel</button>
    </>
  );
}

function renderShowOffer(state: State & { state: 'show-offer' }, dispatcher: React.Dispatch<Action>) {
  const credentialSupported = state.resolvedOffer.credentialsSupported[0];
  return (
    <>
      <CredentialSupported supportedCredential={credentialSupported}>
        <RequestCredential
          buttonText="Request Credential"
          txCode={
            state.resolvedOffer.txCode && {
              // Using "numeric" if not or "text" if unknown
              inputMode: (state.resolvedOffer.txCode?.inputMode || 'numeric') == 'numeric' ? 'numeric' : 'text',
              length: state.resolvedOffer.txCode?.length,
              description: state.resolvedOffer.txCode?.description,
            }
          }
          onSubmit={userCode =>
            operationStartCredentialRequest(
              state.resolvedOffer,
              state.resolvedOffer.credentialsSupported[0],
              userCode,
              dispatcher
            )
          }
        />
        {state.errorMessage && <p className="white">Error: {state.errorMessage}</p>}
      </CredentialSupported>
      <p>
        During the credential request a browser window may be opened to perform authentication and credential issuance
        consent on the credential issuer.
      </p>
    </>
  );
}

function renderWaitingForCredentialIssuance(
  state: State & { state: 'waiting-for-credential-issuance' },
  dispatcher: React.Dispatch<Action>
) {
  return (
    <>
      <CredentialSupported supportedCredential={state.resolvedOffer.credentialsSupported[0]}>
        <button
          className="button button-small button-white-outline button-fullwidth mt2"
          onClick={() => operationCancel(state, dispatcher)}
        >
          Cancel
        </button>
      </CredentialSupported>
      <Spinner />
    </>
  );
}

function renderCredentialIssued(state: State & { state: 'credential-issued' }, dispatcher: Dispatch<Action>) {
  return (
    <>
      <h1>Do you want to save this credential in the wallet?</h1>
      <VerifiableCredential credential={state.credential} detailLevel="detailed" />
      <label htmlFor="optionalname" className="label block">
        Name (Optional)
      </label>
      <input
        className="field w100"
        id="optionalname"
        type="text"
        value={state.credentialName}
        placeholder="Credential name"
        onChange={ev =>
          dispatcher({
            type: 'credential-name-assigned',
            credentialName: ev.target.value,
          })
        }
      />
      <button
        className="button button-primary button-fullwidth mt2"
        onClick={() => {
          const credential = state.credential;
          if (state.credentialName) {
            credential.withUserDefinedName(state.credentialName);
          }
          verifiableCredentialStore.insert(credential.withUserDefinedName(state.credentialName));
          dispatcher({
            type: 'credential-stored',
          });
        }}
      >
        Save Credential
      </button>
    </>
  );
}

function renderCredentialStored() {
  return (
    <div className="center">
      <SuccessCheckmark />
      <h1>This credential is now stored in the wallet</h1>
      <Link className="button button-primary-outline button-medium w100 mb2" to={routes.HOME}>
        View my credentials
      </Link>
    </div>
  );
}

function render(state: State, dispatcher: Dispatch<Action>) {
  switch (state.state) {
    case 'begin':
      return renderBegin(state, dispatcher);
    case 'waiting-for-offer-handling':
      return renderWaitingForOfferHandling(state, dispatcher);
    case 'show-offer':
      return renderShowOffer(state, dispatcher);
    case 'waiting-for-credential-issuance':
      return renderWaitingForCredentialIssuance(state, dispatcher);
    case 'credential-issued':
      return renderCredentialIssued(state, dispatcher);
    case 'credential-stored':
      return renderCredentialStored();
  }
}

/*
 * Private auxiliary component to collect the PIN and show the button to start the credential issuance.
 */
type TxCode = {
  inputMode?: 'numeric' | 'text';
  length?: number;
  description?: string;
};
type RequestCredentialProps = {
  buttonText: string;
  txCode?: TxCode;
  onSubmit: (code?: string) => void;
};

function RequestCredential({ buttonText, txCode, onSubmit }: RequestCredentialProps) {
  const [input, setInput] = useState('');
  const needsTxCode = !!txCode;
  const minLength = txCode?.length || 2;
  const maxLength = txCode?.length || 20;
  const pattern = txCode?.inputMode == 'numeric' || !txCode?.inputMode ? '[0-9]*' : undefined;
  return (
    <>
      {needsTxCode && (
        <>
          <p className="white">Insert provided code for the offer</p>
          <input
            className="field w100"
            type="text"
            pattern={pattern}
            value={input}
            minLength={minLength}
            maxLength={maxLength}
            onChange={ev => setInput(ev.target.value)}
            placeholder="Enter code"
          />
        </>
      )}
      <button
        className="button button-white-outline button-fullwidth mt2"
        onClick={() => onSubmit(input)}
        disabled={needsTxCode && (input.length < minLength || (txCode.length && input.length != txCode.length))}
      >
        {buttonText}
      </button>
    </>
  );
}
