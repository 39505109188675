/*
 * Copyright (C) 2023 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

package io.curity.vc.services

import io.curity.vc.serialization.JsonCredentialIssuer
import io.curity.vc.serialization.JsonCredentialIssuerMetadata
import io.curity.vc.serialization.JsonOAuthMetadata
import io.ktor.client.HttpClient
import io.ktor.client.call.body
import io.ktor.client.request.get
import io.ktor.http.URLBuilder
import io.ktor.http.URLProtocol
import io.ktor.http.Url
import io.ktor.http.isSuccess
import kotlinx.datetime.Clock

class DefaultCredentialIssuerLoader(
    private val clock: Clock = Clock.System,
    private val client: HttpClient,
) : CredentialIssuerLoader {

    override suspend fun load(issuerId: String): JsonCredentialIssuer {

        val issuerMetadataUrl = metadataUrlForIssuerId(issuerId)
        val issuerMetadata: JsonCredentialIssuerMetadata = client.get(issuerMetadataUrl).run {
            if (!status.isSuccess()) {
                // TODO use a better exception
                throw Exception("Error ${status.value} while accessing $issuerMetadataUrl")
            }
            body()
        }
        val authorizationServerMetadataUrl = metadataUrlForAuthorizationServerId(
            // Here we always use the first element in the array, if it exists. Otherwise we fall back
            // to the AS with the same ID as the credential issuer.
            issuerMetadata.authorizationServers?.getOrNull(0) ?: issuerMetadata.credentialIssuer
        )
        val authorizationServerMetadata: JsonOAuthMetadata = client.get(authorizationServerMetadataUrl).run {
            if (!status.isSuccess()) {
                // TODO use a better exception
                throw Exception("Error ${status.value} while accessing $authorizationServerMetadataUrl")
            }
            body()
        }

        val now = clock.now()
        return JsonCredentialIssuer(
            issuerMetadata,
            authorizationServerMetadata,
            now,
        )
    }

    companion object {

        private val WELL_KNOWN_ISSUER_POSTFIX = listOf(".well-known", "openid-credential-issuer")

        private val WELL_KNOWN_AUTHORIZATION_SERVER_PREFIX = listOf(".well-known", "oauth-authorization-server")

        private fun metadataUrlForIssuerId(id: String): Url {
            val idUrl = URLBuilder(id).build()
            if (idUrl.protocol != URLProtocol.HTTPS) {
                // TODO use a better exception
                throw Exception("Invalid Issuer identifier - must use HTTPS")
            }
            return URLBuilder(idUrl).apply {
                pathSegments = pathSegments + WELL_KNOWN_ISSUER_POSTFIX
            }.build()
        }

        private fun metadataUrlForAuthorizationServerId(id: String): Url {
            val idUrl = URLBuilder(id).build()
            if (idUrl.protocol != URLProtocol.HTTPS) {
                // TODO use a better exception
                throw Exception("Invalid Authorization Server identifier - must use HTTPS")
            }
            return URLBuilder(idUrl).apply {
                pathSegments = WELL_KNOWN_AUTHORIZATION_SERVER_PREFIX + removeTrailingEmptySegments(pathSegments)
            }.build()
        }

        // Because URLBuilder seems to always include an empty trailing segment.
        private fun removeTrailingEmptySegments(segments: List<String>): List<String> =
            segments.takeLastWhile { it.isNotBlank() }

    }
}