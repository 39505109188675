/*
 * Copyright (C) 2024 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

@file:OptIn(ExperimentalJsExport::class, DelicateCoroutinesApi::class)

package vc_js.services

import io.curity.vc.services.KeyAliasStore
import kotlinx.coroutines.DelicateCoroutinesApi
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.promise
import kotlin.js.Promise

/**
 * JS compatible wrapper for [KeyAliasStore]
 */
@JsExport
class JsKeyAliasStore internal constructor(
    internal val inner: KeyAliasStore
) {

    fun add(alias: String, kid: String): Promise<Boolean> = GlobalScope.promise {
        inner.add(alias, kid)
        true
    }

    fun get(alias: String): Promise<String?> = GlobalScope.promise {
        inner.get(alias)
    }
}