'use strict'

var low = require('./lib/core.js')

module.exports = low

low.registerLanguage('1c', require('highlight.js/lib/languages/1c'))
low.registerLanguage('abnf', require('highlight.js/lib/languages/abnf'))
low.registerLanguage(
  'accesslog',
  require('highlight.js/lib/languages/accesslog')
)
low.registerLanguage(
  'actionscript',
  require('highlight.js/lib/languages/actionscript')
)
low.registerLanguage('ada', require('highlight.js/lib/languages/ada'))
low.registerLanguage(
  'angelscript',
  require('highlight.js/lib/languages/angelscript')
)
low.registerLanguage('apache', require('highlight.js/lib/languages/apache'))
low.registerLanguage(
  'applescript',
  require('highlight.js/lib/languages/applescript')
)
low.registerLanguage('arcade', require('highlight.js/lib/languages/arcade'))
low.registerLanguage('arduino', require('highlight.js/lib/languages/arduino'))
low.registerLanguage('armasm', require('highlight.js/lib/languages/armasm'))
low.registerLanguage('xml', require('highlight.js/lib/languages/xml'))
low.registerLanguage('asciidoc', require('highlight.js/lib/languages/asciidoc'))
low.registerLanguage('aspectj', require('highlight.js/lib/languages/aspectj'))
low.registerLanguage(
  'autohotkey',
  require('highlight.js/lib/languages/autohotkey')
)
low.registerLanguage('autoit', require('highlight.js/lib/languages/autoit'))
low.registerLanguage('avrasm', require('highlight.js/lib/languages/avrasm'))
low.registerLanguage('awk', require('highlight.js/lib/languages/awk'))
low.registerLanguage('axapta', require('highlight.js/lib/languages/axapta'))
low.registerLanguage('bash', require('highlight.js/lib/languages/bash'))
low.registerLanguage('basic', require('highlight.js/lib/languages/basic'))
low.registerLanguage('bnf', require('highlight.js/lib/languages/bnf'))
low.registerLanguage(
  'brainfuck',
  require('highlight.js/lib/languages/brainfuck')
)
low.registerLanguage('c-like', require('highlight.js/lib/languages/c-like'))
low.registerLanguage('c', require('highlight.js/lib/languages/c'))
low.registerLanguage('cal', require('highlight.js/lib/languages/cal'))
low.registerLanguage(
  'capnproto',
  require('highlight.js/lib/languages/capnproto')
)
low.registerLanguage('ceylon', require('highlight.js/lib/languages/ceylon'))
low.registerLanguage('clean', require('highlight.js/lib/languages/clean'))
low.registerLanguage('clojure', require('highlight.js/lib/languages/clojure'))
low.registerLanguage(
  'clojure-repl',
  require('highlight.js/lib/languages/clojure-repl')
)
low.registerLanguage('cmake', require('highlight.js/lib/languages/cmake'))
low.registerLanguage(
  'coffeescript',
  require('highlight.js/lib/languages/coffeescript')
)
low.registerLanguage('coq', require('highlight.js/lib/languages/coq'))
low.registerLanguage('cos', require('highlight.js/lib/languages/cos'))
low.registerLanguage('cpp', require('highlight.js/lib/languages/cpp'))
low.registerLanguage('crmsh', require('highlight.js/lib/languages/crmsh'))
low.registerLanguage('crystal', require('highlight.js/lib/languages/crystal'))
low.registerLanguage('csharp', require('highlight.js/lib/languages/csharp'))
low.registerLanguage('csp', require('highlight.js/lib/languages/csp'))
low.registerLanguage('css', require('highlight.js/lib/languages/css'))
low.registerLanguage('d', require('highlight.js/lib/languages/d'))
low.registerLanguage('markdown', require('highlight.js/lib/languages/markdown'))
low.registerLanguage('dart', require('highlight.js/lib/languages/dart'))
low.registerLanguage('delphi', require('highlight.js/lib/languages/delphi'))
low.registerLanguage('diff', require('highlight.js/lib/languages/diff'))
low.registerLanguage('django', require('highlight.js/lib/languages/django'))
low.registerLanguage('dns', require('highlight.js/lib/languages/dns'))
low.registerLanguage(
  'dockerfile',
  require('highlight.js/lib/languages/dockerfile')
)
low.registerLanguage('dos', require('highlight.js/lib/languages/dos'))
low.registerLanguage('dsconfig', require('highlight.js/lib/languages/dsconfig'))
low.registerLanguage('dts', require('highlight.js/lib/languages/dts'))
low.registerLanguage('dust', require('highlight.js/lib/languages/dust'))
low.registerLanguage('ebnf', require('highlight.js/lib/languages/ebnf'))
low.registerLanguage('elixir', require('highlight.js/lib/languages/elixir'))
low.registerLanguage('elm', require('highlight.js/lib/languages/elm'))
low.registerLanguage('ruby', require('highlight.js/lib/languages/ruby'))
low.registerLanguage('erb', require('highlight.js/lib/languages/erb'))
low.registerLanguage(
  'erlang-repl',
  require('highlight.js/lib/languages/erlang-repl')
)
low.registerLanguage('erlang', require('highlight.js/lib/languages/erlang'))
low.registerLanguage('excel', require('highlight.js/lib/languages/excel'))
low.registerLanguage('fix', require('highlight.js/lib/languages/fix'))
low.registerLanguage('flix', require('highlight.js/lib/languages/flix'))
low.registerLanguage('fortran', require('highlight.js/lib/languages/fortran'))
low.registerLanguage('fsharp', require('highlight.js/lib/languages/fsharp'))
low.registerLanguage('gams', require('highlight.js/lib/languages/gams'))
low.registerLanguage('gauss', require('highlight.js/lib/languages/gauss'))
low.registerLanguage('gcode', require('highlight.js/lib/languages/gcode'))
low.registerLanguage('gherkin', require('highlight.js/lib/languages/gherkin'))
low.registerLanguage('glsl', require('highlight.js/lib/languages/glsl'))
low.registerLanguage('gml', require('highlight.js/lib/languages/gml'))
low.registerLanguage('go', require('highlight.js/lib/languages/go'))
low.registerLanguage('golo', require('highlight.js/lib/languages/golo'))
low.registerLanguage('gradle', require('highlight.js/lib/languages/gradle'))
low.registerLanguage('groovy', require('highlight.js/lib/languages/groovy'))
low.registerLanguage('haml', require('highlight.js/lib/languages/haml'))
low.registerLanguage(
  'handlebars',
  require('highlight.js/lib/languages/handlebars')
)
low.registerLanguage('haskell', require('highlight.js/lib/languages/haskell'))
low.registerLanguage('haxe', require('highlight.js/lib/languages/haxe'))
low.registerLanguage('hsp', require('highlight.js/lib/languages/hsp'))
low.registerLanguage('htmlbars', require('highlight.js/lib/languages/htmlbars'))
low.registerLanguage('http', require('highlight.js/lib/languages/http'))
low.registerLanguage('hy', require('highlight.js/lib/languages/hy'))
low.registerLanguage('inform7', require('highlight.js/lib/languages/inform7'))
low.registerLanguage('ini', require('highlight.js/lib/languages/ini'))
low.registerLanguage('irpf90', require('highlight.js/lib/languages/irpf90'))
low.registerLanguage('isbl', require('highlight.js/lib/languages/isbl'))
low.registerLanguage('java', require('highlight.js/lib/languages/java'))
low.registerLanguage(
  'javascript',
  require('highlight.js/lib/languages/javascript')
)
low.registerLanguage(
  'jboss-cli',
  require('highlight.js/lib/languages/jboss-cli')
)
low.registerLanguage('json', require('highlight.js/lib/languages/json'))
low.registerLanguage('julia', require('highlight.js/lib/languages/julia'))
low.registerLanguage(
  'julia-repl',
  require('highlight.js/lib/languages/julia-repl')
)
low.registerLanguage('kotlin', require('highlight.js/lib/languages/kotlin'))
low.registerLanguage('lasso', require('highlight.js/lib/languages/lasso'))
low.registerLanguage('latex', require('highlight.js/lib/languages/latex'))
low.registerLanguage('ldif', require('highlight.js/lib/languages/ldif'))
low.registerLanguage('leaf', require('highlight.js/lib/languages/leaf'))
low.registerLanguage('less', require('highlight.js/lib/languages/less'))
low.registerLanguage('lisp', require('highlight.js/lib/languages/lisp'))
low.registerLanguage(
  'livecodeserver',
  require('highlight.js/lib/languages/livecodeserver')
)
low.registerLanguage(
  'livescript',
  require('highlight.js/lib/languages/livescript')
)
low.registerLanguage('llvm', require('highlight.js/lib/languages/llvm'))
low.registerLanguage('lsl', require('highlight.js/lib/languages/lsl'))
low.registerLanguage('lua', require('highlight.js/lib/languages/lua'))
low.registerLanguage('makefile', require('highlight.js/lib/languages/makefile'))
low.registerLanguage(
  'mathematica',
  require('highlight.js/lib/languages/mathematica')
)
low.registerLanguage('matlab', require('highlight.js/lib/languages/matlab'))
low.registerLanguage('maxima', require('highlight.js/lib/languages/maxima'))
low.registerLanguage('mel', require('highlight.js/lib/languages/mel'))
low.registerLanguage('mercury', require('highlight.js/lib/languages/mercury'))
low.registerLanguage('mipsasm', require('highlight.js/lib/languages/mipsasm'))
low.registerLanguage('mizar', require('highlight.js/lib/languages/mizar'))
low.registerLanguage('perl', require('highlight.js/lib/languages/perl'))
low.registerLanguage(
  'mojolicious',
  require('highlight.js/lib/languages/mojolicious')
)
low.registerLanguage('monkey', require('highlight.js/lib/languages/monkey'))
low.registerLanguage(
  'moonscript',
  require('highlight.js/lib/languages/moonscript')
)
low.registerLanguage('n1ql', require('highlight.js/lib/languages/n1ql'))
low.registerLanguage('nginx', require('highlight.js/lib/languages/nginx'))
low.registerLanguage('nim', require('highlight.js/lib/languages/nim'))
low.registerLanguage('nix', require('highlight.js/lib/languages/nix'))
low.registerLanguage(
  'node-repl',
  require('highlight.js/lib/languages/node-repl')
)
low.registerLanguage('nsis', require('highlight.js/lib/languages/nsis'))
low.registerLanguage(
  'objectivec',
  require('highlight.js/lib/languages/objectivec')
)
low.registerLanguage('ocaml', require('highlight.js/lib/languages/ocaml'))
low.registerLanguage('openscad', require('highlight.js/lib/languages/openscad'))
low.registerLanguage('oxygene', require('highlight.js/lib/languages/oxygene'))
low.registerLanguage('parser3', require('highlight.js/lib/languages/parser3'))
low.registerLanguage('pf', require('highlight.js/lib/languages/pf'))
low.registerLanguage('pgsql', require('highlight.js/lib/languages/pgsql'))
low.registerLanguage('php', require('highlight.js/lib/languages/php'))
low.registerLanguage(
  'php-template',
  require('highlight.js/lib/languages/php-template')
)
low.registerLanguage(
  'plaintext',
  require('highlight.js/lib/languages/plaintext')
)
low.registerLanguage('pony', require('highlight.js/lib/languages/pony'))
low.registerLanguage(
  'powershell',
  require('highlight.js/lib/languages/powershell')
)
low.registerLanguage(
  'processing',
  require('highlight.js/lib/languages/processing')
)
low.registerLanguage('profile', require('highlight.js/lib/languages/profile'))
low.registerLanguage('prolog', require('highlight.js/lib/languages/prolog'))
low.registerLanguage(
  'properties',
  require('highlight.js/lib/languages/properties')
)
low.registerLanguage('protobuf', require('highlight.js/lib/languages/protobuf'))
low.registerLanguage('puppet', require('highlight.js/lib/languages/puppet'))
low.registerLanguage(
  'purebasic',
  require('highlight.js/lib/languages/purebasic')
)
low.registerLanguage('python', require('highlight.js/lib/languages/python'))
low.registerLanguage(
  'python-repl',
  require('highlight.js/lib/languages/python-repl')
)
low.registerLanguage('q', require('highlight.js/lib/languages/q'))
low.registerLanguage('qml', require('highlight.js/lib/languages/qml'))
low.registerLanguage('r', require('highlight.js/lib/languages/r'))
low.registerLanguage('reasonml', require('highlight.js/lib/languages/reasonml'))
low.registerLanguage('rib', require('highlight.js/lib/languages/rib'))
low.registerLanguage('roboconf', require('highlight.js/lib/languages/roboconf'))
low.registerLanguage('routeros', require('highlight.js/lib/languages/routeros'))
low.registerLanguage('rsl', require('highlight.js/lib/languages/rsl'))
low.registerLanguage(
  'ruleslanguage',
  require('highlight.js/lib/languages/ruleslanguage')
)
low.registerLanguage('rust', require('highlight.js/lib/languages/rust'))
low.registerLanguage('sas', require('highlight.js/lib/languages/sas'))
low.registerLanguage('scala', require('highlight.js/lib/languages/scala'))
low.registerLanguage('scheme', require('highlight.js/lib/languages/scheme'))
low.registerLanguage('scilab', require('highlight.js/lib/languages/scilab'))
low.registerLanguage('scss', require('highlight.js/lib/languages/scss'))
low.registerLanguage('shell', require('highlight.js/lib/languages/shell'))
low.registerLanguage('smali', require('highlight.js/lib/languages/smali'))
low.registerLanguage(
  'smalltalk',
  require('highlight.js/lib/languages/smalltalk')
)
low.registerLanguage('sml', require('highlight.js/lib/languages/sml'))
low.registerLanguage('sqf', require('highlight.js/lib/languages/sqf'))
low.registerLanguage('sql_more', require('highlight.js/lib/languages/sql_more'))
low.registerLanguage('sql', require('highlight.js/lib/languages/sql'))
low.registerLanguage('stan', require('highlight.js/lib/languages/stan'))
low.registerLanguage('stata', require('highlight.js/lib/languages/stata'))
low.registerLanguage('step21', require('highlight.js/lib/languages/step21'))
low.registerLanguage('stylus', require('highlight.js/lib/languages/stylus'))
low.registerLanguage('subunit', require('highlight.js/lib/languages/subunit'))
low.registerLanguage('swift', require('highlight.js/lib/languages/swift'))
low.registerLanguage(
  'taggerscript',
  require('highlight.js/lib/languages/taggerscript')
)
low.registerLanguage('yaml', require('highlight.js/lib/languages/yaml'))
low.registerLanguage('tap', require('highlight.js/lib/languages/tap'))
low.registerLanguage('tcl', require('highlight.js/lib/languages/tcl'))
low.registerLanguage('thrift', require('highlight.js/lib/languages/thrift'))
low.registerLanguage('tp', require('highlight.js/lib/languages/tp'))
low.registerLanguage('twig', require('highlight.js/lib/languages/twig'))
low.registerLanguage(
  'typescript',
  require('highlight.js/lib/languages/typescript')
)
low.registerLanguage('vala', require('highlight.js/lib/languages/vala'))
low.registerLanguage('vbnet', require('highlight.js/lib/languages/vbnet'))
low.registerLanguage('vbscript', require('highlight.js/lib/languages/vbscript'))
low.registerLanguage(
  'vbscript-html',
  require('highlight.js/lib/languages/vbscript-html')
)
low.registerLanguage('verilog', require('highlight.js/lib/languages/verilog'))
low.registerLanguage('vhdl', require('highlight.js/lib/languages/vhdl'))
low.registerLanguage('vim', require('highlight.js/lib/languages/vim'))
low.registerLanguage('x86asm', require('highlight.js/lib/languages/x86asm'))
low.registerLanguage('xl', require('highlight.js/lib/languages/xl'))
low.registerLanguage('xquery', require('highlight.js/lib/languages/xquery'))
low.registerLanguage('zephir', require('highlight.js/lib/languages/zephir'))
