/*
 * Copyright (C) 2023 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

package io.curity.vp.serialization

import io.curity.ssi.json.data.FromJson
import io.curity.ssi.json.data.FromJsonHelper
import io.curity.ssi.json.data.JsonDictionary
import io.curity.ssi.json.data.ToJson
import io.curity.ssi.json.data.ToJsonDictionary
import io.curity.ssi.json.data.unwrapStringSet
import io.curity.vc.serialization.unwrapOptionalAlgFormat
import io.curity.vc.serialization.unwrapOptionalProofTypeFormat
import io.curity.vc.serialization.unwrapOptionalVcSdJwtAlgsFormat
import io.curity.vp.PresentationDefinition
import io.curity.vp.PresentationDefinition.AlgFormat.Keys.ALG
import io.curity.vp.PresentationDefinition.Constraints.Keys.FIELDS
import io.curity.vp.PresentationDefinition.Constraints.Keys.LIMIT_DISCLOSURE
import io.curity.vp.PresentationDefinition.Field.Keys.FILTER
import io.curity.vp.PresentationDefinition.Field.Keys.ID
import io.curity.vp.PresentationDefinition.Field.Keys.NAME
import io.curity.vp.PresentationDefinition.Field.Keys.OPTIONAL
import io.curity.vp.PresentationDefinition.Field.Keys.PATH
import io.curity.vp.PresentationDefinition.Field.Keys.PURPOSE
import io.curity.vp.PresentationDefinition.Format.Keys.JWT_VC_JSON
import io.curity.vp.PresentationDefinition.Format.Keys.JWT_VP_JSON
import io.curity.vp.PresentationDefinition.Format.Keys.LDP_VC
import io.curity.vp.PresentationDefinition.Format.Keys.LDP_VP
import io.curity.vp.PresentationDefinition.Format.Keys.VC_SD_JWT
import io.curity.vp.PresentationDefinition.InputDescriptor
import io.curity.vp.PresentationDefinition.InputDescriptor.Keys.CONSTRAINTS
import io.curity.vp.PresentationDefinition.InputDescriptor.Keys.FORMAT
import io.curity.vp.PresentationDefinition.PresentationDefinition.Keys
import io.curity.vp.PresentationDefinition.ProofTypeFormat.Keys.PROOF_TYPE
import io.curity.vp.PresentationSubmission
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.json.Json
import kotlinx.serialization.json.JsonElement

@Serializable
data class JsonPresentationDefinition(
    @SerialName(Keys.ID)
    override val id: String,
    @SerialName(Keys.NAME)
    override val name: String? = null,
    @SerialName(Keys.PURPOSE)
    override val purpose: String? = null,
    @SerialName(Keys.FORMAT)
    override val format: JsonFormat? = null,
    @SerialName(Keys.INPUT_DESCRIPTORS)
    override val inputDescriptors: List<JsonInputDescriptor>
) : PresentationDefinition.PresentationDefinition<JsonElement>

@Serializable
data class JsonInputDescriptor(
    @SerialName(InputDescriptor.Keys.ID)
    override val id: String,
    @SerialName(InputDescriptor.Keys.NAME)
    override val name: String? = null,
    @SerialName(InputDescriptor.Keys.PURPOSE)
    override val purpose: String? = null,
    @SerialName(CONSTRAINTS)
    override val constraints: JsonConstraints,
    @SerialName(FORMAT)
    override val format: JsonFormat? = null
) : InputDescriptor<JsonElement>

@Serializable
data class JsonFormat(
    @SerialName(JWT_VC_JSON)
    override val jwtVcJson: JsonAlgFormat? = null,
    @SerialName(JWT_VP_JSON)
    override val jwtVpJson: JsonAlgFormat? = null,
    @SerialName(VC_SD_JWT)
    override val vcSdJwt: JsonVcSdJwtAlgsFormat? = null,
    @SerialName(LDP_VC)
    override val ldpVc: JsonProofTypeFormat? = null,
    @SerialName(LDP_VP)
    override val ldpVp: JsonProofTypeFormat? = null,
    val customFormats: ToJsonDictionary = JsonDictionary(emptyMap()),
) : PresentationDefinition.Format<JsonElement>, ToJsonDictionary {
    companion object : FromJson<JsonFormat> {
        override fun fromJson(json: JsonElement): JsonFormat {
            val map = FromJsonHelper.requireJsonObject(json, "JsonFormat").toMutableMap()
            return JsonFormat(
                jwtVcJson = map.unwrapOptionalAlgFormat(JWT_VC_JSON),
                jwtVpJson = map.unwrapOptionalAlgFormat(JWT_VP_JSON),
                vcSdJwt = map.unwrapOptionalVcSdJwtAlgsFormat(VC_SD_JWT),
                ldpVc = map.unwrapOptionalProofTypeFormat(LDP_VC),
                ldpVp = map.unwrapOptionalProofTypeFormat(LDP_VP),
                customFormats = JsonDictionary(map.toMap())
            )
        }
    }

    private val _serializable by lazy {
        JsonDictionary(buildMap {
            putAll(customFormats.toJson())
            if (jwtVcJson != null) put(JWT_VC_JSON, jwtVcJson.toJson())
            if (jwtVpJson != null) put(JWT_VP_JSON, jwtVpJson.toJson())
            if (ldpVc != null) put(LDP_VC, ldpVc.toJson())
            if (ldpVp != null) put(LDP_VP, ldpVp.toJson())
        })
    }

    override fun toJsonDictionary(): JsonDictionary = _serializable
}

@Serializable
data class JsonAlgFormat(
    @SerialName(ALG)
    override val alg: Set<String>
) : PresentationDefinition.AlgFormat, ToJson {

    companion object : FromJson<JsonAlgFormat> {
        override fun fromJson(json: JsonElement): JsonAlgFormat {
            val map = FromJsonHelper.requireJsonObject(json, "JsonAlgFormat").toMutableMap()
            return JsonAlgFormat(
                alg = map.unwrapStringSet(ALG),
            )
        }
    }

    override fun toJson() = Json.encodeToJsonElement(serializer(), this)
}

@Serializable
data class JsonProofTypeFormat(
    @SerialName(PROOF_TYPE)
    override val proofType: Set<String>
) : PresentationDefinition.ProofTypeFormat, ToJson {

    companion object : FromJson<JsonProofTypeFormat> {
        override fun fromJson(json: JsonElement): JsonProofTypeFormat {
            val map = FromJsonHelper.requireJsonObject(json, "JsonProofTypeFormat").toMutableMap()
            return JsonProofTypeFormat(
                proofType = map.unwrapStringSet(PROOF_TYPE),
            )
        }
    }

    override fun toJson() = Json.encodeToJsonElement(serializer(), this)
}

@Serializable
data class JsonVcSdJwtAlgsFormat(
    @SerialName(PresentationDefinition.VcSdJwtAlgsFormat.Keys.SD_JWT_ALG_VALUES)
    override val sdJwtAlgValues: Set<String>,
    @SerialName(PresentationDefinition.VcSdJwtAlgsFormat.Keys.KB_JWT_ALG_VALUES)
    override val kbJwtAlgValues: Set<String>
) : PresentationDefinition.VcSdJwtAlgsFormat, ToJson {
    companion object : FromJson<JsonVcSdJwtAlgsFormat> {
        override fun fromJson(json: JsonElement): JsonVcSdJwtAlgsFormat {
            val map = FromJsonHelper.requireJsonObject(json, "JsonVcSdJwtAlgsFormat").toMutableMap()
            return JsonVcSdJwtAlgsFormat(
                sdJwtAlgValues = map.unwrapStringSet(PresentationDefinition.VcSdJwtAlgsFormat.Keys.SD_JWT_ALG_VALUES),
                kbJwtAlgValues = map.unwrapStringSet(PresentationDefinition.VcSdJwtAlgsFormat.Keys.KB_JWT_ALG_VALUES),
            )
        }
    }

    override fun toJson() = Json.encodeToJsonElement(serializer(), this)
}

@Serializable
data class JsonConstraints(
    @SerialName(FIELDS)
    override val fields: List<JsonField>? = null,
    @SerialName(LIMIT_DISCLOSURE)
    override val limitDisclosure: String? = null,
) : PresentationDefinition.Constraints

@Serializable
data class JsonField(
    @SerialName(ID)
    override val id: String? = null,
    @SerialName(NAME)
    override val name: String? = null,
    @SerialName(PURPOSE)
    override val purpose: String? = null,
    @SerialName(PATH)
    override val path: List<String>,
    @SerialName(FILTER)
    override val filter: AbstractJsonFilter? = null,
    @SerialName(OPTIONAL)
    override val optional: Boolean? = null,
) : PresentationDefinition.Field

@Serializable(with = JsonFilterSerializer::class)
sealed class AbstractJsonFilter:  PresentationDefinition.Filter {
    @SerialName(PresentationDefinition.Filter.Keys.TYPE)
    abstract override val type: String
}

@Serializable
data class JsonStringFilter(
    @SerialName(PresentationDefinition.Filter.Keys.TYPE)
    override val type: String,
    @SerialName(PresentationDefinition.StringPatternFilter.Keys.PATTERN)
    override val pattern: String
) : AbstractJsonFilter(), PresentationDefinition.StringPatternFilter

@Serializable
data class JsonArrayContainsFilter(
    @SerialName(PresentationDefinition.Filter.Keys.TYPE)
    override val type: String,
    @SerialName(PresentationDefinition.ArrayContainsFilter.Keys.CONTAINS)
    override val contains: JsonContains
) : AbstractJsonFilter(), PresentationDefinition.ArrayContainsFilter

@Serializable
data class JsonContains(
    @SerialName(PresentationDefinition.Contains.Keys.TYPE)
    override val type: String,
    @SerialName(PresentationDefinition.Contains.Keys.PATTERN)
    override val pattern: String
) : PresentationDefinition.Contains

@Serializable
data class JsonPresentationSubmission(
    @SerialName(PresentationSubmission.PresentationSubmission.Keys.ID)
    override val id: String,
    @SerialName(PresentationSubmission.PresentationSubmission.Keys.DEFINITION_ID)
    override val definitionId: String,
    @SerialName(PresentationSubmission.PresentationSubmission.Keys.DESCRIPTOR_MAP)
    override val descriptorMap: List<JsonDescriptorMapObject>,
) : PresentationSubmission.PresentationSubmission

@Serializable
data class JsonDescriptorMapObject(
    @SerialName(PresentationSubmission.DescriptorObject.Keys.ID)
    override val id: String,
    @SerialName(PresentationSubmission.DescriptorObject.Keys.FORMAT)
    override val format: String,
    @SerialName(PresentationSubmission.DescriptorObject.Keys.PATH)
    override val path: String,
    @SerialName(PresentationSubmission.DescriptorObject.Keys.PATH_NESTED)
    override val pathNested: JsonPathNested?,
) : PresentationSubmission.DescriptorObject

@Serializable
data class JsonPathNested(
    @SerialName(PresentationSubmission.PathNested.Keys.FORMAT)
    override val format: String,
    @SerialName(PresentationSubmission.PathNested.Keys.PATH)
    override val path: String,
) : PresentationSubmission.PathNested
