/*
 * Copyright (C) 2024 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

// Because we want these symbols to be in the root package, for better usage from JS
@file:Suppress("PackageDirectoryMismatch")

import io.curity.vc.serialization.JsonIssuer
import io.curity.vc.serialization.SerializableW3CVerifiableCredential
import kotlinx.serialization.json.Json
import vc_js.ToJsonString

@OptIn(ExperimentalJsExport::class)
@JsExport
class JSW3CVerifiableCredential internal constructor(
    private val _credential: SerializableW3CVerifiableCredential,
) : ToJsonString {
    companion object {
        internal fun from(credential: SerializableW3CVerifiableCredential): JSW3CVerifiableCredential {
            return JSW3CVerifiableCredential(credential)
        }
    }

    val id: String?
        get() = _credential.id

    val context: Array<String>
        get() = _credential.context.toTypedArray()

    val types: Array<String>
        get() = _credential.type.toTypedArray()

    val credentialSubject: Array<JSObject>
        get() = _credential.credentialSubject.all.map { JSObject(it) }.toTypedArray()

    val issuer: Either<String, JSIssuerObject>
        get() = when (val iss = _credential.jsonIssuer.toJsonIssuer()) {
            is JsonIssuer.IssuerObject -> Either.right(JSIssuerObject(iss.toIssuer()))
            is JsonIssuer.IssuerURI -> Either.left(iss.value)
        }

    val issuanceDate: String
        get() = _credential.issued

    val proof: Array<JSProofObject>?
        get() = _credential.proof?.all?.map { JSProofObject(it) }?.toTypedArray()

    val expirationDate: String?
        get() = _credential.expirationDate

    val credentialStatus: JSCredentialStatusObject?
        get() = _credential.credentialStatus?.let { JSCredentialStatusObject(it) }

    val customClaims: JSObject?
        get() = _credential.customClaims?.let { JSObject(it) }

    override fun toJson() = Json.encodeToString(SerializableW3CVerifiableCredential.serializer(), _credential)
}
