/*
 * Copyright (C) 2023 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

package io.curity.vc.serialization

import io.curity.ssi.json.data.FromJson
import io.curity.ssi.json.data.FromJsonHelper
import io.curity.ssi.json.data.JsonDictionary
import io.curity.ssi.json.data.ToJsonDictionary
import io.curity.ssi.json.data.toJson
import io.curity.ssi.json.data.unwrapOptionalBoolean
import io.curity.vc.AuthorizationDetail
import io.curity.vc.JwtVcJsonFormat
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.json.JsonElement

@Serializable
class JwtVcJsonAuthorizationDetail private constructor(
    @SerialName(AuthorizationDetail.Keys.TYPE)
    override val type: String,
    @SerialName(AuthorizationDetail.Keys.FORMAT)
    override val format: String? = null,
    @SerialName(AuthorizationDetail.Keys.CREDENTIAL_CONFIGURATION_ID)
    override val credentialConfigurationId: String? = null,
    @SerialName(JwtVcJsonFormat.AuthorizationDetail.Keys.CREDENTIAL_DEFINITION)
    override val credentialDefinition: JwtVcJsonAuthorizationDetailsCredentialDefinition? = null,
) : JwtVcJsonFormat.AuthorizationDetail<JsonElement> {
    constructor(
        types: Set<String>,
        credentialSubject: JwtVcJsonAuthorizationDetailsCredentialDefinition.JsonCredentialSubject?,
    ) : this(
        AuthorizationDetail.Values.TYPE_OPENID_CREDENTIAL,
        JwtVcJsonFormat.FORMAT,
        null,
        JwtVcJsonAuthorizationDetailsCredentialDefinition(
            types,
            credentialSubject
        )
    )
}

@Serializable
data class JwtVcJsonAuthorizationDetailsCredentialDefinition(
    @SerialName(JwtVcJsonFormat.AuthorizationDetailsCredentialDefinition.Keys.TYPE)
    override val type: Set<String>? = null,
    @SerialName(JwtVcJsonFormat.AuthorizationDetailsCredentialDefinition.Keys.CREDENTIAL_SUBJECT)
    override val credentialSubject: JsonCredentialSubject? = null,
) : JwtVcJsonFormat.AuthorizationDetailsCredentialDefinition<JsonElement> {

    @Serializable(with = JwtVcJsonAuthorizationDetailsCredentialDefinitionCredentialSubjectSerializer::class)
    data class JsonCredentialSubject(
        override val claims: Map<String, JsonCredentialSubjectItem>
    ) : ToJsonDictionary, JwtVcJsonFormat.AuthorizationDetailsCredentialDefinition.CredentialSubject<JsonElement> {

        companion object : FromJson<JsonCredentialSubject> {
            override fun fromJson(json: JsonElement): JsonCredentialSubject {
                val map = FromJsonHelper.requireJsonObject(json, "JsonCredentialSubject")
                return JsonCredentialSubject(
                    claims = map.entries.associate { (key, value) ->
                        key to JsonCredentialSubjectItem.fromJson(value)
                    }
                )
            }
        }

        private val _serialized = lazy {
            JsonDictionary(
                claims.entries.associate { (key, value) -> key to value.toJsonDictionary().toJson() }
            )
        }

        override fun toJsonDictionary(): JsonDictionary = _serialized.value
    }

    @Serializable(with = JwtVcJsonAuthorizationDetailsCredentialDefinitionCredentialSubjectItemSerializer::class)
    data class JsonCredentialSubjectItem(
        override val mandatory: Boolean?,
        val customClaims: ToJsonDictionary? = null,
    ) : ToJsonDictionary, JwtVcJsonFormat.AuthorizationDetailsCredentialDefinition.CredentialSubjectItem<JsonElement> {

        companion object : FromJson<JsonCredentialSubjectItem> {
            override fun fromJson(json: JsonElement): JsonCredentialSubjectItem {
                val map = FromJsonHelper.requireJsonObject(json, "JsonCredentialSubjectItem").toMutableMap()
                return JsonCredentialSubjectItem(
                    mandatory = map.unwrapOptionalBoolean(
                        JwtVcJsonFormat.AuthorizationDetailsCredentialDefinition.CredentialSubjectItem.Keys.MANDATORY
                    ),
                    customClaims = if (map.isEmpty()) null else JsonDictionary(map.toMap()),
                )
            }
        }

        private val _serializable by lazy {
            JsonDictionary(buildMap {
                if (customClaims != null) {
                    putAll(customClaims.toJson())
                }
                if (mandatory != null) {
                    put(
                        JwtVcJsonFormat.AuthorizationDetailsCredentialDefinition.CredentialSubjectItem.Keys.MANDATORY,
                        mandatory.toJson()
                    )
                }

            })
        }

        override fun toJsonDictionary(): JsonDictionary = _serializable
    }

}
