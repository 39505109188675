/*
 * Copyright (C) 2023 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

package io.curity.ssi.json.data

import kotlinx.datetime.Instant
import kotlinx.serialization.json.JsonArray
import kotlinx.serialization.json.JsonElement
import kotlinx.serialization.json.JsonNull
import kotlinx.serialization.json.JsonObject
import kotlinx.serialization.json.JsonPrimitive
import kotlinx.serialization.json.boolean
import kotlinx.serialization.json.booleanOrNull
import kotlinx.serialization.json.longOrNull

fun MutableMap<String, JsonElement>.unwrapBoolean(key: String): Boolean {
    val value = remove(key)
    if (value is JsonPrimitive) return value.boolean
    throwDeserializationError("Expected value of \"$key\" to be a String but was $value")
}

fun MutableMap<String, JsonElement>.unwrapOptionalBoolean(key: String): Boolean? {
    return when (val value = remove(key)) {
        null, JsonNull -> null
        is JsonPrimitive -> value.booleanOrNull ?: throwDeserializationError("Expected value of \"$key\" to be a Boolean but was $value")
        else -> throwDeserializationError("Expected value of \"$key\" to be a String but was $value")
    }
}

fun MutableMap<String, JsonElement>.unwrapString(key: String): String {
    val value = remove(key)
    if (value is JsonPrimitive && value.isString) return value.content
    throwDeserializationError("Expected value of \"$key\" to be a String but was $value")
}

fun MutableMap<String, JsonElement>.unwrapOptionalString(key: String): String? {
    return when (val value = remove(key)) {
        null, JsonNull -> null
        is JsonPrimitive -> if (value.isString) value.content else
            throwDeserializationError("Expected value of \"$key\" to be a String but was $value")

        else -> throwDeserializationError("Expected value of \"$key\" to be a String but was $value")
    }
}

/**
 * Extracts and converts a [String] from the [MutableMap], then parse it using [Instant.parse].
 */
fun MutableMap<String, JsonElement>.unwrapOptionalStringInstant(key: String): Instant? {
    val string = unwrapOptionalString(key) ?: return null
    return try {
        Instant.parse(string)
    } catch (e: Exception) {
        throwDeserializationError("Expected value of \"$key\" to be a String Instant but it could not be parsed: $e")
    }
}

fun MutableMap<String, JsonElement>.unwrapLong(key: String): Long {
    val value = remove(key)
    if (value is JsonPrimitive) {
        val result = value.longOrNull
        if (result != null) return result
    }
    throwDeserializationError("Expected value of \"$key\" to be a String but was $value")
}

fun MutableMap<String, JsonElement>.unwrapOptionalLong(key: String): Long? {
    return when (val value = remove(key)) {
        null, JsonNull -> null
        is JsonPrimitive -> value.longOrNull ?: throwDeserializationError("Expected value of \"$key\" to be a Number but was $value")
        else -> throwDeserializationError("Expected value of \"$key\" to be a Number but was $value")
    }
}

fun MutableMap<String, JsonElement>.unwrapStringList(key: String): List<String> {
    val value = remove(key)
    if (value is JsonArray) return value.asSequence().map {
        if (it is JsonPrimitive && it.isString) it.content
        else throwDeserializationError("Expected array item to be a String but was $it")
    }.toList()
    throwDeserializationError("Expected value of \"$key\" to be an array but was $value")
}

fun MutableMap<String, JsonElement>.unwrapOptionalStringList(key: String): List<String>? {
    return when (val value = remove(key)) {
        null, JsonNull -> null
        is JsonArray -> value.asSequence().map {
            if (it is JsonPrimitive && it.isString) it.content
            else throwDeserializationError("Expected array item to be a String but was $it")
        }.toList()

        else -> throwDeserializationError("Expected array but was $value")
    }
}

fun MutableMap<String, JsonElement>.unwrapStringSet(key: String): Set<String> {
    val value = remove(key)
    if (value is JsonArray) return value.asSequence().map {
        if (it is JsonPrimitive && it.isString) it.content
        else throwDeserializationError("Expected array item to be a String but was $it")
    }.toSet()
    throwDeserializationError("Expected value of \"$key\" to be an array but was $value")
}

fun MutableMap<String, JsonElement>.unwrapMultiValueJsonDictionary(key: String): DefaultJsonMultiValue<out ToJsonDictionary> {
    return when (val value = remove(key)) {
        is JsonObject -> DefaultJsonMultiValue(JsonDictionary.fromJson(value))
        is JsonArray -> DefaultJsonMultiValue(value.mapIndexed { index, item ->
            if (item is JsonObject) JsonDictionary.fromJson(item)
            else throwDeserializationError("Expected value of \"$key\" to contain only objects but at [$index] was $value")
        })

        else -> throwDeserializationError("Expected value of \"$key\" to be an object but was $value")
    }
}

fun MutableMap<String, JsonElement>.unwrapOptionalMultiValueString(key: String): DefaultJsonMultiValue<String>? {
    return when (val value = remove(key)) {
        null, JsonNull -> null
        is JsonPrimitive -> if (value.isString) DefaultJsonMultiValue(value.content) else
            throwDeserializationError("Expected value of \"$key\" to contain string or string[], but was $value")

        is JsonArray -> DefaultJsonMultiValue(value.mapIndexed { index, item ->
            if (item is JsonPrimitive && item.isString) item.content else
                throwDeserializationError("Expected value of \"$key\" to contain only objects but at [$index] was $value")
        })

        else -> throwDeserializationError("Expected value of \"$key\" to be an object but was $value")
    }
}

fun MutableMap<String, JsonElement>.unwrapJsonDictionary(key: String): ToJsonDictionary? {
    return when (val value = remove(key)) {
        null, JsonNull -> null
        is JsonObject -> JsonDictionary.fromJson(value)
        else -> throwDeserializationError("Expected value of \"$key\" to be an object but was $value")
    }
}

fun MutableMap<String, JsonElement>.unwrapOptionalJsonDictionary(key: String): ToJsonDictionary? {
    return when (val value = remove(key)) {
        null, JsonNull -> null
        is JsonObject -> JsonDictionary.fromJson(value)
        else -> throwDeserializationError("Expected value of \"$key\" to be an object but was $value")
    }
}

fun MutableMap<String, JsonElement>.unwrapJsonElement(key: String): JsonElement {
    return when (val value = remove(key)) {
        is JsonElement -> value
        else -> throwDeserializationError("Expected value of \"$key\" to be present")
    }
}

// We represent an unwrap error as an IllegalArgumentException, following the pattern
// of Kotlin Serialization, which also represents a deserialization error with exceptions derived from
// IllegalArgumentException
private fun throwDeserializationError(message: String): Nothing = throw IllegalArgumentException(message)
