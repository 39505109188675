/*
 * Copyright (C) 2023 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

package io.curity.ssi.did.json

import io.curity.ssi.data.MultiValue
import io.curity.ssi.data.ToString
import io.curity.ssi.did.Did
import io.curity.ssi.did.DidUrl
import io.curity.ssi.did.document.DidDocument
import io.curity.ssi.did.document.DidDocumentMetadata
import io.curity.ssi.did.document.DidResolutionMetadata
import io.curity.ssi.did.document.DidResolutionSuccessResult
import io.curity.ssi.did.document.PublicKeyJwk
import io.curity.ssi.did.document.Service
import io.curity.ssi.did.document.ServiceEndpoint
import io.curity.ssi.did.document.VerificationMethod
import io.curity.ssi.did.document.VerificationMethodOrDid
import io.curity.ssi.json.data.AnyString
import io.curity.ssi.json.data.DefaultJsonMultiValue
import io.curity.ssi.json.data.FromJson
import io.curity.ssi.json.data.FromJsonHelper
import io.curity.ssi.json.data.JsonDictionary
import io.curity.ssi.json.data.ToJson
import io.curity.ssi.json.data.ToJsonDictionary
import io.curity.ssi.json.data.unwrapOptionalString
import io.curity.ssi.json.data.unwrapOptionalStringInstant
import kotlinx.datetime.Instant
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.json.Json
import kotlinx.serialization.json.JsonElement
import kotlinx.serialization.json.JsonPrimitive
import kotlinx.serialization.json.jsonObject

typealias SerializableDid = @Serializable(with = DidAsStringSerializer::class) Did

typealias SerializableDidUrl = @Serializable(with = DidUrlAsStringSerializer::class) DidUrl

@Serializable(with = JsonDidDocumentMetadataSerializer::class)
class JsonDidDocumentMetadata(
    override val created: Instant? = null,
    override val updated: Instant? = null,
    val customClaims: Map<String, JsonElement>,
) : DidDocumentMetadata<JsonElement>, ToJsonDictionary {
    companion object : FromJson<JsonDidDocumentMetadata> {
        override fun fromJson(json: JsonElement): JsonDidDocumentMetadata {
            val obj = FromJsonHelper.requireJsonObject(json, "JsonDidDocumentMetadata").toMutableMap()
            return JsonDidDocumentMetadata(
                obj.unwrapOptionalStringInstant(DidDocumentMetadata.Keys.UPDATED),
                obj.unwrapOptionalStringInstant(DidDocumentMetadata.Keys.UPDATED),
                obj.toMap(),
            )
        }
    }

    private val _serialized by lazy {
        JsonDictionary(
            buildMap {
                putAll(customClaims)
                created?.let {
                    put(
                        DidDocumentMetadata.Keys.CREATED,
                        Json.encodeToJsonElement(Instant.serializer(), it)
                    )
                }
                updated?.let {
                    put(
                        DidDocumentMetadata.Keys.UPDATED,
                        Json.encodeToJsonElement(Instant.serializer(), it)
                    )
                }
            }
        )
    }

    override fun toJsonDictionary() = _serialized
}

@Serializable(with = JsonDidResolutionMetadataSerializer::class)
class JsonDidResolutionMetadata(
    override val contentType: ToString? = null,
    val customClaims: Map<String, JsonElement> = emptyMap(),
) : DidResolutionMetadata<JsonElement>, ToJsonDictionary {
    companion object : FromJson<JsonDidResolutionMetadata> {
        override fun fromJson(json: JsonElement): JsonDidResolutionMetadata {
            val obj = FromJsonHelper.requireJsonObject(json, "JsonDidDocumentMetadata").toMutableMap()
            return JsonDidResolutionMetadata(
                obj.unwrapOptionalString(DidResolutionMetadata.Keys.CONTENT_TYPE)?.let { AnyString(it) },
                obj.toMap(),
            )
        }
    }

    private val _serialized by lazy {
        JsonDictionary(buildMap {
            putAll(customClaims)
            contentType?.let { put(DidResolutionMetadata.Keys.CONTENT_TYPE, JsonPrimitive(it.toString())) }
        })
    }

    override fun toJsonDictionary() = _serialized
}

@Serializable
class JsonDidResolutionSuccessResult(
    override val document: JsonDidDocument,
    override val documentMetadata: JsonDidDocumentMetadata? = null,
    override val resolutionMetadata: JsonDidResolutionMetadata? = null,
) : DidResolutionSuccessResult<JsonElement>

@Serializable
class JsonResponseModel(
    val didDocument: JsonDidDocument,
    val didResolutionMetadata: JsonDidResolutionMetadata,
)

@Serializable
class JsonDidDocument(
    @Serializable(with = DidAsStringSerializer::class)
    override val id: Did,
    override val verificationMethod: MultiValue<JsonVerificationMethod>,
    override val alsoKnownAs: DefaultJsonMultiValue<AnyString>? = null,
    override val controller: DefaultJsonMultiValue<@Serializable(with = DidAsStringSerializer::class) Did>? = null,
    @SerialName(DidDocument.Keys.AUTHENTICATION)
    private val _authentication: DefaultJsonMultiValue<JsonVerificationMethodOrDid>? = null,
    @SerialName(DidDocument.Keys.ASSERTION_METHOD)
    private val _assertionMethod: DefaultJsonMultiValue<JsonVerificationMethodOrDid>? = null,
    @SerialName(DidDocument.Keys.KEY_AGREEMENT)
    private val _keyAgreement: DefaultJsonMultiValue<JsonVerificationMethodOrDid>? = null,
    @SerialName(DidDocument.Keys.CAPABILITY_INVOCATION)
    private val _capabilityInvocation: DefaultJsonMultiValue<JsonVerificationMethodOrDid>? = null,
    @SerialName(DidDocument.Keys.CAPABILITY_DELEGATION)
    private val _capabilityDelegation: DefaultJsonMultiValue<JsonVerificationMethodOrDid>? = null,
    // this type works fine, so no need to have an indirect getter
    override val service: DefaultJsonMultiValue<JsonService>? = null
) : DidDocument<JsonElement>, ToJsonDictionary {
    override val authentication: MultiValue<out VerificationMethodOrDid<JsonElement>>? by lazy {
        _authentication?.map { it.toVerificationMethodOrDid() }
    }

    override val assertionMethod: MultiValue<out VerificationMethodOrDid<JsonElement>>? by lazy {
        _assertionMethod?.map { it.toVerificationMethodOrDid() }
    }

    override val keyAgreement: MultiValue<out VerificationMethodOrDid<JsonElement>>? by lazy {
        _keyAgreement?.map { it.toVerificationMethodOrDid() }
    }
    override val capabilityInvocation: MultiValue<out VerificationMethodOrDid<JsonElement>>? by lazy {
        _capabilityInvocation?.map { it.toVerificationMethodOrDid() }
    }
    override val capabilityDelegation: MultiValue<out VerificationMethodOrDid<JsonElement>>? by lazy {
        _capabilityDelegation?.map { it.toVerificationMethodOrDid() }
    }

    override fun toJsonDictionary() = JsonDictionary(
        Json.encodeToJsonElement(serializer(), this).jsonObject
    )

    override fun getVerificationMethodFor(didUrl: DidUrl): JsonVerificationMethod? {
        val didString = didUrl.toString()
        return verificationMethod.all.firstOrNull {
            it.id.toString() == didString
        }
    }
}

@Serializable
class JsonService(
    @SerialName(Service.Keys.ID)
    override val id: String,
    @SerialName(Service.Keys.TYPE)
    override val type: DefaultJsonMultiValue<AnyString>,
    @SerialName(Service.Keys.SERVICE_ENDPOINT)
    private val _serviceEndpoint: DefaultJsonMultiValue<JsonServiceEndpoint>,
) : Service<JsonElement>, ToJsonDictionary {

    override val serviceEndpoint: MultiValue<out ServiceEndpoint<JsonElement>> by lazy {
        _serviceEndpoint.map { it.toServiceEndpoint() }
    }

    override fun toJsonDictionary() =
        JsonDictionary(Json.encodeToJsonElement(serializer(), this).jsonObject)
}

@Serializable(with = JsonServiceEndpointSerializer::class)
sealed interface JsonServiceEndpoint : ToJson {
    fun toServiceEndpoint(): ServiceEndpoint<JsonElement>

    @Serializable(with = JsonServiceUriSerializer::class)
    data class JsonServiceUri(val value: String) : JsonServiceEndpoint {
        override fun toServiceEndpoint() = ServiceEndpoint.ServiceUri<JsonElement>(value)

        override fun toJson() = JsonPrimitive(value)
    }

    @Serializable(with = JsonServiceObjectSerializer::class)
    data class JsonServiceObject(val value: JsonDictionary) : JsonServiceEndpoint, ToJsonDictionary {
        override fun toServiceEndpoint() = ServiceEndpoint.ServiceObject(toJsonDictionary())

        override fun toJsonDictionary() = JsonDictionary(
            Json.encodeToJsonElement(JsonDictionary.serializer(), value).jsonObject
        )
    }
}

/**
 * Closed hierarchy that must reflect [VerificationMethodOrDid]'s, but with JSON types.
 */
@Serializable(with = JsonVerificationMethodOrDidSerializer::class)
sealed interface JsonVerificationMethodOrDid : ToJson {
    companion object : FromJson<JsonVerificationMethodOrDid> {
        override fun fromJson(json: JsonElement): JsonVerificationMethodOrDid =
            Json.decodeFromJsonElement(JsonVerificationMethodOrDidSerializer, json)
    }

    fun toVerificationMethodOrDid(): VerificationMethodOrDid<JsonElement>

    @Serializable(with = JsonMethodVerificationSerializer::class)
    data class JsonMethodVerification(val value: JsonVerificationMethod) : JsonVerificationMethodOrDid {
        override fun toVerificationMethodOrDid() = VerificationMethodOrDid.MethodVerification(value)

        override fun toJson() = value.toJson()
    }

    @Serializable(with = JsonDidVerificationSerializer::class)
    data class JsonDidVerification(
        @Serializable(with = DidAsStringSerializer::class)
        val value: Did
    ) : JsonVerificationMethodOrDid {
        override fun toVerificationMethodOrDid() = VerificationMethodOrDid.DidVerification<JsonElement>(value)

        override fun toJson() = Json.encodeToJsonElement(serializer(), this)
    }
}

@Serializable
class JsonVerificationMethod(
    override val id: SerializableDidUrl,
    override val controller: DefaultJsonMultiValue<SerializableDid>? = null,
    override val type: ToString,
    override val publicKeyJwk: JsonPublicKeyJwk? = null,
    override val publicKeyMultibase: String? = null
) : VerificationMethod<JsonElement>, ToJsonDictionary {
    companion object : FromJson<JsonVerificationMethod> {
        override fun fromJson(json: JsonElement): JsonVerificationMethod =
            Json.decodeFromJsonElement(serializer(), json)
    }

    override fun toJsonDictionary() = JsonDictionary(
        Json.encodeToJsonElement(serializer(), this).jsonObject
    )
}

@Serializable(with = JsonPublicKeyJwkSerializer::class)
class JsonPublicKeyJwk(
    private val map: Map<String, JsonElement>
) : PublicKeyJwk<JsonElement>, ToJsonDictionary {
    companion object : FromJson<JsonPublicKeyJwk> {
        override fun fromJson(json: JsonElement) =
            JsonPublicKeyJwk(FromJsonHelper.requireJsonObject(json, "PublicKeyJwk").toMap())

        fun from(other: PublicKeyJwk<out JsonElement>) = if (other is JsonPublicKeyJwk) {
            other
        } else {
            JsonPublicKeyJwk(other.toMap())
        }
    }

    override fun toJsonDictionary() = JsonDictionary(map)
}
