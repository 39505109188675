/*
 * Copyright (C) 2023 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

package io.curity.vp.serialization

import io.curity.ssi.data.Dictionary
import io.curity.ssi.json.data.FromJson
import io.curity.ssi.json.data.FromJsonHelper
import io.curity.ssi.json.data.JsonDictionary
import io.curity.ssi.json.data.ToJson
import io.curity.ssi.json.data.ToJsonDictionary
import io.curity.vp.JwtVpJsonFormat
import io.curity.vp.LdpVpFormat
import io.curity.vp.VcSdJwtFormat
import io.curity.vp.VerifiablePresentation
import kotlinx.serialization.Serializable
import kotlinx.serialization.json.JsonElement
import kotlinx.serialization.json.JsonPrimitive


@Serializable
data class JsonJwtVpJsonVerifiablePresentation(
    private val _presentation: String,
    override val w3cPresentation: SerializableW3CVerifiablePresentation
) : JwtVpJsonFormat.JwtVpJsonVerifiablePresentation<JsonElement>, ToJson {

    override val presentation: VerifiablePresentation.StringPresentation
        get() = VerifiablePresentation.StringPresentation(_presentation)

    override fun toJson() = JsonPrimitive(_presentation)
}

@Serializable
data class JsonVcSdJwtVerifiablePresentation(
    private val _presentation: String,
) : VcSdJwtFormat.VcSdJwtVerifiablePresentation<JsonElement>, ToJson {

    override val presentation: VerifiablePresentation.StringPresentation
        get() = VerifiablePresentation.StringPresentation(_presentation)

    override fun toJson() = JsonPrimitive(_presentation)
}

@Serializable
data class JsonLdpVpVerifiablePresentation(
    val w3cPresentation: SerializableW3CVerifiablePresentation,
) : LdpVpFormat.LdpVpVerifiablePresentation<Dictionary<JsonElement>>, ToJsonDictionary {

    override val presentation: VerifiablePresentation.DictionaryPresentation<Dictionary<JsonElement>>
        get() = VerifiablePresentation.DictionaryPresentation(w3cPresentation)

    override fun toJsonDictionary() = w3cPresentation.toJsonDictionary()
}

@Serializable(with = JsonVerifiablePresentationSerializer::class)
sealed interface JsonVerifiablePresentation : ToJson {
    companion object : FromJson<JsonVerifiablePresentation> {
        override fun fromJson(json: JsonElement): JsonVerifiablePresentation {
            return if (json is JsonPrimitive && json.isString) JsonStringVerifiablePresentation(json.content)
            else JsonDictionaryVerifiablePresentation(
                JsonDictionary(
                    FromJsonHelper.requireJsonObject(json, "JsonDictionaryVerifiablePresentation")
                )
            )
        }
    }

    fun toVerifiablePresentation(): VerifiablePresentation

    data class JsonStringVerifiablePresentation(
        val value: String
    ) : JsonVerifiablePresentation {
        override fun toVerifiablePresentation() = VerifiablePresentation.StringPresentation(value)

        override fun toJson() = JsonPrimitive(value)
    }

    data class JsonDictionaryVerifiablePresentation(
        val value: JsonDictionary
    ) : JsonVerifiablePresentation, ToJsonDictionary {
        override fun toVerifiablePresentation() = VerifiablePresentation.DictionaryPresentation(value)

        override fun toJsonDictionary() = value
    }
}