/*
 * Copyright (C) 2024 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

package io.curity.ssi.crypto

import web.crypto.Crypto

/**
 * [Crypto] object usable in both the browser and Node
 */
val portableCrypto: Crypto = js("globalThis.crypto").unsafeCast<Crypto>()