/*
 * Copyright (C) 2024 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

package io.curity.ssi.crypto

import io.curity.ssi.data.Dictionary
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json
import kotlinx.serialization.json.JsonElement
import kotlinx.serialization.json.JsonObject

/**
 * Helper function to create [VerificationKey] from [Dictionary] instead of strings.
 */
suspend fun VerificationKey.Companion.fromJwkDictionary(jwk: Dictionary<JsonElement>) =
    this.fromJwk(Json.encodeToString(JsonObject(jwk.toMap())))
