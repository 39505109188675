/*
 * Copyright (C) 2024 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

package io.curity.vc.serialization

import kotlinx.serialization.Serializable

@Serializable
sealed interface ValidatedCredentialResponse {
    val response: ToVerifiableCredentialResponse
}

@Serializable
sealed interface ValidatedJwtVcJsonCredentialResponse : ValidatedCredentialResponse {
    override val response: JwtVcJsonCredentialResponse
    val credentialJwt: String
}

interface ValidatedJwtVcJsonLdCredentialResponse : ValidatedCredentialResponse {
    override val response: JwtVcJsonLdCredentialResponse
    val credentialJwt: String
}

interface ValidatedLdpVcCredentialResponse : ValidatedCredentialResponse {
    override val response: LdpVcCredentialResponse
    val w3cCredential: SerializableW3CVerifiableCredential
}

sealed interface ValidatedVcSdJwtCredentialResponse: ValidatedCredentialResponse {
    override val response: VcSdJwtCredentialResponse
}