/*
 * Copyright (C) 2023 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import { Link } from 'react-router-dom';

interface AlertProps {
  kind?: string;
  title: string;
  errorMessage: string;
  link?: string;
  linkText?: string;
  classes?: string;
}

export const Alert: React.FC<AlertProps> = ({ kind, title, errorMessage, link, linkText, classes = '' }) => (
  <div className={`alert alert-${kind} ${classes}`}>
    <h4 className="mt0">{title}</h4>
    <p className="mb0">{errorMessage}</p>
    {link && (
      <Link className="" to={link}>
        {linkText}
      </Link>
    )}
  </div>
);
