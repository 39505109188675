/*
 * Copyright (C) 2023 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

package io.curity.vc

import io.curity.ssi.data.Dictionary
import io.curity.ssi.data.MultiValue
import io.curity.ssi.data.ToString

/**
 * Verifiable Credential Issuer.
 *
 * The value of the `issuer` property MUST be either a URI or an object containing an `id` property.
 *
 * It is RECOMMENDED that the URI in the issuer or its id be one which, if dereferenced, results in a document
 * containing machine-readable information about the issuer that can be used to verify the information expressed
 * in the credential.
 *
 * See [issuer](https://www.w3.org/TR/vc-data-model/#issuer).
 */
sealed interface Issuer<T> : ToString {
    data class IssuerURI<T>(val value: String) : Issuer<T> {
        override fun toString(): String = value
    }

    interface IssuerObject<T> : Issuer<T>, Dictionary<T> {
        object Keys {
            const val ID = "id"
        }

        val id: String
    }
}

/**
 * An object representing the status of a Verifiable Credential.
 *
 * A Verifiable Credential Extension Registry [VC-EXTENSION-REGISTRY](https://www.w3.org/TR/vc-data-model/#bib-vc-extension-registry)
 * exists that contains available status schemes for implementers who want to implement verifiable credential status
 * checking.
 *
 * Defined in [status](https://www.w3.org/TR/vc-data-model/#status).
 */
interface CredentialStatus<T> : Dictionary<T> {

    /**
     * An URI.
     */
    val id: String

    /**
     * This property expresses the credential status type (also referred to as the credential status method).
     *
     * It is expected that the value will provide enough information to determine the current status of the credential
     * and that machine-readable information needs to be retrievable from the URI.
     *
     * For example, the object could contain a link to an external document noting whether or not the credential is
     * suspended or revoked.
     */
    val type: String

}

/**
 *
 */
interface W3cProof<V> : Dictionary<V> {
    val type: String
}

/**
 * Data model of a Verifiable Credential as specified by
 * [Verifiable Credentials Data Model v1.1](https://www.w3.org/TR/vc-data-model/).
 */
interface W3CVerifiableCredential<V> : Dictionary<V> {
    object Keys {
        const val CONTEXT = "@context"
        const val ID = "id"
        const val TYPE = "type"
        const val CREDENTIAL_SUBJECT = "credentialSubject"
        const val ISSUER = "issuer"
        // FIXME IS-8293
        const val ISSUANCE_DATE = "issued"
        const val PROOF = "proof"
        const val EXPIRATION_DATE = "expirationDate"
        const val CREDENTIAL_STATUS = "credentialStatus"
    }

    /**
     * A Verifiable Credential MUST include a `@context` property.
     *
     * See [contexts](https://www.w3.org/TR/vc-data-model/#contexts)
     */
    val context: List<String>

    /**
     * The `id` property is intended to unambiguously refer to an object, such as a person, product, or organization.
     *
     * Using the `id` property allows for the expression of statements about specific things in the
     * verifiable credential.
     *
     * See [identifiers](https://www.w3.org/TR/vc-data-model/#identifiers)
     */
    val id: String?

    /**
     * The value of the `type` property MUST be, or map to (through interpretation of the `@context` property),
     * one or more URIs.
     *
     * If more than one URI is provided, the URIs MUST be interpreted as an unordered set.
     *
     * Syntactic conveniences SHOULD be used to ease developer usage. Such conveniences might include JSON-LD terms.
     * It is RECOMMENDED that each URI in the type be one which, if dereferenced, results in a document containing
     * machine-readable information about the type.
     *
     * See [types](https://www.w3.org/TR/vc-data-model/#types).
     */
    val type: Set<String>

    /**
     * A verifiable credential contains claims about one or more subjects. The `credentialSubject` property is defined
     * for the expression of claims about one or more subjects.
     *
     * The value of the `credentialSubject` property is defined as a set of objects that contain one or more properties
     * that are each related to a subject of the verifiable credential.
     * Each object MAY contain an `id`, as described in Section 4.2 Identifiers.
     */
    val credentialSubject: MultiValue<out Dictionary<V>>

    /**
     * @see Issuer
     */
    val issuer: Issuer<V>

    /**
     * The value of the `issuanceDate` property MUST be a string value of an [XMLSCHEMA11-2](https://www.w3.org/TR/xmlschema11-2/#dateTime)
     * combined date-time string representing the date and time the credential becomes valid,
     * which could be a date and time in the future.
     *
     * Note that this value represents the earliest point in time at which the information associated with the
     * [credentialSubject] property becomes valid.
     *
     * See [issuance-date](https://www.w3.org/TR/vc-data-model/#issuance-date).
     */
    // FIXME /IS-8293
    val issued: String

    /**
     * One or more cryptographic proofs that can be used to detect tampering and verify the authorship of a credential
     * or presentation.
     *
     * The specific method used for an embedded proof MUST be included using the [EmbeddedProof.type] property.
     *
     * See [Proofs](https://www.w3.org/TR/vc-data-model/#proofs-signatures).
     */
    val proof: MultiValue<out Proof<V>>?

    /**
     * If present, the value of the expirationDate property MUST be a string value of an
     * [XMLSCHEMA11-2](https://www.w3.org/TR/xmlschema11-2/#dateTime) date-time representing the date and time the
     * credential ceases to be valid.
     *
     * See [expiration](https://www.w3.org/TR/vc-data-model/#expiration).
     */
    val expirationDate: String?

    /**
     *
     *
     * See [status](https://www.w3.org/TR/vc-data-model/#status).
     */
    val credentialStatus: CredentialStatus<*>?

}
