import * as styles from './debug-pane.module.css';
import { useState } from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { nightOwl } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { Chevron } from '../icons';

interface DebugPaneProps<T> {
  data: T[];
  title?: string;
}

export const DebugPane: React.FC<DebugPaneProps<any>> = ({ data, title }) => {
  const [isMinmized, setIsMinmized] = useState(true);
  return (
    <div className={`${styles['debug-pane']}  ${isMinmized ? styles['debug-pane-minimized'] : ''}`}>
      <header
        className={`flex flex-center justify-between ${styles['debug-pane-header']}`}
        onClick={() => setIsMinmized(!isMinmized)}
      >
        Debug
        <div style={{ transform: `rotate(${isMinmized ? '180' : '0'})` }}>
          <Chevron />
        </div>
      </header>
      <section className="p2">
        {data.length && title ? <h3 className="white">{title}</h3> : null}
        {data.length ? (
          <SyntaxHighlighter language="javascript" style={nightOwl} wrapLongLines>
            {JSON.stringify(data, null, 2)}
          </SyntaxHighlighter>
        ) : (
          <em className="white">No data passed to the Debug Pane.</em>
        )}
      </section>
    </div>
  );
};
