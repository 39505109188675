/*
 * Copyright (C) 2023 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

package io.curity.ssi.did

import io.curity.ssi.PublicApi
import io.curity.ssi.data.ToString
import kotlin.jvm.JvmStatic

/**
 * Represents a Decentralized Identifier, as defined by [DID-Core](https://www.w3.org/TR/did-core/).
 */
@PublicApi
data class Did(
    /** The DID method, i.e. the second component */
    val method: String,

    /** The DID identifier, i.e. the third component */
    val identifier: String,
) : ToString {

    /** The URI String representation of this DID */
    val uriString: String = "did:$method:$identifier"

    override fun toString(): String = uriString

    /**
     * Builds a DID URL from the current DID by adding a URL path to it
     */
    fun withPath(path: String) = DidUrl(this, path = path)

    /**
     * Builds a DID URL from the current DID by adding a URL query string to it
     */
    fun withQueryString(query: String) = DidUrl(this, query = query)

    /**
     * Builds a DID URL from the current DID by adding a URL fragment to it
     */
    fun withFragment(fragment: String) = DidUrl(this, fragment = fragment)

    /**
     * Tries to build a [DidUrl] from the provided [String], using the current DID as the base to
     * resolve a relative DID URL.
     */
    fun didUrlFrom(relativeOrAbsolute: String): DidUrl? {
        // First check if it is absolute
        DidUrl.from(relativeOrAbsolute)?.let {
            return it
        }

        // Then use the concatenation of the current DID with the provided string
        val absoluteString = toString() + relativeOrAbsolute
        return DidUrl.from(absoluteString)
    }

    override fun equals(other: Any?): Boolean {
        if (this === other) return true
        if (other == null || this::class != other::class) return false

        other as Did

        return uriString == other.uriString
    }

    override fun hashCode(): Int {
        return uriString.hashCode()
    }


    companion object {
        /**
         * Tries to parse a [Did] from the provided [String], returning `null` if the string does not represent a
         * DID.
         */
        @JvmStatic
        fun parse(value: String): Did? = DidUrl.from(value)?.let {
            if (it.isDid) {
                it.did
            } else {
                null
            }
        }
    }
}
