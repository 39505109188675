/*
 * Copyright (C) 2024 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

package io.curity.vc.services

import io.curity.ssi.crypto.SigningKeyPair
import io.curity.ssi.jose.JwsUtil
import io.curity.ssi.sdjwt.SdJwtParser
import io.curity.ssi.sdjwt.vc.VcSdJwtIssuerJwtValidator
import io.curity.ssi.sdjwt.vc.VcSdJwtIssuerMetadataLoader
import io.curity.ssi.validation.suspendableValidationScope
import io.curity.vc.serialization.DefaultValidatedVcSdJwtCredentialResponse
import io.curity.vc.serialization.JsonCredentialIssuer
import io.curity.vc.serialization.JwtVcJsonCredentialResponse
import io.curity.vc.serialization.JwtVcJsonLdCredentialResponse
import io.curity.vc.serialization.LdpVcCredentialResponse
import io.curity.vc.serialization.ValidatedJwtVcJsonCredentialResponse
import io.curity.vc.serialization.ValidatedJwtVcJsonLdCredentialResponse
import io.curity.vc.serialization.ValidatedLdpVcCredentialResponse
import io.curity.vc.serialization.ValidatedVcSdJwtCredentialResponse
import io.curity.vc.serialization.VcSdJwtCredentialConfigurationsSupported
import io.curity.vc.serialization.VcSdJwtCredentialResponse
import io.curity.vc.serialization.VcSdJwtVerifiableCredentialResponse
import kotlinx.datetime.Clock

class VcSdJwtVerifiableCredentialResponseValidator(
    private val clock: Clock,
    private val issuerMetadataLoader: VcSdJwtIssuerMetadataLoader,
    private val issuer: JsonCredentialIssuer,
    private val supportedCredential: VcSdJwtCredentialConfigurationsSupported,
    private val signingKeyPair: SigningKeyPair,
) : VerifiableCredentialResponseValidator {

    override suspend fun validateVcSdJwt(response: VcSdJwtCredentialResponse): ValidatedVcSdJwtCredentialResponse =
        suspendableValidationScope {
            val sdJwtString = when (response) {
                is VcSdJwtVerifiableCredentialResponse -> response.credential
                is VcSdJwtCredentialResponse.Deferred -> error("Cannot handle deferred response")
            }
            val sdJwt = SdJwtParser.decodeFromString(sdJwtString.value) {
                JwsUtil.unsafeParseJws(it)
            }
            if (sdJwt.keyBindingJwt != null || sdJwt.keyBindingJwtString != null) {
                addValidationError("Issued credential must not have key-binding JWT")
            }

            val validatedIssuerJwtResult = VcSdJwtIssuerJwtValidator(clock, issuerMetadataLoader)
                .validateIssuerJwt(
                    this, sdJwt.jwt, sdJwt.jwtString
                )

            // validate iss
            if (issuer.issuerMetadata.credentialIssuer != validatedIssuerJwtResult?.iss) {
                addValidationError("Issued credential issuer does not match metadata issuer")
            }

            // validate vct
            if (supportedCredential.vct != validatedIssuerJwtResult?.vct) {
                addValidationError("Issued credential 'vct' does not match metadata 'vct'")
            }

            // validate cnf.jwt
            if (validatedIssuerJwtResult != null) {
                if (!validatedIssuerJwtResult.cnf.matches(signingKeyPair.verificationKey.jwk)) {
                    addValidationError("Issued credential 'cnf' claims does not match signing key")
                }
            }

            if (hasErrors) {
                return@suspendableValidationScope null
            }

            val subjectDisplayInformation = supportedCredential.claims
                ?.claims
                ?.entries?.mapNotNull { (key, value) ->
                    val valueDisplay = value.display
                    if (valueDisplay != null) {
                        key to valueDisplay
                    } else {
                        null
                    }
                }
                ?.associate { (key, value) ->
                    key to value
                } ?: emptyMap()

            if (validatedIssuerJwtResult != null) {
                DefaultValidatedVcSdJwtCredentialResponse(
                    response,
                    subjectDisplayInformation,
                    supportedCredential.display,
                    sdJwtString.value,
                    validatedIssuerJwtResult.iss,
                    validatedIssuerJwtResult.vct,
                    sdJwt.disclosedCustomClaims,
                    signingKeyPair.id
                )
            } else {
                null
            }
        }.orThrow()

    override suspend fun validateJwtVcJson(response: JwtVcJsonCredentialResponse): ValidatedJwtVcJsonCredentialResponse {
        throw UnsupportedOperationException("This validator can only handle the 'vc+sd-jwt' format")
    }

    override suspend fun validateJwtVcJsonLd(response: JwtVcJsonLdCredentialResponse): ValidatedJwtVcJsonLdCredentialResponse {
        throw UnsupportedOperationException("This validator can only handle the 'vc+sd-jwt' format")
    }

    override suspend fun validateLdp(response: LdpVcCredentialResponse): ValidatedLdpVcCredentialResponse {
        throw UnsupportedOperationException("This validator can only handle the 'vc+sd-jwt' format")
    }

}