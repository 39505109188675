/*
 * Copyright (C) 2023 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import { JsCredentialIssuer, JsCredentialIssuerSupportedCredential } from '../ssi-libs';
import { CredentialSupported } from './CredentialSupported';
import { JSX } from 'react';

type SupportedCredentialActionComponentProps = {
  issuer: JsCredentialIssuer;
  id: string,
  credentialSupported: JsCredentialIssuerSupportedCredential;
};
export type SupportedCredentialActionComponent = (props: SupportedCredentialActionComponentProps) => JSX.Element;

type CredentialIssuerProps = {
  issuer: JsCredentialIssuer;
  SupportedCredentialAction?: SupportedCredentialActionComponent;
};
export function CredentialIssuer({ issuer, SupportedCredentialAction }: CredentialIssuerProps) {
  return (
    <>
      <div className="center">
        <h1>{issuer.name}</h1>
      </div>

      <h3>Credentials supported by this issuer:</h3>
      {issuer.credentialsSupported.size > 0 && (
        <div className="flex flex-column flex-gap-1 mb2">
          {Array.from(issuer.credentialsSupported, ([id, credentialSupported]) => {
            return (
              <div key={id}>
                <CredentialSupported supportedCredential={credentialSupported}>
                  {SupportedCredentialAction && (
                    <SupportedCredentialAction issuer={issuer} id={id} credentialSupported={credentialSupported} />
                  )}
                </CredentialSupported>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
}
