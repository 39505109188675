/*
 * Copyright (C) 2023 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

export const Wallet: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="2"
    className="lucide lucide-wallet"
    viewBox="0 0 24 24"
  >
    <path d="M21 12V7H5a2 2 0 010-4h14v4"></path>
    <path d="M3 5v14a2 2 0 002 2h16v-5"></path>
    <path d="M18 12a2 2 0 000 4h4v-4z"></path>
  </svg>
);
