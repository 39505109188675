/*
 * Copyright (C) 2023 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

package io.curity.ssi.crypto

import io.curity.ssi.PublicApi

@PublicApi
class CryptoException(msg: String, cause: Throwable? = null) : Throwable(msg, cause) {

    override val message: String = msg

    companion object {
        fun jwkDoesNotContainSigningKey(msg: String? = null) =
            CryptoException("JWK does not contain a signing key ('${msg ?: "no extra information"}')")

        fun jwkDoesNotContainVerificationKey(msg: String? = null) =
            CryptoException("JWK does not contain a verification key ('${msg ?: "no extra information"}')")
    }
}

inline fun <T> tryAndMapToCryptoException(msg: String, block: () -> T) = try {
    block()
} catch (ex: Throwable) {
    throw CryptoException("$msg (${ex.message})", ex)
}
