/*
 * Copyright (C) 2024 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

package io.curity.ssi.sdjwt

import io.curity.ssi.crypto.createHashFunction
import io.curity.ssi.crypto.sha256
import io.curity.ssi.jose.JsonJwt
import io.curity.ssi.json.NativeToJsonSerializationAdapter
import io.curity.ssi.validation.ValidationException
import kotlinx.serialization.json.Json
import kotlinx.serialization.json.JsonArray
import kotlinx.serialization.json.JsonElement
import kotlinx.serialization.json.JsonNull
import kotlinx.serialization.json.JsonObject
import kotlinx.serialization.json.JsonPrimitive

/**
 * Provides functionality to parse an SD-JWT producing [SdJwt] instances.
 */
class SdJwtParser(
    private val disclosuresByHash: DisclosuresByHash,
) {

    private suspend fun disclose(
        input: JsonElement,
    ): SdValue = when (input) {
        is JsonArray -> disclose(input)
        is JsonObject -> disclose(input)
        is JsonPrimitive -> SdValue.Primitive(input)
        JsonNull -> SdValue.Primitive(null)
    }

    private suspend fun disclose(
        input: JsonArray,
    ): SdValue.Array {
        val elements = mutableListOf<SdArrayValue>()
        input.forEach { jsonElement ->
            val sdValue = when (jsonElement) {
                is JsonPrimitive -> SdArrayValue(SdValue.Primitive(jsonElement))
                JsonNull -> SdArrayValue(SdValue.Primitive(null))
                is JsonArray -> SdArrayValue(disclose(jsonElement))
                is JsonObject -> {
                    val singleOrNull = jsonElement.entries.singleOrNull()
                    val value = singleOrNull?.value
                    if (singleOrNull?.key == SdJwtConstants.CLAIM_ARRAY_VALUE && value is JsonPrimitive && value.isString) {
                        disclosuresByHash.getArrayValue(value.content)?.let { (value, disclosure) ->
                            SdArrayValue(
                                disclose(value),
                                disclosure
                            )
                        }
                    } else {
                        SdArrayValue(disclose(jsonElement))
                    }
                }
            }
            if (sdValue != null) {
                elements.add(sdValue)
            }
        }
        return SdValue.Array(elements.toTypedArray())
    }

    private suspend fun disclose(
        input: JsonObject,
    ): SdValue.Object {
        val members = mutableMapOf<String, SdProperty>()
        val sdClaim = input[SdJwtConstants.CLAIM_SD]

        if (sdClaim != null) {
            if (sdClaim !is JsonArray) {
                throw IllegalArgumentException("Invalid '$${SdJwtConstants.CLAIM_SD_ALG}' claim: must be an array")
            }
            sdClaim.forEach {
                if (it !is JsonPrimitive || !it.isString) {
                    throw IllegalArgumentException("Invalid '${SdJwtConstants.CLAIM_SD_ALG}' claim: all its elements must be strings")
                }
                val disclosureHash = it.content
                disclosuresByHash.getProperty(disclosureHash)?.let { (name, value, disclosureString) ->
                    val sdValue = disclose(value)
                    members[name] = SdProperty(name, sdValue, disclosureString)
                }
            }
        }
        input.entries.forEach { (name, value) ->
            if (name != SdJwtConstants.CLAIM_SD && name != SdJwtConstants.CLAIM_SD_ALG) {
                val sdValue = disclose(value)
                members[name] = SdProperty(name, sdValue)
            }
        }
        return SdValue.Object(members)
    }

    companion object {

        /**
         * Parses a SD-JWT from a string
         *
         * @param encodedSdJwt the SD-JWT string
         * @param json the JSON decoder to use
         * @param jwtDecoder the JWT decoder, that given a JWT string produces a [JsonJwt.Jws],
         *  used for both the main JWT (i.e. Issuer-signed JWT) and the KB-JWT.
         */
        suspend fun decodeFromString(
            encodedSdJwt: String,
            json: Json = Json,
            jwtDecoder: suspend (String) -> JsonJwt.Jws,
        ) = decodeFromString(
            encodedSdJwt,
            json,
            jwtDecoder
        ) { encodedKbJwt, _ -> jwtDecoder(encodedKbJwt) }


        /**
         * Parses a SD-JWT from a string
         *
         * @param encodedSdJwt the SD-JWT string
         * @param json the JSON decoder to use
         * @param jwtDecoder the JWT decoder, that given a JWT string produces a [JsonJwt.Jws]
         * @param kbJwtDecoder the KB-JWT decoder, that given the issuer JWT and the KB-JWT string
         *  produces a [JsonJwt.Jws]
         */
        suspend fun decodeFromString(
            encodedSdJwt: String,
            json: Json = Json,
            jwtDecoder: suspend (String) -> JsonJwt.Jws,
            kbJwtDecoder: suspend (String, JsonJwt.Jws) -> JsonJwt.Jws
        ): SdJwt {
            val (jwtString, disclosures, kbJwtString) = SdJwt.decode(encodedSdJwt).orThrow()
            val jwt = jwtDecoder(jwtString)
            val kbJwt = if (kbJwtString != null) {
                kbJwtDecoder(kbJwtString, jwt)
            } else {
                null
            }
            val hashFunction = SdJwt.getHashFunctionToUse(jwt)

            // check KB-JWT binding
            if (kbJwt != null) {
                // These checks may already have been done by the kbJwtDecoder, so they may be redundant
                // However, since they are fully specified by the base SD-JWT spec, it is safer to also perform them here
                // check header.typ
                if (kbJwt.header?.typ != SdJwtConstants.TYP_KB_JWT) {
                    throw ValidationException("KB-JWT must have a 'typ' header with value'${SdJwtConstants.TYP_KB_JWT}'")
                }

                // check header.alg
                if (kbJwt.header?.alg == null) {
                    throw ValidationException("KB-JWT must have a 'alg' header")
                }
                if (kbJwt.header?.alg?.contentEquals(SdJwtConstants.ALG_NONE, true) == true) {
                    throw ValidationException("KB-JWT must not use the 'none' algorithm")
                }

                // check payload.sd_hash
                val sdHashStringValue = when (val sdHash = kbJwt.payload[SdJwtConstants.CLAIM_SD_HASH]) {
                    is JsonPrimitive -> sdHash.content
                    else -> throw ValidationException("KB-JWT must have a 'sd_hash' string claim")
                }
                if (sdHashStringValue != SdJwt.computeKbJwtSdHash(jwt, jwtString, disclosures)) {
                    throw ValidationException("KB-JWT has invalid 'sd_hash' value")
                }
            }

            val parser = SdJwtParser(
                DisclosuresByHash.create(disclosures, hashFunction, json)
            )
            return SdJwt(
                jwtString,
                jwt,
                parser.disclose(JsonObject(jwt.payload.customClaims)),
                disclosures,
                kbJwtString,
                kbJwt
            )
        }

        suspend fun decodeFromMap(
            jwtMap: Map<String, *>,
            disclosures: List<String>,
            nativeToJsonSerializationAdapter: NativeToJsonSerializationAdapter = NativeToJsonSerializationAdapter.DEFAULT,
        ): Map<String, *> {
            val jwtJsonObject = nativeToJsonSerializationAdapter.convertValue(jwtMap) as? JsonObject
                ?: throw IllegalArgumentException("map must be a non-null map")
            val hashFunction = getHashFunctionToUse(jwtJsonObject)
            val parser = SdJwtParser(
                DisclosuresByHash.create(disclosures, hashFunction)
            )
            return parser.disclose(jwtJsonObject).toNative()
        }

        private fun getHashFunctionToUse(obj: Map<String, JsonElement>) =
            obj[SdJwtConstants.CLAIM_SD_ALG]?.let { sdAlgElement ->
                if (sdAlgElement !is JsonPrimitive || !sdAlgElement.isString) {
                    throw IllegalArgumentException("Invalid SD-JWT: ${SdJwtConstants.CLAIM_SD_ALG} is not a string")
                }
                val hashAlg = sdAlgElement.content
                createHashFunction(hashAlg)
                    ?: throw IllegalArgumentException("Invalid SD-JWT: Unsupported hash algorithm $hashAlg ")

            } ?: sha256()
    }
}