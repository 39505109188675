/*
 * Copyright (C) 2024 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

package io.curity.ssi.crypto

typealias HashFunction = suspend (ByteArray)->(ByteArray)

/**
 * Return a new HashFunction, that can be repeatedly used but is not thread-safe.
 * @param algorithm the IANA algorithm name for the hash function (see [HashAlgorithmsNames]).
 */
expect fun createHashFunction(algorithm: String): HashFunction?

fun sha256(): HashFunction = createHashFunction(HashAlgorithmsNames.SHA_256)
    ?: throw IllegalStateException("sha-256 not supported")
fun sha384(): HashFunction = createHashFunction(HashAlgorithmsNames.SHA_384)
    ?: throw IllegalStateException("sha-384 not supported")
fun sha512(): HashFunction = createHashFunction(HashAlgorithmsNames.SHA_512)
    ?: throw IllegalStateException("SHA-512 not supported")

/**
 * IANA standard [hash algorithm names](https://www.iana.org/assignments/named-information/named-information.xhtml)
 */
object HashAlgorithmsNames {
    // Using the names from https://www.iana.org/assignments/named-information/named-information.xhtml
    const val SHA_256 = "sha-256"
    const val SHA_384 = "sha-384"
    const val SHA_512 = "sha-512"
}