import { Link } from 'react-router-dom';
import * as styles from './issuer-card.module.css';

interface IssuerCardProps {
  name: string;
  link: string;
}

export const IssuerCard: React.FC<IssuerCardProps> = ({ name, link }) => {
  return (
    <div
      className={`br-8 ${styles['issuer-card']}`}
    >
      <div className="flex flex-gap-2 justify-between">
        <div className="flex flex-center justify-between">
          <Link className="border-light p3 br-8" to={link}>
            <h3 className="mt0">{name}</h3>
            <span className="button button-tiny button-primary-outline">View supported credentials</span>
          </Link>
        </div>
      </div>
    </div>
  );
};
