/*
 * Copyright (C) 2023 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

package io.curity.ssi.crypto

import io.curity.ssi.jose.Jose
import io.curity.ssi.jose.NativeKeyStore
import io.curity.ssi.jose.NativeSigningKey
import io.curity.ssi.jose.NativeVerificationKey
import kotlinx.coroutines.await
import kotlin.js.Json
import kotlin.js.json

/**
 * JS [KeyStore] implementation using the 'node-jose' library.
 */
class NodeJoseKeyStore(
    val keyStore: NativeKeyStore, // the node-jose keystore
) : KeyStore {

    override suspend fun generateSigningKeyPair(id: String, spec: GenerateKeyPairSpec): SigningKeyPair {
        val nativeKey = when (spec) {
            P256 -> keyStore.generate(
                P256.kty, "P-256", json(
                    "kid" to id,
                    "use" to "sig",
                )
            ).await()
        }
        return SigningKeyPair(
            NativeSigningKey(nativeKey),
            NativeVerificationKey(nativeKey),
            id,
        )
    }

    override suspend fun getSigningKeyPairById(id: String): SigningKeyPair? =
        keyStore.get(id)?.let {
            return SigningKeyPair(
                NativeSigningKey(it),
                NativeVerificationKey(it),
                id,
            )
        }

    override suspend fun importSigningKeyPair(id: String, spec: ImportKeyPairSpec): SigningKeyPair {
        val nativeKey = when (spec) {
            is JWK -> {
                keyStore.add(
                    // Force kid
                    JSON.parse<Json>(spec.jsonString).let {
                        it["kid"] = id
                        JSON.stringify(it)
                    }
                ).await()
            }
        }
        return SigningKeyPair(
            NativeSigningKey(nativeKey),
            NativeVerificationKey(nativeKey),
            id,
        )
    }

    companion object {
        fun create() = NodeJoseKeyStore(Jose.JWK.createKeyStore())
    }
}
