/*
 * Copyright (C) 2023 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import { Curity, Back } from '../icons';
import { Link, useNavigate } from 'react-router-dom';
import * as styles from './header.module.css';

export const Header = ({ title, backButton }: { title?: string; backButton?: string | -1 }) => {
  const navigate = useNavigate();
  return (
    <header className={`${styles['header']} px2 flex flex-center flex-gap-1 justify-center absolute w100`}>
      {backButton == -1 ? (
        <button
          className={`${styles['header-button']} button button-tiny button-transparent`}
          onClick={() => navigate(-1)}
        >
          <Back width="24" height="24" />
        </button>
      ) : backButton ? (
        <Link className="button button-tiny button-transparent" to={backButton}>
          <Back width="24" height="24" />
        </Link>
      ) : null}
      <Curity width="24" height="24" />
      {title}
    </header>
  );
};
