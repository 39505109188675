/*
 * Copyright (C) 2023 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

@file:OptIn(ExperimentalJsExport::class)

// Because we want these symbols to be in the root package, for better usage from JS
@file:Suppress("PackageDirectoryMismatch")

import io.curity.ssi.did.DidUrl
import io.curity.ssi.did.resolvers.jwk.JwkDidResolver
import io.curity.vc.serialization.JwtVcJsonCredentialConfigurationsSupported
import io.curity.vc.services.DidSubjectVerifiableCredentialResponseValidator
import io.curity.vc.services.VcClient
import js.core.toList
import vc_js.services.JsOAuthSupport
import kotlin.js.Promise

/**
 * JavaScript-friendly Verifiable Credential Client.
 *
 * Wraps [VcClient] with a JS API.
 */
@JsExport
class JsVcClient(
    vcServerMetadata: JsServerMetadata,
    oauthSupport: JsOAuthSupport,
    proxy: JsProxy,
) {
    private val httpClient = createJsClient(oauthSupport, proxy)

    private val vcClient = VcClient(
        httpClient, vcServerMetadata.inner(),
        responseValidator = DidSubjectVerifiableCredentialResponseValidator(
            // FIXME load() requires suspend
            issuer = TODO(), // DefaultCredentialIssuerLoader(client = httpClient).load(vcServerMetadata.credentialIssuer),
            didSubjectIdentifier = DidUrl.from("TODO")!!,
            supportedCredential = vcServerMetadata.credentialsSupported.values().toList().first().inner()
                    as JwtVcJsonCredentialConfigurationsSupported,
            didResolver = JwkDidResolver()
        )
    )

    fun requestJwtVcJsonCredential(
        request: JSJwtVcJsonVerifiableCredentialRequest,
    ): Promise<Either<JSJwtVcJsonVerifiableCredentialResponse, JSDeferredJsonCredentialResponse>> {
        return vcClient.coroutineScope.safePromise {
            // TODO need to use the validatedResponse from JS
            val validatedResponse = vcClient.requestCredential(convertJwtVcJsonRequest(request))
            convertJwtVcJsonResponse(validatedResponse.response)
        }
    }

    fun requestJwtVcJsonLdCredential(request: JSJwtVcJsonLdVerifiableCredentialRequest):
            Promise<Either<JSJwtVcJsonLdVerifiableCredentialResponse, JSDeferredJsonCredentialResponse>> {
        return vcClient.coroutineScope.safePromise {
            // TODO need to use the validatedResponse from JS
            val validatedResponse = vcClient.requestCredential(convertJwtVcJsonLdRequest(request))
            convertJwtVcJsonLdResponse(validatedResponse.response)
        }
    }

    fun requestLdpVcJsonCredential(request: JSLdpVerifiableCredentialRequest):
            Promise<Either<JSLdpVerifiableCredentialResponse, JSDeferredJsonCredentialResponse>> {
        return vcClient.coroutineScope.safePromise {
            // TODO need to use the validatedResponse from JS
            val validatedResponse = vcClient.requestCredential(convertLdpVcJsonRequest(request))
            convertLdpVcJsonResponse(validatedResponse.response)
        }
    }

}
