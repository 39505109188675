/*
 * Copyright (C) 2023 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import { useEffect, useState } from 'react';
import { Alert } from '../components/Alert';

export function OAuthCallback() {
  const [error, setError] = useState<string | undefined>(undefined);
  useEffect(() => {
    // FIXME on a firefox/android PWA, the window.opener is null
    if (window.opener) {
      window.opener.postMessage(window.location.search);
    } else {
      setError('Unsupported PWA browser, please close window.');
    }
  }, []);
  return (
    <div>
      <h1>OAuth Callback</h1>
      {error && <Alert kind="danger" title="There was an error performing the authorization" errorMessage={error} />}
    </div>
  );
}
