/*
 * Copyright (C) 2024 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

package io.curity.vc.serialization

import io.curity.ssi.json.data.CompositeFromJson
import io.curity.ssi.json.data.FromJson
import io.curity.ssi.json.data.FromJsonHelper
import io.curity.ssi.json.data.JsonDictionary
import io.curity.ssi.json.data.ToJsonDictionary
import io.curity.ssi.json.data.toJson
import io.curity.ssi.json.data.unwrapOptionalJsonDictionary
import io.curity.ssi.json.data.unwrapStringSet
import io.curity.vc.CredentialEndpoint
import io.curity.vc.JwtVcJsonFormat
import io.curity.vc.JwtVcJsonLdFormat
import io.curity.vc.LdpVcFormat
import kotlinx.serialization.Required
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.json.JsonElement
import kotlinx.serialization.json.JsonObject

/**
 * A basic implementation of [ToJsonDictionary] where keys are always associated with
 * an empty object, so that it is only possible to declare which claims are desired in
 * the credential response, but not any nested properties of the claims.
 */
@Serializable(BasicCredentialSubjectSerializer::class)
data class BasicCredentialSubject(
    private val _claims: Set<String>
) : ToJsonDictionary {

    companion object : CompositeFromJson<JsonDictionary, BasicCredentialSubject>(JsonDictionary) {
        val EMPTY_OBJECT = JsonObject(emptyMap())

        override fun fromSerialized(toJson: JsonDictionary): BasicCredentialSubject {
            return BasicCredentialSubject(toJson.keys)
        }
    }

    private val _map by lazy { _claims.associateWith { EMPTY_OBJECT } }

    constructor(vararg claims: String) : this(claims.toSet())

    override fun toJsonDictionary(): JsonDictionary {
        return JsonDictionary(_map)
    }

    override fun toMap() = _map
}

@Serializable
data class JwtVcJsonVerifiableCredentialRequest (
    @SerialName(CredentialEndpoint.VerifiableCredentialRequest.Keys.PROOF)
    override val proof: ToJsonProofOfPossession? = null,
    @SerialName(JwtVcJsonFormat.VerifiableCredentialRequest.Keys.CREDENTIAL_DEFINITION)
    override val credentialDefinition: CredentialDefinition,
    @SerialName(CredentialEndpoint.VerifiableCredentialRequest.Keys.FORMAT)
    @Required
    override val format: String = JwtVcJsonFormat.FORMAT,
) : JwtVcJsonFormat.VerifiableCredentialRequest<JsonElement>, SerializableVerifiableCredentialRequest {

    @Serializable(with = JwtVcJsonVerifiableCredentialRequestCredentialDefinitionSerializer::class)
    data class CredentialDefinition(
        override val type: Set<String>,
        override val credentialSubject: ToJsonDictionary? = null,
    ): ToJsonDictionary, JwtVcJsonFormat.VerifiableCredentialRequest.CredentialDefinition<JsonElement> {

        companion object : FromJson<CredentialDefinition> {
            override fun fromJson(json: JsonElement): CredentialDefinition {
                val map = FromJsonHelper.requireJsonObject(json, "CredentialDefinition").toMutableMap()
                return CredentialDefinition(
                    type = map.unwrapStringSet(
                        JwtVcJsonFormat.VerifiableCredentialRequest.CredentialDefinition.Keys.TYPE),
                    credentialSubject = map.unwrapOptionalJsonDictionary(
                        JwtVcJsonFormat.VerifiableCredentialRequest.CredentialDefinition.Keys.CREDENTIAL_SUBJECT)
                )
            }
        }

        private val _serializable by lazy {
            JsonDictionary(buildMap {
                put(
                    JwtVcJsonFormat.VerifiableCredentialRequest.CredentialDefinition.Keys.TYPE,
                    type.toJson()
                )
                if(credentialSubject != null) {
                   put(
                       JwtVcJsonFormat.VerifiableCredentialRequest.CredentialDefinition.Keys.CREDENTIAL_SUBJECT,
                       credentialSubject.toJson()
                   )
                }
            })
        }

        override fun toJsonDictionary(): JsonDictionary = _serializable
    }
}

@Serializable
data class JwtVcJsonLdVerifiableCredentialRequest(
    @SerialName(CredentialEndpoint.VerifiableCredentialRequest.Keys.PROOF)
    override val proof: ToJsonProofOfPossession? = null,
    @SerialName(JwtVcJsonLdFormat.VerifiableCredentialRequest.Keys.CREDENTIAL_DEFINITION)
    override val credentialDefinition: JsonCredentialDefinition,
    @SerialName(CredentialEndpoint.VerifiableCredentialRequest.Keys.FORMAT)
    @Required
    override val format: String = JwtVcJsonLdFormat.FORMAT
) : JwtVcJsonLdFormat.VerifiableCredentialRequest, SerializableVerifiableCredentialRequest

@Serializable
data class LdpVerifiableCredentialRequest(
    @SerialName(CredentialEndpoint.VerifiableCredentialRequest.Keys.PROOF)
    override val proof: ToJsonProofOfPossession? = null,
    @SerialName(LdpVcFormat.VerifiableCredentialRequest.Keys.CREDENTIAL_DEFINITION)
    override val credentialDefinition: JsonCredentialDefinition,
    @SerialName(CredentialEndpoint.VerifiableCredentialRequest.Keys.FORMAT)
    @Required
    override val format: String = LdpVcFormat.FORMAT,
) : LdpVcFormat.VerifiableCredentialRequest, SerializableVerifiableCredentialRequest
