/*
 * Copyright (C) 2024 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

package io.curity.vp.services

import io.curity.ssi.sdjwt.SdJwt
import io.curity.ssi.sdjwt.vc.VcSdJwtConstants
import io.curity.vc.serialization.DefaultValidatedVcSdJwtCredentialResponse
import io.curity.vc.serialization.DidValidatedJwtVcJsonCredentialResponse
import io.curity.vc.serialization.JsonIssuer
import io.curity.vc.serialization.UserVerifiableCredential
import io.curity.vp.serialization.JsonArrayContainsFilter
import io.curity.vp.serialization.JsonField
import io.curity.vp.serialization.JsonStringFilter
import kotlinx.serialization.json.jsonPrimitive

/**
 * A credential matcher defines predicates on credentials, useful for matching credentials against presentation
 * requests.
 */
sealed interface CredentialMatcher {

    fun matches(credential: UserVerifiableCredential): Boolean

    fun matches(credential: SdJwt): Boolean = false

    companion object {
        /**
         * Tries to create a matcher from a given Presentation Exchange [JsonField].
         * Only supports a small subset of well known matching criteria.
         */
        fun buildFrom(field: JsonField): CredentialMatcher? = when (val fieldFilter = field.filter) {
            is JsonArrayContainsFilter ->
                if (field.path.any { it == "$.vc.type" }) {
                    JwtVcJsonType(fieldFilter.contains.pattern)
                } else {
                    null
                }

            is JsonStringFilter ->
                if (field.path.any { it == "$.vc.issuer" }) {
                    JwtVcJsonIssuer(fieldFilter.pattern)
                } else if (field.path.any { it == "$.iss" }) {
                    VcSdJwtIssuer(fieldFilter.pattern)
                } else if (field.path.any { it == "$.vct" }) {
                    VcSdJwtType(fieldFilter.pattern)
                } else {
                    null
                }

            null -> null
        }
    }

    // jwt_vc_json
    class JwtVcJsonIssuer(
        private val issuerPattern: String
    ) : CredentialMatcher {
        override fun matches(credential: UserVerifiableCredential) =
            when (val credentialResponse = credential.credentialResponse) {
                is DidValidatedJwtVcJsonCredentialResponse ->
                    when (val issuer = credentialResponse.w3cCredential.jsonIssuer) {
                        is JsonIssuer.IssuerURI -> Regex(issuerPattern).matches(issuer.value)
                        is JsonIssuer.IssuerObject -> Regex(issuerPattern).matches(issuer.id)
                        else -> false
                    }

                else -> false
            }
    }

    class JwtVcJsonType(
        typePattern: String
    ) : CredentialMatcher {
        private val typePatternRegex = Regex(typePattern)
        override fun matches(credential: UserVerifiableCredential) =
            when (val credentialResponse = credential.credentialResponse) {
                is DidValidatedJwtVcJsonCredentialResponse -> credentialResponse.w3cCredential.type
                    .any {
                        typePatternRegex.matches(it)
                    }

                else -> false
            }
    }

    // vc+sd-jwt
    class VcSdJwtIssuer(
        private val issuerPattern: String
    ) : CredentialMatcher {

        private val issuerPatternRegex = Regex(issuerPattern)

        override fun matches(credential: UserVerifiableCredential) =
            when (val credentialResponse = credential.credentialResponse) {
                is DefaultValidatedVcSdJwtCredentialResponse ->
                    issuerPatternRegex.matches(credentialResponse.issuer)

                else -> false
            }

        override fun matches(credential: SdJwt): Boolean {
            val credentialIssuer = credential.jwt.payload.iss
            return credentialIssuer != null && issuerPatternRegex.matches(credentialIssuer)
        }
    }

    class VcSdJwtType(
        private val typePattern: String
    ) : CredentialMatcher {

        private val typePatternRegex = Regex(typePattern)
        override fun matches(credential: UserVerifiableCredential) =
            when (val credentialResponse = credential.credentialResponse) {
                is DefaultValidatedVcSdJwtCredentialResponse ->
                    typePatternRegex.matches(credentialResponse.vct)

                else -> false
            }

        override fun matches(credential: SdJwt): Boolean {
            val credentialVct = credential.jwt.payload[VcSdJwtConstants.CLAIM_VCT]?.jsonPrimitive?.content
            return credentialVct != null && typePatternRegex.matches(credentialVct)
        }
    }
}