/*
 * Copyright (C) 2024 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

package io.curity.vp.services

import kotlinx.serialization.Serializable

@Serializable
class InMemoryRequestJwtStore : RequestJwtStore {

    private val memory = mutableMapOf<String, ValidatedRequestJwt>()

    override fun insert(id: String, requestJwt: ValidatedRequestJwt) {
        memory[id] = requestJwt
    }

    override fun delete(id: String) {
        memory.remove(id)
    }

    override fun getById(id: String): ValidatedRequestJwt? = memory[id]

    override fun getAll(): List<Pair<String, ValidatedRequestJwt>> = memory.entries.map {
        Pair(it.key, it.value)
    }
}