/*
 * Copyright (C) 2023 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

package io.curity.vc

import io.curity.ssi.data.Dictionary

/**
 * OpenID Connect Verifiable Credential Issuance Specification's Credential Endpoint.
 *
 * See [openid-4-verifiable-credential-issuance-1_0#name-credential-endpoint](https://openid.bitbucket.io/connect/openid-4-verifiable-credential-issuance-1_0.html#name-credential-endpoint).
 */
object CredentialEndpoint {

    /**
     * Data representation of a Credential Request.
     *
     * See [openid-4-verifiable-credential-issuance-1_0.html#section-8.2](https://openid.net/specs/openid-4-verifiable-credential-issuance-1_0.html#section-8.2).
     */
    interface VerifiableCredentialRequest {
        object Keys {
            const val FORMAT = "format"
            const val PROOF = "proof"
        }

        /**
         * Format of the Credential to be issued.
         *
         * See [format_profiles](https://openid.net/specs/openid-4-verifiable-credential-issuance-1_0.html#format_profiles).
         */
        val format: String

        /**
         * Object containing proof of possession of the key material the issued Credential shall be bound to.
         *
         * See [proof_types](https://openid.net/specs/openid-4-verifiable-credential-issuance-1_0.html#proof_types).
         */
        val proof: ProofOfPossession<*>?
    }

    /**
     * Response to a [VerifiableCredentialRequest].
     *
     * Defined in [openid-4-verifiable-credential-issuance-1_0.html#section-8.3](https://openid.net/specs/openid-4-verifiable-credential-issuance-1_0.html#section-8.3).
     */
    sealed interface VerifiableCredentialEndpointResponse

    /**
     * Error codes used in [VerifiableCredentialError].
     *
     * Defined in [rfc6750#section-3.1](https://www.rfc-editor.org/rfc/rfc6750#section-3.1) and extended
     * in [openid-4-verifiable-credential-issuance-1_0#section-8.3.1](https://openid.net/specs/openid-4-verifiable-credential-issuance-1_0.html#section-8.3.1)
     */
    enum class ErrorCode {
        /**
         * Credential Request was malformed. One or more of the parameters (i.e. format, proof) are missing or malformatted.
         */
        invalid_request,

        /**
         * Credential Request contains the wrong Access Token or the Access Token is missing.
         */
        invalid_token,

        /**
         * The request requires higher privileges than provided by the access token.
         */
        insufficient_scope,

        /**
         * The requested credential type is not supported.
         */
        unsupported_credential_type,

        /**
         * The requested credential format is not supported.
         */
        unsupported_credential_format,

        /**
         * Credential Request did not contain a proof, or proof was invalid, i.e. it was not bound to a
         * Credential Issuer provided nonce.
         */
        invalid_or_missing_proof,
    }

    /**
     * When the Credential Request is invalid or unauthorized,
     * the Credential Issuer constructs the error response with this type.
     *
     * Defined in [openid-4-verifiable-credential-issuance-1_0#name-credential-error-response](https://openid.net/specs/openid-4-verifiable-credential-issuance-1_0.html#name-credential-error-response).
     */
    interface VerifiableCredentialError : VerifiableCredentialEndpointResponse {

        object Keys {
            const val ERROR = "error"
            const val ERROR_DESCRIPTION = "error_description"
            const val C_NONCE = "c_nonce"
            const val C_NONCE_EXPIRES_IN = "c_nonce_expires_in"
        }

        /**
         * Error code as defined in [rfc6750#section-3.1](https://www.rfc-editor.org/rfc/rfc6750#section-3.1)
         * and extended in [openid-4-verifiable-credential-issuance-1_0.html#section-8.3.1](https://openid.bitbucket.io/connect/openid-4-verifiable-credential-issuance-1_0.html#section-8.3.1).
         */
        val error: ErrorCode

        /**
         * Human-readable ASCII text providing additional information,
         * used to assist the client developer in understanding the error that occurred.
         *
         * Refer to [error_description](https://www.rfc-editor.org/rfc/rfc6749#appendix-A.8).
         */
        val errorDescription: String?

        /**
         * Upon receiving a Credential Request, the Credential Issuer MAY require the client to send a proof of
         * possession of the key material it wants a Credential to be bound to.
         *
         * This proof MUST incorporate a nonce generated by the Credential Issuer.
         * The Credential Issuer will provide the client with a nonce in an error response to any Credential Request
         * not including such a proof or including an invalid proof.
         *
         * See [Section 8.3.2](https://openid.bitbucket.io/connect/openid-4-verifiable-credential-issuance-1_0.html#section-8.3.2).
         */
        val cNonce: String?

        /**
         * The lifetime in seconds of the [cNonce].
         */
        val cNonceExpiresIn: String?
    }

    /**
     * Issued credential or token subsequently used to obtain a credential.
     */
    sealed interface VerifiableCredential {
        /**
         * The credential represented as a String value.
         *
         * This type of value requires the caller to decode the String into something meaningful.
         * For example, in the `jwt_vc_json` format, this String is a JWT.
         */
        data class StringCredential(
            val value: String
        ) : VerifiableCredential

        /**
         * The credential represented as a [Dictionary] of claims.
         *
         * The format of the credential should allow verifiers to make sense of what each claim means, usually
         * via use of common schemas understood by all parties.
         */
        data class DictionaryCredential<T : Dictionary<out Any>>(val value: T) : VerifiableCredential
    }

    /**
     * Verifiable Credential Response.
     *
     * Defined in [Section 8.3](https://openid.bitbucket.io/connect/openid-4-verifiable-credential-issuance-1_0.html#name-credential-response).
     */
    interface VerifiableCredentialResponse : VerifiableCredentialEndpointResponse {

        object Keys {
            const val C_NONCE = "c_nonce"
            const val C_NONCE_EXPIRES_IN = "c_nonce_expires_in"
        }

        /**
         * A nonce to be used to create a proof of possession of key material when requesting a Credential (see Section 8.2).
         */
        val cNonce: String?

        /**
         * An integer denoting the lifetime in seconds of the [cNonce].
         */
        val cNonceExpiresIn: Long?
    }

    /**
     * Response used when a server is able to immediately issue a requested credential.
     *
     * Defined in [openid-4-verifiable-credential-issuance-1_0.html#section-8.3](https://openid.net/specs/openid-4-verifiable-credential-issuance-1_0.html#section-8.3).
     */
    sealed interface SynchronousVerifiableCredentialResponse : VerifiableCredentialResponse {
        object Keys {
            const val CREDENTIAL = "credential"
        }

        /**
         * Contains issued Credential.
         *
         * MAY be a JSON string or a JSON object, depending on the Credential format.
         */
        val credential: VerifiableCredential
    }

    /**
     * Response used when a server is not able to immediately issue a requested credential and would want to send
     * a token to the client to be used later to receive a credential when it is ready.
     *
     * Defined in [openid-4-verifiable-credential-issuance-1_0.html#section-8.3](https://openid.net/specs/openid-4-verifiable-credential-issuance-1_0.html#section-8.3).
     */
    interface DeferredVerifiableCredentialResponse : VerifiableCredentialResponse {
        object Keys {
            const val TRANSACTION_ID = "transaction_id"
        }

        /**
         * A value containing a transaction ID subsequently used to obtain a Credential.
         */
        val transactionId: String
    }

}
