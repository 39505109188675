/*
 * Copyright (C) 2023 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

interface HomeIllustrationProps {
  width?: string;
  height?: string;
}

export const HomeIllustration: React.FC<HomeIllustrationProps> = ({ width = '100%', height = '100%' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 231.32 219.38" width={width} height={height}>
    <path
      fill="#99a2b5"
      d="M229.52 145.97h-64.05c-10.58 0-19.15-8.57-19.15-19.15v-.39c0-10.58 8.57-19.15 19.15-19.15h64.05v38.68Z"
    />
    <circle cx={165.51} cy={126.63} r={7.8} fill="#fff" />
    <g fill="none" stroke="#626c87" strokeLinecap="round" strokeLinejoin="round">
      <g strokeWidth={11.38}>
        <path d="M65.77 139.04c-18.61 0-33.7-15.09-33.7-33.7 0-18.61 15.09-33.7 33.7-33.7 18.61 0 33.7 15.09 33.7 33.7 0 18.61-15.09 33.7-33.7 33.7M5.69 213.69c0-29.88 26.9-54.11 60.08-54.11s60.08 24.23 60.08 54.11" />
      </g>
      <g strokeWidth={11.38}>
        <path d="M37.09 35.9V15.12c0-5.21 4.22-9.43 9.43-9.43h141.4V43.4h28.28c5.21 0 9.43 4.22 9.43 9.43v113.13c0 5.21-4.22 9.43-9.43 9.43h-66.33M187.92 43.4H83.59" />
      </g>
      <path strokeWidth={12.83} d="M188.48 81.59h-67.01" />
    </g>
  </svg>
);
