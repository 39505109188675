.layout {
  position: relative;
  background-color: white;
  width: 100vw;
  height: 100dvh;
}

@media screen and (width >= 600px) {
  .layout {
    border-radius: 16px;
    max-width: 375px;
    height: 836px;
    min-height: 400px;
    max-height: 694px;
  }
}
