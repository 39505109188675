/*
 * Copyright (C) 2023 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import { Link, useLoaderData } from 'react-router-dom';
import { credentialIssuerStore, JsCredentialIssuer, JsCredentialIssuerSupportedCredential } from '../ssi-libs';
import { useCallback, useReducer } from 'react';
import { routes } from '../routes';
import { CredentialIssuer } from '../components/CredentialIssuer';
import { SuccessCheckmark } from '../components/SuccessCheckmark';
import { Alert } from '../components/Alert';

type State =
  | { state: 'loaded' }
  | { state: 'removal-confirmation' }
  | { state: 'removing' }
  | { state: 'error'; message: string }
  | { state: 'removed' };

type Action =
  | { type: 'remove' }
  | { type: 'removing' }
  | { type: 'cancel-remove' }
  | { type: 'removed' }
  | { type: 'error'; message: string };

function reducer(state: State, action: Action): State {
  switch (state.state) {
    case 'loaded':
      if (action.type == 'remove') {
        return { state: 'removal-confirmation' };
      }
      break;
    case 'removal-confirmation':
      if (action.type == 'removing') {
        return { state: 'removing' };
      }
      if (action.type == 'cancel-remove') {
        return { state: 'loaded' };
      }
      break;
    case 'removing':
      if (action.type == 'removed') {
        return { state: 'removed' };
      }
      if (action.type == 'error') {
        return { state: 'error', message: action.message };
      }
      break;
    case 'removed':
      break;
    case 'error':
      break;
  }
}

export function IssuerDetail() {
  const issuer = useLoaderData() as JsCredentialIssuer | null;
  const [state, dispatch] = useReducer(reducer, { state: 'loaded' });

  const handleRemove = useCallback(() => {
    dispatch({ type: 'remove' });
  }, [dispatch]);

  const handleRemovalConfirmation = useCallback(async () => {
    dispatch({ type: 'removing' });
    try {
      await credentialIssuerStore.removeById(issuer.id);
      dispatch({ type: 'removed' });
    } catch (ex) {
      dispatch({ type: 'error', message: ex.message });
    }
  }, [dispatch, issuer?.id]);

  const handleRemovalCancellation = useCallback(async () => {
    dispatch({ type: 'cancel-remove' });
  }, [dispatch]);

  if (issuer == null) {
    return <p>No issuer found with that identifier</p>;
  }

  switch (state.state) {
    case 'loaded':
      return (
        <>
          <CredentialIssuer issuer={issuer} SupportedCredentialAction={LinkToCredentialIssue} />
          <button className="button button-medium button-fullwidth button-danger" onClick={handleRemove}>
            Remove issuer
          </button>
        </>
      );
    case 'removal-confirmation':
      return (
        <>
          <h2>Are you sure you want to remove this credential issuer?</h2>
          <div className="flex flex-gap flex-column">
            <button className="button button-medium button-fullwidth button-danger" onClick={handleRemovalConfirmation}>
              Remove
            </button>
            <button
              className="button button-medium button-fullwidth button-transparent"
              onClick={handleRemovalCancellation}
            >
              Cancel
            </button>
          </div>
        </>
      );
    case 'removing':
      return (
        <>
          <p>Removing...</p>
        </>
      );
    case 'removed':
      return (
        <>
          <div className="h100 flex flex-column flex-center justify-center">
            <div className="center">
              <SuccessCheckmark />

              <h1 className="h3 mt0">Success</h1>
              <p>Credential Issuer was successfully removed</p>
            </div>

            <Link to={routes.ISSUER_LIST} className="button button-medium button-primary button-fullwidth">
              View Credential Issuers
            </Link>
          </div>
        </>
      );
    case 'error':
      return (
        <Alert
          kind="danger"
          title="There was an error removing the issuer"
          errorMessage={state.message}
          link={routes.ISSUER_LIST}
          linkText="View Issuers"
        />
      );
  }
}

type LinkToCredentialIssueProps = {
  issuer: JsCredentialIssuer;
  id: string,
  credentialSupported: JsCredentialIssuerSupportedCredential;
};

function LinkToCredentialIssue({ issuer, id}: LinkToCredentialIssueProps) {
  return (
    <Link
      className="button button-small button-white-outline"
      to={routes.TO_CREDENTIAL_ISSUANCE(issuer.id, id)}
    >
      Get Credential
    </Link>
  );
}
