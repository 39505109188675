/*
 * Copyright (C) 2023 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

package io.curity.ssi.did.document

import io.curity.ssi.PublicApi
import io.curity.ssi.data.DefaultToString
import io.curity.ssi.data.ToString
import io.curity.ssi.did.Did
import kotlinx.coroutines.flow.asFlow
import kotlinx.coroutines.flow.firstOrNull
import kotlinx.coroutines.flow.mapNotNull

/**
 * Provides a single [DidResolver] interface on top of a list of [DidResolver].
 * Can have more than one resolver per DID method. It will use the first and fallback to the other ones if there is
 * an error doing the resolution.
 *
 * This composite resolver allows for:
 *  - Aggregate multiple resolvers of different types, so that the consuming code only sees a single resolver.
 *  - Aggregate multiple resolvers of the same type, to allow for fallback when one fails.
 */
@PublicApi
class CompositeDidResolver<T>(
    private val didResolvers: List<DidResolver<T>>
) : DidResolver<T> {
    private val map: Map<String, MutableList<DidResolver<T>>> = buildMap {
        didResolvers.forEach { resolver ->
            resolver.supportedDidMethods.forEach { method ->
                getOrPut(method.toString()) { mutableListOf() }.add(resolver)
            }
        }
    }

    private val supportedMethods: Set<ToString> = didResolvers.flatMap { it.supportedDidMethods }.toSet()

    override suspend fun resolve(did: Did): DidResolutionSuccessResult<T> {
        val resolverList: List<DidResolver<T>> = map[did.method]
            ?: throw DidResolutionException("DID method is not supported")

        val resolutionResult = resolverList.asFlow()
            .mapNotNull { resolver ->
                val document = try {
                    resolver.resolve(did)
                } catch (ex: DidResolutionException) {
                    // try next resolver
                    null
                }
                document
            }
            .firstOrNull() ?: throw DidResolutionException("Could not resolve DID")
        return resolutionResult
    }

    override val supportedDidMethods = supportedMethods
}
