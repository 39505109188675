/*
 * Copyright (C) 2024 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

package io.curity.vc.services


class InMemoryKeyAliasStore : KeyAliasStore {

    private val kidByAlias = mutableMapOf<String, String>()

    override suspend fun add(alias: String, kid: String) {
        kidByAlias[alias] = kid
    }

    override suspend fun get(alias: String): String? = kidByAlias[alias]
}