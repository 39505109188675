/*
 * Copyright (C) 2023 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

package io.curity.vc.serialization

import io.curity.vc.CredentialEndpoint
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

interface ToVerifiableCredentialResponse {
    fun toVerifiableCredentialResponse(): CredentialEndpoint.VerifiableCredentialResponse
}

@Serializable
data class JsonDeferredCredentialResponse(
    @SerialName(CredentialEndpoint.DeferredVerifiableCredentialResponse.Keys.TRANSACTION_ID)
    override val transactionId: String,
    @SerialName(CredentialEndpoint.VerifiableCredentialResponse.Keys.C_NONCE)
    override val cNonce: String? = null,
    @SerialName(CredentialEndpoint.VerifiableCredentialResponse.Keys.C_NONCE_EXPIRES_IN)
    override val cNonceExpiresIn: Long? = null
) : CredentialEndpoint.DeferredVerifiableCredentialResponse

/**
 * Sealed type hierarchy reflecting [CredentialEndpoint.SynchronousVerifiableCredentialResponse],
 * but allowing only serializable implementations.
 * This type is NOT serializable itself, because since VCI ID1 the credential response does not
 * contain a format, so it is not possible to have a generic deserializer. Instead, the code doing
 * the request will need to deserialize into the proper derived type, based on what was requested.
 */
sealed interface SerializableCredentialResponse {
    val credential: CredentialEndpoint.VerifiableCredential

    /**
     * [CredentialEndpoint.SynchronousVerifiableCredentialResponse] is a sealed hierarchy,
     * therefore this type [SerializableCredentialResponse] cannot subtype that. For this
     * reason, we demand implementations of this type to be convertable to that to try to
     * ensure that that type hierarchy is reflected by the implementations of this type.
     */
    fun toCredentialResponse(): CredentialEndpoint.SynchronousVerifiableCredentialResponse
}