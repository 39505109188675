/*
 * Copyright (C) 2023 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

package io.curity.oauth

import io.ktor.client.HttpClient
import io.ktor.client.call.body
import io.ktor.client.engine.HttpClientEngine
import io.ktor.client.plugins.contentnegotiation.ContentNegotiation
import io.ktor.client.request.forms.submitForm
import io.ktor.http.Parameters
import io.ktor.http.parameters
import io.ktor.serialization.kotlinx.json.json

/**
 * Service that knows how to interact with an OAuth 2.0 Authorization Server
 */
class OAuthCodeFlowClient(
    engine: HttpClientEngine,
    private val oAuthClient: OAuthClient,
    private val tokenEndpoint: String,
) {
    private val _httpClient = HttpClient(engine) {
        install(ContentNegotiation) { json() }
    }

    private fun createParameters(code: String, redirectUri: String): Parameters {
        return parameters {
            append("grant_type", "authorization_code")
            append("code", code)
            append("redirect_uri", redirectUri)
            append("client_id", oAuthClient.id)
            append("client_secret", oAuthClient.secret)
        }
    }

    /**
     * Performs a code-based token request
     */
    suspend fun requestToken(code: String): JsonOAuthSuccessTokenResponse {
        val httpTokenResponse = _httpClient.submitForm(
            tokenEndpoint,
            createParameters(code, oAuthClient.redirectUri)
        )
        if (httpTokenResponse.status.value != 200) {
            throw Exception("Unexpected status code for token request")
        }

        return httpTokenResponse.body()
    }
}
