/*
 * Copyright (C) 2024 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

package io.curity.ssi.jose

import io.curity.ssi.json.data.FromJson
import io.curity.ssi.json.data.FromJsonHelper
import io.curity.ssi.json.data.JsonDictionary
import io.curity.ssi.json.data.ToJsonDictionary
import io.curity.ssi.json.data.toJson
import io.curity.ssi.json.data.unwrapOptionalString
import kotlinx.serialization.Serializable
import kotlinx.serialization.json.JsonElement

/**
 * Serialization dependent representation for a JWK
 */
@Serializable(with = JsonJwkSerializer::class)
data class JsonJwk(
    override val kid: String? = null,
    private val customClaims: Map<String, JsonElement> = emptyMap()
) : Jwk<JsonElement>, ToJsonDictionary {

    override fun toJsonDictionary() = JsonDictionary(buildMap {
        putAll(customClaims)
        kid?.let { put("kid", it.toJson()) }
    })

    /**
     * Checks if the current JWK represents the same *key material* as the [other] JWK,
     * allowing for some parameters to be absent (but not different) between the two compared JWKS (see [KEYS_TO_IGNORE]).
     * This is required because the JWK in a `cnf` claim may not have the exact same parameters as the JWK resulting
     * from serializing a key.
     */
    fun matches(other: JsonJwk): Boolean {
        val allKeys = this.customClaims.keys.union(other.customClaims.keys)
        return allKeys.all { key ->
            val thisValue = this.customClaims[key]
            val otherValue = other.customClaims[key]
            if ((thisValue == null || otherValue == null) && KEYS_TO_IGNORE.contains(key)) {
                // ignore mismatch
                true
            } else {
                thisValue == otherValue
            }
        }
    }

    companion object : FromJson<JsonJwk> {
        override fun fromJson(json: JsonElement): JsonJwk {
            val map = FromJsonHelper.requireJsonObject(json, "Jwk").toMutableMap()
            return JsonJwk(
                kid = map.unwrapOptionalString("kid"),
                customClaims = map.toMap(),
            )
        }

        private val KEYS_TO_IGNORE = setOf("use", "alg")
    }
}