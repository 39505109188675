/*
 * Copyright (C) 2023 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

// Because we want these symbols to be in the root package, for better usage from JS
@file:Suppress("PackageDirectoryMismatch")

import io.curity.vp.services.InMemoryRequestJwtStore
import io.curity.vp.services.RequestJwtStore
import io.curity.vp.services.ValidatedRequestJwt
import kotlinx.browser.localStorage
import kotlinx.datetime.Clock
import kotlinx.serialization.SerializationException
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json

class LocalStorageRequestJwtStore internal constructor(
    private val delegate: InMemoryRequestJwtStore = loadFromLocalStorage(),
    private val cleanupPeriodInSeconds: Int
) : RequestJwtStore by delegate {

    companion object {
        private const val KEY_LOCAL_STORAGE = "request-jwt-store"
        private const val KEY_LAST_CLEANUP = "request-jwt-store-last-cleanup"

        private fun loadFromLocalStorage(): InMemoryRequestJwtStore {
            return localStorage.getItem(KEY_LOCAL_STORAGE)?.let {
                try {
                    Json.decodeFromString(it)
                } catch (e: SerializationException) {
                    // in case we've changed the serialized class in an uncompatible way
                    // just drop the store a start with an empty one
                    InMemoryRequestJwtStore()
                }
            } ?: InMemoryRequestJwtStore()
        }
    }

    fun cleanupIfAfterPeriod() {
        val nowEpochSeconds = Clock.System.now().epochSeconds
        val lastCleanup = localStorage.getItem(KEY_LAST_CLEANUP)
        if (lastCleanup == null || lastCleanup.toLong() + cleanupPeriodInSeconds < nowEpochSeconds) {
            getAll().forEach {
                val id = it.first
                val requestJwt = it.second
                if (requestJwt.expAt < Clock.System.now().epochSeconds) {
                    // using delegate for the delete operation to avoid saving
                    // into local storage after each delete
                    delegate.delete(id)
                }
            }
            saveIntoLocalStorage()
            localStorage.setItem(KEY_LAST_CLEANUP, nowEpochSeconds.toString())
        }
    }

    override fun insert(id: String, requestJwt: ValidatedRequestJwt) {
        delegate.insert(id, requestJwt)
        saveIntoLocalStorage()
    }

    override fun delete(id: String) {
        delegate.delete(id)
        saveIntoLocalStorage()
    }

    private fun saveIntoLocalStorage() {
        localStorage.setItem(KEY_LOCAL_STORAGE, Json.encodeToString(delegate))
    }
}
