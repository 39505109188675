/*
 * Copyright (C) 2023 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

// Because we need to use GlobalScope.promise
@file:OptIn(DelicateCoroutinesApi::class, ExperimentalJsExport::class)

// Because we want these symbols to be in the root package, for better usage from JS
@file:Suppress("PackageDirectoryMismatch")

import io.curity.ssi.crypto.SigningKeyPair
import io.curity.ssi.jose.NativeSigningKey
import io.curity.ssi.jose.NativeVerificationKey
import io.curity.vc.serialization.ResolvedOffer
import io.curity.vc.services.CredentialIssuanceCoordinator
import io.curity.vc.services.CredentialIssuerLoader
import io.curity.vc.services.CredentialIssuerStore
import io.curity.vc.services.OfferResult
import io.curity.vc.services.VerifiableCredentialStore
import io.ktor.utils.io.CancellationException
import kotlinx.coroutines.DelicateCoroutinesApi
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.cancel
import kotlinx.coroutines.coroutineScope
import kotlinx.coroutines.promise
import web.abort.AbortSignal
import kotlin.js.Promise

/**
 * Set of classes providing adapters that:
 * - Implement @JsExport service interfaces
 * - by using delegated/inner non-@JsExport types from commonMain
 */

@JsExport
class JsCredentialIssuerLoader internal constructor(
    val inner: CredentialIssuerLoader
) {
    /**
     * Loads information about an issuer given the issuer's id.
     *
     * @param issuerId the credential issuer identifier.
     * @return A promise that fulfills to a [JsCredentialIssuer].
     */
    fun load(issuerId: String): Promise<JsCredentialIssuer> = GlobalScope.promise {
        createJsCredentialIssuer(inner.load(issuerId))
    }
}

@JsExport
class JsCredentialIssuerStore internal constructor(
    private val store: CredentialIssuerStore
) {

    /**
     * Inserts or updates a credential issuer into the store.
     *
     * @param credentialIssuer the credential issuer.
     * @return a promise that fulfills to `true`.
     */
    fun upsert(credentialIssuer: JsCredentialIssuer): Promise<Boolean> = GlobalScope.promise {
        val adapter = credentialIssuer as? JsCredentialIssuer ?: TODO("")
        store.upsert(adapter.credentialIssuer)
        true
    }


    /**
     * Gets all stored issuers.
     *
     * @return a promise that fulfills to an array of verifiable credential issuers.
     */
    fun getAll(): Promise<Array<JsCredentialIssuer>> = GlobalScope.promise {
        store.getAll().map {
            createJsCredentialIssuer(it)
        }.toTypedArray()
    }

    /**
     * Gets a stored issuer by its identifier.
     *
     * @return a promise that fulfills to an optional issuer.
     */
    fun getById(id: String): Promise<JsCredentialIssuer?> = GlobalScope.promise {
        store.getById(id)?.let {
            createJsCredentialIssuer(it)
        }
    }

    /**
     * Removes a stored issuer by its identifier.
     */
    fun removeById(id: String): Promise<Boolean> = GlobalScope.promise {
        store.removeById(id)
        true
    }
}

/**
 * Stores verifiable credentials.
 */
@OptIn(ExperimentalJsExport::class)
@JsExport
class JsVerifiableCredentialStore internal constructor(
    val inner: VerifiableCredentialStore,
) {
    /**
     * Inserts a verifiable credential.
     *
     * @param credential the credential to insert.
     */
    fun insert(credential: JsUserVerifiableCredential) {
        inner.insert(credential.inner())
    }

    /**
     * Deletes a verifiable credential given its identifier.
     *
     * @param credentialId the credential identifier.
     */
    fun delete(credentialId: String) {
        inner.delete(credentialId)
    }

    /**
     * Gets a credential given its identifier.
     *
     * @param credentialId the credential identifier.
     *
     * @return an optional [JsUserVerifiableCredential].
     */
    fun getById(credentialId: String): JsUserVerifiableCredential? = inner.getById(credentialId)?.let {
        createJsUserVerifiableCredential(it)
    }


    /**
     * Gets credentials given their user defined name prefix.
     *
     * @param namePrefix the credential identifier.
     *
     * @return an array of [JsUserVerifiableCredential], containing the given prefix on their user defined name.
     */
    fun getByName(namePrefix: String): Array<JsUserVerifiableCredential> =
        inner.getByName(namePrefix).map {
            createJsUserVerifiableCredential(it)
        }.toTypedArray()

    /**
     * Gets credentials given their issuer identifier.
     *
     * @param issuerId the issuer identifier.
     *
     * @return an array of [JsUserVerifiableCredential], issued by the given issuer.
     */
    fun getByIssuer(issuerId: String): Array<JsUserVerifiableCredential> =
        inner.getByIssuer(issuerId).map {
            createJsUserVerifiableCredential(it)
        }.toTypedArray()

    /**
     * Gets all stored credentials.
     *
     * @return an array of [JsUserVerifiableCredential].
     */
    fun getAll(): Array<JsUserVerifiableCredential> =
        inner.getAll().map {
            createJsUserVerifiableCredential(it)
        }.toTypedArray()
}

@JsExport
class JsResolvedOffer internal constructor(
    internal val inner: ResolvedOffer,
) {
    val issuer by lazy { createJsCredentialIssuer(inner.issuer) }
    val credentialsSupported by lazy {
        inner.credentialsSupported
            .map { credentialSupported -> createJsCredentialIssuerSupportedCredential(credentialSupported) }
            .toTypedArray()
    }
    val txCode by lazy {
        inner.txCode?.let { txCode ->
            JsTxCode(
                txCode.inputMode,
                txCode.length,
                txCode.description
            )
        }
    }
}

@JsExport
class JsTxCode(
    val inputMode: String?,
    val length: Int?,
    val description: String?,
)

@JsExport
class JsOfferResult internal constructor(
    private val inner: OfferResult
) {
    val issuedCredentials: Array<JsUserVerifiableCredential>? by lazy {
        (inner as? OfferResult.IssuedCredentials)?.credentials?.map {
            createJsUserVerifiableCredential(it)
        }?.toTypedArray()
    }

    val invalidOfferOrUserCode: Boolean = inner is OfferResult.InvalidOfferOrUserCode
}


@JsExport
class JsCredentialIssuanceCoordinator internal constructor(
    private val inner: CredentialIssuanceCoordinator
) {

    fun startHandleOffer(
        offerString: String,
        abortSignal: AbortSignal,
    ): Promise<JsResolvedOffer> = GlobalScope.safePromise {
        coroutineScope {
            abortSignal.onabort = {
                cancel()
            }
            val resolvedOffer = inner.startHandleOffer(offerString)
            JsResolvedOffer(resolvedOffer)
        }
    }

    fun continueHandleOffer(
        resolvedOffer: JsResolvedOffer,
        credentialsSupported: Array<JsCredentialIssuerSupportedCredential>,
        userCode: String?,
        signingKeyPair: JsSigningKeyPair,
        abortSignal: AbortSignal,
    ): Promise<JsOfferResult> = GlobalScope.safePromise {
        coroutineScope {
            abortSignal.onabort = {
                cancel()
            }
            JsOfferResult(
                inner.continueHandleOffer(
                    resolvedOffer.inner,
                    credentialsSupported.map { it.inner() }.toList(),
                    userCode,
                    SigningKeyPair(
                        signingKeyPair.signingKey as NativeSigningKey,
                        signingKeyPair.verificationKey as NativeVerificationKey,
                        signingKeyPair.id,
                    )
                )
            )
        }
    }

    fun requestCredentials(
        issuer: JsCredentialIssuer,
        credentialSupported: JsCredentialIssuerSupportedCredential,
        signingKeyPair: JsSigningKeyPair,
        abortSignal: AbortSignal
    ): Promise<Array<JsUserVerifiableCredential>> =
        GlobalScope.safePromise {
            coroutineScope {
                abortSignal.onabort = {
                    val cause = abortSignal.reason as? CancellationException
                    cancel(cause)
                }
                inner.requestCredentials(
                    issuer.credentialIssuer,
                    credentialSupported.inner(),
                    SigningKeyPair(
                        signingKeyPair.signingKey as NativeSigningKey,
                        signingKeyPair.verificationKey as NativeVerificationKey,
                        signingKeyPair.id
                    )
                ).map {
                    createJsUserVerifiableCredential(it)
                }.toTypedArray()
            }
        }
}
