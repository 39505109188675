/*
 * Copyright (C) 2024 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

// Because we want these symbols to be in the root package, for better usage from JS
@file:Suppress("PackageDirectoryMismatch")
@file:OptIn(ExperimentalJsExport::class)

import io.curity.vc.serialization.BasicCredentialSubject
import io.curity.vc.serialization.JsonCredentialDefinition
import io.curity.vc.serialization.JwtProofOfPossession
import io.curity.vc.serialization.JwtVcJsonLdVerifiableCredentialRequest
import io.curity.vc.serialization.JwtVcJsonVerifiableCredentialRequest
import io.curity.vc.serialization.LdpVerifiableCredentialRequest
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json
import vc_js.ToJsonString

@JsExport
class JsJwtVcJsonVerifiableCredentialRequestCredentialDefinition(
    val type: Array<String>,
    val credentialSubjectClaims: Array<String>,
)

@JsExport
class JSJwtVcJsonVerifiableCredentialRequest(
    val jwtProof: String,
    val credentialDefinition: JsJwtVcJsonVerifiableCredentialRequestCredentialDefinition
) : ToJsonString {
    override fun toJson(): String {
        val serializable = convertJwtVcJsonRequest(this)
        return Json.encodeToString(serializable)
    }
}

@JsExport
class JSJwtVcJsonLdVerifiableCredentialRequest(
    val jwtProof: String,
    val types: Array<String>,
    val context: Array<String>,
    val credentialSubjectClaims: Array<String>
) : ToJsonString {
    override fun toJson(): String {
        val serializable = convertJwtVcJsonLdRequest(this)
        return Json.encodeToString(JwtVcJsonLdVerifiableCredentialRequest.serializer(), serializable)
    }
}

@JsExport
class JSLdpVerifiableCredentialRequest(
    val jwtProof: String,
    val context: Array<String>,
    val types: Array<String>,
    val credentialSubjectClaims: Array<String>
) : ToJsonString {
    override fun toJson(): String {
        val serializable = convertLdpVcJsonRequest(this)
        return Json.encodeToString(LdpVerifiableCredentialRequest.serializer(), serializable)
    }
}

/**
 * Convert JS [JSJwtVcJsonVerifiableCredentialRequest] to Kotlin [JwtVcJsonVerifiableCredentialRequest].
 */
fun convertJwtVcJsonRequest(
    request: JSJwtVcJsonVerifiableCredentialRequest
): JwtVcJsonVerifiableCredentialRequest {
    return JwtVcJsonVerifiableCredentialRequest(
        proof = JwtProofOfPossession(request.jwtProof),
        credentialDefinition = convertJsJwtVcJsonVerifiableCredentialRequestCredentialDefinition(
            request.credentialDefinition
        )
    )
}

internal fun convertJsJwtVcJsonVerifiableCredentialRequestCredentialDefinition(
    credentialDefinition: JsJwtVcJsonVerifiableCredentialRequestCredentialDefinition
): JwtVcJsonVerifiableCredentialRequest.CredentialDefinition {
    val claims = credentialDefinition.credentialSubjectClaims.filter { it.isNotBlank() }.toSet()
    return JwtVcJsonVerifiableCredentialRequest.CredentialDefinition(
        type = credentialDefinition.type.filter { it.isNotBlank() }.toSet(),
        credentialSubject = BasicCredentialSubject(claims),
    )
}
/**
 * Convert JS [JSJwtVcJsonLdVerifiableCredentialRequest] to Kotlin [JwtVcJsonLdVerifiableCredentialRequest].
 */
fun convertJwtVcJsonLdRequest(
    request: JSJwtVcJsonLdVerifiableCredentialRequest
): JwtVcJsonLdVerifiableCredentialRequest {
    val types = request.types.filter { it.isNotBlank() }.toSet()
    val context = request.context.filter { it.isNotBlank() }.toList()
    val claims = request.credentialSubjectClaims.filter { it.isNotBlank() }.toSet()

    return JwtVcJsonLdVerifiableCredentialRequest(
        proof = JwtProofOfPossession(request.jwtProof),
        credentialDefinition = JsonCredentialDefinition(
            context = context,
            types = types,
            credentialSubject = BasicCredentialSubject(claims)
        )
    )
}

/**
 * Convert JS [JSLdpVerifiableCredentialRequest] to Kotlin [LdpVerifiableCredentialRequest].
 */
fun convertLdpVcJsonRequest(
    request: JSLdpVerifiableCredentialRequest
): LdpVerifiableCredentialRequest {
    val types = request.types.filter { it.isNotBlank() }.toSet()
    val claims = request.credentialSubjectClaims.filter { it.isNotBlank() }.toSet()
    val context = request.context.filter { it.isNotBlank() }.toList()

    return LdpVerifiableCredentialRequest(
        proof = JwtProofOfPossession(request.jwtProof),
        credentialDefinition = JsonCredentialDefinition(
            types = types,
            context = context,
            credentialSubject = BasicCredentialSubject(claims),
        )
    )
}
