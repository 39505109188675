/*
 * Copyright (C) 2023 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import { Cog, Info, Qr, University, Wallet } from '../icons';
import { routes } from '../../routes';
import { Link } from 'react-router-dom';
import * as styles from './footer.module.css';

export const Footer = () => (
  <footer className={`${styles['footer']} flex flex-center justify-center absolute w100 primary z-2`}>
    <Link to="/" className="button button-small button-transparent">
      <Wallet />
    </Link>

    <Link to={routes.ISSUER_LIST} className="button button-small button-transparent">
      <University width="24" height="24" />
    </Link>

    <Link to={routes.ABOUT} className="button button-small button-transparent">
      <Info />
    </Link>

    <Link to={routes.SETTINGS} className="button button-small button-transparent">
      <Cog width="24" height="24" />
    </Link>

    <Link
      to={routes.START}
      className={`button ${styles['footer-button-qrcode']} w-2 h-2 flex flex-center justify-center white`}
    >
      <Qr width="24" height="24" />
    </Link>
  </footer>
);
