.debug-pane {
    --height: 100dvh;
    --header-height: 48px;
    --width: 100%;
    --visibility: none;

    position: fixed;
    z-index: 1;
    display: var(--visibility);
    right: 0;
    top: 0;
    height: var(--height);
    width: var(--width);
    background-color: #111;
    color: white;
    border-top-left-radius: var(--form-field-border-radius);
}

@media screen and (width >=600px) {
    .debug-pane {
        --visibility: block;
        --width: 460px;
    }
}

.debug-pane-minimized {
    overflow: clip;
    bottom: 0;
    top: auto;
    height: var(--header-height);
}

.debug-pane-header {
    height: var(--header-height);
    border-bottom: 1px solid #333;
    padding-left: var(--space-2);
    padding-right: var(--space-2);
    cursor: pointer;
}

.debug-pane pre {
    tab-size: 4;
    text-size-adjust: 100%;
    text-wrap: nowrap;
    white-space-collapse: preserve;
    hyphens: auto;
    overflow: auto auto;
    text-indent: 5em hanging each-line;
    font-feature-settings: normal;
}

.debug-pane-close:hover {
    background-color: transparent;
    color: white
}
