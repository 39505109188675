/*
 * Copyright (C) 2023 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

// Because we want these symbols to be in the root package, for better usage from JS
@file:Suppress("PackageDirectoryMismatch")

package vc_js.services

import io.curity.vc.serialization.UserVerifiableCredential
import io.curity.vc.services.InMemoryVerifiableCredentialStore
import io.curity.vc.services.VerifiableCredentialStore
import kotlinx.browser.localStorage
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json
import vc_js.decodeFromLocalStorageOrNull

class LocalStorageVerifiableCredentialStore internal constructor(
    private val delegate: InMemoryVerifiableCredentialStore = loadFromLocalStorage()
) : VerifiableCredentialStore by delegate {

    companion object {
        private const val KEY_LOCAL_STORAGE = "credential-store"

        private fun loadFromLocalStorage(): InMemoryVerifiableCredentialStore {
            return Json.decodeFromLocalStorageOrNull(KEY_LOCAL_STORAGE) ?: InMemoryVerifiableCredentialStore()
        }
    }

    override fun insert(credential: UserVerifiableCredential) {
        delegate.insert(credential)
        saveIntoLocalStorage()
    }

    override fun delete(localId: String) {
        delegate.delete(localId)
        saveIntoLocalStorage()
    }

    private fun saveIntoLocalStorage() {
        localStorage.setItem(KEY_LOCAL_STORAGE, Json.encodeToString(delegate))
    }

}
