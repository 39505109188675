/*
 * Copyright (C) 2024 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

package io.curity.vc.serialization

import io.curity.ssi.json.data.DerivedSerializer
import io.curity.ssi.json.data.FromJsonHelper
import io.curity.ssi.json.data.JsonSerializer
import io.curity.ssi.json.data.getStringOrDefault
import io.curity.vc.JwtVcJsonFormat
import io.curity.vc.JwtVcJsonLdFormat
import io.curity.vc.LdpVcFormat
import io.curity.vc.Metadata
import io.curity.vc.VcSdJwtFormat
import kotlinx.serialization.DeserializationStrategy
import kotlinx.serialization.json.JsonContentPolymorphicSerializer
import kotlinx.serialization.json.JsonElement

/*
 * Contains serializers for W3C related formats, namely 'jwt_vc_json'
 */

internal object DeferredJwtVcJsonCredentialResponseSerializer :
    DerivedSerializer<JsonDeferredCredentialResponse, JwtVcJsonCredentialResponse.Deferred>(
        JsonDeferredCredentialResponse.serializer(),
        { v -> v.value },
        { v -> JwtVcJsonCredentialResponse.Deferred(v) },
    )

internal object DeferredJwtVcJsonLdCredentialResponseSerializer :
    DerivedSerializer<JsonDeferredCredentialResponse, JwtVcJsonLdCredentialResponse.Deferred>(
        JsonDeferredCredentialResponse.serializer(),
        { v -> v.value },
        { v -> JwtVcJsonLdCredentialResponse.Deferred(v) },
    )

internal object DeferredLdpVcCredentialResponseSerializer :
    DerivedSerializer<JsonDeferredCredentialResponse, LdpVcCredentialResponse.Deferred>(
        JsonDeferredCredentialResponse.serializer(),
        { v -> v.value },
        { v -> LdpVcCredentialResponse.Deferred(v) },
    )

internal object DeferredVcSdJwtCredentialResponseSerializer :
    DerivedSerializer<JsonDeferredCredentialResponse, VcSdJwtCredentialResponse.Deferred>(
        JsonDeferredCredentialResponse.serializer(),
        { v -> v.value },
        { v -> VcSdJwtCredentialResponse.Deferred(v) },
    )

internal object JwtVcJsonCredentialResponseSerializer :
    VerifiableCredentialResponseSerializer<JwtVcJsonCredentialResponse>(
        JwtVcJsonCredentialResponse::class,
        _format = JwtVcJsonFormat.FORMAT,
        deferredSerializer = JwtVcJsonCredentialResponse.Deferred.serializer(),
        synchronousSerializer = JwtVcJsonVerifiableCredentialResponse.serializer()
    )

internal object JwtVcJsonLdCredentialResponseSerializer :
    VerifiableCredentialResponseSerializer<JwtVcJsonLdCredentialResponse>(
        JwtVcJsonLdCredentialResponse::class,
        _format = JwtVcJsonLdFormat.FORMAT,
        deferredSerializer = JwtVcJsonLdCredentialResponse.Deferred.serializer(),
        synchronousSerializer = JwtVcJsonLdVerifiableCredentialResponse.serializer()
    )

internal object LdpVcCredentialResponseSerializer :
    VerifiableCredentialResponseSerializer<LdpVcCredentialResponse>(
        LdpVcCredentialResponse::class,
        _format = LdpVcFormat.FORMAT,
        deferredSerializer = LdpVcCredentialResponse.Deferred.serializer(),
        synchronousSerializer = LdpVcVerifiableCredentialResponse.serializer()
    )

internal object VcSdJwtCredentialResponseSerializer :
    VerifiableCredentialResponseSerializer<VcSdJwtCredentialResponse>(
        VcSdJwtCredentialResponse::class,
        _format = VcSdJwtFormat.FORMAT,
        deferredSerializer = VcSdJwtCredentialResponse.Deferred.serializer(),
        synchronousSerializer = VcSdJwtVerifiableCredentialResponse.serializer()
    )

internal object JwtVcJsonCredentialSubjectMetadataSerializer :
    JsonSerializer<JwtVcJsonCredentialSubjectMetadata>(JwtVcJsonCredentialSubjectMetadata)

internal object JwtVcJsonCredentialSubjectEntryMetadataSerializer :
    JsonSerializer<JwtVcJsonCredentialSubjectEntryMetadata>(JwtVcJsonCredentialSubjectEntryMetadata)

internal object JwtVcJsonAuthorizationDetailsCredentialDefinitionCredentialSubjectSerializer :
    JsonSerializer<JwtVcJsonAuthorizationDetailsCredentialDefinition.JsonCredentialSubject>(
        JwtVcJsonAuthorizationDetailsCredentialDefinition.JsonCredentialSubject
    )

internal object JwtVcJsonAuthorizationDetailsCredentialDefinitionCredentialSubjectItemSerializer :
    JsonSerializer<JwtVcJsonAuthorizationDetailsCredentialDefinition.JsonCredentialSubjectItem>(
        JwtVcJsonAuthorizationDetailsCredentialDefinition.JsonCredentialSubjectItem
    )

internal object JsonSupportedCredentialSerializer :
    JsonContentPolymorphicSerializer<JsonCredentialConfigurationsSupported>(JsonCredentialConfigurationsSupported::class) {

    override fun selectDeserializer(element: JsonElement): DeserializationStrategy<JsonCredentialConfigurationsSupported> {
        val response = FromJsonHelper.requireJsonObject(element, "JsonSupportedCredential")
        val format = response.getStringOrDefault(Metadata.CredentialConfigurationsSupported.Keys.FORMAT)
        return when (format) {
            JwtVcJsonFormat.FORMAT -> JwtVcJsonCredentialConfigurationsSupported.serializer()
            VcSdJwtFormat.FORMAT -> VcSdJwtCredentialConfigurationsSupported.serializer()
            else -> DefaultJsonCredentialConfigurationsSupported.serializer()
        }
    }
}

internal object JwtVcJsonVerifiableCredentialRequestCredentialDefinitionSerializer:
        JsonSerializer<JwtVcJsonVerifiableCredentialRequest.CredentialDefinition>(
            JwtVcJsonVerifiableCredentialRequest.CredentialDefinition)