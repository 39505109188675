/*
 * Copyright (C) 2023 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

// Because we want these symbols to be in the root package, for better usage from JS
@file:Suppress("PackageDirectoryMismatch")

package vc_js.services

import io.curity.vc.serialization.JsonCredentialIssuer
import io.curity.vc.services.CredentialIssuerStore
import kotlinx.browser.localStorage
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json
import vc_js.decodeFromLocalStorageOrNull

class LocalStorageCredentialIssuerStore
    : CredentialIssuerStore {

    private val map: MutableMap<String, JsonCredentialIssuer> = loadFromLocalStorage()

    override suspend fun upsert(credentialIssuer: JsonCredentialIssuer) {
        map[credentialIssuer.issuerMetadata.credentialIssuer] = credentialIssuer
        saveIntoLocalStorage()
    }

    override suspend fun removeById(id: String) {
        map.remove(id)
        saveIntoLocalStorage()
    }

    override suspend fun getAll(): List<JsonCredentialIssuer> =
        map.values.toList()

    override suspend fun getById(id: String): JsonCredentialIssuer? = map[id]

    private fun loadFromLocalStorage(): MutableMap<String, JsonCredentialIssuer> {
        return json.decodeFromLocalStorageOrNull(KEY_LOCAL_STORAGE) ?: mutableMapOf()
    }

    private fun saveIntoLocalStorage() {
        localStorage.setItem(KEY_LOCAL_STORAGE, json.encodeToString(map))
    }

    companion object {
        private const val KEY_LOCAL_STORAGE = "issuer-store"
        private val json = Json {
            ignoreUnknownKeys = true
        }
    }
}