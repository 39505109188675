/*
 * Copyright (C) 2023 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import { JsCredentialIssuer, credentialIssuerStore } from './ssi-libs';

export function loadStoredCredentialIssuers(): Promise<Array<JsCredentialIssuer>> {
  return credentialIssuerStore.getAll();
}

export function loadStoredCredentialIssuerById({ params }): Promise<JsCredentialIssuer | null> {
  const decodedId = decodeURIComponent(params.id);
  return credentialIssuerStore.getById(decodedId);
}
