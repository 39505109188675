/*
 * Copyright (C) 2023 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import {
  createCredentialFinder,
  createCredentialIssuanceCoordinator,
  createCredentialIssuerLoader,
  createCredentialIssuerStore,
  createInMemoryKeyAliasStore,
  createKeyStore,
  createOAuthClient,
  createPreRegisteredClientStore,
  createPresentationResponseCreator,
  createRequestJwtStore,
  createVerifiableCredentialStore,
  createVerifierClient,
  JsCredentialIssuer as CredentialIssuer,
  JsCredentialIssuerSupportedCredential as CredentialIssuerSupportedCredential,
  JsPreRegisteredClient,
  JsProxy,
  JsVpFormat,
} from 'curity-ssi-libs-verifiable-credentials-vc-ks-services';
import {PreLoadedCredentialIssuerStore} from './pre-loaded-credential-issuer-store';

const isElectron = window.location.protocol !== 'http:' && window.location.protocol !== 'https:';
const isBrowser = !isElectron;

// Exported types
export type JsCredentialIssuer = CredentialIssuer;
export type JsCredentialIssuerSupportedCredential = CredentialIssuerSupportedCredential;

// The HTTP proxy to use
const proxy = isBrowser
  ? new JsProxy(
      window.location.protocol.substring(0, window.location.protocol.length - 1),
      window.location.hostname,
      Number(window.location.port)
    )
  : new JsProxy('http', 'localhost', 1234);

// Key store

const JWK_P256 = `
{
  "kty": "EC",
  "d": "d_PpSCGQWWgUc1t4iLLH8bKYlYfc9Zy_M7TsfOAcbg8",
  "use": "sig",
  "crv": "P-256",
  "x": "ngy44T1vxAT6Di4nr-UaM9K3Tlnz9pkoksDokKFkmNc",
  "y": "QCRfOKlSM31GTkb4JHx3nXB4G_jSPMsbdjzlkT_UpPc",
  "alg": "ES256"
}`
const DID_URL =
  'did:jwk:eyJrdHkiOiJFQyIsInVzZSI6InNpZyIsImFsZyI6IkVTMjU2IiwiY3J2IjoiUC0yNTYiLCJ4Ijoibmd5NDRUMXZ4QVQ2RGk0bnItVWFNOUszVGxuejlwa29rc0Rva0tGa21OYyIsInkiOiJRQ1JmT0tsU00zMUdUa2I0Skh4M25YQjRHX2pTUE1zYmRqemxrVF9VcFBjIiwia2lkIjoic2lnbmluZ0tleVBhaXJJZCJ9#0';

const keyStore = createKeyStore()
const signingKeyPairId = "signingKeyPairId"
export const signingKeyPairPromise= keyStore.importSigningKeySpec(signingKeyPairId, JWK_P256)
const keyAliasStore = createInMemoryKeyAliasStore()
keyAliasStore.add(DID_URL, signingKeyPairId)
  .catch(err => console.log(err))

// Exported constant services
export const credentialIssuerLoader = createCredentialIssuerLoader();
export const credentialIssuerStore = new PreLoadedCredentialIssuerStore(
  createCredentialIssuerStore(),
  credentialIssuerLoader,
  // EDIT with the issuers to preload
  ['https://login-demo.curity.io/oauth/v2/oauth-anonymous']
);
export const verifiableCredentialStore = createVerifiableCredentialStore();

export const presentationResponseCreator = createPresentationResponseCreator(
  keyAliasStore,
  keyStore
);

// Wallet configuration dependent services
const localStorageConfigKey = 'wallet-config';

// Defining the wallet configuration in the wallet itself
// In the future the ssi-libs KMP may already provide types for this.
// However, a specific wallet may have config settings that only make sense on that specific wallet
export type WalletOAuthConfig = {
  id: string;
  secret: string;
};
export type WalletPreRegisteredClientConfig = {
  id: string;
  jwksUri: string;
};

export type WalletConfig = {
  oauthClient: WalletOAuthConfig;
  preRegisteredClient: WalletPreRegisteredClientConfig;
};

function computeRedirectUriFromLocation() {
  const redirectUri = new URL(window.location.toString());
  redirectUri.pathname = '/oauth/callback';
  redirectUri.hash = '';
  redirectUri.search = '';
  return redirectUri.toString();
}

export const redirectUri = isBrowser ? computeRedirectUriFromLocation() : 'dev-wallet://app/oauth/callback';

function createCredentialIssuanceCoordinatorFromWalletConfig(walletConfig: WalletConfig) {
  return createCredentialIssuanceCoordinator(
    createOAuthClient(walletConfig.oauthClient.id, redirectUri, walletConfig.oauthClient.secret),
    proxy,
    credentialIssuerLoader
  );
}

export const clientStore = createPreRegisteredClientStore();

const SUPPORTED_ALGS = ['EdDSA', 'ES256', 'ES384', 'ES512', 'RS256', 'RS384', 'RS512', 'PS256', 'PS384', 'PS512'];
const SUPPORTED_VP_FORMATS = [new JsVpFormat('jwt_vp_json', SUPPORTED_ALGS)];
updatePreRegisteredClientStore(getWalletConfig());

export const verifierClient = createVerifierClient(proxy, clientStore);
export const credentialFinder = createCredentialFinder(verifiableCredentialStore);

const requestJwtStoreCleanupPeriodInSeconds = 24 * 60 * 60; // 1 day
export const requestJwtStore = createRequestJwtStore(requestJwtStoreCleanupPeriodInSeconds);
requestJwtStore.cleanupIfAfterPeriod();

export function getWalletConfig() {
  const retrievedConfig = localStorage.getItem(localStorageConfigKey);
  if (retrievedConfig) {
    // TODO use a validation framework
    try {
      return JSON.parse(retrievedConfig) as WalletConfig;
    } catch (e) {
      console.log('Invalid wallet configuration, using a default one');
    }
  }
  // No available config, use default one...
  const defaultConfig: WalletConfig = isBrowser
    ? {
        oauthClient: {
          id: 'client-one',
          // TODO REMOVE REMOVE REMOVE
          secret: '0ne!Secret',
        },
        preRegisteredClient: {
          id: 'openid-wallet-authenticator-client',
          jwksUri: 'https://login-demo.curity.io/authn/anonymous/wallet/jwks',
        },
      }
    : {
        oauthClient: {
          id: 'client-one',
          // TODO REMOVE REMOVE REMOVE
          secret: '0ne!Secret',
        },
        preRegisteredClient: {
          id: 'openid-wallet-authenticator-client',
          jwksUri: 'https://login-demo.curity.io/authn/anonymous/wallet/jwks',
        },
      };
  // ... and save it
  localStorage.setItem(localStorageConfigKey, JSON.stringify(defaultConfig));
  return defaultConfig;
}

// Mutable credentialIssuanceClient
export let credentialIssuanceClient = createCredentialIssuanceCoordinatorFromWalletConfig(getWalletConfig());

export function setWalletConfig(config: WalletConfig) {
  localStorage.setItem(localStorageConfigKey, JSON.stringify(config));
  // Side effect: update the mutable `credentialIssuanceClient` to reflect the new config
  const walletConf = getWalletConfig();
  credentialIssuanceClient = createCredentialIssuanceCoordinatorFromWalletConfig(walletConf);
  updatePreRegisteredClientStore(walletConf);
}

function updatePreRegisteredClientStore(walletConf: WalletConfig) {
  clientStore.upsert(
    new JsPreRegisteredClient(
      walletConf.preRegisteredClient.id,
      walletConf.preRegisteredClient.jwksUri,
      '',
      SUPPORTED_VP_FORMATS
    )
  );
}
