.picker-button {
  align-items: unset;
}

.picker-button:hover {
  background-color: transparent;
  color: initial;
}

.picker-button-list {
  z-index: 1;
  top: calc(100% + 8px);
  left: 0;
  background-color: white;
  border: 1px solid var(--color-grey-light);
  box-shadow:
    rgb(50 50 93 / 25%) 0 50px 100px -20px,
    rgb(0 0 0 / 30%) 0 30px 60px -30px;
}

.picker-button-list li:not(:last-child) {
  border-bottom: 1px solid var(--color-grey-light);
}

.picker-button-item {
  text-align: left;
}

.picker-button-item-label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 22ch;
}
