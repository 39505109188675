/*
 * Copyright (C) 2023 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

package io.curity.vp.services

import io.curity.ssi.crypto.Id
import io.curity.ssi.crypto.KeyStore
import io.curity.ssi.did.DidUrl
import io.curity.ssi.jose.JsonJwsHeader
import io.curity.ssi.jose.JsonJwtClaimsSet
import io.curity.ssi.jose.JwsUtil
import io.curity.ssi.json.data.DefaultJsonMultiValue
import io.curity.ssi.sdjwt.SdJwt
import io.curity.ssi.sdjwt.SdJwtKeyBindingBuilder
import io.curity.ssi.sdjwt.SdJwtParser
import io.curity.vc.CredentialEndpoint.VerifiableCredential.StringCredential
import io.curity.vc.JwtVcJsonFormat
import io.curity.vc.VcSdJwtFormat
import io.curity.vc.serialization.DefaultValidatedVcSdJwtCredentialResponse
import io.curity.vc.serialization.DidValidatedJwtVcJsonCredentialResponse
import io.curity.vc.serialization.UserVerifiableCredential
import io.curity.vc.services.KeyAliasStore
import io.curity.vp.JwtVpJsonFormat
import io.curity.vp.JwtVpJsonFormat.JwtVpJsonVerifiablePresentation.Keys.VP
import io.curity.vp.serialization.JsonDescriptorMapObject
import io.curity.vp.serialization.JsonInputDescriptor
import io.curity.vp.serialization.JsonPathNested
import io.curity.vp.serialization.JsonPresentationSubmission
import io.curity.vp.serialization.JsonVerifiablePresentation
import io.curity.vp.serialization.SerializableW3CVerifiablePresentation
import kotlinx.datetime.Clock
import kotlinx.serialization.json.jsonObject
import kotlinx.serialization.json.jsonPrimitive

class PresentationResponseCreator(
    private val clock: Clock,
    private val keyAliasStore: KeyAliasStore,
    private val keyStore: KeyStore,
) {

    companion object {
        val CONTEXT = listOf("https://www.w3.org/2018/credentials/v1")
        val TYPES = listOf("VerifiablePresentation")
    }

    suspend fun createVerifiablePresentationResponse(
        validatedRequestJwt: ValidatedRequestJwt,
        userSelectedCredentials: UserSelectedCredentials
    ): VerifiablePresentationResponseParameters {

        // TODO: can/should we combine all credentials into a single presentation?
        val descriptorsAndPresentations = if (userSelectedCredentials.data.size == 1) {
            val firstSelectedCredential = userSelectedCredentials.data.first()
            val inputDescriptor = firstSelectedCredential.descriptor
            val userCredential = firstSelectedCredential.credential
            val presentationDisclosure = firstSelectedCredential.presentationDisclosure
            val topLevelJsonPath = "$"
            listOf(
                createVerifiablePresentation(
                    validatedRequestJwt, inputDescriptor, userCredential, topLevelJsonPath, presentationDisclosure
                )
            )
        } else {
            userSelectedCredentials.data.mapIndexed { index, selectedCredential ->
                val inputDescriptor = selectedCredential.descriptor
                val userCredential = selectedCredential.credential
                val presentationDisclosure = selectedCredential.presentationDisclosure
                val topLevelJsonPath = "$[$index]"
                createVerifiablePresentation(
                    validatedRequestJwt, inputDescriptor, userCredential, topLevelJsonPath, presentationDisclosure
                )
            }
        }
        val descriptors = descriptorsAndPresentations.map { it.first }
        val presentations = descriptorsAndPresentations.map { it.second }

        return VerifiablePresentationResponseParameters(
            DefaultJsonMultiValue(presentations),
            createPresentationSubmission(validatedRequestJwt, descriptors),
            validatedRequestJwt.state
        )
    }

    private suspend fun createVerifiablePresentation(
        validatedRequestJwt: ValidatedRequestJwt,
        inputDescriptor: JsonInputDescriptor,
        credential: UserVerifiableCredential,
        topLevelJsonPath: String,
        presentationDisclosures: PresentationDisclosure?,
    ): Pair<JsonDescriptorMapObject, JsonVerifiablePresentation> {
        return when (val credentialResponse = credential.credentialResponse) {
            is DidValidatedJwtVcJsonCredentialResponse -> {
                if (presentationDisclosures != null && presentationDisclosures.disclosures.isNotEmpty()) {
                    error("presentation disclosures cannot be used with the 'jwt_vc_json' format")
                }
                val w3cPresentation = SerializableW3CVerifiablePresentation(
                    context = CONTEXT,
                    type = TYPES,
                    verifiableCredential = DefaultJsonMultiValue(
                        StringCredential(credentialResponse.credentialJwt)
                    ),
                )
                val inputDescriptorFormat = inputDescriptor.format
                if (inputDescriptorFormat != null &&
                    (inputDescriptorFormat.jwtVcJson == null || inputDescriptorFormat.jwtVpJson == null)
                ) {
                    error("Unsupported presentation format requested: ${inputDescriptor.format}")
                }

                val descriptorMapObject = JsonDescriptorMapObject(
                    id = inputDescriptor.id,
                    format = JwtVpJsonFormat.FORMAT,
                    path = topLevelJsonPath,
                    pathNested = JsonPathNested(JwtVcJsonFormat.FORMAT, "$.vp.verifiableCredential"),
                )
                val presentation = JsonVerifiablePresentation.JsonStringVerifiablePresentation(
                    createVpJwt(validatedRequestJwt, w3cPresentation, credentialResponse.credentialJwt)
                )
                descriptorMapObject to presentation
            }

            is DefaultValidatedVcSdJwtCredentialResponse -> {
                val descriptorMapObject = JsonDescriptorMapObject(
                    id = inputDescriptor.id,
                    format = VcSdJwtFormat.FORMAT,
                    path = topLevelJsonPath,
                    pathNested = null,
                )
                val presentation = JsonVerifiablePresentation.JsonStringVerifiablePresentation(
                    createVpSdJwt(
                        validatedRequestJwt,
                        credentialResponse.credential,
                        presentationDisclosures?.disclosures ?: emptyList(),
                        credentialResponse.kid,
                    )
                )
                descriptorMapObject to presentation
            }

            else -> error("Unsupported credential type: ${credential.credentialResponse::class.simpleName}")
        }
    }

    private suspend fun createVpJwt(
        validatedRequestJwt: ValidatedRequestJwt,
        w3cPresentation: SerializableW3CVerifiablePresentation,
        vc: String
    ): String {
        val vpIssuerDidUrl = getVerifiablePresentationIssuer(vc)

        val jwsHeader = JsonJwsHeader(
            alg = "ES256", // TODO: get alg
            typ = "JWT",
            kid = vpIssuerDidUrl.toString()
        )

        val now = clock.now()
        val issuer = vpIssuerDidUrl.did.uriString

        val jwsPayload = JsonJwtClaimsSet(
            jti = Id.uniqueAlphanumericId(32), // TODO: add proper jti
            iss = issuer,
            aud = DefaultJsonMultiValue(validatedRequestJwt.iss),
            nbf = now.epochSeconds,
            exp = now.epochSeconds + 3600, // TODO: make this configurable?
            customClaims = mapOf(
                VP to w3cPresentation.toJson()
            )
        )

        val signingKey = keyAliasStore.get(vpIssuerDidUrl.toString())
            ?.let { keyStore.getSigningKeyPairById(it)?.signingKey }
            ?: error("Cannot create a presentation. Missing private key for DID URL: $vpIssuerDidUrl")

        return JwsUtil.build(signingKey, jwsHeader, jwsPayload)
    }

    private suspend fun createVpSdJwt(
        validatedRequestJwt: ValidatedRequestJwt,
        vc: String,
        presentationDisclosures: List<String>,
        kid: String
    ): String {

        val sdJwt = SdJwtParser.decodeFromString(vc) {
            JwsUtil.unsafeParseJws(it)
        }
        if (sdJwt.keyBindingJwt != null || sdJwt.keyBindingJwtString != null) {
            throw IllegalArgumentException("Credential SD-JWT cannot have a KB-JWT")
        }

        val signingKey = keyStore.getSigningKeyPairById(kid)?.signingKey
            ?: error("Cannot create a presentation. Missing private key with id: $kid")

        // presentation disclosures need to be a sub-set of the issued SD-JWT disclosures
        if (presentationDisclosures.any { !sdJwt.disclosureStrings.contains(it) }) {
            error("A presentation disclosure is not in the originally issued SD-JWT")
        }

        // TODO compute this from the signingKey
        val signingAlg = "ES256"

        val kbJwtString = SdJwtKeyBindingBuilder.build(
            iat = clock.now(),
            aud = validatedRequestJwt.iss,
            nonce = validatedRequestJwt.nonce,
            issuerJwt = sdJwt.jwt,
            issuerJwtString = sdJwt.jwtString,
            disclosureStrings = presentationDisclosures,
            signingKey = signingKey,
            signingAlg = signingAlg
        )

        return SdJwt.encode(sdJwt.jwtString, presentationDisclosures, kbJwtString)
    }

    private fun getVerifiablePresentationIssuer(credential: String): DidUrl {
        val parsedVc = JwsUtil.unsafeParseJws(credential)
        val vcClaim = parsedVc.payload["vc"]?.jsonObject ?: error("missing vc claim")
        val credentialSubject = vcClaim["credentialSubject"]?.jsonObject ?: error("missing credentialSubject claim")
        val credSubjectString = credentialSubject["id"]?.jsonPrimitive?.content
            ?: error("Cannot create a presentation from credential which doesn't have credential subject.")
        val didUrl = DidUrl.from("$credSubjectString#0")
            ?: error("Cannot create a presentation because credential subject is not a DID.")

        return didUrl
    }

    private fun createPresentationSubmission(
        validatedRequestJwt: ValidatedRequestJwt,
        descriptorMapObjects: List<JsonDescriptorMapObject>
    ): JsonPresentationSubmission {
        return JsonPresentationSubmission(
            id = Id.uniqueAlphanumericId(16),
            definitionId = validatedRequestJwt.presentationDefinition.id,
            descriptorMap = descriptorMapObjects,
        )
    }
}

data class UserSelectedCredentials(
    val data: List<UserSelectedCredential>
)

data class UserSelectedCredential(
    val descriptor: JsonInputDescriptor,
    val credential: UserVerifiableCredential,
    val presentationDisclosure: PresentationDisclosure,
)

data class PresentationDisclosure(
    val disclosures: List<String>,
)