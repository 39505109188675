/*
 * Copyright (C) 2024 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

package io.curity.ssi.crypto

import kotlin.io.encoding.Base64
import kotlin.io.encoding.ExperimentalEncodingApi

@OptIn(ExperimentalEncodingApi::class)
fun encodeBase64url(bytes: ByteArray) = Base64.UrlSafe.encode(bytes).dropLastWhile { it == '=' }
@OptIn(ExperimentalEncodingApi::class)
fun decodeBase64Url(string: String) = Base64.UrlSafe.decode(string)
