/*
 * Copyright (C) 2023 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

package io.curity.ssi.js

import io.curity.ssi.data.SerializationAdapter
import kotlinx.serialization.json.JsonArray
import kotlinx.serialization.json.JsonElement
import kotlinx.serialization.json.JsonNull
import kotlinx.serialization.json.JsonObject
import kotlinx.serialization.json.JsonPrimitive
import kotlinx.serialization.json.booleanOrNull
import kotlinx.serialization.json.doubleOrNull
import kotlinx.serialization.json.floatOrNull
import kotlinx.serialization.json.intOrNull
import kotlin.js.Json

/**
 * Converter from [JsonElement] to native JS types.
 *
 * The target values can be used by native JS libraries but can rarely be used directly in Kotlin.
 * For example, JS arrays are not instances of Kotlin [List] and JS objects are not instances of [Map].
 *
 * The types [String], [Number], [Boolean] and [Null] do have the same representation in Kotlin and JS.
 */
object JsonToJsConverter : SerializationAdapter<JsonElement, Any?> {
    override fun convertValue(value: JsonElement): Any? {
        return when (value) {
            JsonNull -> null
            is JsonObject -> {
                val result: dynamic = js("({})")
                for ((name, item) in value.entries) {
                    result[name] = convertValue(item)
                }
                return result
            }

            is JsonArray -> {
                val result: dynamic = js("[]")
                for (item in value) {
                    result.push(convertValue(item))
                }
                return result
            }

            is JsonPrimitive -> if (value.isString) value.content
            else value.booleanOrNull ?: value.intOrNull ?: value.floatOrNull ?: value.doubleOrNull
        }
    }

    /**
     * Converts a map to a JS-native object.
     */
    fun convertToJson(map: Map<String, JsonElement>): Json {
        return convertValue(JsonObject(map)).unsafeCast<Json>()
    }
}