/*
 * Copyright (C) 2024 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

interface CredentialDefaultIconProps {
  width?: string;
  height?: string;
}

export const CredentialDefaultIcon: React.FC<CredentialDefaultIconProps> = ({ width = '100%', height = '100%' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 67.44 97.99" width={width} height={height}>
    <path
      fill="#fff"
      d="M35.97 24.77h-4.51c-2.74 0-4.96-2.23-4.96-4.96V4.96C26.5 2.22 28.73 0 31.46 0h4.51c2.74 0 4.96 2.23 4.96 4.96V19.8c0 2.74-2.23 4.96-4.96 4.96zm-.04-19.8l-4.47.04.04 14.8 4.43-.04V4.96zm.04 14.8z"
    ></path>
    <path
      fill="#fff"
      d="M62.24 97.99H5.2c-2.87 0-5.2-2.33-5.2-5.2V12.84c0-2.87 2.33-5.2 5.2-5.2h13.26a2.5 2.5 0 010 5H5.2c-.11 0-.2.1-.2.2v79.95c0 .11.1.2.2.2h57.04c.11 0 .2-.1.2-.2V12.84c0-.11-.1-.2-.2-.2H49.96a2.5 2.5 0 010-5h12.28c2.87 0 5.2 2.33 5.2 5.2v79.95c0 2.87-2.33 5.2-5.2 5.2z"
    ></path>
    <g fill="#fff">
      <path d="M49.96 50.31h-31.5c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5h31.5c.83 0 1.5.67 1.5 1.5s-.67 1.5-1.5 1.5zM49.96 61.02h-31.5c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5h31.5c.83 0 1.5.67 1.5 1.5s-.67 1.5-1.5 1.5zM32.15 71.73H18.46c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5h13.69c.83 0 1.5.67 1.5 1.5s-.67 1.5-1.5 1.5z"></path>
    </g>
  </svg>
);
