/*
 * Copyright (C) 2024 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

// Because we want these symbols to be in the root package, for better usage from JS
@file:Suppress("PackageDirectoryMismatch")

package vc_js

import kotlinx.browser.localStorage
import kotlinx.serialization.json.Json
import org.w3c.dom.get

inline fun <reified T> Json.decodeFromLocalStorageOrNull(key: String): T? {
    val storedValue = localStorage[key] ?: return null
    return try {
        decodeFromString(storedValue)
    } catch (ex: Exception) {
        console.log("Ignoring localStorage key '$key'")
        null
    }
}