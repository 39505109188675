/*
 * Copyright (C) 2024 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

package io.curity.vc.serialization

import io.curity.ssi.json.data.FromJson
import io.curity.ssi.json.data.FromJsonHelper
import io.curity.ssi.json.data.JsonDictionary
import io.curity.ssi.json.data.ToJsonDictionary
import io.curity.ssi.json.data.toJson
import io.curity.ssi.json.data.toJsons
import io.curity.ssi.json.data.unwrapOptionalBoolean
import io.curity.ssi.json.data.unwrapOptionalString
import io.curity.vc.JwtVcJsonFormat
import io.curity.vc.Metadata
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.json.JsonElement

/*
 * Contains metadata types for the W3C related formats, namely 'jwt_vc_json'
 */

@Serializable(with = JwtVcJsonCredentialSubjectMetadataSerializer::class)
data class JwtVcJsonCredentialSubjectMetadata(
    override val claims: Map<String, JwtVcJsonCredentialSubjectEntryMetadata>
) : ToJsonDictionary, JwtVcJsonFormat.CredentialSubjectMetadata<JsonElement> {

    companion object : FromJson<JwtVcJsonCredentialSubjectMetadata> {
        override fun fromJson(json: JsonElement): JwtVcJsonCredentialSubjectMetadata {
            val map = FromJsonHelper.requireJsonObject(json, "JwtVcJsonCredentialSubjectMetadata")
            return JwtVcJsonCredentialSubjectMetadata(
                claims = map.entries.associate { (key, value) ->
                    key to JwtVcJsonCredentialSubjectEntryMetadata.fromJson(value)
                }
            )
        }
    }

    private val _serialized = lazy {
        JsonDictionary(
            claims.entries.associate { (key, value) ->
                key to value.toJsonDictionary().toJson()
            }
        )
    }

    override fun toJsonDictionary(): JsonDictionary = _serialized.value
}

@Serializable(with = JwtVcJsonCredentialSubjectEntryMetadataSerializer::class)
data class JwtVcJsonCredentialSubjectEntryMetadata(
    override val valueType: String? = null,
    override val display: List<JsonDisplayable>? = null,
    override val mandatory: Boolean? = null,
    val customClaims: ToJsonDictionary? = null,
) : ToJsonDictionary, JwtVcJsonFormat.CredentialSubjectEntryMetadata<JsonElement> {

    companion object : FromJson<JwtVcJsonCredentialSubjectEntryMetadata> {
        override fun fromJson(json: JsonElement): JwtVcJsonCredentialSubjectEntryMetadata {
            val map = FromJsonHelper.requireJsonObject(json, "JsonLogo").toMutableMap()
            return JwtVcJsonCredentialSubjectEntryMetadata(
                valueType = map.unwrapOptionalString(JwtVcJsonFormat.CredentialSubjectEntryMetadata.Keys.VALUE_TYPE),
                display = map.unwrapOptionalDisplays(JwtVcJsonFormat.CredentialSubjectEntryMetadata.Keys.DISPLAY),
                mandatory = map.unwrapOptionalBoolean(JwtVcJsonFormat.CredentialSubjectEntryMetadata.Keys.MANDATORY),
                customClaims = if (map.isEmpty()) null else JsonDictionary(map.toMap())
            )
        }
    }

    private val _serializable by lazy {
        JsonDictionary(buildMap {
            if (customClaims != null) putAll(customClaims.toJson())
            if (valueType != null) put(
                JwtVcJsonFormat.CredentialSubjectEntryMetadata.Keys.VALUE_TYPE,
                valueType.toJson()
            )
            if (display != null) put(
                JwtVcJsonFormat.CredentialSubjectEntryMetadata.Keys.DISPLAY,
                display.toJsons()
            )
            if (mandatory != null) put(
                JwtVcJsonFormat.CredentialSubjectEntryMetadata.Keys.MANDATORY,
                mandatory.toJson()
            )
        })
    }

    override fun toJsonDictionary(): JsonDictionary = _serializable
}

@Serializable
data class JwtVcJsonCredentialConfigurationsSupported(
    @SerialName(Metadata.CredentialConfigurationsSupported.Keys.FORMAT)
    override val format: String,
    @SerialName(JwtVcJsonFormat.CredentialConfigurationsSupported.Keys.CREDENTIAL_DEFINITION)
    override val credentialDefinition: JwtVcJsonMetadataCredentialDefinition,
    @SerialName(Metadata.CredentialConfigurationsSupported.Keys.CRYPTOGRAPHIC_BINDING_METHODS_SUPPORTED)
    override val cryptographicBindingMethodsSupported: List<String>? = null,
    @SerialName(Metadata.CredentialConfigurationsSupported.Keys.DISPLAY)
    override val display: List<JsonCredentialDisplay>? = null,
    @SerialName(Metadata.CredentialConfigurationsSupported.Keys.SCOPE)
    override val scope: String? = null,
    @SerialName(Metadata.CredentialConfigurationsSupported.Keys.CREDENTIAL_SIGNING_ALG_VALUES_SUPPORTED)
    override val credentialSigningAlgValuesSupported: List<String>? = null,
    @SerialName(Metadata.CredentialConfigurationsSupported.Keys.PROOF_TYPES_SUPPORTED)
    override val proofTypesSupported: Map<String, JsonProofTypesSupported>? = null,
    @SerialName(JwtVcJsonFormat.CredentialConfigurationsSupported.Keys.ORDER)
    override val order: List<String>? = null,
) : JsonCredentialConfigurationsSupported, JwtVcJsonFormat.CredentialConfigurationsSupported<JsonElement>

@Serializable
data class JwtVcJsonMetadataCredentialDefinition(
    @SerialName(JwtVcJsonFormat.MetadataCredentialDefinition.Keys.TYPE)
    override val type: Set<String>,
    @SerialName(JwtVcJsonFormat.MetadataCredentialDefinition.Keys.CREDENTIAL_SUBJECT)
    override val credentialSubject: JwtVcJsonCredentialSubjectMetadata?,
): JwtVcJsonFormat.MetadataCredentialDefinition<JsonElement>
