/*
 * Copyright (C) 2023 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

package io.curity.vp.services

import io.curity.vc.serialization.UserVerifiableCredential
import io.curity.vp.serialization.JsonInputDescriptor
import io.curity.vp.serialization.JsonPresentationDefinition

interface CredentialFinder {

    /**
     * Tries to find credentials that conform to provided [presentationDefinition].
     * The result will contain a list of [JsonInputDescriptor] and [UserVerifiableCredential]s
     * pairs. [JsonInputDescriptor]s are taken from provided [presentationDefinition]. If a credential is not
     * found for a given descriptor, then the given pair will contain an empty credential list.
     *
     * @return result with found credentials
     */
    suspend fun find(presentationDefinition: JsonPresentationDefinition): CredentialFinderResult
}

data class CredentialFinderResult(
    val data: List<Pair<JsonInputDescriptor, List<UserVerifiableCredential>>>
)
