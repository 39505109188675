/*
 * Copyright (C) 2023 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import { JsUserVerifiableCredential } from 'curity-ssi-libs-verifiable-credentials-vc-ks-services';
import { DetailLevel } from '../types/DetailLevel';
import { DidJwtVcJsonVerifiableCredential } from './credential-formats/DidJwtVcJsonVerifiableCredential';
import { VcSdJwtVerifiableCredential } from './credential-formats/VcSdJwtVerifiableCredential';

type VerifiableCredentialProps = {
  credential: JsUserVerifiableCredential;
  detailLevel?: DetailLevel;
  onClick?: (credential: JsUserVerifiableCredential) => void;
};

export function VerifiableCredential({ credential, detailLevel, onClick }: VerifiableCredentialProps) {
  const level = detailLevel || 'summary';
  const didJwtVcJsonCredential = credential.credential.didJwtVcJson;
  const vcSdJwtCredential = credential.credential.vcSdJwt;
  if (didJwtVcJsonCredential) {
    return (
      <DidJwtVcJsonVerifiableCredential
        userDefinedName={credential.userDefinedName}
        credential={didJwtVcJsonCredential}
        detailLevel={level}
        onClick={() => onClick && onClick(credential)}
      />
    );
  }
  if (vcSdJwtCredential) {
    return (
      <VcSdJwtVerifiableCredential
        userDefinedName={credential.userDefinedName}
        credential={vcSdJwtCredential}
        detailLevel={level}
        onClick={() => onClick && onClick(credential)}
      />
    );
  } else {
    return <p>Credential format not supported</p>;
  }
}
