/*
 * Copyright (C) 2024 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

package io.curity.ssi.sdjwt

import io.curity.ssi.crypto.SigningKey
import io.curity.ssi.jose.JsonJwsHeader
import io.curity.ssi.jose.JsonJwt
import io.curity.ssi.jose.JsonJwtClaimsSet
import io.curity.ssi.jose.JwsUtil
import io.curity.ssi.json.data.DefaultJsonMultiValue
import kotlinx.datetime.Instant
import kotlinx.serialization.json.JsonPrimitive

/**
 * Contains functions to create SD-JWT Key Binding JWTs, as documented in
 * [https://www.ietf.org/archive/id/draft-ietf-oauth-selective-disclosure-jwt-08.html#name-key-binding-jwt](https://www.ietf.org/archive/id/draft-ietf-oauth-selective-disclosure-jwt-08.html#name-key-binding-jwt)
 */
object SdJwtKeyBindingBuilder {

    suspend fun build(
        iat: Instant,
        aud: String,
        nonce: String,
        issuerJwt: JsonJwt.Jws,
        issuerJwtString: String,
        disclosureStrings: List<String>,
        signingKey: SigningKey,
        signingAlg: String,
    ): String {

        val sdHash = SdJwt.computeKbJwtSdHash(issuerJwt, issuerJwtString, disclosureStrings)

        val jwsHeader = JsonJwsHeader(
            alg = signingAlg,
            typ = SdJwtConstants.TYP_KB_JWT,
        )

        val jwsPayload = JsonJwtClaimsSet(
            iat = iat.epochSeconds,
            aud = DefaultJsonMultiValue(aud),
            customClaims = mapOf(
                "nonce" to JsonPrimitive(nonce),
                SdJwtConstants.CLAIM_SD_HASH to JsonPrimitive(sdHash),
            )
        )

        return JwsUtil.build(signingKey, jwsHeader, jwsPayload)
    }
}