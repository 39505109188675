/*
 * Copyright (C) 2023 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */
@file:OptIn(ExperimentalJsExport::class)

package io.curity.oauth

import io.ktor.http.ParametersBuilder
import kotlinx.serialization.Required
import kotlinx.serialization.Serializable
import kotlin.js.ExperimentalJsExport
import kotlin.js.JsExport
import kotlin.js.JsName

@JsExport
enum class OAuthResponseType {
    code
}

/**
 * Represents an OAuth 2.0 authorization request.
 */
@Serializable
@JsExport
data class OAuthAuthorizationRequest(
    @JsName("client_id")
    val clientId: String,
    @JsName("authorization_details")
    val authorizationDetails: String? = null,
    @JsName("scope")
    val scope: String? = null,
    @JsName("state")
    val state: String? = null,
    @JsName("redirect_uri")
    val redirectUri: String? = null,
    @JsName("response_type")
    @Required // required only in the serialized form
    val responseType: OAuthResponseType = OAuthResponseType.code,
)

fun ParametersBuilder.appendParameters(request: OAuthAuthorizationRequest) {
    with(request) {
        append("response_type", responseType.name)
        append("client_id", clientId)
        if (authorizationDetails != null) {
            append("authorization_details", authorizationDetails)
        }
        if (scope != null) {
            append("scope", scope)
        }
        state?.let { append("state", it) }
        redirectUri?.let { append("redirect_uri", it) }
    }
}
