/*
 * Copyright (C) 2023 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import { Dispatch, useCallback, useReducer } from 'react';

import { credentialIssuerLoader, credentialIssuerStore, JsCredentialIssuer } from '../ssi-libs';

import { routes } from '../routes';
import { Link } from 'react-router-dom';
import { CredentialIssuer } from '../components/CredentialIssuer';
import { SuccessCheckmark } from '../components/SuccessCheckmark';
import { Spinner } from '../components/Spinner';
import { Alert } from '../components/Alert';

type State =
  | { state: 'begin' }
  | { state: 'loading'; issuerId: string }
  | { state: 'loaded'; issuerId: string; issuer: JsCredentialIssuer }
  | { state: 'load-error'; issuerId: string; message: string }
  | { state: 'saving'; issuerId: string; issuer: JsCredentialIssuer }
  | { state: 'save-error'; issuerId: string; issuer: JsCredentialIssuer; message: string }
  | { state: 'saved'; issuer: JsCredentialIssuer };

type Action =
  | { type: 'load'; issuerId: string }
  | { type: 'loaded'; issuerId: string; issuer: JsCredentialIssuer }
  | { type: 'load-error'; issuerId: string; message: string }
  | { type: 'save'; issuer: JsCredentialIssuer }
  | { type: 'saved'; issuer: JsCredentialIssuer }
  | { type: 'save-error'; issuer: JsCredentialIssuer; message: string }
  | { type: 'clear' };

function reducer(state: State, action: Action): State {
  switch (state.state) {
    case 'begin':
      switch (action.type) {
        case 'load':
          return { state: 'loading', issuerId: action.issuerId };
        default:
          return state;
      }

    case 'loading':
      switch (action.type) {
        case 'loaded':
          return { state: 'loaded', issuerId: action.issuerId, issuer: action.issuer };
        case 'load-error':
          return { state: 'load-error', issuerId: action.issuerId, message: action.message };
        default:
          return state;
      }

    case 'loaded':
      switch (action.type) {
        case 'load':
          return { state: 'loading', issuerId: action.issuerId };
        case 'save':
          return { state: 'saving', issuerId: state.issuerId, issuer: state.issuer };
        case 'clear':
          return { state: 'begin' };
        default:
          return state;
      }
    case 'load-error':
      switch (action.type) {
        case 'load':
          return { state: 'loading', issuerId: action.issuerId };
        default:
          return state;
      }

    case 'saving':
      switch (action.type) {
        case 'saved':
          return { state: 'saved', issuer: state.issuer };
        case 'save-error':
          return {
            state: 'save-error',
            message: action.message,
            issuer: state.issuer,
            issuerId: state.issuerId,
          };
        default:
          return state;
      }
    case 'save-error':
      switch (action.type) {
        case 'save':
          return { state: 'saving', issuerId: state.issuerId, issuer: state.issuer };
        default:
          return state;
      }
    case 'saved':
      break;
  }
}

async function operLoad(issuerId: string, dispatch: Dispatch<Action>) {
  dispatch({ type: 'load', issuerId: issuerId });
  try {
    const issuer = await credentialIssuerLoader.load(issuerId);
    dispatch({ type: 'loaded', issuerId: issuerId, issuer: issuer });
  } catch (e) {
    dispatch({ type: 'load-error', issuerId: issuerId, message: e.message });
  }
}

async function operSave(issuer: JsCredentialIssuer, dispatch: Dispatch<Action>) {
  dispatch({ type: 'save', issuer: issuer });
  try {
    await credentialIssuerStore.upsert(issuer);
    dispatch({ type: 'saved', issuer: issuer });
  } catch (e) {
    dispatch({ type: 'save-error', issuer: issuer, message: e.message });
  }
}

export function IssuerAdd() {
  const [state, dispatch] = useReducer(reducer, { state: 'begin' });
  switch (state.state) {
    case 'begin':
    case 'loading':
    case 'load-error':
      return <LoadForm state={state} dispatch={dispatch} />;
    case 'loaded':
    case 'saving':
    case 'save-error':
      return <ShowIssuer state={state} dispatch={dispatch} issuer={state.issuer} />;
    case 'saved':
      return <ShowSaved />;
  }
}

function LoadForm({ state, dispatch }: { state: State; dispatch: Dispatch<Action> }) {
  const handleSubmit = useCallback(
    ev => {
      ev.preventDefault();
      const formData = new FormData(ev.target);
      const issuerId = formData.get('issuerId').valueOf() as string;
      operLoad(issuerId, dispatch);
    },
    [dispatch]
  );
  const enabled = state.state === 'begin' || state.state === 'loaded' || state.state === 'load-error';
  const errorMessage = state.state === 'load-error' && state.message;
  return (
    <>
      <div className="center">
        <h2>Add a Credential Issuer</h2>
        <p>Only issuers that support the OpenID for Verifiable Credential Issuance spec are supported.</p>
      </div>
      <form onSubmit={e => handleSubmit(e)}>
        <label className="block" htmlFor="credential-issuer-url">
          Credential Issuer Identifier
        </label>
        <input
          type="text"
          className="field w100 mb2"
          id="credential-issuer-url"
          placeholder="Enter URL"
          name="issuerId"
          disabled={!enabled}
        />
        <div className="flex flex-column flex-gap-2 flex-align-normal">
          <input
            className="button button-medium button-primary button-fullwidth"
            type="submit"
            value="Load credentials"
            disabled={!enabled}
          />
          <Link to="/" className="button button-medium button-transparent button-fullwidth">
            Cancel
          </Link>
        </div>
      </form>
      {state.state == 'loading' && <Spinner />}
      {errorMessage && <Alert kind="danger" title="There was an error adding the issuer" errorMessage={errorMessage} />}
    </>
  );
}

function ShowIssuer({
  state,
  dispatch,
  issuer,
}: {
  state: State;
  dispatch: Dispatch<Action>;
  issuer: JsCredentialIssuer;
}) {
  const handleClick = useCallback(async () => {
    operSave(issuer, dispatch);
  }, [issuer, dispatch]);
  return (
    <>
      <CredentialIssuer issuer={issuer} />
      <button className="button button-medium button-primary button-fullwidth" onClick={handleClick} disabled={false}>
        Save Issuer
      </button>
      {state.state == 'save-error' && <p>Error: {state.message}</p>}
    </>
  );
}

function ShowSaved() {
  return (
    <div className="h100 flex flex-column flex-center justify-center">
      <div className="center">
        <SuccessCheckmark />

        <h1 className="h3 mt0">Success</h1>
        <p>Credential Issuer added successfully</p>
      </div>

      <Link to={routes.ISSUER_LIST} className="button button-medium button-primary button-fullwidth">
        View Credential Issuers
      </Link>
    </div>
  );
}
