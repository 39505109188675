/*
 * Copyright (C) 2024 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import { useEffect, useState } from 'react';
import * as styles from './pwa-prompt.module.css';
import { Close } from '../icons/Close';
import { Curity, KebabMenu, Plus, Share } from '../icons';

export const PwaPrompt: React.FC = () => {
  const isiOS = /iphone|ipad|ipod/.test(window.navigator.userAgent.toLowerCase());
  const isAndroid = /android/.test(window.navigator.userAgent.toLowerCase());
  const isHiddenByUserPreference = localStorage.getItem('pwa-prompt-status') === 'hidden';

  const [isVisible, setVisibility] = useState(Boolean);

  useEffect(() => {
    const activeElement = document.activeElement as HTMLInputElement;

    if (activeElement) {
      activeElement.blur();
    }

    setVisibility(isiOS || isAndroid);
  }, [isiOS, isAndroid]);

  useEffect(() => {
    if (isVisible) {
      document.body.classList.add(styles['lock-scroll']);
    }
  }, [isVisible]);

  const dismissPrompt = () => {
    document.body.classList.remove(styles['lock-scroll']);
    setVisibility(false);

    localStorage.setItem('pwa-prompt-status', 'hidden');
  };

  return (
    <>
      {isVisible && !isHiddenByUserPreference ? (
        <div className={`${styles['pwa-prompt-background']}`}>
          <div className={`${styles['pwa-prompt-content']}`}>
            <header className={`${styles['pwa-prompt-content-header']}`}>
              <h1 className={`m0 flex flex-gap-2 flex-center ${styles['pwa-prompt-content-header-heading']}`}>
                <Curity width="24" height="24" />
                Add Wallet to Home Screen
              </h1>
              <button aria-label="Close" className="button button-tiny button-transparent" onClick={dismissPrompt}>
                <Close />
              </button>
            </header>
            <div className={`p2 ${styles['pwa-prompt-content-copy']}`}>
              <p>
                This Wallet application can be used as a Progressive Web App PWA. You can add it to your home screen and
                use it in full screen.
              </p>
              <ul className="m0 list-reset">
                <li className="flex flex-gap-2 p2">
                  {isAndroid ? (
                    <>
                      <KebabMenu />
                      In Chrome click the menu button
                    </>
                  ) : (
                    <>
                      <Share />
                      Press the Share button
                    </>
                  )}
                </li>
                <li className="flex flex-gap-2 p2">
                  {isAndroid ? (
                    <>
                      <Plus />
                      Press Install Application
                    </>
                  ) : (
                    <>
                      <Plus />
                      Press Add to Home screen
                    </>
                  )}
                </li>
              </ul>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};
