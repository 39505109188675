/*
 * Copyright (C) 2024 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

package io.curity.vc.serialization

import io.curity.ssi.json.data.JsonSerializer

internal object VcSdJwtClaimsMetadataSerializer :
    JsonSerializer<VcSdJwtClaimsMetadata>(VcSdJwtClaimsMetadata)

internal object VcSdJwtClaimsMetadataEntrySerializer :
    JsonSerializer<VcSdJwtClaimsEntryMetadata>(VcSdJwtClaimsEntryMetadata)

internal object VcSdJwtAuthorizationDetailClaimsSerializer:
        JsonSerializer<VcSdJwtAuthorizationDetail.JsonClaims>(VcSdJwtAuthorizationDetail.JsonClaims)

internal object VcSdJwtAuthorizationDetailClaimsItemSerializer:
    JsonSerializer<VcSdJwtAuthorizationDetail.JsonClaimsItem>(VcSdJwtAuthorizationDetail.JsonClaimsItem)