/*
 * Copyright (C) 2024 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

// Because we want these symbols to be in the root package, for better usage from JS
@file:Suppress("PackageDirectoryMismatch")

@OptIn(ExperimentalJsExport::class)
@JsExport
class JsRequestJwtStore internal constructor(
    val inner: LocalStorageRequestJwtStore,
) {

    fun insert(id: String, requestJwt: JsValidatedRequestJwt) {
        inner.insert(id, requestJwt.inner)
    }

    fun delete(id: String) {
        inner.delete(id)
    }

    fun getById(id: String): JsValidatedRequestJwt? = inner.getById(id)?.let {
        JsValidatedRequestJwt(it)
    }

    fun getAll(): Array<JsPair<String, JsValidatedRequestJwt>> =
        inner.getAll().map {
            JsPair(it.first, JsValidatedRequestJwt(it.second))
        }.toTypedArray()

    fun cleanupIfAfterPeriod() {
        inner.cleanupIfAfterPeriod()
    }
}