/*
 * Copyright (C) 2023 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */
@file:OptIn(ExperimentalJsExport::class)

package io.curity.oauth

import kotlinx.serialization.Serializable
import kotlin.js.ExperimentalJsExport
import kotlin.js.JsExport
import kotlin.js.JsName

@Serializable
@JsExport
data class OAuthAuthorizationResponse(
    @JsName("state")
    val state: String,
    @JsName("code")
    val code: String?
)

