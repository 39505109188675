/*
 * Copyright (C) 2024 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

package io.curity.ssi.sdjwt

import kotlinx.serialization.Serializable
import kotlinx.serialization.json.JsonArray
import kotlinx.serialization.json.JsonElement
import kotlinx.serialization.json.JsonNull
import kotlinx.serialization.json.JsonObject
import kotlinx.serialization.json.JsonPrimitive
import kotlinx.serialization.json.booleanOrNull
import kotlinx.serialization.json.doubleOrNull
import kotlinx.serialization.json.floatOrNull
import kotlinx.serialization.json.intOrNull
import kotlinx.serialization.json.longOrNull

interface IsDiscloseable {
    val disclosureString: String?
}

/**
 * Represents a JSON value (i.e. JSON element) added with disclosure information, on [SdProperty] and [SdArrayValue].
 * It is used on the output of the SD-JWT parsing process.
 */
/*
 * Implementation note:
 * The output of the SD-JWT parsing process is a JSON object with a subset of the concealed properties and array values
 * disclosed.
 * However, this SD-JWT may be used again, with only a subset of the disclosures. For that, it is useful to know
 * which properties or array values have associated disclosures. For that, we identified two options
 * - Option A: Have a new representation for a JSON object that includes that extra information.
 * - Option B: Have a regular JsonObject representation added with a set of paths for the properties and array values for which
 *   there are disclosures.
 * Currently, we opted for the first options. This SdValue is exactly that: a way to represent a JSON hierarchy
 * with extra disclosure information.
 * New requirements and usage experience may reveal that option B is preferable.
 */
@Serializable
sealed interface SdValue {

    /**
     * Converts the [SdValue] into plain JSON, i.e., to a [JsonElement]
     */
    fun toJsonElement(): JsonElement

    @Serializable
    data class Object(
        val members: Map<String, SdProperty>
    ) : SdValue {
        override fun toNative() = members.entries.associate { it.key to it.value.toNative() }
        override fun toJsonElement() = JsonObject(members.entries.associate { (key, value) ->
            key to value.value.toJsonElement()
        })
        override fun get(index: String): SdValue? = members[index]?.value
    }

    @Serializable
    data class Array(
        val elements: kotlin.Array<SdArrayValue>
    ) : SdValue {
        override fun toNative() = elements.map { it.toNative() }
        override fun toJsonElement() = JsonArray(elements.map { it.value.toJsonElement() })
        override fun get(index: Int): SdValue = elements[index].value
        override fun equals(other: Any?): Boolean {
            if (this === other) return true
            if (other == null || this::class != other::class) return false

            other as Array

            return elements.contentEquals(other.elements)
        }

        override fun hashCode(): Int {
            return elements.contentHashCode()
        }
    }

    @Serializable
    data class Primitive(
        val value: JsonPrimitive? // can be used to represent nulls
    ) : SdValue {
        override fun toNative() = when (value) {
            null -> null
            else -> if (value.isString) value.content
            else value.booleanOrNull ?: value.intOrNull ?: value.floatOrNull ?: value.doubleOrNull
        }

        override fun toJsonElement() = value ?: JsonNull

        override fun asString(): String? = value?.content
        override fun asLong(): Long? = value?.longOrNull
        override fun asBoolean(): Boolean? = value?.booleanOrNull
    }

    // Helper methods, mostly used on tests
    fun toNative(): Any?
    operator fun get(index: Int): SdValue = throw IllegalStateException("Array indexing is only possible on arrays")
    operator fun get(index: String): SdValue? = throw IllegalStateException("Map indexing is only possible on objects")
    fun asString(): String? = throw IllegalStateException("Not a primitive value")
    fun asLong(): Long? = throw IllegalStateException("Not a primitive value")
    fun asBoolean(): Boolean? = throw IllegalStateException("Not a primitive value")
}

@Serializable
data class SdProperty(
    val name: String,
    val value: SdValue,
    override val disclosureString: String? = null
) : IsDiscloseable {
    fun toNative() = value.toNative()
}

@Serializable
data class SdArrayValue(
    val value: SdValue,
    override val disclosureString: String? = null
) : IsDiscloseable {
    fun toNative() = value.toNative()
}

/**
 * Given a [SdValue] returns a map associating all selectively-disclosable properties and array values to their
 * disclosure strings.
 */
fun SdValue.Object.getSelectivelyDiscloseablePaths(): Map<JsonPath, String> {
    val map = mutableMapOf<JsonPath, String>()
    fun process(path: JsonPath?, value: SdValue) {
        when (value) {
            is SdValue.Object -> {
                value.members.values.forEach {
                    val newPath = path.withProperty(it.name)
                    if (it.disclosureString != null) {
                        map[newPath] = it.disclosureString
                    }
                    process(newPath, it.value)
                }
            }

            is SdValue.Array -> {
                value.elements.forEachIndexed { index, element ->
                    if (path == null) {
                        throw IllegalArgumentException("path cannot be null")
                    }
                    val newPath = path.withIndex(index)
                    if (element.disclosureString != null) {
                        map[newPath] = element.disclosureString
                    }
                    process(newPath, element.value)
                }
            }

            is SdValue.Primitive -> {
                /* nothing to do */
            }
        }
    }
    process(null, this)
    return map.toMap()
}
