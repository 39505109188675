/*
 * Copyright (C) 2023 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import { createRoot } from 'react-dom/client';
import { App } from './App';

// Load service worker
if ("serviceWorker" in navigator) {
    navigator.serviceWorker.register(
            // The service-worker needs to be loaded from the root ('/') path, so that it
            // can control the complete application scope (i.e. the '/' scope).
            // See https://web.dev/learn/pwa/service-workers#scope
            new URL("../service-worker.ts", import.meta.url),
            {type: 'module', scope: "/"}
    )
}

// Start UI controlled by React
const container = document.getElementById('app')!;
const root = createRoot(container);
root.render(<App />);
