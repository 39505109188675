/*
 * Copyright (C) 2023 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import { VerifiableCredential, verifiableCredentialStore } from '../ssi-libs';
import { Link, useNavigate } from 'react-router-dom';
import { routes } from '../routes';
import { useCallback, useMemo } from 'react';
import { VerifiableCredential as ShowVerifiableCredential } from '../components/VerifiableCredential';
import { EmptyState } from '../components/EmptyState';

export function CredentialList() {
  const navigate = useNavigate();
  const onClick = useCallback(
    (credential: VerifiableCredential) => {
      navigate(routes.TO_CREDENTIAL_DETAIL(credential.id));
    },
    [navigate]
  );

  const credentials = useMemo(() => verifiableCredentialStore.getAll(), []);

  return credentials.length ? (
    <>
      {credentials.map(credential => (
        <ShowVerifiableCredential
          credential={credential as VerifiableCredential}
          detailLevel={'summary'}
          onClick={onClick}
          key={credential.id}
        />
      ))}
      <Link to={routes.HOME}>Home</Link>
    </>
  ) : (
    <EmptyState
      heading="Looks you don't have any credentials"
      text="A way to obtain credentials is by going to the issuers and using one of
                            its supported credentials."
    />
  );
}
