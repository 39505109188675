/*
 * Copyright (C) 2024 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

@file:OptIn(ExperimentalJsExport::class)

// Because we want these symbols to be in the root package, for better usage from JS
@file:Suppress("PackageDirectoryMismatch")

import io.curity.vp.VerifierDirectPostAuthorizationResponse
import io.curity.vp.serialization.AbstractJsonFilter
import io.curity.vp.serialization.JsonAlgFormat
import io.curity.vp.serialization.JsonArrayContainsFilter
import io.curity.vp.serialization.JsonConstraints
import io.curity.vp.serialization.JsonContains
import io.curity.vp.serialization.JsonDescriptorMapObject
import io.curity.vp.serialization.JsonField
import io.curity.vp.serialization.JsonFormat
import io.curity.vp.serialization.JsonInputDescriptor
import io.curity.vp.serialization.JsonPathNested
import io.curity.vp.serialization.JsonPresentationDefinition
import io.curity.vp.serialization.JsonPresentationSubmission
import io.curity.vp.serialization.JsonProofTypeFormat
import io.curity.vp.serialization.JsonStringFilter
import io.curity.vp.services.ValidatedRequestJwt
import io.curity.vp.services.VerifiablePresentationResponseParameters

@JsExport
class JsValidatedRequestJwt internal constructor(
    val inner: ValidatedRequestJwt,
) {
    val jws: JsJwsHeaderAndPayload = JsJwsHeaderAndPayload(inner.jws)
    val responseUri: String = inner.responseUri
    val iss: String = inner.iss
    val presentationDefinition = JsPresentationDefinition(inner.presentationDefinition)
    val state: String? = inner.state
    val expAt: Long = inner.expAt
}


@JsExport
class JsPresentationDefinition internal constructor(
    val inner: JsonPresentationDefinition,
) {
    val id: String = inner.id
    val name: String? = inner.name
    val purpose: String? = inner.purpose
    val format: JsFormat? by lazy {
        val innerFormat = inner.format
        if (innerFormat != null) {
            JsFormat(innerFormat)
        } else {
            null
        }
    }
    val inputDescriptors: Array<JsInputDescriptor> by lazy {
        inner.inputDescriptors.map { JsInputDescriptor(it) }.toTypedArray()
    }
}

@JsExport
class JsFormat internal constructor(
    inner: JsonFormat,
) {
    val jwtVcJson: JsAlgFormat? by lazy {
        val innerJwtVcJson = inner.jwtVcJson
        if (innerJwtVcJson != null) {
            JsAlgFormat(innerJwtVcJson)
        } else {
            null
        }
    }
    val jwtVpJson: JsAlgFormat? by lazy {
        val innerJwtVpJson = inner.jwtVpJson
        if (innerJwtVpJson != null) {
            JsAlgFormat(innerJwtVpJson)
        } else {
            null
        }
    }
    val ldpVc: JsProofTypeFormat? by lazy {
        val innerLdpVc = inner.ldpVc
        if (innerLdpVc != null) {
            JsProofTypeFormat(innerLdpVc)
        } else {
            null
        }
    }
    val ldpVp: JsProofTypeFormat? by lazy {
        val innerLdpVc = inner.ldpVc
        if (innerLdpVc != null) {
            JsProofTypeFormat(innerLdpVc)
        } else {
            null
        }
    }
    val customFormats: JSObject by lazy {
        createJSObject(inner.customFormats)
    }
}

@JsExport
class JsAlgFormat internal constructor(
    inner: JsonAlgFormat,
) {
    val alg: Array<String> = inner.alg.toTypedArray()
}

@JsExport
class JsProofTypeFormat internal constructor(
    inner: JsonProofTypeFormat,
) {
    val proofType: Array<String> = inner.proofType.toTypedArray()
}


@JsExport
class JsInputDescriptor internal constructor(
    val inner: JsonInputDescriptor,
) {
    val id: String = inner.id
    val name: String? = inner.name
    val purpose: String? = inner.purpose
    val constraints = JsConstraint(inner.constraints)
    val format: JsFormat? by lazy {
        val innerFormat = inner.format
        if (innerFormat != null) {
            JsFormat(innerFormat)
        } else {
            null
        }
    }
}

@JsExport
class JsConstraint internal constructor(
    inner: JsonConstraints,
) {
    val fields: Array<JsField>? by lazy {
        val innerFields = inner.fields
        innerFields?.map { JsField(it) }?.toTypedArray()
    }
    val limitDisclosure: String? = inner.limitDisclosure
}

@JsExport
class JsField internal constructor(
    inner: JsonField,
) {
    val id: String? = inner.id
    val name: String? = inner.name
    val purpose: String? = inner.purpose
    val path: Array<String> = inner.path.toTypedArray()
    val filter: AbstractJsfilter? by lazy {
        val innerFilter = inner.filter
        if (innerFilter != null) {
            AbstractJsfilter.from(innerFilter)
        } else {
            null
        }
    }
    val optional: Boolean? = inner.optional
}

@JsExport
abstract class AbstractJsfilter {
    abstract val type: String

    companion object {
        internal fun from(jsonFilter: AbstractJsonFilter): AbstractJsfilter =
            when (jsonFilter) {
                is JsonStringFilter -> JsStringFilter(jsonFilter)
                is JsonArrayContainsFilter -> JsArrayContainsFilter(jsonFilter)
            }

    }
}

@JsExport
class JsStringFilter internal constructor(
    inner: JsonStringFilter,
) : AbstractJsfilter() {
    override val type: String = inner.type
    val pattern: String = inner.pattern
}

@JsExport
class JsArrayContainsFilter internal constructor(
    inner: JsonArrayContainsFilter,
) : AbstractJsfilter() {
    override val type: String = inner.type
    val contains = JsContains(inner.contains)
}

@JsExport
class JsContains internal constructor(
    inner: JsonContains,
) {
    val type: String = inner.type
    val pattern: String = inner.pattern
}

@JsExport
class JsVerifiablePresentationResponseParameters internal constructor(
    val inner: VerifiablePresentationResponseParameters,
)

@JsExport
class JsPresentationSubmission internal constructor(
    inner: JsonPresentationSubmission,
) {
    val id: String = inner.id
    val definitionId: String = inner.definitionId
    val descriptorMap: Array<JsDescriptorMapObject> by lazy {
        inner.descriptorMap.map { JsDescriptorMapObject(it) }.toTypedArray()
    }
}

@JsExport
class JsDescriptorMapObject internal constructor(
    inner: JsonDescriptorMapObject
) {
    val id: String = inner.id
    val format: String = inner.format
    val path: String = inner.path
    val pathNested: JsPathNested? = inner.pathNested?.let { JsPathNested(it) }
}

@JsExport
class JsPathNested internal constructor(
    inner: JsonPathNested
) {
    val format: String = inner.format
    val path: String = inner.path
}

@JsExport
class JsVerifierDirectPostAuthorizationResponse internal constructor(
    inner: VerifierDirectPostAuthorizationResponse,
) {
    val redirectUri: String? = inner.redirectUri
}

@JsExport
class JsUserSelectedCredentials(
    val data: Array<JsUserSelectedCredential>,
)

@JsExport
class JsUserSelectedCredential(
    val descriptor: JsInputDescriptor,
    val credential: JsUserVerifiableCredential,
    val presentationDisclosures: JsPresentationDisclosure,
)

@JsExport
class JsPresentationDisclosure(
    val disclosures: Array<String>,
)
