/*
 * Copyright (C) 2024 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

@file:OptIn(ExperimentalJsExport::class, DelicateCoroutinesApi::class)

// Because we want these symbols to be in the root package, for better usage from JS
@file:Suppress("PackageDirectoryMismatch")

import io.curity.vp.services.PresentationDisclosure
import io.curity.vp.services.PresentationResponseCreator
import io.curity.vp.services.UserSelectedCredential
import io.curity.vp.services.UserSelectedCredentials
import kotlinx.coroutines.DelicateCoroutinesApi
import kotlinx.coroutines.GlobalScope
import kotlin.js.Promise

/**
 * JavaScript-friendly Presentation Response Creator.
 *
 * Wraps [io.curity.vp.services.PresentationResponseCreator] with a JS API.
 */
@JsExport
class JsPresentationResponseCreator internal constructor(
    private val inner: PresentationResponseCreator
) {

    fun createVerifiablePresentationResponse(
        validatedRequestJwt: JsValidatedRequestJwt,
        userSelectedCredentials: JsUserSelectedCredentials
    ): Promise<JsVerifiablePresentationResponseParameters> {
        return GlobalScope.safePromise {
            val params = inner.createVerifiablePresentationResponse(
                validatedRequestJwt.inner,
                convertToUserSelectCredentials(userSelectedCredentials)
            )
            JsVerifiablePresentationResponseParameters(params)
        }
    }

    companion object {
        private fun convertToUserSelectCredentials(userSelectedCredentials: JsUserSelectedCredentials): UserSelectedCredentials {
            return UserSelectedCredentials(
                userSelectedCredentials.data.map {
                    UserSelectedCredential(
                        it.descriptor.inner,
                        it.credential.inner(),
                        PresentationDisclosure(it.presentationDisclosures.disclosures.toList())
                    )
                }
            )
        }
    }
}