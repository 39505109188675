/*
 * Copyright (C) 2023 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

package io.curity.vp.serialization

import io.curity.ssi.data.MultiValue
import io.curity.ssi.json.data.DefaultJsonMultiValue
import io.curity.ssi.json.data.FromJson
import io.curity.ssi.json.data.FromJsonHelper
import io.curity.ssi.json.data.JsonDictionary
import io.curity.ssi.json.data.ToJsonDictionary
import io.curity.ssi.json.data.toJson
import io.curity.ssi.json.data.unwrapOptionalString
import io.curity.ssi.json.data.unwrapStringList
import io.curity.vc.CredentialEndpoint
import io.curity.vc.serialization.ToJsonProof
import io.curity.vc.serialization.unwrapOptionalProofs
import io.curity.vc.serialization.unwrapOptionalVerifiableCredentials
import io.curity.vp.W3CVerifiablePresentation
import io.curity.vp.W3CVerifiablePresentation.Keys.CONTEXT
import io.curity.vp.W3CVerifiablePresentation.Keys.HOLDER
import io.curity.vp.W3CVerifiablePresentation.Keys.ID
import io.curity.vp.W3CVerifiablePresentation.Keys.PROOF
import io.curity.vp.W3CVerifiablePresentation.Keys.TYPE
import io.curity.vp.W3CVerifiablePresentation.Keys.VERIFIABLE_CREDENTIAL
import kotlinx.serialization.Serializable
import kotlinx.serialization.json.JsonArray
import kotlinx.serialization.json.JsonElement
import kotlinx.serialization.json.JsonObject
import kotlinx.serialization.json.JsonPrimitive

@Serializable(with = SerializableW3CVerifiablePresentationSerializer::class)
data class SerializableW3CVerifiablePresentation(
    override val id: String? = null,
    override val context: List<String>,
    override val type: List<String>,
    override val holder: String? = null,
    override val proof: MultiValue<out ToJsonProof>? = null,
    override val verifiableCredential: DefaultJsonMultiValue<out CredentialEndpoint.VerifiableCredential>? = null,
    val customClaims: ToJsonDictionary? = null,
) : ToJsonDictionary, W3CVerifiablePresentation<JsonElement> {

    companion object : FromJson<SerializableW3CVerifiablePresentation> {
        override fun fromJson(json: JsonElement): SerializableW3CVerifiablePresentation {
            val map = FromJsonHelper.requireJsonObject(json, "SerializableW3CVerifiablePresentation").toMutableMap()
            return SerializableW3CVerifiablePresentation(
                id = map.unwrapOptionalString(ID),
                context = map.unwrapStringList(CONTEXT),
                type = map.unwrapStringList(TYPE),
                holder = map.unwrapOptionalString(HOLDER),
                proof = map.unwrapOptionalProofs(PROOF),
                verifiableCredential = map.unwrapOptionalVerifiableCredentials(VERIFIABLE_CREDENTIAL),
                customClaims = if (map.isEmpty()) null else JsonDictionary(map.toMap())
            )
        }
    }

    private val _serializable by lazy {
        JsonDictionary(buildMap {
            if (customClaims != null) putAll(customClaims.toJson())
            if (id != null) put(ID, id.toJson())
            put(CONTEXT, JsonArray(context.map { it.toJson() }))
            put(TYPE, JsonArray(type.map { it.toJson() }))
            if (holder != null) put(HOLDER, holder.toJson())
            if (proof != null) put(PROOF, proof.toJson())
            if (verifiableCredential != null) {
                put(VERIFIABLE_CREDENTIAL, verifiableCredential.toJson {
                    when(it) {
                        is CredentialEndpoint.VerifiableCredential.StringCredential -> {
                            JsonPrimitive(it.value)
                        }
                        is CredentialEndpoint.VerifiableCredential.DictionaryCredential<*> -> {
                            JsonObject(it.value as Map<String, JsonElement>)
                        }
                    }
                })
            }
        })
    }

    override fun toJsonDictionary(): JsonDictionary = _serializable
}
