/*
 * Copyright (C) 2023 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import * as React from 'react';
import { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { routes } from '../routes';
import { QrScan } from '../components/icons';
import { Alert } from '../components/Alert';

export function Start() {
  const navigate = useNavigate();
  const location = useLocation();
  const [error, setError] = useState('');

  const onSubmit: React.FormEventHandler<HTMLFormElement> = ev => {
    ev.preventDefault();
    const request = new FormData(ev.currentTarget).get('url') as string;
    try {
      const queryString = new URL(request).searchParams.toString();
      // TODO HACK
      // We are using this extra initial navigation just to add history state to the offer/presentation screen.
      // (notice the {state: {request: request, replace: true})
      // This way, if the user presses back the app comes back to this screen with the last edited offer/presentation URL
      // Is there a better way to achieve this?
      navigate(routes.START, { state: { request: request, replace: true } });

      if (request.startsWith('openid-credential-offer://?')) {
        // Goes to the offer resolution path
        navigate(routes.TO_OFFER_RESOLVE(queryString));
      } else if (request.startsWith('web+haip://?')) {
        navigate(routes.TO_PRESENTATION_RESOLVE(queryString));
      } else {
        setError('Provided URL is not recognized as a valid offer or presentation request');
      }
    } catch (e) {
      setError(e.message);
    }
  };
  return (
    <div className="center">
      <h1>Start from a URL</h1>
      <p>Handle a credential offer or a presentation request by pasting a URL</p>
      <form onSubmit={onSubmit}>
        <textarea
          className="field w100"
          placeholder="Enter or paste a URL including the uri scheme"
          name="url"
          rows={5}
          cols={15}
          defaultValue={location.state?.request}
        />
        <button className="button button-medium button-primary w100 mb1" type="submit">
          Continue
        </button>
        {error && <Alert kind="danger" title="There was an error" errorMessage={error} />}
      </form>
      <span className="or mt2 mb2">or</span>
      <h1 className="h3">Scan a QR Code</h1>
      <p>Open the camera on your device to scan a QR Code.</p>
      <Link to={routes.SCAN} className="button button-medium button-primary-outline w100">
        <QrScan width="24" height="24" />
        Open camera
      </Link>
    </div>
  );
}
