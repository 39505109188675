/*
 * Copyright (C) 2024 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import {JsUserVerifiableCredential} from 'curity-ssi-libs-verifiable-credentials-vc-ks-services';

export function getCredentialSimpleLabel(credential: JsUserVerifiableCredential, languages: Array<string>): string {
  return credential.userDefinedName || getCredentialName(credential, languages);
}

function getCredentialName(credential: JsUserVerifiableCredential, languages: Array<string>): string {
  const didJwtVcJsonCredential = credential.credential.didJwtVcJson;
  if (didJwtVcJsonCredential) {
    return didJwtVcJsonCredential.credentialDisplay.getBestMatchForLocales(languages).name;
  } else {
    // TODO have a fallback value to show
    return "credential";
  }
}
