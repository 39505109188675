package io.curity.ssi.crypto

import io.curity.ssi.jose.DefaultVerificationKeySet
import io.curity.ssi.jose.Jose
import kotlinx.coroutines.await

actual interface VerificationKeySet {
    val keyStore: NodeJoseKeyStore
    actual companion object {
        actual suspend fun fromJwks(jwksString: String): VerificationKeySet {
            return DefaultVerificationKeySet(NodeJoseKeyStore(Jose.JWK.asKeyStore(jwksString).await()))
        }
    }
}