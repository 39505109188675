/*
 * Copyright (C) 2023 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import { Header } from '../header/Header';
import * as styles from './page.module.css';
import { ReactNode } from 'react';

export const Page = ({
  children,
  title,
  backButton,
}: {
  children: ReactNode;
  title: string;
  backButton?: string | -1;
}) => (
  <>
    <Header title={title} backButton={backButton} />
    <div className={`${styles['inner']} absolute p2`}>{children}</div>
  </>
);
