/*
 * Copyright (C) 2023 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import { Link, useLoaderData, useParams } from 'react-router-dom';
import {
  credentialIssuanceClient,
  JsCredentialIssuer,
  JsCredentialIssuerSupportedCredential,
  signingKeyPairPromise,
  verifiableCredentialStore,
} from '../ssi-libs';
import { CredentialSupported } from '../components/CredentialSupported';
import { Dispatch, useReducer } from 'react';
import { VerifiableCredential } from '../components/VerifiableCredential';
import { routes } from '../routes';
import { SuccessCheckmark } from '../components/SuccessCheckmark';
import { Spinner } from '../components/Spinner';
import { Alert } from '../components/Alert';
import {
  JsUserVerifiableCredential,
  newUserCancellationException,
} from 'curity-ssi-libs-verifiable-credentials-vc-ks-services';
import { logGAEvent } from '../util';

type State =
  | { state: 'begin'; errorMessage?: string }
  | { state: 'waiting-for-credential'; abortController: AbortController }
  | { state: 'waiting-for-cancel' }
  | { state: 'credential-issued'; credential: JsUserVerifiableCredential; credentialName: string }
  | { state: 'credential-stored'; credential: JsUserVerifiableCredential };

type Action =
  | { type: 'start-credential-request'; abortController: AbortController }
  | { type: 'cancel-credential-request' }
  | { type: 'error'; message: string }
  | { type: 'credential-issued'; credential: JsUserVerifiableCredential }
  | { type: 'credential-name-assigned'; credentialName: string }
  | { type: 'credential-stored' };

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'cancel-credential-request':
      return { state: 'waiting-for-cancel' };
    case 'start-credential-request':
      return {
        state: 'waiting-for-credential',
        abortController: action.abortController,
      };
    case 'error':
      if (state.state === 'waiting-for-cancel') {
        // an error is expected in this case
        return {
          state: 'begin',
        };
      }
      return {
        state: 'begin',
        errorMessage: action.message,
      };
    case 'credential-issued':
      return {
        state: 'credential-issued',
        credential: action.credential,
        credentialName: '',
      };

    case 'credential-name-assigned':
      if (state.state != 'credential-issued') {
        // don't do anything
        return state;
      }
      return { ...state, credentialName: action.credentialName };

    case 'credential-stored':
      if (state.state != 'credential-issued') {
        // don't do anything
        return state;
      }
      return {
        state: 'credential-stored',
        credential: state.credential,
      };
  }

  return state;
}

async function operStartCredentialRequest(
  issuer: JsCredentialIssuer,
  credential: JsCredentialIssuerSupportedCredential,
  dispatcher: Dispatch<Action>
) {
  const abortController = new AbortController();
  try {
    const signingKeyPair = await signingKeyPairPromise;
    dispatcher({ type: 'start-credential-request', abortController: abortController });
    const credentials = await credentialIssuanceClient.requestCredentials(
      issuer,
      credential,
      signingKeyPair,
      abortController.signal
    );
    const issuedCredential = credentials[0];
    if (!issuedCredential || credentials.length > 1) {
      dispatcher({ type: 'error', message: 'response must have a single credential' });
    }
    dispatcher({ type: 'credential-issued', credential: issuedCredential });
  } catch (error) {
    dispatcher({ type: 'error', message: error.message });
  }
}

function operCancelAuthorization(state: State, dispatcher: Dispatch<Action>) {
  if (state.state == 'waiting-for-credential') {
    dispatcher({ type: 'cancel-credential-request' });
    state.abortController.abort(newUserCancellationException());
  }
}

export function CredentialIssuance() {
  const issuer = useLoaderData() as JsCredentialIssuer | null;
  const [state, dispatcher] = useReducer(reducer, { state: 'begin' });

  const params = useParams();
  if (issuer == null) {
    return <p>No issuer found with provided identifier</p>;
  }
  const credentialSupported = issuer.credentialsSupported.get(params.credentialId);
  if (credentialSupported == null) {
    return <p>No supported credential found with provided identifier</p>;
  }
  return render(state, dispatcher, issuer, credentialSupported);
}

function render(
  state: State,
  dispatcher: Dispatch<Action>,
  issuer: JsCredentialIssuer,
  credentialSupported: JsCredentialIssuerSupportedCredential
) {
  switch (state.state) {
    case 'begin':
      return (
        <>
          <CredentialSupported supportedCredential={credentialSupported}>
            <button
              className="button button-white-outline button-fullwidth mt2"
              onClick={() => operStartCredentialRequest(issuer, credentialSupported, dispatcher)}
            >
              Request Credential
            </button>
          </CredentialSupported>
          {state.errorMessage && (
            <Alert kind="danger" title="Error" errorMessage={state.errorMessage} classes="mt2 mb2" />
          )}
          <p>
            During the credential request a browser window may be opened to perform authentication and credential
            issuance consent on the credential issuer.
          </p>
        </>
      );
    case 'waiting-for-credential':
      return (
        <>
          <CredentialSupported supportedCredential={credentialSupported}>
            <button
              className="button button-small button-white-outline button-fullwidth mt2"
              onClick={() => operCancelAuthorization(state, dispatcher)}
            >
              Cancel
            </button>
          </CredentialSupported>
          <Spinner />
        </>
      );
    case 'credential-issued':
      return (
        <>
          <h1>Do you want to save this credential in the wallet?</h1>
          <VerifiableCredential credential={state.credential} detailLevel="detailed" />
          <label htmlFor="optionalname" className="label block mt2">
            Name (Optional)
          </label>
          <input
            className="field w100"
            id="optionalname"
            type="text"
            value={state.credentialName}
            placeholder="Credential name"
            onChange={ev =>
              dispatcher({
                type: 'credential-name-assigned',
                credentialName: ev.target.value,
              })
            }
          />
          <button
            className="button button-primary button-fullwidth mt2"
            onClick={() => {
              const credential = state.credential;
              verifiableCredentialStore.insert(credential.withUserDefinedName(state.credentialName));
              logGAEvent('User actions', 'Credential', 'stored');
              dispatcher({
                type: 'credential-stored',
              });
            }}
          >
            Save Credential
          </button>
        </>
      );
    case 'credential-stored':
      return (
        <div className="center">
          <SuccessCheckmark />
          <h1>This credential is now stored in the wallet</h1>
          <Link className="button button-primary-outline button-medium w100 mb2" to={routes.HOME}>
            View my credentials
          </Link>
        </div>
      );
  }
}
