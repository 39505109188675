/*
 * Copyright (C) 2024 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

// Because we want these symbols to be in the root package, for better usage from JS
@file:Suppress("PackageDirectoryMismatch")

import io.curity.vc.serialization.JsonDeferredCredentialResponse
import io.curity.vc.serialization.JwtVcJsonCredentialResponse
import io.curity.vc.serialization.JwtVcJsonLdCredentialResponse
import io.curity.vc.serialization.JwtVcJsonLdVerifiableCredentialResponse
import io.curity.vc.serialization.JwtVcJsonVerifiableCredentialResponse
import io.curity.vc.serialization.LdpVcCredentialResponse
import io.curity.vc.serialization.LdpVcVerifiableCredentialResponse
import kotlinx.serialization.json.Json
import vc_js.ToJsonString

@OptIn(ExperimentalJsExport::class)
@JsExport
class JSDeferredJsonCredentialResponse internal constructor(
    private val _response: JsonDeferredCredentialResponse
) : ToJsonString {
    val acceptanceToken = _response.transactionId
    val cNonce = _response.cNonce
    val cNonceExpiresIn = _response.cNonceExpiresIn?.toInt()

    override fun toJson() = Json.encodeToString(JsonDeferredCredentialResponse.serializer(), _response)
}

@OptIn(ExperimentalJsExport::class)
@JsExport
class JSJwtVcJsonVerifiableCredentialResponse internal constructor(
    private val _response: JwtVcJsonVerifiableCredentialResponse
) : ToJsonString {
    val credential = _response.credential.value
    val cNonce = _response.cNonce
    val cNonceExpiresIn = _response.cNonceExpiresIn?.toInt()

    override fun toJson() = Json.encodeToString(JwtVcJsonVerifiableCredentialResponse.serializer(), _response)
}

@OptIn(ExperimentalJsExport::class)
@JsExport
class JSLdpVerifiableCredentialResponse internal constructor(
    private val _response: LdpVcVerifiableCredentialResponse
) : ToJsonString {
    val credential = JSW3CVerifiableCredential.from(_response.credential.value)
    val cNonce = _response.cNonce
    val cNonceExpiresIn = _response.cNonceExpiresIn?.toInt()

    override fun toJson() = Json.encodeToString(LdpVcVerifiableCredentialResponse.serializer(), _response)
}

@OptIn(ExperimentalJsExport::class)
@JsExport
class JSJwtVcJsonLdVerifiableCredentialResponse internal constructor(
    private val _response: JwtVcJsonLdVerifiableCredentialResponse
) : ToJsonString {
    val credential = _response.credential.value
    val cNonce = _response.cNonce
    val cNonceExpiresIn = _response.cNonceExpiresIn?.toInt()

    override fun toJson() = Json.encodeToString(JwtVcJsonLdVerifiableCredentialResponse.serializer(), _response)
}

/**
 * Convert Kotlin [JwtVcJsonCredentialResponse] to the equivalent JS type
 * `Either<JSJwtVcJsonVerifiableCredentialResponse, JSDeferredJsonCredentialResponse>`.
 *
 * Notice that [JwtVcJsonCredentialResponse] is a sealed interface, which is not supported
 * by TypeScript, hence the use of the [Either] type.
 */
fun convertJwtVcJsonResponse(
    response: JwtVcJsonCredentialResponse
): Either<JSJwtVcJsonVerifiableCredentialResponse, JSDeferredJsonCredentialResponse> {
    return when (response) {
        is JwtVcJsonCredentialResponse.Deferred -> Either.right(JSDeferredJsonCredentialResponse(response.value))

        is JwtVcJsonVerifiableCredentialResponse -> Either.left(JSJwtVcJsonVerifiableCredentialResponse(response))
    }
}

/**
 * Convert Kotlin [JwtVcJsonLdCredentialResponse] to the equivalent JS type
 * `Either<JSJwtVcJsonLdVerifiableCredentialResponse, JSDeferredJsonCredentialResponse>`.
 *
 * Notice that [JwtVcJsonLdCredentialResponse] is a sealed interface, which is not supported
 * by TypeScript, hence the use of the [Either] type.
 */
fun convertJwtVcJsonLdResponse(
    response: JwtVcJsonLdCredentialResponse
): Either<JSJwtVcJsonLdVerifiableCredentialResponse, JSDeferredJsonCredentialResponse> {
    return when (response) {
        is JwtVcJsonLdCredentialResponse.Deferred -> Either.right(JSDeferredJsonCredentialResponse(response.value))

        is JwtVcJsonLdVerifiableCredentialResponse -> Either.left(JSJwtVcJsonLdVerifiableCredentialResponse(response))
    }
}

/**
 * Convert Kotlin [LdpVcCredentialResponse] to the equivalent JS type
 * `Either<JSLdpVerifiableCredentialResponse, JSDeferredJsonCredentialResponse>`.
 *
 * Notice that [LdpVcCredentialResponse] is a sealed interface, which is not supported
 * by TypeScript, hence the use of the [Either] type.
 */
fun convertLdpVcJsonResponse(
    response: LdpVcCredentialResponse
): Either<JSLdpVerifiableCredentialResponse, JSDeferredJsonCredentialResponse> {
    return when (response) {
        is LdpVcCredentialResponse.Deferred -> Either.right(JSDeferredJsonCredentialResponse(response.value))
        is LdpVcVerifiableCredentialResponse -> Either.left(JSLdpVerifiableCredentialResponse(response))
    }
}
