.inner {
  inset: 48px 0;
  overflow: hidden auto;
}

.video {
  max-width: 100%;
  object-fit: cover;
  object-position: center;
  aspect-ratio: 4 / 3;
}
