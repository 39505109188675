/*
 * Copyright (C) 2023 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

package io.curity.ssi.crypto

import io.curity.ssi.jose.Jose
import io.curity.ssi.jose.JsonJwk
import io.curity.ssi.jose.NativeVerificationKey
import kotlinx.coroutines.await

actual interface VerificationKey {

    actual val jwk: JsonJwk

    actual companion object {
        actual suspend fun fromJwk(jwkString: String): VerificationKey = createJsVerificationKey(jwkString)

        suspend fun createJsVerificationKey(jwkString: String) = try {
            val key = Jose.JWK.asKey(jwkString).await()
            NativeVerificationKey(key)
        } catch (ex: Throwable) {
            throw CryptoException.jwkDoesNotContainVerificationKey(ex.message)
        }
    }
}