/*
 * Copyright (C) 2024 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

package io.curity.vc.serialization

import io.curity.ssi.json.data.FromJson
import io.curity.ssi.json.data.FromJsonHelper
import io.curity.ssi.json.data.JsonDictionary
import io.curity.ssi.json.data.ToJsonDictionary
import io.curity.ssi.json.data.toJson
import io.curity.ssi.json.data.unwrapOptionalBoolean
import io.curity.vc.AuthorizationDetail
import io.curity.vc.JwtVcJsonFormat
import io.curity.vc.VcSdJwtFormat
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.json.JsonElement

@Serializable
class VcSdJwtAuthorizationDetail private constructor(
    @SerialName(AuthorizationDetail.Keys.TYPE)
    override val type: String,
    @SerialName(AuthorizationDetail.Keys.FORMAT)
    override val format: String? = null,
    @SerialName(AuthorizationDetail.Keys.CREDENTIAL_CONFIGURATION_ID)
    override val credentialConfigurationId: String? = null,
    @SerialName(VcSdJwtFormat.AuthorizationDetail.Keys.VCT)
    override val vct: String? = null,
    @SerialName(VcSdJwtFormat.AuthorizationDetail.Keys.CLAIMS)
    override val claims: JsonClaims? = null
) : VcSdJwtFormat.AuthorizationDetail<JsonElement> {
    constructor(
        vct: String,
        claims: JsonClaims?
    ) : this(
        AuthorizationDetail.Values.TYPE_OPENID_CREDENTIAL, VcSdJwtFormat.FORMAT, null, vct, claims
    )

    @Serializable(with = VcSdJwtAuthorizationDetailClaimsSerializer::class)
    class JsonClaims(
        override val claims: Map<String, JsonClaimsItem>
    ) : ToJsonDictionary, VcSdJwtFormat.AuthorizationDetail.Claims<JsonElement> {
        companion object : FromJson<JsonClaims> {
            override fun fromJson(json: JsonElement): JsonClaims {
                val map = FromJsonHelper.requireJsonObject(json, "Claims")
                return JsonClaims(
                    claims = map.entries.associate { (key, value) ->
                        key to JsonClaimsItem.fromJson(value)
                    }
                )
            }
        }

        private val _serialized = lazy {
            JsonDictionary(
                claims.entries.associate { (key, value) -> key to value.toJsonDictionary().toJson() }
            )
        }

        override fun toJsonDictionary(): JsonDictionary = _serialized.value
    }

    @Serializable(with = VcSdJwtAuthorizationDetailClaimsItemSerializer::class)
    class JsonClaimsItem(
        override val mandatory: Boolean?,
        val customClaims: ToJsonDictionary? = null,
    ) : ToJsonDictionary, VcSdJwtFormat.AuthorizationDetail.ClaimsItem<JsonElement> {
        companion object : FromJson<JsonClaimsItem> {
            override fun fromJson(json: JsonElement): JsonClaimsItem {
                val map = FromJsonHelper.requireJsonObject(json, "ClaimsItem").toMutableMap()
                return JsonClaimsItem(
                    mandatory = map.unwrapOptionalBoolean(
                        VcSdJwtFormat.AuthorizationDetail.ClaimsItem.Keys.MANDATORY
                    ),
                    customClaims = if (map.isEmpty()) null else JsonDictionary(map.toMap()),
                )
            }
        }

        private val _serializable by lazy {
            JsonDictionary(buildMap {
                if (customClaims != null) {
                    putAll(customClaims.toJson())
                }
                if (mandatory != null) {
                    put(
                        JwtVcJsonFormat.AuthorizationDetailsCredentialDefinition.CredentialSubjectItem.Keys.MANDATORY,
                        mandatory.toJson()
                    )
                }

            })
        }

        override fun toJsonDictionary(): JsonDictionary = _serializable
    }
}

