import {
  JsCredentialIssuer,
  JsCredentialIssuerLoader,
  JsCredentialIssuerStore,
  Nullable,
} from 'curity-ssi-libs-verifiable-credentials-vc-ks-services';
import {addPreLoadedIssuer, getInitState} from './init';

/**
 * CredentialIssuerStore decorator used to preload issuers.
 * It supports deletion of a pre-loaded issuer, without automatically loading it again.
 * If the loading of a pre-loaded issuer fails, a new attempt will be done the next time the wallet is started.
 */
export class PreLoadedCredentialIssuerStore {
  constructor(
    private delegate: JsCredentialIssuerStore,
    private loader: JsCredentialIssuerLoader,
    private issuersToPreLoad: Array<string>
  ) {}

  async upsert(credentialIssuer: JsCredentialIssuer): Promise<boolean> {
    await this.ensureInit();
    return this.delegate.upsert(credentialIssuer);
  }

  async getAll(): Promise<Array<JsCredentialIssuer>> {
    await this.ensureInit();
    return this.delegate.getAll();
  }

  async getById(id: string): Promise<Nullable<JsCredentialIssuer>> {
    await this.ensureInit();
    return this.delegate.getById(id);
  }

  async removeById(id: string): Promise<boolean> {
    await this.ensureInit();
    return this.delegate.removeById(id);
  }

  private initPromise: Promise<void>;

  private async init(): Promise<void> {
    // gets the initialization state of the wallet and for each issuer to pre-load
    const initState = getInitState();
    for (const issuerId of this.issuersToPreLoad) {
      try {
        // checks if the issuer was already loaded in the past
        if (initState && initState.loadedIssuers.includes(issuerId)) {
          // already loaded, move to next
          continue;
        }
        // checks if the issuer is stored
        if (await this.delegate.getById(issuerId)) {
          // already exists, move to next
          continue;
        }
        // try to load the issuer
        const issuer = await this.loader.load(issuerId);
        await this.delegate.upsert(issuer);
        addPreLoadedIssuer(issuerId);
        console.log(`Pre-loaded issuer '${issuerId}'`);
      } catch (ex) {
        // unable to load or store an issuer, logging and moving to next
        console.log(`Unable to pre-load issuer '${issuerId}'`);
      }
    }
  }

  private async ensureInit() {
    if (!this.initPromise) {
      // the first one to arrive starts the initialization process
      this.initPromise = this.init();
    }
    // all the calls wait for the initialization process to complete
    await this.initPromise;
  }
}
