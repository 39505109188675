package io.curity.vc.serialization

import kotlinx.serialization.Serializable

@Serializable
data class ResolvedOffer(
    /** The original offer */
    val offer: JsonOffer,
    /** The offer issuer */
    val issuer: JsonCredentialIssuer,
    /** The credential supported entries pointed by the offer */
    val credentialsSupported: List<JsonCredentialConfigurationsSupported>,
) {
    /**
     * Returns information about the transaction code to use,
     * if there is a pre-authorized_code grand with this information
     */
    val txCode: JsonOffer.JsonGrants.JsonPreAuthorizedCode.JsonTxCode? =
        offer.grants?.preAuthorizedCode?.txCode
}
