/*
 * Copyright (C) 2023 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

package io.curity.ssi.jose

import io.curity.ssi.json.NativeToJsonSerializationAdapter
import io.curity.ssi.json.data.DefaultJsonMultiValue
import io.curity.ssi.json.data.FromJson
import io.curity.ssi.json.data.FromJsonHelper
import io.curity.ssi.json.data.JsonDictionary
import io.curity.ssi.json.data.ToJsonDictionary
import io.curity.ssi.json.data.toJson
import io.curity.ssi.json.data.unwrapJsonDictionary
import io.curity.ssi.json.data.unwrapOptionalJsonDictionary
import io.curity.ssi.json.data.unwrapOptionalLong
import io.curity.ssi.json.data.unwrapOptionalMultiValueString
import io.curity.ssi.json.data.unwrapOptionalString
import io.curity.ssi.json.data.unwrapOptionalStringList
import io.curity.ssi.json.data.unwrapString
import kotlinx.serialization.Serializable
import kotlinx.serialization.json.JsonElement
import kotlinx.serialization.json.JsonObject

/**
 * Sealed interface that mirrors [Jwt], using [JsonElement] as the serialization type.
 */
sealed interface JsonJwt {
    fun toJwt(): Jwt<JsonElement>

    @Serializable
    data class Jws(
        override val header: JsonJwsHeader? = null,
        override val payload: JsonJwtClaimsSet,
        override val unprotectedHeader: JsonJwsHeader? = null,
        override val signature: String? = null
    ) : Jwt.Jws<JsonElement>, JsonJwt {
        override fun toJwt(): Jwt<JsonElement> = this

        companion object {
            /**
             * Converts unsafe Maps expected to contain "native" data types into a safe [JsonJwt.Jws] object.
             */
            fun fromNativeMap(header: Map<String, *>, payload: Map<String, *>): Jws {
                val jsonHeader = NativeToJsonSerializationAdapter.DEFAULT.convertMap(header)
                val jsonPayload = NativeToJsonSerializationAdapter.DEFAULT.convertMap(payload)
                return Jws(
                    header = JsonJwsHeader.fromJson(JsonObject(jsonHeader)),
                    payload = JsonJwtClaimsSet.fromJson(JsonObject(jsonPayload))
                )
            }
        }
    }

    // TODO implement type conversion methods, add all fields mentioned in the spec etc...
    data class Jwe(
        override val payload: JwtClaimsSet<JsonElement>,
        override val header: JweHeader<JsonElement>
    ) : Jwt.Jwe<JsonElement>, JsonJwt {
        override fun toJwt(): Jwt<JsonElement> = this
    }
}

@Serializable(with = JsonJwsHeaderSerializer::class)
data class JsonJwsHeader(
    override val alg: String,
    override val typ: String? = null,
    override val cty: String? = null,
    override val jku: String? = null,
    override val jwk: ToJsonDictionary? = null,
    override val kid: String? = null,
    override val x5u: String? = null,
    override val x5c: String? = null,
    override val x5t: String? = null,
    override val x5tS256: String? = null,
    override val crit: Set<String>? = null,
    val customClaims: Map<String, JsonElement> = emptyMap(),
) : JwsHeader<JsonElement>, ToJsonDictionary {

    companion object : FromJson<JsonJwsHeader> {
        override fun fromJson(json: JsonElement): JsonJwsHeader {
            val map = FromJsonHelper.requireJsonObject(json, "JwsHeader").toMutableMap()
            return JsonJwsHeader(
                typ = map.unwrapOptionalString("typ"),
                cty = map.unwrapOptionalString("cty"),
                alg = map.unwrapString("alg"),
                jku = map.unwrapOptionalString("jku"),
                jwk = map.unwrapOptionalJsonDictionary("jwk"),
                kid = map.unwrapOptionalString("kid"),
                x5u = map.unwrapOptionalString("x5u"),
                x5c = map.unwrapOptionalString("x5c"),
                x5t = map.unwrapOptionalString("x5t"),
                x5tS256 = map.unwrapOptionalString("x5t#s256"),
                crit = map.unwrapOptionalStringList("crit")?.toSet(),
                customClaims = map.toMap(),
            )
        }
    }

    override fun toJsonDictionary(): JsonDictionary {
        return JsonDictionary(buildMap {
            putAll(customClaims)
            typ?.let { put("typ", it.toJson()) }
            cty?.let { put("cty", it.toJson()) }
            put("alg", alg.toJson())
            jku?.let { put("jku", it.toJson()) }
            jwk?.let { put("jwk", it.toJson()) }
            kid?.let { put("kid", it.toJson()) }
            x5u?.let { put("x5u", it.toJson()) }
            x5t?.let { put("x5t", it.toJson()) }
            x5tS256?.let { put("x5t#s256", it.toJson()) }
            crit?.let { put("crit", it.toJson()) }
        })
    }
}

@Serializable(with = JsonJwtClaimsSetSerializer::class)
data class JsonJwtClaimsSet(
    override val iss: String? = null,
    override val sub: String? = null,
    override val aud: DefaultJsonMultiValue<String>? = null,
    override val exp: Long? = null,
    override val nbf: Long? = null,
    override val iat: Long? = null,
    override val jti: String? = null,
    override val cnf: ToJsonDictionary? = null,
    val customClaims: Map<String, JsonElement> = emptyMap(),
) : JwtClaimsSet<JsonElement>, ToJsonDictionary {

    companion object : FromJson<JsonJwtClaimsSet> {
        override fun fromJson(json: JsonElement): JsonJwtClaimsSet {
            val map = FromJsonHelper.requireJsonObject(json, "JwsHeader").toMutableMap()
            return JsonJwtClaimsSet(
                iss = map.unwrapOptionalString("iss"),
                sub = map.unwrapOptionalString("sub"),
                aud = map.unwrapOptionalMultiValueString("aud"),
                exp = map.unwrapOptionalLong("exp"),
                nbf = map.unwrapOptionalLong("nbf"),
                iat = map.unwrapOptionalLong("iat"),
                jti = map.unwrapOptionalString("jti"),
                cnf = map.unwrapJsonDictionary("cnf"),
                customClaims = map.toMap(),
            )
        }
    }

    override fun toJsonDictionary(): JsonDictionary {
        return JsonDictionary(buildMap {
            putAll(customClaims)
            iss?.let { put("iss", it.toJson()) }
            sub?.let { put("sub", it.toJson()) }
            aud?.let { put("aud", it.toJson { item -> item.toJson() }) }
            exp?.let { put("exp", it.toJson()) }
            nbf?.let { put("nbf", it.toJson()) }
            iat?.let { put("iat", it.toJson()) }
            jti?.let { put("jti", it.toJson()) }
            cnf?.let { put("cnf", it.toJson()) }
        })
    }

}
